import axios from "axios";
import config from "../../../../config";

/*
  * getHostDetails() shows and dynamically renders the assign host modal
 */
export const getHostDetails = (accessToken, hostIDType, setHostDetails, setIsLoading) => {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
    };

    let data = {
        "host_id_type": hostIDType
    };

    setIsLoading(true);

    axios.post(
        config.request_license.HOST_TYPE_DETAILS,
        data,
        {headers: headers}
    )
        .then((response) => {
            if (response.status === 200) {
                let data = typeof response.data === 'undefined' ? [] : response.data;

                let isSerial = data.get_serial.toUpperCase() || "";
                let hostPatterns = data.host_id_info.patterns || [];
                let serialPatterns = data.serial_number_info.patterns || [];


                // cast pattern strings to regex
                // note: regex casting removes addtional \ character for all regex patterns
                // ex. ^\\w{1,20}$ becomes ^\w{1,20}$
                hostPatterns.length ? hostPatterns = hostPatterns.map(pattern => new RegExp(pattern))
                    : hostPatterns = [];
                serialPatterns.length ? serialPatterns = serialPatterns.map(pattern => new RegExp(pattern))
                    : serialPatterns = [];

                // if optional serial numbers append empty string pattern to valid serial number patterns
                if (isSerial === "O") {
                    let emptyPattern = new RegExp("^$");
                    serialPatterns.push(emptyPattern);
                }

                data.host_id_info.patterns = hostPatterns
                data.serial_number_info.patterns = serialPatterns
                setHostDetails(data)
            }
        })
        .catch((error) => {
            console.log("ERROR: Failed to POST Host Type Details", error);
        })
        .finally(() => {
            setIsLoading(false)
        });
}