import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import uuid from 'react-uuid';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/SyncLicense.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import {DateCell, NoWrapCell} from '../components/common/Grid.js';
import {EmailDialog} from '../components/common/EmailModal.js';
import Spinner from '../components/common/Spinner.js';
import Alert from '../components/common/Alert.js';
import {downloadLicenses} from '../components/common/utilities.js';

// kendo react
import {Upload} from '@progress/kendo-react-upload';
import {Button} from '@progress/kendo-react-buttons';
import {Input} from '@progress/kendo-react-inputs';
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {process} from '@progress/kendo-data-query';
import {Grid, GridColumn} from '@progress/kendo-react-grid';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    emailReceiveKey,
    emailSuccessKey,
    syncMoreKey,
    currentSupportEndDate,
    descriptionKey,
    downloadAllLicensesKey,
    mailAllKey,
    enterHostIdKey,
    genericErrorContactUsKey,
    syncFailedKey,
    searchKey,
    contactUsHeaderKey,
    genericErrorTitleKey,
    syncLicensesKey,
    searchHostSyncKey,
    hostKey,
    hostReadySyncKey,
    newSupportEndDateKey,
    hostNoExistKey,
    hostNoRemainingLicensesSyncKey,
    noLicensesSyncKey,
    addCertSyncKey,
    licenseNoAssociationKey,
    notSupportedSyncKey,
    orKey,
    productNumberKey,
    requestSyncKey,
    generatedRetrieveLicensesV2Key,
    syncedSuccessKey,
    syncedHostKey,
    generationTimeoutMessageV2Key,
    generationTimeoutKey,
    addRequestFileKey,
    syncUploaderKey,
    binFileMessageKey,
    invalidFileKey,
    confirmHostIdCorrectKey,
} from '../assets/text/MultilingualText.js';
import {Tooltip} from "@progress/kendo-react-tooltip";
import {DownloadIcon, IconButton, MailIcon} from "../components/common/icons";


// initial pagination setup
const initialGridState = {
    take: 20,
    skip: 0,
};

function SyncLicense(props) {
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showFileUploadError, setShowFileUploadError] = useState(false);
    const [showHostNotExistError, setShowHostNotExistError] = useState(false);
    const [showHostNotSupportError, setShowHostNotSupportError] = useState(false);
    const [showHostNotAssociatedError, setShowHostNotAssociatedError] = useState(false);
    const [showNoLicensesError, setShowNoLicensesError] = useState(false);
    const [showGeneralHostError, setShowGeneralHostError] = useState(false);
    const [hostID, setHostID] = useState('');
    const [searchHostID, setSearchHostID] = useState('');
    const [products, setProducts] = useState([]);
    const [productGridState, setProductGridState] = useState(initialGridState);
    const [productState, setProductState] = useState(
        process(products, initialGridState)
    );
    const [syncRequested, setSyncRequested] = useState(false);
    const [showGenerationSuccess, setShowGenerationSuccess] = useState(false);
    const [showSyncGeneralError, setShowSyncGeneralError] = useState(false);
    const [showGenerationTimeOutError, setShowGenerationTimeOutError] = useState(false);
    const [disableEmailDownloadButton, setDisableEmailDownloadButton] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [hostFile, setHostFile] = useState(null);
    const [transactionID, setTransactionID] = useState("");

    // generate license for request license
    async function requestSync() {
        resetAlerts();

        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let formData = new FormData();
        formData.append('File', hostFile);
        let data = {
            "transaction_id": transactionID,
            "hostid_type": products[0]['hostid_type'],
            "host_id": hostID,
        };

        formData.append('Data', JSON.stringify(data));

        setIsLoading(true);
        axios.post(
            config.sync_license.GENERATE_LICENSE,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setSyncRequested(true);
                    setShowGenerationSuccess(true);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to request new license", error.response);
                let errorSet = false;
                if (error.response.hasOwnProperty('data')) {
                    if (error.response.data.hasOwnProperty('error_code')) {
                        if (error.response.data['error_code'].toUpperCase() === 'MOD_SYNC_004') {
                            setShowSyncGeneralError(true);
                        }
                    }
                }
                if (!errorSet) {
                    setSyncRequested(true);
                    setShowGenerationTimeOutError(true);
                }
                setIsLoading(false);
            })
    }

    // closes the success general alert
    const alertGeneralSuccessHandler = () => {
        setShowGenerationSuccess(false);
    }

    // closes the success email alert
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    // closes the success email alert
    const alertTimeOutHandler = () => {
        setShowGenerationTimeOutError(false);
    }

    // disable email and download button if license generation errors out
    useEffect(() => {
        if (showGenerationTimeOutError) {
            setDisableEmailDownloadButton(true);
        }
        if (showGenerationSuccess) {
            setDisableEmailDownloadButton(false);
        }
    }, [showGenerationTimeOutError, showGenerationSuccess])

    // reset all alerts to be hidden
    const resetAlerts = () => {
        setShowFileUploadError(false);
        setShowHostNotExistError(false);
        setShowHostNotSupportError(false);
        setShowHostNotAssociatedError(false);
        setShowSyncGeneralError(false);
        setShowGenerationSuccess(false);
        setShowGenerationTimeOutError(false);
        setDisableEmailDownloadButton(false);
        setShowEmailSuccess(false);
        setShowGeneralHostError(false);
        setShowNoLicensesError(false);
    }

    // change enter host id input field
    const onChangeHostID = (e) => {
        setSearchHostID(e.value);
    }

    const allowedExtensions = {
        allowedExtensions: [".bin"]
    };

    // on host file upload on add event
    const onHostFileUpload = (event) => {
        resetAlerts();
        setHostID('');
        setSearchHostID('');
        setProducts([]);
        setFiles(event.newState);
        if (allowedExtensions.allowedExtensions.includes(event.newState[0].extension)) {
            setIsLoading(true);
            setHostFile(event.newState[0].getRawFile());
        } else {
            setShowFileUploadError(true);
            setHostFile(null);
        }
    }

    // uploader remove event
    const onRemove = () => {
        resetAlerts();
        setFiles(null);
    };

    // gets the return status from the file uploader api call
    const onStatusChange = (event) => {
        setFiles(event.newState);
        const response = event.response.response;

        if (event.response.status === 200) {
            successSearch(response);
        } else {
            errorSearch(response);
        }
    };

    // sort, filter and pagination for product state
    const onProductStateChange = (event) => {
        if (event.dataState.sort === undefined) {
            event.dataState.sort = [];
        }

        const newProductState = process(
            products,
            event.dataState
        );
        setProductGridState(event.dataState);
        setProductState(newProductState);
    };

    // search Host ID from the input field
    const uploadHostID = () => {
        resetAlerts('');
        setHostID('');
        setProducts([]);
        onRemove();

        if (searchHostID.trim() === '') {
            setShowHostNotExistError(true);
            return null;
        }

        setIsLoading(true);

        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let formData = new FormData();
        formData.append('HostID', searchHostID.trim());

        axios.post(
            config.sync_license.SEARCH_SYNC,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    successSearch(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
                errorSearch(error.response);
            });
    }

    // process successful search api calls
    const successSearch = (body) => {
        body['products'].forEach(product => {
            if (product['current_support_expiration_date'] !== null) {
                product['current_support_expiration_date'] = new Date(product['current_support_expiration_date']);
            }
            if (product['new_support_expiration_date'] !== null) {
                product['new_support_expiration_date'] = new Date(product['new_support_expiration_date']);
            }
        })
        setProducts(body['products'] ?? []);
        setProductState(process(body['products'] ?? [], initialGridState));
        setHostID(body['host_id']);
        setTransactionID(uuid());
        setIsLoading(false);
    }

    // process error api calls
    const errorSearch = (error) => {
        console.log("ERROR: Failed to search host id", error);
        resetAlerts();
        let errorCode = '';
        if (error.hasOwnProperty('data')) {
            if (error.data.hasOwnProperty('error_code')) {
                errorCode = error.data['error_code'].toUpperCase();
                switch (errorCode) {
                    case "MOD_SYNC_001":
                        // host does not exist
                        setShowHostNotExistError(true);
                        break;
                    case "MOD_SYNC_002":
                        // does not support sync
                        setShowHostNotSupportError(true);
                        break;
                    case "MOD_SYNC_003":
                        // not associated to profile
                        setShowHostNotAssociatedError(true);
                        break;
                    case "MOD_SYNC_005":
                        // no licenses available
                        setShowNoLicensesError(true);
                        break;
                    case "MOD_209":
                        // invalid file
                        setShowFileUploadError(true);
                        break;
                    default:
                        errorCode = '';
                        break;
                }
            }
        }

        if (errorCode === '') {
            setShowGeneralHostError(true);
        }
        setHostFile(null);
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <div
                    className={"k-h3"}
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    {localization.toLanguageString(syncLicensesKey, mainMessages[siteLanguageDefault][syncLicensesKey])}
                </div>
                {!syncRequested && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                gap: '0.938rem',
                                marginBottom: '0.938rem'
                            }}
                        >
                            <div
                                style={{
                                    flexGrow: '1'
                                }}
                            >
                                <div className={'k-h5'}>
                                    {localization.toLanguageString(syncUploaderKey, mainMessages[siteLanguageDefault][syncUploaderKey])}
                                </div>
                                <Upload
                                    className={'ksm-upload'}
                                    multiple={false}
                                    restrictions={allowedExtensions}
                                    files={files}
                                    saveField={'File'}
                                    saveMethod={'POST'}
                                    saveHeaders={{'Authorization': 'Bearer ' + accessToken}}
                                    responseType={'json'}
                                    withCredentials={false}
                                    saveUrl={config.sync_license.SEARCH_SYNC}
                                    onAdd={onHostFileUpload}
                                    onRemove={onRemove}
                                    onStatusChange={onStatusChange}
                                    selectMessageUI={() =>
                                        <Button
                                            themeColor={"primary"}
                                            size={"large"}
                                            fillMode={"solid"}
                                            type={"button"}
                                        >
                                            {localization.toLanguageString(addRequestFileKey, mainMessages[siteLanguageDefault][addRequestFileKey])}
                                        </Button>
                                    }
                                />
                            </div>
                            <div
                                className={'k-h5'}
                                style={{
                                    textAlign: 'center',
                                    width: '4.375rem',
                                    marginTop: '3.75rem'
                                }}
                            >
                                {localization.toLanguageString(orKey, mainMessages[siteLanguageDefault][orKey])}
                            </div>
                            <div>
                                <div className={'k-h5'}>
                                    {localization.toLanguageString(searchHostSyncKey, mainMessages[siteLanguageDefault][searchHostSyncKey])}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        marginTop: '1.25rem',
                                        alignItems: 'end',
                                    }}
                                >
                                    <div>
                                        <div>
                                            {localization.toLanguageString(enterHostIdKey, mainMessages[siteLanguageDefault][enterHostIdKey])}
                                        </div>
                                        <Input
                                            value={searchHostID}
                                            onChange={onChangeHostID}
                                            style={{
                                                width: '18.75rem',
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            themeColor={"primary"}
                                            size={"large"}
                                            fillMode={"solid"}
                                            type={"button"}
                                            onClick={uploadHostID}
                                        >
                                            {localization.toLanguageString(searchKey, mainMessages[siteLanguageDefault][searchKey])}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '32.313rem'
                                }}
                            >
                                {showFileUploadError && (
                                    <Alert
                                        type={'error'}
                                        title={localization.toLanguageString(invalidFileKey, mainMessages[siteLanguageDefault][invalidFileKey])}
                                        message={localization.toLanguageString(binFileMessageKey, mainMessages[siteLanguageDefault][binFileMessageKey])}
                                    />
                                )}
                                {showNoLicensesError && (
                                    <Alert
                                        type={'error'}
                                        title={localization.toLanguageString(noLicensesSyncKey, mainMessages[siteLanguageDefault][noLicensesSyncKey])}
                                        message={localization.toLanguageString(hostNoRemainingLicensesSyncKey, mainMessages[siteLanguageDefault][hostNoRemainingLicensesSyncKey])}
                                    />
                                )}
                                {showHostNotExistError && (
                                    <Alert
                                        type={'error'}
                                        title={localization.toLanguageString(hostNoExistKey, mainMessages[siteLanguageDefault][hostNoExistKey])}
                                        message={localization.toLanguageString(confirmHostIdCorrectKey, mainMessages[siteLanguageDefault][confirmHostIdCorrectKey])}
                                    />
                                )}
                                {showHostNotSupportError && (
                                    <Alert
                                        type={'error'}
                                        title={localization.toLanguageString(notSupportedSyncKey, mainMessages[siteLanguageDefault][notSupportedSyncKey])}
                                        message={localization.toLanguageString(confirmHostIdCorrectKey, mainMessages[siteLanguageDefault][confirmHostIdCorrectKey])}
                                    />
                                )}
                                {showHostNotAssociatedError && (
                                    <Alert
                                        type={'error'}
                                        title={localization.toLanguageString(licenseNoAssociationKey, mainMessages[siteLanguageDefault][licenseNoAssociationKey])}
                                        message={localization.toLanguageString(addCertSyncKey, mainMessages[siteLanguageDefault][addCertSyncKey])}
                                    />
                                )}
                                {showGeneralHostError && (
                                    <Alert
                                        type={'error'}
                                        title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                        message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
                {!syncRequested && products.length !== 0 && (
                    <div
                        className={"k-h5"}
                        style={{
                            marginBottom: '0.938rem'
                        }}
                    >
                        {localization.toLanguageString(hostReadySyncKey, mainMessages[siteLanguageDefault][hostReadySyncKey])}
                    </div>
                )}
                {syncRequested && (
                    <div
                        className={"k-h5"}
                        style={{
                            marginBottom: '0.938rem'
                        }}
                    >
                        {localization.toLanguageString(syncedHostKey, mainMessages[siteLanguageDefault][syncedHostKey])}
                    </div>
                )}
                {products?.length !== 0 && (
                    <>
                        <PanelBar
                            expanded={['.0']}
                            isControlled={true}
                            className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                            style={{
                                marginBottom: '0.938rem'
                            }}
                        >
                            <PanelBarItem
                                title={localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey]) + " " + hostID}
                            >
                                <Grid
                                    className={'sync-grid'}
                                    onDataStateChange={onProductStateChange}
                                    total={products?.length}
                                    data={productState}
                                    scrollable={'none'}
                                    pageable={products?.length <= initialGridState.take ? false : {
                                        info: true,
                                        previousNext: true,
                                        pageSizes: [10, 20, 50, 100],
                                    }}
                                    {...productGridState}
                                >
                                    <GridColumn
                                        field="product_number"
                                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                        cell={NoWrapCell}
                                    />
                                    <GridColumn
                                        field="product_desc"
                                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                    />
                                    <GridColumn
                                        field="current_support_expiration_date"
                                        title={localization.toLanguageString(currentSupportEndDate, mainMessages[siteLanguageDefault][currentSupportEndDate])}
                                        cell={DateCell}
                                    />
                                    <GridColumn
                                        field="new_support_expiration_date"
                                        title={localization.toLanguageString(newSupportEndDateKey, mainMessages[siteLanguageDefault][newSupportEndDateKey])}
                                        cell={DateCell}
                                    />
                                </Grid>
                            </PanelBarItem>
                        </PanelBar>
                        {!syncRequested && (
                            <Row>
                                <Col/>
                                <Col>
                                    {showSyncGeneralError && (
                                        <div
                                            style={{
                                                width: '29.563rem'
                                            }}
                                        >
                                            <Alert
                                                type={'error'}
                                                title={<div
                                                    style={{
                                                        whiteSpace: 'pre-line'
                                                    }}
                                                >
                                                    {localization.toLanguageString(syncFailedKey, mainMessages[siteLanguageDefault][syncFailedKey])}
                                                </div>}
                                                message={localization.toLanguageString(genericErrorContactUsKey, mainMessages[siteLanguageDefault][genericErrorContactUsKey])}
                                            />
                                        </div>

                                    )}
                                </Col>
                                <Col>
                                    <Button
                                        themeColor={"primary"}
                                        size={"large"}
                                        shape={"rectangle"}
                                        fillMode={"solid"}
                                        rounded={"small"}
                                        onClick={requestSync}
                                        style={{
                                            float: 'right',
                                        }}
                                    >
                                        {localization.toLanguageString(requestSyncKey, mainMessages[siteLanguageDefault][requestSyncKey])}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        {syncRequested && (
                            <>
                                <Row
                                    style={{
                                        marginBottom: '0.938rem'
                                    }}
                                >
                                    <Col>
                                        <Button
                                            themeColor={"primary"}
                                            size={"large"}
                                            shape={"rectangle"}
                                            fillMode={"outline"}
                                            rounded={"small"}
                                            onClick={() => {
                                                setSyncRequested(false);
                                                setProducts([]);
                                                setHostID("");
                                                setSearchHostID('');
                                                setFiles(null);
                                            }}
                                            style={{
                                                marginBottom: '0.938rem'
                                            }}
                                        >
                                            {localization.toLanguageString(syncMoreKey, mainMessages[siteLanguageDefault][syncMoreKey])}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '0.938rem'
                                            }}
                                        >
                                            {showGenerationSuccess && (
                                                <Alert
                                                    type={'success'}
                                                    showHandler={alertGeneralSuccessHandler}
                                                    title={localization.toLanguageString(syncedSuccessKey, mainMessages[siteLanguageDefault][syncedSuccessKey])}
                                                    message={localization.toLanguageString(generatedRetrieveLicensesV2Key, mainMessages[siteLanguageDefault][generatedRetrieveLicensesV2Key])}
                                                />
                                            )}
                                            {showEmailSuccess && (
                                                <Alert
                                                    type={'success'}
                                                    showHandler={alertEmailSuccessHandler}
                                                    title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                                    message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                                                />
                                            )}

                                            {showGenerationTimeOutError && (
                                                <Alert
                                                    type={'error'}
                                                    showHandler={alertTimeOutHandler}
                                                    title={localization.toLanguageString(generationTimeoutKey, mainMessages[siteLanguageDefault][generationTimeoutKey])}
                                                    message={localization.toLanguageString(generationTimeoutMessageV2Key, mainMessages[siteLanguageDefault][generationTimeoutMessageV2Key])}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                                flexWrap: 'wrap',
                                                gap: '0.5rem'
                                            }}
                                        >
                                            <Tooltip
                                                anchorElement="target"
                                                showCallout={true}
                                                parentTitle={true}
                                                openDelay={0}
                                                position="left"
                                            >
                                                <IconButton
                                                    title={localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
                                                    themeColor={"primary"}
                                                    fillMode={"solid"}
                                                    size={"large"}
                                                    disabled={disableEmailDownloadButton}
                                                    onClick={() => {
                                                        setShowEmailModal(true)
                                                    }}
                                                    Icon={MailIcon}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                anchorElement="target"
                                                showCallout={true}
                                                parentTitle={true}
                                                openDelay={0}
                                                position="left"
                                            >
                                                <IconButton
                                                    title={localization.toLanguageString(downloadAllLicensesKey, mainMessages[siteLanguageDefault][downloadAllLicensesKey])}
                                                    themeColor={"primary"}
                                                    fillMode={"solid"}
                                                    size={"large"}
                                                    onClick={() => {
                                                        downloadLicenses([transactionID], accessToken, setIsLoading);
                                                    }}
                                                    disabled={disableEmailDownloadButton}
                                                    Icon={DownloadIcon}
                                                />
                                            </Tooltip>
                                        </div>
                                    </Col>
                                </Row>
                                {showEmailModal && (
                                    <EmailDialog
                                        setShowEmailModal={setShowEmailModal}
                                        setShowEmailSuccess={setShowEmailSuccess}
                                        transactionIDs={[transactionID]}
                                        setIsLoading={setIsLoading}
                                        request_source='SYNC_LICENSE'
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default SyncLicense;
