export const requestQtyValidator = (requestQty, min, max) => {
    const isNull = requestQty == null;
    const isGTE = requestQty > max;
    const isLTE = requestQty < min;

    if (isNull) {
        return [false, min];
    }
    if (isGTE) {
        return [false, max];
    }
    if (isLTE) {
        return [false, min];
    }
    return [true, requestQty];
}

export const nameValidator = (name) => {
    return Boolean(name);
}

export const addressLine1Validator = (addr) => {
    return Boolean(addr);
}

export const cityValidator = (city) => {
    return Boolean(city);
}

export const stateValidator = (state) => {
    return Boolean(state);
}

export const postalValidator = (postal) => {
    return Boolean(postal)
}

export const countryValidator = (country) => {
    return Boolean(country)
}

export const phoneValidator = (phone) => {
    return Boolean(phone)
}