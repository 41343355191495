import React, {useContext, useState} from "react";
import UserContext from "../common/UserContext";

//multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {mainMessages, reviewAndSubmitKey} from "../../assets/text/MultilingualText";

//components
import ReviewBanked from "./ReviewSubmit/ReviewBanked";
import SubmitBanked from "./ReviewSubmit/SubmitBanked";
import ReviewTransport from "./ReviewSubmit/ReviewTransport";
import SubmitTransport from "./ReviewSubmit/SubmitTransport";

export default function ReviewSubmit(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        xAuthToken,
        handleBeforeUnload,
        setIsLoading,
        transport,
        assigned,
        setAssigned,
        setNoLongerValid,
        setGeneralError,
        setSaveError
    } = props

    const [review, setReview] = useState(true)


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.938rem',
        }}>
            <div className={"k-h5"}>
                {localization.toLanguageString(reviewAndSubmitKey, mainMessages[siteLanguageDefault][reviewAndSubmitKey])}
            </div>

            {review && !transport && (
                <ReviewBanked
                    assigned={assigned}
                    setReview={setReview}
                />
            )}

            {!review && !transport && (
                <SubmitBanked
                    assigned={assigned}
                    xAuthToken={xAuthToken}
                    setIsLoading={setIsLoading}
                    setNoLongerValid={setNoLongerValid}
                    setGeneralError={setGeneralError}
                    setSaveError={setSaveError}
                />
            )}

            {review && transport && (
                <ReviewTransport
                    assigned={assigned}
                    setReview={setReview}
                />
            )}

            {!review && transport && (
                <SubmitTransport
                    setIsLoading={setIsLoading}
                    handleBeforeUnload={handleBeforeUnload}
                    assigned={assigned}
                    setAssigned={setAssigned}
                    xAuthToken={xAuthToken}
                />
            )}
        </div>
    )
}
