import React, {useContext, useEffect, useRef, useState} from 'react';
import config from '../../config.js';
import UserContext from './UserContext.js';

// css
import '../../assets/css/Header.css';

// react router
import {useHistory, useLocation} from 'react-router-dom';

// kendo react
import {Popup} from "@progress/kendo-react-popup";
import {Button} from "@progress/kendo-react-buttons";

// components
import {agLocaleMap, setCookie} from "../../utilities.js";

// svgs
import keysightLogoShorter from '../../assets/icons/keysight-logo-shorter.svg';
import globeImage from '../../assets/icons/icon-globe.svg';
import loggedOutImage from '../../assets/icons/util-login-active.svg';
import loggedInImage from '../../assets/icons/util-loggedin-active.svg';
import loggedInBeforeImage from '../../assets/icons/util-loginrem-active.svg';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {AnchorWrap, Text} from './MultilingualText.js';
import {
    contactUsKey,
    morePeriodsKey,
    loginKey,
    logoutKey,
    myProfileKey,
    registerKey,
    welcomeKey,
    mainMessages,
} from '../../assets/text/MultilingualText.js';
import {Menu} from "@progress/kendo-react-layout";
import {HomeIcon, WarningErrorIcon} from "./icons";

function Header(props) {
    const {setSiteLanguage} = props;
    const {
        userEmail,
        agLocale,
        agrlCookie,
        userCredentialsCookie,
        siteLanguageDefault,
        userName,
        lacFlag,
        mainMenu,
    } = useContext(UserContext);

    const localization = useLocalization();
    const AnchorText = AnchorWrap(Text);
    const [loginStatus, setLoginStatus] = useState('loggedOut');
    const [loginImageUrl, setLoginImageUrl] = useState(`url(${loggedOutImage})`);

    useEffect(() => {
        if (agrlCookie && userCredentialsCookie === "VALID") {
            setLoginStatus("loggedIn");
            setLoginImageUrl(`url(${loggedInImage})`);
        } else if (agrlCookie && !userCredentialsCookie) {
            setLoginStatus("loggedInBefore");
            setLoginImageUrl(`url(${loggedInBeforeImage})`);
        }
    }, [agrlCookie, userCredentialsCookie]);

    // add js function to grab cookie and set which image
    const anchorRefLogin = useRef();
    const contentRefLogin = useRef();
    const blurTimeoutRefLogin = useRef();
    const [showPopupLogin, setShowPopupLogin] = useState(false);
    const onOpenLogin = () => {
        contentRefLogin.current.focus();
    }
    const onFocusLogin = () => {
        // user is still inside the content
        clearTimeout(blurTimeoutRefLogin.current);
    }
    const onBlurTimeoutLogin = () => {
        setShowPopupLogin(false);
    }
    const onBlurLogin = () => {
        // timeout avoids race condition when blur happens on icon click
        clearTimeout(blurTimeoutRefLogin.current);
        blurTimeoutRefLogin.current = setTimeout(onBlurTimeoutLogin, 200);
    }
    const onClickButtonLogin = () => {
        setShowPopupLogin(!showPopupLogin);
    };
    const onClickCloseLogin = () => {
        onBlurLogin();
    }

    const globeImageUrl = "url(" + globeImage + ")";
    const anchorRefGlobe = useRef();
    const contentRefGlobe = useRef();
    const blurTimeoutRefGlobe = useRef();
    const [showPopupGlobe, setShowPopupGlobe] = useState(false);
    const onOpenGlobe = () => {
        contentRefGlobe.current.focus();
    }
    const onFocusGlobe = () => {
        // user is still inside the content
        clearTimeout(blurTimeoutRefGlobe.current);
    }
    const onBlurTimeoutGlobe = () => {
        setShowPopupGlobe(false);
    }
    const onBlurGlobe = () => {
        clearTimeout(blurTimeoutRefGlobe.current);
        blurTimeoutRefGlobe.current = setTimeout(onBlurTimeoutGlobe, 200);
    }
    const onClickButtonGlobe = () => {
        setShowPopupGlobe(!showPopupGlobe);
    };
    const onClickCloseGlobe = () => {
        onBlurGlobe();
    }

    const [countryOptions, setCountryOptions] = useState([
        ['China', '中国', 'CNchi', 'active'],
        ['Japan', '日本', 'JPjpn', 'active'],
        ['Traditional Chinese', '繁體中文', 'TWcht', 'active'],
        ['Korea', '한국', 'KRkor', 'active'],
        ['Russia', 'Россия', 'RUrus', 'active'],
        ['Brazil', 'Brasil', 'BRpor', 'active'],
        ['Canada', 'Canada', 'CAeng', 'active'],
        ['Germany', 'Deutschland', 'DEger', 'active'],
        ['France', 'France', 'FRfre', 'active'],
        ['India', 'India', 'INeng', 'active'],
        ['Malaysia', 'Malaysia', 'MYeng', 'active'],
        ['United Kingdom', 'United Kingdom', 'GBeng', 'active'],
        ['United States', 'United States', 'USeng', 'active'],
    ]);

    const convertLocaleToCountry = () => {
        let country = "Worldwide";
        let options = countryOptions;
        if (agLocaleMap[agLocale]) {
            country = agLocaleMap[agLocale][1];
        }
        options.forEach((option) => {
            if (country === option[0]) {
                country = option[1];
                option[3] = 'inactive';
            }
        });
        setCountryOptions(options);
        return country;
    }

    const [currentCountry, setCurrentCountry] = useState(convertLocaleToCountry);

    useEffect(() => {
        anchorRefGlobe.current.title = currentCountry;
    }, [currentCountry]);

    const onChangeCountry = (event) => {
        let selectedCountry = event.target.title;
        let currentOptions = countryOptions;
        currentOptions.forEach((option) => {
            // add currentCountry back to dropdown options
            if (option[1] === currentCountry) {
                option[3] = "active";
            }
            // update currentCountry with selected and remove option from dropdown
            if (option[0] === selectedCountry) {
                option[3] = "inactive";
                setCurrentCountry(option[1]);
                setCountryOptions(currentOptions);
                // update browser AG_Locale Cookie
                setCookie("AG_LOCALE", option[2], 365);
                // update siteCountry state
                // grab country from aglocalemap
                let language = agLocaleMap[option[2]][0].slice(-2);
                setSiteLanguage(language);
            }
        });
        setShowPopupGlobe(false);
    }

    const ksmStage = process.env.REACT_APP_STAGE
    const ksmVersion = process.env.REACT_APP_KSM_VERSION

    let history = useHistory();
    let location = useLocation();

    const [items, setItems] = useState([])
    const onSelect = (event) => {
        if (event.item.childItem) {
            const actionLink = event.item.data.actionLink
            if (actionLink.indexOf('http') > -1) {
                window.location.href = actionLink;
            } else {
                const pathname = location.pathname.toLowerCase()
                if(pathname === actionLink.toLowerCase()){
                    history.push('/reload?path=' +  actionLink)
                } else {
                    history.push(actionLink);
                }
            }
        }
    }

    useEffect(() => {
        let header = mainMenu.map((item) => {
            const result = {
                text: localization.toLanguageString(item.titleKey, mainMessages[siteLanguageDefault][item.titleKey]).toUpperCase(),
                childItem: false,
            };

            // convert children items to menu items
            if (item['SUBMENU']) {
                result.items = item['SUBMENU'].map((childItem) => {
                    return {
                        childItem: true,
                        text: localization.toLanguageString(childItem.titleKey, mainMessages[siteLanguageDefault][childItem.titleKey]),
                        data: {actionLink: childItem.actionLink}
                    }
                })
            }
            return result;
        })
        setItems(header)
    }, [mainMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    const [showHeader, setShowHeader] = useState(true)
    useEffect(() => {
        //anonymous pages
        let pathName = location.pathname.toLowerCase()
        if (pathName === "/sign-in" ||
            pathName === "/reactivate" ||
            pathName === "/transport" ||
            pathName === "/trial-license") {
            setShowHeader(false)
        } else {
            setShowHeader(mainMenu.length !== 0)
        }
    }, [location, mainMenu])

    return (
        <>
            <div
                style={{
                    borderBottom: "0.063rem solid var(--color-border-primary)",
                    boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                }}
            >
                <div
                    style={{
                        marginLeft: "5.063rem",
                        marginRight: "6.25rem"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: '13.25rem',
                                height: '4.6875rem'
                            }}>
                            <a
                                href={config.keysight}
                                title={"Keysight"}
                            >
                                <img
                                    title={'Keysight'}
                                    src={keysightLogoShorter}
                                    alt={"Keysight Technologies"}
                                />
                            </a>
                        </div>
                        {ksmStage !== 'prod' && (
                            <div
                                className='k-messagebox'
                                style={{
                                    background: "var(--color-alert-warning-light)",
                                    border: "none",
                                    boxSizing: "border-box",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "40%",
                                    height: '3rem',
                                    margin: '0 0 0 8.875rem',
                                    padding: '1rem',
                                    minWidth: '20rem',
                                    gap: '0.5rem',
                                }}
                            >
                                <WarningErrorIcon
                                    size={'large'}
                                    style={{
                                        color: 'var(--color-alert-warning-base)',
                                        alignSelf: 'center',
                                    }}
                                />
                                <span style={{
                                    alignSelf: 'center',
                                    color: 'var(--color-alert-warning-dark)'
                                }}>
                                    <b style={{fontWeight: '600'}}>Test System</b> Keysight Software Manager ( KSM {ksmVersion} )
                                </span>
                            </div>
                        )}
                        <div>
                            <div
                                style={{
                                    "display": "flex",
                                    "alignItems": "center",
                                    "justifyContent": "end"
                                }}
                            >
                                <div
                                    style={{
                                        "display": "flex"
                                    }}
                                >
                                    <div>
                                        {userName && (
                                            <div style={{
                                                color: 'var(--color-text-primary)',
                                                fontWeight: '700',
                                                fontSize: '0.875rem',
                                            }}>
                                                <Text
                                                    textkey={welcomeKey}
                                                    textdefault={mainMessages[siteLanguageDefault][welcomeKey]}
                                                />
                                                {' ' + userName}
                                            </div>
                                        )}
                                    </div>
                                    <span className={"header-separator"} style={{
                                        marginRight: '0.75rem'
                                    }}>|</span>
                                    <AnchorText
                                        className={"header-contact-us"}
                                        href={config.keysight + "us/en/contact.html"}
                                        data-location="Header"
                                        data-name="Header"
                                        data-trigger="false"
                                        data-parentvalue="Header"
                                        textkey={contactUsKey}
                                        textdefault={mainMessages[siteLanguageDefault][contactUsKey]}
                                    />
                                    <span className={"header-separator"}>|</span>
                                    <button
                                        className={"header-button"}
                                        onClick={onClickButtonLogin}
                                        ref={anchorRefLogin}
                                        style={{
                                            background: loginImageUrl
                                        }}
                                        title={'Welcome'}
                                    />
                                    <Popup
                                        anchor={anchorRefLogin.current}
                                        anchorAlign={{
                                            horizontal: "right",
                                            vertical: "bottom",
                                        }}
                                        popupAlign={{
                                            horizontal: "right",
                                            vertical: "top",
                                        }}
                                        margin={{
                                            vertical: 6
                                        }}
                                        show={showPopupLogin}
                                        animate={false}
                                        onOpen={onOpenLogin}
                                        popupClass={"header-popup"}
                                    >
                                        {(loginStatus === "loggedIn") ?
                                            (
                                                <div
                                                    className={"header-button-menu header-button-menu-welcome"}
                                                    ref={contentRefLogin}
                                                    tabIndex={0}
                                                    onFocus={onFocusLogin}
                                                    onBlur={onBlurLogin}
                                                >
                                                    <Button
                                                        icon="x"
                                                        className={"close-x"}
                                                        iconClass={"close-x"}
                                                        onClick={onClickCloseLogin}
                                                    />
                                                    <h4>
                                                        <Text
                                                            textkey={welcomeKey}
                                                            textdefault={mainMessages[siteLanguageDefault][welcomeKey]}
                                                        />
                                                    </h4>
                                                    <ul>
                                                        <li>{userEmail}</li>
                                                        <li>
                                                            <AnchorText
                                                                href={config.keysightSupport + "KeysightdCX/s/portal-myprofile?cc=US&returnURL=" + encodeURIComponent(window.location)}
                                                                data-location="Header"
                                                                data-name="Header"
                                                                data-trigger="false"
                                                                data-parentvalue="Header"
                                                                textkey={myProfileKey}
                                                                textdefault={mainMessages[siteLanguageDefault][myProfileKey]}
                                                            />
                                                        </li>
                                                        <li>
                                                            <AnchorText
                                                                href={config.keysight + "ssoauth/logout"}
                                                                data-location="Header"
                                                                data-name="Header"
                                                                data-trigger="false"
                                                                data-parentvalue="Header"
                                                                textkey={logoutKey}
                                                                textdefault={mainMessages[siteLanguageDefault][logoutKey]}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>)
                                            :
                                            (<div
                                                className={"header-button-menu header-button-menu-login"}
                                                ref={contentRefLogin}
                                                tabIndex={0}
                                                onFocus={onFocusLogin}
                                                onBlur={onBlurLogin}
                                            >
                                                <Button
                                                    icon="x"
                                                    className={"close-x"}
                                                    iconClass={"close-x"}
                                                    onClick={onClickCloseLogin}
                                                />
                                                <ul>
                                                    <li>
                                                        <button
                                                            className={"language-button"}
                                                            onClick={() => {
                                                                window.location.href = config.sso.SSO_WELCOME_URL + '?site=ext';
                                                            }}
                                                        >
                                                            <Text
                                                                textkey={loginKey}
                                                                textdefault={mainMessages[siteLanguageDefault][loginKey]}
                                                            />
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <AnchorText
                                                            href={config.keysightSupport + "KeysightdCX/s/user-registration"}
                                                            data-location="Header"
                                                            data-name="Header"
                                                            data-trigger="false"
                                                            data-parentvalue="Header"
                                                            textkey={registerKey}
                                                            textdefault={mainMessages[siteLanguageDefault][registerKey]}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>)
                                        }
                                    </Popup>
                                    <button
                                        className={"header-button"}
                                        onClick={onClickButtonGlobe}
                                        ref={anchorRefGlobe}
                                        style={{
                                            background: globeImageUrl,
                                            backgroundSize: "1.25rem 1.25rem"
                                        }}
                                    />
                                    <Popup
                                        anchor={anchorRefGlobe.current}
                                        anchorAlign={{
                                            horizontal: "right",
                                            vertical: "bottom",
                                        }}
                                        popupAlign={{
                                            horizontal: "right",
                                            vertical: "top",
                                        }}
                                        margin={{
                                            vertical: 6
                                        }}
                                        show={showPopupGlobe}
                                        animate={false}
                                        onOpen={onOpenGlobe}
                                        popupClass={"header-popup"}
                                    >
                                        <div
                                            className={"header-button-menu header-button-menu-globe"}
                                            ref={contentRefGlobe}
                                            tabIndex={0}
                                            onFocus={onFocusGlobe}
                                            onBlur={onBlurGlobe}
                                        >
                                            <Button
                                                icon="x"
                                                className={"close-x"}
                                                iconClass={"close-x"}
                                                onClick={onClickCloseGlobe}
                                            />
                                            <p className={"locale-default"}>
                                                <strong>{currentCountry}</strong>
                                            </p>
                                            <ul
                                                style={{
                                                    columns: "2 6.25rem"
                                                }}
                                            >
                                                {countryOptions.filter(function (option) {
                                                    return option[3] === "active";
                                                }).map(function (option, index) {
                                                    return (
                                                        <li key={index}>
                                                            <button
                                                                key={index}
                                                                className={"language-button"}
                                                                title={option[0]}
                                                                onClick={onChangeCountry}
                                                            >
                                                                {option[1]}
                                                            </button>
                                                        </li>
                                                    )
                                                })}
                                                <li>
                                                    <AnchorText
                                                        href={config.keysight + "us/en/more-countries.html?prev_url=" + encodeURIComponent(window.location)}
                                                        data-location="Header"
                                                        data-name="Header"
                                                        data-trigger="false"
                                                        data-parentvalue="Header"
                                                        textkey={morePeriodsKey}
                                                        textdefault={mainMessages[siteLanguageDefault][morePeriodsKey]}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </Popup>
                                </div>
                            </div>
                            {lacFlag === "Y" && (
                                <div style={{
                                    color: 'var(--color-alert-danger-base)'
                                }}>
                                    (Logged in as {userEmail})
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {showHeader && (
                    <div
                        className={'ksm-page-container'}
                        style={{
                            display: 'flex',
                            marginBottom: '0.5rem'
                        }}
                    >
                        <div>
                            <HomeIcon
                                className={'fill'}
                                size={'medium'}
                                onClick={() => history.push('/')}
                                style={{
                                    color: "var(--color-text-primary)",
                                    marginTop: '0.3rem',
                                    cursor: 'pointer'
                            }}/>
                        </div>
                        <Menu
                            items={items}
                            className={'page-header-menu'}
                            onSelect={onSelect}
                            key={"NavigationMenu"}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default Header;