import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import {DateCell, NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';

// kendo react
import {orderBy} from '@progress/kendo-data-query';
import {Grid, GridColumn} from '@progress/kendo-react-grid';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    productDescriptionKey,
    licenseExpirationDateKey,
    notesKey,
    productNumberKey,
    qtyKey,
    versionKey
} from '../../../assets/text/MultilingualText.js';


export const DownloadGrid = (props) => {
    const {products} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    return (
        <Grid
            className={"download-panelbar-grid"}
            data={orderBy(products, [{
                field: "product_number",
                dir: "desc"
            }])}
            scrollable={'none'}
            sortable={false}
        >
            <GridColumn
                field="product_number"
                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                cell={NoWrapCell}
            />
            <GridColumn
                field="product_desc"
                title={localization.toLanguageString(productDescriptionKey, mainMessages[siteLanguageDefault][productDescriptionKey])}
            />
            <GridColumn
                field="notes"
                title={localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey])}
                cell={NoWrapCell}
            />
            <GridColumn
                field="version"
                title={localization.toLanguageString(versionKey, mainMessages[siteLanguageDefault][versionKey])}
            />
            <GridColumn
                field="lic_exp_date"
                title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                cell={DateCell}
            />
            <GridColumn
                field="qty"
                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                cell={TextAlignMiddleCell}
            />
        </Grid>
    )
}