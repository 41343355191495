import React, {useContext, useState} from 'react';

// components
import UserContext from '../../components/common/UserContext.js';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    reviewCompleteKey,
    deleteProductHostStep2Key,
    detailedInfoSwaClientKey,
} from "../../assets/text/MultilingualText";
import Review from "./ReviewAndComplete/Review";
import Complete from "./ReviewAndComplete/Complete";


function ReviewAndComplete(props) {
    const {
        siteLanguageDefault,
    } = useContext(UserContext);
    const {
        setIsLoading,
        changeStepActivation,
        clientInfo,
        cartProducts,
        assignedProducts,
        setAssignedProducts,
        requestedProducts,
        setRequestedProducts,
        handleBeforeUnload,
        stepperItems,
        setStepperItems,
    } = props;

    const localization = useLocalization();
    const [review, setReview] = useState(true)

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
        }}>
            <div>
                <div className={"k-h5"}>
                    {localization.toLanguageString(reviewCompleteKey, mainMessages[siteLanguageDefault][reviewCompleteKey])}
                </div>
                <div style={{
                    color: 'var(--color-text-primary)',
                    fontSize: '0.875rem',
                    fontStyle: 'italic',
                }}>
                    {review && (localization.toLanguageString(deleteProductHostStep2Key, mainMessages[siteLanguageDefault][deleteProductHostStep2Key]))}
                    {!review && (localization.toLanguageString(detailedInfoSwaClientKey, mainMessages[siteLanguageDefault][detailedInfoSwaClientKey]))}
                </div>
            </div>

            {review && (
                <Review
                    setIsLoading={setIsLoading}
                    assignedProducts={assignedProducts}
                    setAssignedProducts={setAssignedProducts}
                    changeStepActivation={changeStepActivation}
                    clientInfo={clientInfo}
                    setReview={setReview}
                    requestedProducts={requestedProducts}
                    setRequestedProducts={setRequestedProducts}
                />
            )}

            {!review && (
                <Complete
                    setIsLoading={setIsLoading}
                    assignedProducts={assignedProducts}
                    setAssignedProducts={setAssignedProducts}
                    changeStepActivation={changeStepActivation}
                    clientInfo={clientInfo}
                    setReview={setReview}
                    requestedProducts={requestedProducts}
                    setRequestedProducts={setRequestedProducts}
                    handleBeforeUnload={handleBeforeUnload}
                    stepperItems={stepperItems}
                    setStepperItems={setStepperItems}
                    cartProducts={cartProducts}
                />
            )}

        </div>
    );

}

export default ReviewAndComplete;