import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// react strap
import {Col, Row} from 'reactstrap';

// kendo react
import {Tooltip} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    removeProductKey,
} from '../../../assets/text/MultilingualText.js';
import {orderBy} from "@progress/kendo-data-query";
import {DeleteIcon} from "../../common/icons";


export const PanelbarHeader = (props) => {
    const {
        hostJSON,
        cartProducts,
        setCartProducts,
        searchProducts,
        setSearchProducts,
        assignedProducts,
        setAssignedProducts,
    } = props;
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const deleteHost = () => {
        let assignedProductUpdates = [...assignedProducts];
        // find index of host in assignedProducts
        let hostIndex = assignedProductUpdates.findIndex((host) => host.transaction_id === hostJSON.transaction_id);
        // remove host from assignedProducts
        assignedProductUpdates.splice(hostIndex, 1);

        // loop over all products in deleted host
        hostJSON.products.forEach((hostProduct) => {
            // update the qty available for the deleted products across all hosts
            assignedProductUpdates.forEach((host, hostIndex) => {
                let productIndex = host.products.findIndex(assignedProduct => assignedProduct.line_id === hostProduct.line_id);
                if (productIndex !== -1) {
                    let qty = parseInt(hostProduct.qty_available);
                    let assignQty = parseInt(hostProduct.qty_requested);
                    assignedProductUpdates[hostIndex].products[productIndex]["qty_available"] = qty + assignQty;
                }
            });

            // update the products for the products grid when removing a product from a host
            let productUpdates = [...cartProducts];
            let index = productUpdates.findIndex(productUpdate => productUpdate.line_id === hostProduct.line_id);

            // add assigned qty to the product's qty in the products grid for an existing product
            if (productUpdates[index] && hostProduct.line_id === productUpdates[index].line_id) {
                productUpdates[index].qty_available += parseInt(hostProduct.qty_requested);
                productUpdates[index].qty_requested = productUpdates[index].qty_available === 1 ? 1 : null;
                if (productUpdates[index].redeem_one_per_host === "Y") {
                    productUpdates[index].qty_requested = 1
                }
            }
            // add assigned qty to the product's qty in the products grid for a product that does not exist in products
            else {
                let newProduct = JSON.parse(JSON.stringify(hostProduct));

                delete newProduct.transaction_id
                newProduct.qty_available += parseInt(hostProduct.qty_requested);
                newProduct.qty_requested = newProduct.qty_available === 1 ? 1 : null;
                if (newProduct.redeem_one_per_host === "Y") {
                    newProduct.qty_requested = 1
                }
                productUpdates.push(newProduct);
            }

            setCartProducts(orderBy(productUpdates, [{
                field: 'unique_id',
                dir: 'asc'
            }]));

            // update the products for the search products grid when removing a product from a host
            let searchProductUpdates = [...searchProducts];
            let indexSearch = searchProductUpdates.findIndex(productUpdate => productUpdate.line_id === hostProduct.line_id);

            // add assigned qty to the product's qty in the search products grid for an existing product
            if (searchProductUpdates[indexSearch] && hostProduct.line_id === searchProductUpdates[indexSearch].line_id) {
                searchProductUpdates[indexSearch].qty_available += parseInt(hostProduct.qty_requested);
                searchProductUpdates[indexSearch].qty_requested = searchProductUpdates[indexSearch].qty_available === 1 ? 1 : null;
                if (searchProductUpdates[indexSearch].redeem_one_per_host === "Y") {
                    searchProductUpdates[indexSearch].qty_requested = 1
                }
            }
            // add assigned qty to the product's qty in the search products grid for a product that does not exist in products
            else {
                let newProduct = JSON.parse(JSON.stringify(hostProduct));

                delete newProduct.transaction_id
                newProduct.qty_available += parseInt(hostProduct.qty_requested);
                newProduct.qty_requested = newProduct.qty_available === 1 ? 1 : null;
                if (newProduct.redeem_one_per_host === "Y") {
                    newProduct.qty_requested = 1
                }
                searchProductUpdates.push(newProduct);
            }

            setSearchProducts(orderBy(searchProductUpdates, [{
                field: 'unique_id',
                dir: 'asc'
            }]));

        })

        setAssignedProducts(assignedProductUpdates);
    };


    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h5"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {hostJSON.panelBarTitle}
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            float: 'right'
                        }}
                    >
                        <div>
                            <Tooltip
                                anchorElement="target"
                                showCallout={true}
                                parentTitle={true}
                                openDelay={0}
                                position="top"
                            >
                                <div
                                    title={localization.toLanguageString(removeProductKey, mainMessages[siteLanguageDefault][removeProductKey])}
                                    onClick={() => {
                                        deleteHost()
                                    }}
                                >
                                    <DeleteIcon
                                        style={{
                                            cursor: 'pointer',
                                            color: 'white'
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}