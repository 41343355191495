export const getCookie = (name) => {
    let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2]
    }
    return null
}

export function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=.keysight.com";
}

export const agLocaleMap = {
    'AUeng': ['/au/en', 'Australia'],
    'ATger': ['/at/de', 'Austria'],
    'BEdut': ['/be/en', 'Belgium'],
    'BEfre': ['/be/en', 'Belgium'],
    'BRpor': ['/br/pt', 'Brazil'],
    'CAeng': ['/ca/en', 'Canada'],
    'CAfre': ['/ca/en', 'Canada'],
    'CNchi': ['/cn/zh', 'China'],
    'DKdan': ['/dk/en', 'Denmark'],
    'FIfin': ['/fi/en', 'Finland'],
    'DEger': ['/de/de', 'Germany'],
    'HKeng': ['/hk/en', 'Hong Kong'],
    'INeng': ['/in/en', 'India'],
    'IEeng': ['/ie/en', 'Ireland'],
    'ILeng': ['/il/en', 'Israel'],
    'ITita': ['/it/en', 'Italy'],
    'JPjpn': ['/jp/ja', 'Japan'],
    'KRkor': ['/kr/ko', 'Korea'],
    'MYeng': ['/my/en', 'Malaysia'],
    'MXspa': ['/mx/en', 'Mexico'],
    'NLdut': ['/nl/en', 'Netherlands'],
    'RUrus': ['/ru/ru', 'Russia'],
    'SGeng': ['/sg/en', 'Singapore'],
    'ESspa': ['/es/en', 'Spain'],
    'SEswe': ['/se/en', 'Sweden'],
    'CHger': ['/ch/de', 'Switzerland'],
    'CHfre': ['/ch/de', 'Switzerland'],
    'TWcht': ['/tw/zh', 'Traditional Chinese'],
    'THeng': ['/th/en', 'Thailand'],
    'GBeng': ['/gb/en', 'United Kingdom'],
    'USeng': ['/us/en', 'United States'],
    'FRfre': ['/fr/en', 'France'],
    'ARspa': ['/zz/en', 'Argentina'],
    'AWeng': ['/zz/en', 'Aruba'],
    'BZeng': ['/zz/en', 'Belize'],
    'BOeng': ['/zz/en', 'Bolivia'],
    'CLeng': ['/zz/en', 'Chile'],
    'COeng': ['/zz/en', 'Colombia'],
    'CReng': ['/zz/en', 'Costa Rica'],
    'DOeng': ['/zz/en', 'Dominican Republic'],
    'ECeng': ['/zz/en', 'Ecuador'],
    'SVeng': ['/zz/en', 'El Salvador'],
    'GTeng': ['/zz/en', 'Guatemala'],
    'HNeng': ['/zz/en', 'Honduras'],
    'NIeng': ['/zz/en', 'Nicaragua'],
    'PAeng': ['/zz/en', 'Panama'],
    'PYeng': ['/zz/en', 'Paraguay'],
    'PEeng': ['/zz/en', 'Peru'],
    'PReng': ['/zz/en', 'Puerto Rico'],
    'UYeng': ['/zz/en', 'Uruguay'],
    'VEspa': ['/zz/en', 'Venezuela'],
    'ALeng': ['/zz/en', 'Albania'],
    'AMeng': ['/zz/en', 'Armenia'],
    'AZeng': ['/zz/en', 'Azerbaijan'],
    'BYeng': ['/zz/en', 'Belarus'],
    'BAeng': ['/zz/en', 'Bosnia and Herzegovina'],
    'BGeng': ['/zz/en', 'Bulgaria'],
    'HReng': ['/zz/en', 'Croatia'],
    'CYeng': ['/zz/en', 'Cyprus'],
    'CZeng': ['/zz/en', 'Czech Republic'],
    'EEeng': ['/zz/en', 'Estonia'],
    'GEeng': ['/zz/en', 'Georgia'],
    'GReng': ['/zz/en', 'Greece'],
    'HUeng': ['/zz/en', 'Hungary'],
    'ISeng': ['/zz/en', 'Iceland'],
    'LVeng': ['/zz/en', 'Latvia'],
    'LTeng': ['/zz/en', 'Lithuania'],
    'LUeng': ['/zz/en', 'Luxembourg'],
    'MKeng': ['/zz/en', 'Macedonia, The Former Yugoslav Republic of'],
    'MTeng': ['/zz/en', 'Malta'],
    'MDeng': ['/zz/en', 'Moldova, Republic of'],
    'MEeng': ['/zz/en', 'Montenegro'],
    'NOeng': ['/zz/en', 'Norway'],
    'PLeng': ['/zz/en', 'Poland'],
    'PTeng': ['/zz/en', 'Portugal'],
    'ROeng': ['/zz/en', 'Romania'],
    'RSeng': ['/zz/en', 'Serbia'],
    'SKeng': ['/zz/en', 'Slovakia'],
    'SIeng': ['/zz/en', 'Slovenia'],
    'TReng': ['/zz/en', 'Turkey'],
    'UAeng': ['/zz/en', 'Ukraine'],
    'BHeng': ['/zz/en', 'Bahrain'],
    'EGeng': ['/zz/en', 'Egypt'],
    'IQeng': ['/zz/en', 'Iraq'],
    'JOeng': ['/zz/en', 'Jordan'],
    'KWeng': ['/zz/en', 'Kuwait'],
    'LBeng': ['/zz/en', 'Lebanon'],
    'OMeng': ['/zz/en', 'Oman'],
    'QAeng': ['/zz/en', 'Qatar'],
    'SAeng': ['/zz/en', 'Saudi Arabia'],
    'AEeng': ['/zz/en', 'United Arab Emirates'],
    'YEeng': ['/zz/en', 'Yemen'],
    'DZeng': ['/zz/en', 'Algeria'],
    'AOeng': ['/zz/en', 'Angola'],
    'Bjeng': ['/zz/en', 'Benin'],
    'BWeng': ['/zz/en', 'Botswana'],
    'BFeng': ['/zz/en', 'Burkina Faso'],
    'BIeng': ['/zz/en', 'Burundi'],
    'CMeng': ['/zz/en', 'Cameroon'],
    'CFeng': ['/zz/en', 'Central African Republic'],
    'TDeng': ['/zz/en', 'Chad'],
    'CGeng': ['/zz/en', 'Congo'],
    'CDeng': ['/zz/en', 'Congo, the Democratic Republic of the'],
    'DJeng': ['/zz/en', 'Djibouti'],
    'GQeng': ['/zz/en', 'Equatorial Guinea'],
    'EReng': ['/zz/en', 'Eritrea'],
    'ETeng': ['/zz/en', 'Ethiopia'],
    'GAeng': ['/zz/en', 'Gabon'],
    'GMeng': ['/zz/en', 'Gambia'],
    'GHeng': ['/zz/en', 'Ghana'],
    'GNeng': ['/zz/en', 'Guinea'],
    'GWeng': ['/zz/en', 'Guinea-Bissau'],
    'CIeng': ['/zz/en', 'Ivory Coast'],
    'KEeng': ['/zz/en', 'Kenya'],
    'LSeng': ['/zz/en', 'Lesotho'],
    'LReng': ['/zz/en', 'Liberia'],
    'LYeng': ['/zz/en', 'Libya'],
    'MGeng': ['/zz/en', 'Madagascar'],
    'MWeng': ['/zz/en', 'Malawi'],
    'MLeng': ['/zz/en', 'Mali'],
    'MReng': ['/zz/en', 'Mauritania'],
    'MUeng': ['/zz/en', 'Mauritius'],
    'MAeng': ['/zz/en', 'Morocco'],
    'MZeng': ['/zz/en', 'Mozambique'],
    'NAeng': ['/zz/en', 'Namibia'],
    'NEeng': ['/zz/en', 'Niger'],
    'NGeng': ['/zz/en', 'Nigeria'],
    'RWeng': ['/zz/en', 'Rwanda'],
    'SNeng': ['/zz/en', 'Senegal'],
    'SCeng': ['/zz/en', 'Seychelles'],
    'SLeng': ['/zz/en', 'Sierra Leone'],
    'SOeng': ['/zz/en', 'Somalia'],
    'ZAeng': ['/zz/en', 'South Africa'],
    'SZeng': ['/zz/en', 'Swaziland'],
    'TZeng': ['/zz/en', 'Tanzania, United Republic of'],
    'TGeng': ['/zz/en', 'Togo'],
    'TNeng': ['/zz/en', 'Tunisia'],
    'UGeng': ['/zz/en', 'Uganda'],
    'ZMeng': ['/zz/en', 'Zambia'],
    'ZWeng': ['/zz/en', 'Zimbabwe'],
    'BDeng': ['/zz/en', 'Bangladesh'],
    'BNeng': ['/zz/en', 'Brunei Darussalam'],
    'IDeng': ['/zz/en', 'Indonesia'],
    'KZeng': ['/zz/en', 'Kazakhstan'],
    'KGeng': ['/zz/en', 'Kyrgyzstan'],
    'NZeng': ['/zz/en', 'New Zealand'],
    'PKeng': ['/zz/en', 'Pakistan'],
    'PHeng': ['/zz/en', 'Philippines'],
    'LKeng': ['/zz/en', 'Sri Lanka'],
    'TJeng': ['/zz/en', 'Tajikistan'],
    'TMeng': ['/zz/en', 'Turkmenistan'],
    'UZeng': ['/zz/en', 'Uzbekistan'],
    'VNvie': ['/zz/en', 'Viet Nam'],
    'Worldwide': ['/zz/en', 'Worldwide'],
};

export const getLocaleInfo = () => {
    let localeCookie = getCookie('AG_LOCALE');
    if (agLocaleMap[localeCookie]) {
        const localeInfo = agLocaleMap[localeCookie][0].substring(1).split('/');

        return {
            'country': localeInfo.length === 2 ? localeInfo[0] : 'us',
            'language': localeInfo.length === 2 ? localeInfo[1] : 'en',
            'agLocale': localeCookie,
        }
    }
    return {
        'country': 'worldwide',
        'language': 'en',
        'agLocale': 'worldwide',
    }
}
