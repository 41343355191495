import React from "react";
import {NumericTextBox} from "@progress/kendo-react-inputs";

//Custom cell and logic for Assign Qty
export const DeactivateQty = (props) => {

    const onChange = (ev) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: ev.syntheticEvent,
                value: ev.target.value,
            });
        }
    };

    const field = props.field;
    const dataValue = props.dataItem[field];
    return (
        <td>
            <NumericTextBox
                onChange={onChange}
                value={dataValue}
                spinners={false}
                format={{
                    useGrouping: false,
                }}
            />
        </td>
    );
};