import React from 'react';

// css
import '../../assets/css/App.css';

function NotesHtmlCell(props) {
    let field = props.field || "";
    const value = props.dataItem[field];
    return (<td dangerouslySetInnerHTML={{__html: value}}></td>)
}

export default NotesHtmlCell;