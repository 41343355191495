import React, {useContext} from "react";

//react strap
import {Col, Row} from "reactstrap";

//components
import UserContext from "../../common/UserContext";

//multilingual
import {
    deleteHostKey,
    deleteProductHostKey,
    mainMessages,
    descriptionKey,
    productKey,
    qtyKey
} from "../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";

//kendo
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {NoWrapCell} from "../../common/Grid";
import {orderBy} from "@progress/kendo-data-query";
import {DeleteIcon} from "../../common/icons";


export default function AssignedHostDelete(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        unassigned,
        setUnassigned,
        assigned,
        setAssigned
    } = props

    const deleteProduct = (items) => {
        for (const item of items) {
            const transactionID = item.transaction_id
            const license_id = item.license_id

            const hostIndex = assigned.findIndex(a => a.transaction_id === transactionID);
            console.log(transactionID, hostIndex)
            let products = assigned[hostIndex].products
            const productIndex = products.findIndex(p => p.license_id === license_id);
            products.splice(productIndex, 1)
            if (products.length === 0) {
                assigned.splice(hostIndex, 1)
            }

            unassigned.push(item) //TODO clean up fields
        }
        setAssigned([...assigned])
        setUnassigned([...unassigned])
    }

    const DeleteCell = (props) => {
        const {
            dataItem,
        } = props;

        return (
            <td>
                <Tooltip
                    anchorElement="target"
                    showCallout={true}
                    parentTitle={true}
                    openDelay={0}
                    position="top"
                >
                    <div
                        title={localization.toLanguageString(deleteProductHostKey, mainMessages[siteLanguageDefault][deleteProductHostKey])}
                        onClick={() => {
                            deleteProduct([dataItem])
                        }}
                    >
                        <DeleteIcon
                            className={'ksm-grid-icon'}
                        />
                    </div>
                </Tooltip>
            </td>
        )
    }

    return (
        assigned.map((item, index) => {
            return (
                <PanelBar
                    key={item.transaction_id}
                    isControlled={false}
                    expanded={['.0']}
                    className={'ksm-panelbar-default'}
                >
                    <PanelBarItem
                        title={
                            <Row
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Col
                                    style={{
                                        flex: '0 0 auto',
                                        width: 'auto'
                                    }}
                                >
                                    <div
                                        className={"k-h5"}
                                        style={{
                                            color: 'white',
                                            margin: '0'
                                        }}
                                    >
                                        {item.panelBarTitle}
                                    </div>
                                </Col>
                                <Col>
                                    <div
                                        style={{
                                            float: 'right'
                                        }}
                                    >
                                        <Tooltip
                                            anchorElement="target"
                                            showCallout={true}
                                            parentTitle={true}
                                            openDelay={0}
                                            position="top"
                                        >
                                            <div
                                                title={localization.toLanguageString(deleteHostKey, mainMessages[siteLanguageDefault][deleteHostKey])}
                                                onClick={(e) => {
                                                    deleteProduct([...assigned[index].products])
                                                }}
                                            >
                                                <DeleteIcon
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'white'
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </Col>
                            </Row>
                        }
                    >

                        <Grid
                            className={'assign-host-delete-grid'}
                            scrollable={'none'}
                            data={orderBy(item.products, [{
                                field: "prod_num",
                                dir: "desc"
                            }])}
                        >
                            <GridColumn
                                field={null}
                                title={""}
                                cell={DeleteCell}
                            />
                            <GridColumn
                                field="prod_num"
                                title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="prod_desc"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                            />
                            <GridColumn
                                field="qty_redeemed"
                                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                            />
                        </Grid>

                    </PanelBarItem>
                </PanelBar>
            )
        })
    )
}