import DOMPurify from 'dompurify';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import {capitalize} from 'lodash'
import config from '../../config.js';
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import Moment from 'moment';

/*
 * toHtml() converts string to html and sanitizes the html to prevent against XSS attacks
 * @param {html} the string containing html
 * @returns html
*/
export function toHtml(html) {
    DOMPurify.setConfig({ADD_ATTR: ['target']});
    if (typeof html === 'object') {
        html = ReactDOMServer.renderToStaticMarkup(html);
    }
    return <div
        dangerouslySetInnerHTML={createMarkup(html)}
    />
}

/*
 * createMarkup() is a helper function to toHtml()
 * @param {html} the string containing html
 * @returns object
*/
function createMarkup(html) {
    let clean = DOMPurify.sanitize(html);
    return {__html: clean};
}

/*
* downloadFile() downloads files from url
* @param {url} string url of download path/link
*/
export const downloadFile = async (url) => {
    return new Promise((res, rej) => {
        try {
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = url;
            link.setAttribute('target', '_self');
            link.click();
            document.body.removeChild(link);
            res(true);
        } catch (error) {
            console.log('err', error);
            rej(error);
        }
    })
}

/*
* downloadContent() creates and downloads a html file
* @param {content} html content
* @param {filename} string file name
*/
const downloadContent = async (content, filename) => {
    return new Promise((res, rej) => {
        try {
            let link = document.createElement('a');
            document.body.appendChild(link);
            const blob = new Blob([content], {type: "text/html;charset=utf-8"});
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', filename);
            link.click();
            link.remove();
            res(true);
        } catch (error) {
            console.log('err', error);
            rej(error);
        }
    })
}

/*
 * downloadLicenses() download licenses based on transaction IDs
 * @param {transactionIDs} array of string transaction IDs
 * @param {accessToken} accessToken to call api backend
 * @param {setIsLoading} state set method to show/close the loading spinner
 * @param {handleBeforeUnload} window event method that is used to reload alert
*/
export async function downloadLicenses(transactionIDs, accessToken, setIsLoading, handleBeforeUnload = null, request_source = null, publicCall = false) {

    let headers
    let data
    let url

    if (publicCall) {
        url = config.trial_license.GUEST_API
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': accessToken
        }

        data = new FormData();
        data.append('File', null);
        data.append('Data', JSON.stringify(
            {
                "request_source": request_source,
                "transaction_ids": transactionIDs
            }
        ));


    } else {
        url = config.request_license.DOWNLOAD_LICENSES
        headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        data = {
            "request_source": request_source,
            "transaction_ids": transactionIDs
        };
    }


    setIsLoading(true);
    axios.post(
        url,
        data,
        {
            headers: headers
        }
    )
        .then(async (response) => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            let timeOut = 2000;
            for (let itemIndex = 0; itemIndex < response?.data?.length; itemIndex++) {
                for (let innerItemIndex = 0; innerItemIndex < response?.data?.[itemIndex]?.['transaction_details']?.length; innerItemIndex++) {
                    let detail = response?.data?.[itemIndex]?.['transaction_details']?.[innerItemIndex];
                    if (detail.hasOwnProperty('instruction_content') && detail.hasOwnProperty('instruction_file_name')) {
                        await new Promise((res) => setTimeout(() => {
                            res(true);
                        }, timeOut));
                        await downloadContent(detail['instruction_content'], detail['instruction_file_name']);
                    }
                    await new Promise((res) => setTimeout(() => {
                        res(true);
                    }, timeOut));
                    await downloadFile(detail['license_file_download_link'], handleBeforeUnload);
                }
            }
            await new Promise((res) => setTimeout(() => {
                res(true);
            }, timeOut));
            window.addEventListener("beforeunload", handleBeforeUnload);
            setIsLoading(false);
        })
        .catch((error) => {
            console.log("ERROR: Failed to download license", error);
            setIsLoading(false);
        });
}

/*
 * sendEmail() send emails with licenses based on transaction IDs
 * @param {emails} list of string emails
 * @param {transactionIDs} array of string transaction IDs
 * @param {accessToken} accessToken to call api backend
 * @param {setIsLoading} state set method to show/close the loading spinner
*/
export async function emailLicenses(emails, transactionIDs, accessToken, setIsLoading, request_source = null, publicCall = false) {
    let headers
    let data
    let url

    if (publicCall) {
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': accessToken
        };
        data = new FormData();
        data.append('File', null);
        data.append('Data', JSON.stringify(
            {
                "request_source": request_source,
                "transaction_ids": transactionIDs,
                "to_emails": emails
            }
        ));
        url = config.trial_license.GUEST_API

    } else {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        data = {
            "request_source": request_source,
            "transaction_ids": transactionIDs,
            "to_emails": emails
        };

        url = config.request_license.EMAIL_LICENSE
    }


    setIsLoading(true);
    return axios.post(
        url,
        data,
        {headers: headers}
    )
}

/*
 * capitalizeSentence() returns first letter as capital ex: KEYSIGHT -> Keysight
 * @param {sentence}
*/
export const capitalizeSentence = (sentence, removeDashUnderscore = false) => {
    if (sentence.includes('-')) {
        sentence = sentence.split('-').map((word) => capitalize(word))
        if (removeDashUnderscore) {
            return sentence.join(' ');
        }
        return sentence.join('-');
    }
    if (sentence.includes('_')) {
        sentence = sentence.split('_').map((word) => capitalize(word))
        if (removeDashUnderscore) {
            return sentence.join(' ');
        }
        return sentence.join('_');
    }
    return capitalize(sentence)
}

// format currency
export const formatCurrency = (amount, locale = 'en-US', currency = 'USD') => {
    let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    })
    return formatter.format(amount)
}


/* Export Json to Excel Buffer*/
export const exportExcel = (json, tableName) => {
    const worksheet = XLSX.utils.json_to_sheet(json);
    const workbook = {Sheets: {'Sheet1': worksheet}, SheetNames: ['Sheet1']};
    const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    const fileName = tableName + Moment(new Date()).format(' YYYYMMDD hmmss') + '.xlsx'
    saveExcel(excelBuffer, fileName)
}

/* Save Excel Buffer to Browser */
export const saveExcel = (buffer, fileName) => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const data = new Blob([buffer], {type: EXCEL_TYPE})
    FileSaver.saveAs(data, fileName)
}

/**
 * Multiples money based on the decimals of the currency. USD is two decimals
 * @param amount
 * @param multiplyBy
 * @param significantDecimals
 * @returns {number}
 */
export const multiplyMoney = (amount, multiplyBy, significantDecimals = 2) => {
    const precision = Math.pow(10, significantDecimals);
    const wholeAmount = amount * precision;
    const result = Math.floor(wholeAmount * multiplyBy);
    return result / precision;
}