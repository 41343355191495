import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import {NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    descriptionKey,
    mainMessages,
    productNumberKey,
    qtyToAssignKey,
    versionKey
} from '../../../assets/text/MultilingualText.js';


export const RequestUpdateLicensesGrid = (props) => {
    const {products} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    return (
        <Grid
            className={'request-update-license-grid'}
            data={products}
            scrollable={'none'}
        >
            < GridColumn
                field="UP_PRODUCT_NUM"
                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                cell={NoWrapCell}
            />
            <GridColumn
                field="UPDATE_ITEM_DESCRIPTION"
                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
            />
            <GridColumn
                field="UP_PRODUCT_VERSION_NUMBER"
                title={localization.toLanguageString(versionKey, mainMessages[siteLanguageDefault][versionKey])}
            />
            <GridColumn
                field="QTY_AVAILABLE"
                title={localization.toLanguageString(qtyToAssignKey, mainMessages[siteLanguageDefault][qtyToAssignKey])}
                cell={TextAlignMiddleCell}
            />
        </Grid>
    )
}