import React, {useContext} from 'react';
import '../assets/css/Help.css';
import UserContext from '../components/common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

//assets
import KSMDeactivateLicenseNavigationImg from '../assets/img/Help/ksm-deactivate-license-navigation.png';
import KSMDeactivateLicenseSpecifyHostImg from '../assets/img/Help/ksm-deactivate-license-specify-host.png';
import KSMDeactivateLicenseSpecifyLicenseImg from '../assets/img/Help/ksm-deactivate-license-specify-license.png';
import KSMDeactivateRequestLicenseImg from '../assets/img/Help/ksm-deactivate-request-license.png';
import KSMDeactivateObtainLicenseImg from '../assets/img/Help/ksm-deactivate-obtain-license.png';
import KSMDeactivateConfirmationImg from '../assets/img/Help/ksm-deactivate-confirmation.png';

// multilingual
import {Text} from '../components/common/MultilingualText.js';
import {useLocalization} from '@progress/kendo-react-intl';
import {
    deactivationConfirmationKey,
    helpDeactivateConfirmationSectionKey,
    entitlementCertificatesKey,
    helpDeactivateEntitlementCertFaqQAKey,
    errorHandlingKey,
    helpDeactivateErroHandlingSectionKey,
    frequentlyAskedQuestionsKey,
    deactivatingLicensesKey,
    deactiveLicenseKey,
    mainMessages,
    navigationKey,
    quickStepsDeactivationKey,
    helpDeactivateLicenseSectionKey,
    licensesKey,
    helpDeactivateLicensesFaqQAKey,
    specifyingDeactivateKey,
    specifyingTheHostKey,
    helpDeactivateNavigationSectionKey,
    obtainingConfirmingDeactivationKey ,
    helpDeactivateObtainLicensesSectionKey,
    helpDeactivateQuickStepsSectionKey,
    helpDeactivateRequestingLicenseSectionKey,
    requestingLicensesKey,
    helpDeactivateSpecifyHostSectionKey,
    helpDeactivateSpecifyLicenseSectionKey
} from '../assets/text/MultilingualText';


function DeactivateLicenseHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpMenuContent = {
        'header': {
            key: deactiveLicenseKey,
            default: mainMessages[siteLanguageDefault][deactiveLicenseKey]
        },
        'topics': [
            {
                key: quickStepsDeactivationKey,
                default: mainMessages[siteLanguageDefault][quickStepsDeactivationKey],
                hash: "#quick-steps"
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: "#navigation"
            },
            {
                key: deactivatingLicensesKey,
                default: mainMessages[siteLanguageDefault][deactivatingLicensesKey],
                hash: "#deactivate-license"
            },
            {
                key: specifyingTheHostKey,
                default: mainMessages[siteLanguageDefault][specifyingTheHostKey],
                hash: "#specify-host"
            },
            {
                key: specifyingDeactivateKey,
                default: mainMessages[siteLanguageDefault][specifyingDeactivateKey],
                hash: "#specify-license"
            },
            {
                key: requestingLicensesKey,
                default: mainMessages[siteLanguageDefault][requestingLicensesKey],
                hash: "#request-license"
            },
            {
                key: obtainingConfirmingDeactivationKey ,
                default: mainMessages[siteLanguageDefault][obtainingConfirmingDeactivationKey ],
                hash: "#obtain-license"
            },
            {
                key: deactivationConfirmationKey,
                default: mainMessages[siteLanguageDefault][deactivationConfirmationKey],
                hash: "#deactivate-confirmation"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            }
        ],
        'faq': [
            {
                key: entitlementCertificatesKey,
                default: mainMessages[siteLanguageDefault][entitlementCertificatesKey],
                hash: "#entitment-license-faq"
            },
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: "#licenses-faq"
            }
        ]
    };

    const helpDeactivateQuickStepsContent = localization.toLanguageString(
        helpDeactivateQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateQuickStepsSectionKey]
    );

    const helpDeactivateNavigationContent = localization.toLanguageString(
        helpDeactivateNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateNavigationSectionKey]
    );

    const helpDeactivateSpecifyHostContent = localization.toLanguageString(
        helpDeactivateSpecifyHostSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateSpecifyHostSectionKey]
    );

    const helpDeactivateLicenseContent = localization.toLanguageString(
        helpDeactivateLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateLicenseSectionKey]
    );

    const helpDeactivateSpecifyLicenseContent = localization.toLanguageString(
        helpDeactivateSpecifyLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateSpecifyLicenseSectionKey]
    );

    const helpDeactivateRequestingLicenseContent = localization.toLanguageString(
        helpDeactivateRequestingLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateRequestingLicenseSectionKey]
    );

    const helpDeactivateObtainLicensesContent = localization.toLanguageString(
        helpDeactivateObtainLicensesSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateObtainLicensesSectionKey]
    );

    const helpDeactivateConfirmationContent = localization.toLanguageString(
        helpDeactivateConfirmationSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateConfirmationSectionKey]
    );

    const helpDeactivateErroHandlingContent = localization.toLanguageString(
        helpDeactivateErroHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpDeactivateErroHandlingSectionKey]
    );

    const helpDeactivateEntitlementCertFaqQAContent = localization.toLanguageString(
        helpDeactivateEntitlementCertFaqQAKey,
        mainMessages[siteLanguageDefault][helpDeactivateEntitlementCertFaqQAKey]
    );

    const helpDeactivateLicensesFaqQAContent = localization.toLanguageString(
        helpDeactivateLicensesFaqQAKey,
        mainMessages[siteLanguageDefault][helpDeactivateLicensesFaqQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3 header"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsDeactivationKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsDeactivationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpDeactivateQuickStepsContent?.section_one}</p>
                            <ol>
                                {helpDeactivateQuickStepsContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`quick-steps-${idx}`}>
                                        {toHtml(bullet?.bullet_point)}
                                        {
                                            bullet?.sub_bullet_point && <>{toHtml(bullet?.sub_bullet_point)}</>
                                        }
                                        {
                                            bullet?.sub_bullet_points && <ol type="a">
                                                {
                                                    bullet?.sub_bullet_points?.map((sub_bullet, subIdx) => (
                                                        <li key={`sub-steps-${subIdx}`}>
                                                            {toHtml(sub_bullet)}
                                                        </li>
                                                    ))
                                                }
                                            </ol>
                                        }
                                    </li>;
                                })}
                            </ol>
                            {toHtml(helpDeactivateQuickStepsContent?.section_two)}
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpDeactivateNavigationContent?.section_one}</p>
                            <ol>
                                {helpDeactivateNavigationContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`navigation-${idx}`}>
                                        {toHtml(bullet)}
                                        {
                                            idx === 1 &&
                                            <img src={KSMDeactivateLicenseNavigationImg}
                                                 alt={'ksm-deactivate-navition'}/>
                                        }
                                    </li>;
                                })}
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="deactivate-license"
                        >
                            <Text
                                textkey={deactivatingLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][deactivatingLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(helpDeactivateLicenseContent?.section_one)}
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="specify-host"
                        >
                            <Text
                                textkey={specifyingTheHostKey}
                                textdefault={mainMessages[siteLanguageDefault][specifyingTheHostKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpDeactivateSpecifyHostContent?.section_one}</p>
                            <ol>
                                {helpDeactivateSpecifyHostContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`quick-steps-${idx}`}>
                                        {toHtml(bullet?.bullet_point)}
                                        {
                                            bullet?.sub_bullet_point && <>{toHtml(bullet?.sub_bullet_point)}</>
                                        }
                                        {
                                            bullet?.sub_bullet_points && <ol type="a">
                                                {
                                                    bullet?.sub_bullet_points?.map((sub_bullet, subIdx) => (
                                                        <li key={`sub-steps-${subIdx}`}>
                                                            {toHtml(sub_bullet)}
                                                        </li>
                                                    ))
                                                }
                                            </ol>
                                        }
                                        {
                                            idx === 1 &&
                                            <img src={KSMDeactivateLicenseSpecifyHostImg}
                                                 alt={'ksm-deactivate-specify-host'}/>
                                        }

                                    </li>;
                                })}
                            </ol>
                        </div>

                        <div
                            className={"k-h3 header"}
                            id="specify-license"
                        >
                            <Text
                                textkey={specifyingDeactivateKey}
                                textdefault={mainMessages[siteLanguageDefault][specifyingDeactivateKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpDeactivateSpecifyLicenseContent?.section_one}</p>
                            <ol>
                                {helpDeactivateSpecifyLicenseContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`quick-steps-${idx}`}>
                                        {toHtml(bullet?.bullet_point)}
                                        {
                                            bullet?.sub_bullet_point && <>{toHtml(bullet?.sub_bullet_point)}</>
                                        }
                                        {
                                            bullet?.sub_bullet_points && <ol type="a">
                                                {
                                                    bullet?.sub_bullet_points?.map((sub_bullet, subIdx) => (
                                                        <li key={`sub-steps-${subIdx}`}>
                                                            {toHtml(sub_bullet)}
                                                        </li>
                                                    ))
                                                }
                                            </ol>
                                        }
                                        {
                                            bullet?.bullet_footer_note && <>{toHtml(bullet?.bullet_footer_note)}</>
                                        }
                                        {
                                            idx === 1 &&
                                            <img src={KSMDeactivateLicenseSpecifyLicenseImg}
                                                 alt={'ksm-deactivate-specify-licnse'}/>
                                        }

                                    </li>;
                                })}
                            </ol>
                        </div>

                        <div
                            className={"k-h3 header"}
                            id="request-license"
                        >
                            <Text
                                textkey={requestingLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][requestingLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpDeactivateRequestingLicenseContent?.section_one}</p>
                            <ol>
                                {helpDeactivateRequestingLicenseContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`quick-steps-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>;
                                })}
                            </ol>
                            <img src={KSMDeactivateRequestLicenseImg} alt={'ksm-deactivate-specify-host'}/>
                            {toHtml(helpDeactivateRequestingLicenseContent?.section_two)}
                        </div>

                        <div
                            className={"k-h3 header"}
                            id="obtain-license"
                        >
                            <Text
                                textkey={obtainingConfirmingDeactivationKey }
                                textdefault={mainMessages[siteLanguageDefault][obtainingConfirmingDeactivationKey ]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpDeactivateObtainLicensesContent?.section_one}</p>
                            <ol>
                                {helpDeactivateObtainLicensesContent?.section_one_bullets?.map((bullet, idx) => {
                                    if (idx === 1) {
                                        return <li key={`quick-steps-${idx}`}>
                                            {toHtml(bullet)}
                                            {helpDeactivateObtainLicensesContent?.section_one_2_bullets?.map((subBullet, idx) => {
                                                let quickStepSubBullet = <>&bull; {subBullet}</>
                                                return <div key={`quick-steps-2-${idx}`}>
                                                    {toHtml(quickStepSubBullet)}
                                                </div>
                                            })}
                                        </li>;
                                    } else {
                                        return <li key={`quick-steps-${idx}`}>
                                            {toHtml(bullet)}
                                        </li>;
                                    }
                                })}
                            </ol>
                            {toHtml(helpDeactivateObtainLicensesContent?.section_two)}
                            <img src={KSMDeactivateObtainLicenseImg} alt={'ksm-deactivate-specify-host'}/>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="deactivate-confirmation"
                        >
                            <Text
                                textkey={deactivationConfirmationKey}
                                textdefault={mainMessages[siteLanguageDefault][deactivationConfirmationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpDeactivateConfirmationContent?.section_one}</p>
                            <ol>
                                {helpDeactivateConfirmationContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`quick-steps-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>;
                                })}
                            </ol>
                            <img src={KSMDeactivateConfirmationImg} alt={'ksm-deactivate-confirmation-host'}/>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpDeactivateErroHandlingContent?.section_one}</p>

                            <p>{helpDeactivateErroHandlingContent?.section_two}</p>
                            {toHtml(helpDeactivateErroHandlingContent?.section_three)}
                        </div>

                        <div
                            className={"k-h3 header"}
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="entitment-license-faq">
                            <h3>
                                <Text
                                    textkey={entitlementCertificatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][entitlementCertificatesKey]}
                                />
                            </h3>
                            <ul>
                                {helpDeactivateEntitlementCertFaqQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"entitlement-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div id="licenses-faq">
                            <h3>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h3>
                            <ul>
                                {helpDeactivateLicensesFaqQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"license-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default DeactivateLicenseHelp;

