import React, {useContext} from 'react';
import UserContext from '../common/UserContext.js';

// react router
import {Redirect, Route} from 'react-router-dom';

function ProtectedRoute({component: Component, ...rest}) {
    const {
        accessToken,
        agrlCookie
    } = useContext(UserContext);

    return (
        <Route {...rest} render={props =>
            (!accessToken && agrlCookie) ? (
                <Redirect to={{
                    pathname: '/sso-redirect',
                    accessToken: accessToken,
                    path: rest.path,
                    previous: rest.location
                }}
                />
            ) : (!accessToken && !agrlCookie) ? (
                <Redirect to={{
                    pathname: '/sign-in',
                    accessToken: accessToken
                }}
                />
            ) : (
                <Component {...rest} accessToken={accessToken}/>
            )
        }/>
    );
};

export default ProtectedRoute;