import React, {useContext} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/App.css';

// react router
import {useHistory} from 'react-router-dom';

// components
import IconCell from './IconCell.js'

// kendo react
import {GRID_COL_INDEX_ATTRIBUTE} from '@progress/kendo-react-grid';
import {useTableKeyboardNavigation} from '@progress/kendo-react-data-tools';
import {Tooltip} from '@progress/kendo-react-tooltip';

function LinkCell(props) {
    const {accessToken} = useContext(UserContext);
    const {setIsLoading} = props;
    let history = useHistory();

    const redirect = (actionLink) => {
        var r = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (!r.test(actionLink)) {
            if (props?.dataItem?.SoftwareFamily && props?.dataItem?.Update?.text) {
                history.push(
                    actionLink,
                    {
                        softwareFamily: props.dataItem["SoftwareFamily"],
                        update: props.dataItem["Update"]["text"],
                    }
                );
            } else {
                history.push(actionLink);
            }
        } else {
            try {
                setIsLoading(true);
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                };
                let urls = [actionLink];
                update_item_ids.push(updateItemId);
                let data = {
                    "urls": urls,
                    "update_item_ids": update_item_ids,
                    "update_id": updateId
                };
                let url = config.software_updates.GET_SOFTWARE_UPDATE_URL;
                axios.post(
                    url,
                    data,
                    {
                        headers: headers
                    }
                )
                    .then((response) => {
                        if (response.status === 200) {
                            let presigned_urls = response.data || "";
                            presigned_urls.forEach((presigned_url, index) => {
                                let a = document.createElement("a");
                                a.setAttribute('href', presigned_url);
                                a.setAttribute('download', '');
                                a.setAttribute('target', '_blank');
                                a.click();
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("ERROR: Failed to get presigned url", error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } catch (error) {
                console.log('err', error);
            }
        }
    };

    let field = props.field || "";
    if (field.includes(".")) {
        field = field.split('.')[0];
    }
    const value = props.dataItem[field];
    const text = value.text;
    const url = value.link;
    const type = value?.type;
    const disabled = (props?.disabled) ? props?.disabled : false;
    const gridType = (props?.gridType) ? props?.gridType : '';
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const isIcon = (type) ? true : false;
    let updateItemId = props.dataItem['UpdateItemId'];
    let params = (new URL(document.location)).searchParams;
    let updateId = params.get('uuid');
    let update_item_ids = [];

    const performRedirect = () => {
        if (!disabled) {
            redirect(url.toString());
        }
    };
    const disabledStyle = () => {
        return !disabled ? {"textDecoration": "none"} : {
            color: 'grey',
            "textDecoration": "none",
            cursor: "not-allowed"
        };
    };
    const disabledHoverText = () => {
        if (gridType === "kit") {
            return !disabled ? text.toString() : "All available kits have been already used";
        } else if (gridType === "license") {
            return !disabled ? text.toString() : "All available licenses have been already used";
        } else {
            return text.toString();
        }
    };
    return !isIcon ? (
        <td
            style={{}}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{[GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex}}
            {...navigationAttributes}
        >
            {disabled ? (<Tooltip
                openDelay={100}
                position="right"
                anchorElement='target'
            >
                <button
                    className={"ksm-anchor-button"}
                    onClick={performRedirect}
                    style={disabledStyle()}
                    title={disabledHoverText()}
                >
                    {text.toString()}
                </button>
            </Tooltip>) : (<button
                className={"ksm-anchor-button"}
                onClick={performRedirect}
                style={disabledStyle()}
            >
                {text.toString()}
            </button>)}
        </td>
    ) : (<td
        style={{}}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{[GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,}}
        {...navigationAttributes}
    >
        <Tooltip
            openDelay={100}
            position="right"
            anchorElement='target'
        >
            <div
                className={"ksm-anchor-button"}
                onClick={performRedirect}
                style={disabledStyle()}
            >
                <IconCell {...props}/>
            </div>
        </Tooltip>
    </td>);
}

export default LinkCell;