import React, {useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/App.css';
import '../../assets/css/SoftwareUpdates.css';

// components
import LinkCell from './LinkCell.js';
import {ColumnMenu, DateCell} from '../common/Grid.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {useLocalization} from '@progress/kendo-react-intl';
import {chevronDownIcon, chevronUpIcon, homeIcon, minusIcon, plusIcon} from "@progress/kendo-svg-icons";
import {IconsContext, SvgIcon} from '@progress/kendo-react-common';

import {
    mainMessages,
    releaseDateKey,
    softwareFamilyKey,
    updateKey,
    genericEmptyGridKey
} from '../../assets/text/MultilingualText.js'
import {Button} from "@progress/kendo-react-buttons";

const DetailComponent = props => {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();
    const SoftwareUpdatesUpdates = localization.toLanguageString(updateKey, mainMessages[siteLanguageDefault][updateKey]);
    const SoftwareUpdatesReleaseDate = localization.toLanguageString(releaseDateKey, mainMessages[siteLanguageDefault][releaseDateKey]);
    const defaultGridMessage = localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey]);

    let details = props.dataItem['details'];
    const initialGridState = {}
    const [gridState, setGridState] = useState(initialGridState);
    const [dataState, setDataState] = useState(
        process(details
            .map((item) => ({
                ...item,
            })), initialGridState)
    );
    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            details
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [details]);

    useEffect(() => {
        const newDataState = process(
            details
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [details]) // eslint-disable-line react-hooks/exhaustive-deps

    return <Grid
        className="software-updates-detail-grid k-mt-4 k-mb-4 k-p-0"
        scrollable={'none'}
        data={dataState}
        sortable={true}
        sort={[{field: "ReleaseDate", dir: "desc",},]}
        {...dataState}
        onDataStateChange={onDataStateChange}
        {...gridState}
    >
        <GridNoRecords>
            {defaultGridMessage}
        </GridNoRecords>
        <GridColumn
            columnMenu={ColumnMenu}
            field="Update.text"
            title={SoftwareUpdatesUpdates}
            cell={LinkCell}
        />
        <GridColumn
            columnMenu={ColumnMenu}
            filter={"date"}
            field="ReleaseDate"
            title={SoftwareUpdatesReleaseDate}
            cell={DateCell}
        />
    </Grid>
};

function SoftwareGrid(props) {
    const {updates, setUpdates} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const SoftwareUpdatesFamily = localization.toLanguageString(softwareFamilyKey, mainMessages[siteLanguageDefault][softwareFamilyKey]);

    const initialGridState = {
        take: 20,
        skip: 0,
    };

    const [massExpanded, setMassExpanded] = useState(false);
    const [gridState, setGridState] = useState(initialGridState);
    const [dataState, setDataState] = useState(
        process(updates
            .map((item) => ({
                ...item,
            })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            updates
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );

        setGridState(event.dataState);
        setDataState(newDataState);
    }, [updates]);

    useEffect(() => {
        const newDataState = process(
            updates
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [updates]) // eslint-disable-line react-hooks/exhaustive-deps

    const expandChange = event => {
        let newData = updates.map(item => {
            if (item.product_family === event.dataItem.product_family) {
                item.expanded = event.value
            }
            return item
        })
        setUpdates(newData)
    };

    const massExpandCell = (content, props) => {
        if (props.field === 'expanded') {
            return (
                <SvgIcon
                    icon={!massExpanded ? chevronDownIcon : chevronUpIcon}
                    size="medium"
                    onClick={expand}
                    style={{cursor: 'pointer'}}
                />
            );
        }
        return content;
    };

    const expand = () => {
        updates.map((item) => {
            const expandEvent = {
                value: !massExpanded,
                dataItem: item,
            };
            expandChange(expandEvent);
        });
        setMassExpanded(!massExpanded);
    };

    return (
        <IconsContext.Provider
            value={{
                type: 'svg',
                icons: {
                    [plusIcon.name]: chevronDownIcon,
                    [minusIcon.name]: chevronUpIcon,
                },
            }}
        >
            <Grid
                scrollable={'none'}
                data={dataState}
                sortable={true}
                sort={[{"field": "product_family", "dir": "asc"}]}
                //pagination
                pageable={(updates.length <= initialGridState.take) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100]
                }}
                onDataStateChange={onDataStateChange}
                total={dataState.total}
                // details
                detail={DetailComponent}
                expandField="expanded"
                onExpandChange={expandChange}
                headerCellRender={massExpandCell}
                {...gridState}
            >
                <GridColumn
                    title={SoftwareUpdatesFamily}
                    field="product_family"
                    columnMenu={ColumnMenu}

                />
            </Grid>
        </IconsContext.Provider>
    )
}

export default SoftwareGrid;