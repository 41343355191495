import React, {useContext, useRef, useState} from 'react';
import uuid from 'react-uuid';
import axios from 'axios';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';
import Alert from '../../common/Alert.js';
import {toHtml} from '../../common/utilities.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Label} from '@progress/kendo-react-labels';
import {Popover} from '@progress/kendo-react-tooltip';
import {TextBox} from "@progress/kendo-react-inputs";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {AnchorWrap, Text} from '../../common/MultilingualText.js';
import {
    assignProductsGenericMessageIssueErrorKey,
    assignProductsGenericMessagePleaseErrorKey,
    genericErrorTitleKey,
    hostIdFormattingKey,
    invalidHostKey,
    cancelChangesKey,
    descriptionKey,
    productNumberKey,
    qtyKey,
    saveHostIDKey,
    assignHostKey,
    serialNumberFormattingKey,
    invalidSerialNumberKey,
    contactUsKey,
    hostKey,
    mainMessages
} from '../../../assets/text/MultilingualText.js';
import KSMPopover from "../../common/Popovers";
import {InfoIcon} from "../../common/icons";

function AssignProductsModal(props) {
    const {
        setIsLoading,
        details,
        setDetails,
        assigned,
        setAssigned,
        licenses,
        xAuthToken,
        changeStepActivation
    } = props;
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [hostID, setHostID] = useState("");
    const hostIDPopover = useRef(null);
    const hostIDAnchor = useRef(null);
    const [isVisible, setIsVisible] = useState({
        hostIDHint: false,
        serialNumberHint: false
    })

    const [serialNumber, setSerialNumber] = useState("");
    const serialNumberAnchor = useRef(null);

    const [errors, setErrors] = useState([]);
    const [isValid, setIsValid] = useState({
        hostID: true,
        serialNumber: true,
    });

    const validateHostRegEx = () => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': xAuthToken
        };

        let inputJSON = {
            "host_id_type": details.hostIDType,
            "host_or_imei_values": [hostID.trim()],
            "serial_id_value": serialNumber.trim()
        };

        let data = {
            module: "HOST",
            sub_module: "PATTERN",
            action: "VALIDATE",
            input_json: [inputJSON]
        };

        let formData = new FormData();
        formData.append('File', null);
        formData.append('Data', JSON.stringify(data));

        setIsLoading(true);

        axios.post(
            config.trial_license.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    addProductsToAssigned()
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Validation Regex", error);
                let field;
                let message;
                let status = typeof error.response.status === 'undefined' ? "" : error.response.status;
                let errorCode = typeof error.response.data.error_code === 'undefined' ? "" : error.response.data.error_code;

                if (status === 400) {
                    field = errorCode === "MOD_HSVAL_211" ? "hostID"
                        : errorCode === "MOD_HSVAL_212" ? "serialNumber"
                            : "";
                    message = errorCode === "MOD_HSVAL_211" ? details.hostIDError
                        : errorCode === "MOD_HSVAL_212" ? details.serialNumberError
                            : "";
                } else if (status === 500) {
                    field = "database";
                    message = "";
                } else {
                    field = "generic";
                    message = "";
                }

                let hostError = {
                    field: field,
                    message: message
                };
                showError(hostError);
            })
            .finally(() => {
                setIsLoading(false);

            })
    }


    const validateHostPatterns = () => {
        setErrors([]);
        setIsValid({
            hostID: true,
            serialNumber: true,
        });

        let isValidHostID = !hostID ? false : details.hostIDPatterns.some(regex => regex.test(hostID.trim()));
        let isValidSerialNumber = (!serialNumber && details.hostIsSerial === "Y") ? false : details.serialNumberPatterns.some(regex => regex.test(serialNumber.trim()));

        let valid = true
        // show errors for invalid host ids and serial numbers
        if (!isValidHostID) {
            let hostError = {
                field: "hostID",
                message: details.hostIDError
            };
            valid = false
            showError(hostError);
        }
        if (!isValidSerialNumber && (details.hostIsSerial === "Y" || details.hostIsSerial === "O")) {
            let serialError = {
                field: "serialNumber",
                message: details.serialNumberError
            };
            valid = false
            showError(serialError);
        }

        if (valid) {
            validateHostRegEx();
        }
    };

    /*
     * showError() appends to errors object and redlines field
     * @param {error} the error object containing the attributes for the Alert dialog and the field to redline
     *  {
     *      field: hostID || serialNumber || multi || file || database || onePerHost || duplicateHost || qtyDistribution
     *      title: error's title (note: only applicable for error.field onePerHost || duplicateHost || qtyDistribution)
     *      message: error's message
     *  }
    */
    const showError = (error) => {
        let genericMessagePlease = localization.toLanguageString(
            assignProductsGenericMessagePleaseErrorKey,
            mainMessages[siteLanguageDefault][assignProductsGenericMessagePleaseErrorKey]
        );
        let genericMessageIssue = localization.toLanguageString(
            assignProductsGenericMessageIssueErrorKey,
            mainMessages[siteLanguageDefault][assignProductsGenericMessageIssueErrorKey]
        );

        let generic = {
            field: "generic",
            message:
                <div>{genericMessagePlease} {contactUs} {genericMessageIssue}</div>
        };

        error = error.field === "database" ? generic : error;

        // if all values in the error object are not null, render the error
        if (!Object.values(error).every(e => !e)) {
            setErrors(errors => [...errors, error]);
            setIsValid(isValid => ({...isValid, [error.field]: false}));
        } else {
            setErrors(errors => [...errors, generic]);
        }
    }

    const addProductsToAssigned = () => {
        const hostIDTrim = hostID.trim()
        const serialNumberTrim = serialNumber.trim()
        let hostId = [hostIDTrim];
        let panelBarTitle = localization.toLanguageString(
            hostKey,
            mainMessages[siteLanguageDefault][hostKey]
        ) + " " + hostIDTrim;

        if (details.hostIsSerial === "Y" || details.hostIsSerial === "O") {
            panelBarTitle += serialNumberTrim ? ", " + serialNumberTrim : "";
        }
        let transactionID = uuid()

        //new host
        let products = []
        for (const item of licenses) {
            let newItem = JSON.parse(JSON.stringify(item))
            newItem.transaction_id = transactionID
            newItem.selected = false
            products.push(newItem)
        }

        let newHost = {
            transaction_id: transactionID,
            panelBarTitle: panelBarTitle,
            products: products,
            icons: {
                loading: true,
                download: false,
                cloudAlert: false,
                supportError: false,
                generationError: false,
                qtyError: false,
                entitlementError: false,
            },
            host_id: hostId,
            serial_id: serialNumberTrim,
            host_id_type: details.hostIDType
        }

        setAssigned([...assigned, newHost])

        //close modal
        setDetails({})
        changeStepActivation(1)
    }

    const AnchorText = AnchorWrap(Text);
    const contactUs = <AnchorText
        className={"header-contact-us"}
        href={config.keysight + "us/en/contact.html"}
        data-trigger="false"
        textkey={contactUsKey}
        textdefault={mainMessages[siteLanguageDefault][contactUsKey]}
    />

    /*
    * closeAssignHost() closes the assign host modal and reset assign host modal fields
    */
    const closeAssignHost = () => {
        setDetails({})
    };

    return (
        <Dialog
            className={"assign-products-modal ksm-dialog"}
            title={<h2 style={{
                fontWeight: "normal",
                margin: 0,
                color: "#424242"
            }}>
                {localization.toLanguageString(assignHostKey, mainMessages[siteLanguageDefault][assignHostKey])}
            </h2>}
            onClose={closeAssignHost}
            width={"64.625rem"}
            height={"90vh"}
        >
            <Row>
                <Col>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.2rem',
                        lineHeight: '2rem'
                    }}>
                        <Label>{toHtml(details.hostIDLabel)}</Label>
                        <InfoIcon
                            onClick={() => {
                                setIsVisible(isVisible => ({
                                    ...isVisible,
                                    hostIDHint: !isVisible.hostIDHint,
                                    serialNumberHint: false
                                }));
                            }}
                            size={"medium"}
                            style={{
                                color: 'var(--keysight-secondary)',
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <KSMPopover
                        show={isVisible.hostIDHint}
                        setShowHandler={() => {
                            setIsVisible(isVisible => ({
                                ...isVisible,
                                hostIDHint: false,
                            }));
                        }}
                        anchor={hostIDPopover.current}
                        position={'right'}
                        type={'warning'}
                        style={{maxWidth: 600}}
                        message={
                            <>
                                <b>
                                    {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                </b>
                                <br/>
                                {toHtml(details.hostIDHint)}
                            </>
                        }
                    />
                    <div ref={hostIDPopover}>
                        <TextBox
                            ref={hostIDAnchor}
                            value={hostID}
                            onChange={(e) => {
                                setHostID(e.value);
                            }}
                            valid={isValid.hostID}
                            autoFocus={true}
                        />
                    </div>
                </Col>
                <Col>
                    {details.hostIsSerial === "Y" || details.hostIsSerial === "O" ?
                        <>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.2rem',
                                lineHeight: '2rem'
                            }}>
                                <Label>
                                    {details.hostIsSerial === "O" ? toHtml(details.serialNumberLabel + " (Optional)") : toHtml(details.serialNumberLabel)}
                                </Label>
                                <InfoIcon
                                    onClick={() => {
                                        setIsVisible(isVisible => ({
                                            ...isVisible,
                                            hostIDHint: false,
                                            serialNumberHint: !isVisible.serialNumberHint
                                        }));
                                    }}
                                    size={"medium"}
                                    style={{
                                        color: 'var(--keysight-secondary)',
                                        cursor: 'pointer',
                                    }}
                                />
                            </div>
                            <KSMPopover
                                show={isVisible.serialNumberHint}
                                setShowHandler={() => {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        serialNumberHint: false,
                                    }));
                                }}
                                anchor={serialNumberAnchor.current}
                                position={'left'}
                                type={'warning'}
                                style={{maxWidth: 600}}
                                message={
                                    <>
                                        <b>
                                            {localization.toLanguageString(serialNumberFormattingKey, mainMessages[siteLanguageDefault][serialNumberFormattingKey])}
                                        </b>
                                        <br/>
                                        {toHtml(details.serialNumberHint)}
                                    </>
                                }
                            />
                            <div ref={serialNumberAnchor}>
                                <TextBox
                                    value={serialNumber}
                                    onChange={(e) => {
                                        setIsValid(isValid => ({
                                            ...isValid,
                                            serialNumber: true
                                        }));
                                        setSerialNumber(e.value);
                                    }}
                                    valid={isValid.serialNumber}
                                />
                            </div>
                        </>
                        :
                        <></>
                    }
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Grid
                        className="assign-products-grid"
                        data={licenses}
                    >
                        <GridColumn
                            field="prod_num"
                            title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                            editable={false}
                            cell={NoWrapCell}
                        />
                        <GridColumn
                            field="prod_desc"
                            title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                            editable={false}
                        />
                        <GridColumn
                            width={"50px"}
                            cell={TextAlignMiddleCell}
                            field="qty_redeemed"
                            title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                            editable={false}
                        />
                    </Grid>
                </Col>
            </Row>
            <Row>
                <Col>
                    {errors.length ? (errors.map((error, index) => {
                            let field = error.field;
                            let message = error.message;

                            let errorTitleKey = field === "hostID" ? invalidHostKey
                                : field === "serialNumber" ? invalidSerialNumberKey
                                    : field === "database" ? genericErrorTitleKey
                                        : genericErrorTitleKey

                            let errorTitle = localization.toLanguageString(
                                errorTitleKey,
                                mainMessages[siteLanguageDefault][errorTitleKey]
                            )

                            let errorMessage = (field === "hostID") ? <>
                                    {toHtml(details.hostIDLabel)}
                                    {toHtml(message)}
                                </>
                                : field === "serialNumber" ? <>
                                        {toHtml(details.serialNumberLabel)}
                                        {toHtml(message)}
                                    </>
                                    : toHtml(message)

                            return (
                                <div key={'error-' + index}>
                                    <br/>
                                    <Alert
                                        type={'error'}
                                        title={errorTitle}
                                        message={errorMessage}
                                    />
                                </div>
                            )
                        }))
                        :
                        <></>
                    }
                </Col>
            </Row>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    onClick={closeAssignHost}
                >
                    {localization.toLanguageString(cancelChangesKey, mainMessages[siteLanguageDefault][cancelChangesKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={() => {
                        validateHostPatterns()
                    }}
                >
                    {localization.toLanguageString(saveHostIDKey, mainMessages[siteLanguageDefault][saveHostIDKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default AssignProductsModal;