import React, {useEffect, useState} from 'react';

// kendo react
import {capitalizeSentence} from "../../common/utilities";

export const LicenseTypeCell = (props) => {
    const field = props.field;
    const dataValue = props.dataItem[field];
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        let newlySelected = null
        for (const item of dataValue) {
            if (item.selected) {
                newlySelected = capitalizeSentence(item.license_type)
            }
        }
        setSelected(newlySelected)
    }, [dataValue])

    return (
        <td>
            <div style={{
                whiteSpace: 'nowrap'
            }}>
                {selected}
            </div>
        </td>
    );
};