import React, {useContext} from 'react';
import config from '../../config.js';
import UserContext from './UserContext.js';

// css
import '../../assets/bootstrap/css/bootstrap-grid.min.css';
import '../../assets/css/Footer.css';

// multilingual
import {AnchorWrap, Image, Text} from './MultilingualText.js';
import {
    aboutKeysightKey,
    blogKey,
    careersKey,
    communityKey,
    corporateSocialKey,
    discoverInsightsKey,
    diversityEquityKey,
    eventsKey,
    exploreKey,
    facebookConnectKey,
    facebookLinkKey,
    feedbackKey,
    industriesKey,
    insightsKey,
    investorRelationsKey,
    ixiaProductSupportKey,
    keysightProductSupportKey,
    keysightUniversityKey,
    linkedInConnectKey,
    manageSoftwareLicensesKey,
    mainMessages,
    modernSlaveryActKey,
    newsroomKey,
    partnersKey,
    partsKey,
    privacyKey,
    productOrderStatusKey,
    productServicesKey,
    resourcesKey,
    solutionsKey,
    successStoriesKey,
    supportKey,
    termsKey,
    twitterConnectKey,
    twitterLinkKey,
    weChatConnectKey,
    youtubeConnectKey,
    youtubeLinkKey, aboutLicensingKey
} from '../../assets/text/MultilingualText.js';


export const Footer = () => {
    const AnchorText = AnchorWrap(Text);
    const AnchorImg = AnchorWrap(Image);
    const {siteLanguageDefault} = useContext(UserContext);
    const currentYear = new Date().getFullYear();
    return (
        <>
            <div id="footer" style={{
                fontSize: '0.75rem',
                color: 'var(--color-text-secondary)',
            }}>
                <div className="wrapper">
                    {/*<div className="footer-columns">*/}
                    {/*    <div className="footer-column">*/}
                    {/*        <h4>*/}
                    {/*            <Text*/}
                    {/*                textkey={exploreKey}*/}
                    {/*                textdefault={mainMessages[siteLanguageDefault][exploreKey]}*/}
                    {/*            />*/}
                    {/*        </h4>*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/products.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={productServicesKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][productServicesKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/solutions.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={solutionsKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][solutionsKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/industries.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={industriesKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][industriesKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/events.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    data-value="Events"*/}
                    {/*                    textkey={eventsKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][eventsKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={"https://learn.keysight.com/"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={keysightUniversityKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][keysightUniversityKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*    <div className="footer-column">*/}
                    {/*        <h4>*/}
                    {/*            <Text*/}
                    {/*                textkey={insightsKey}*/}
                    {/*                textdefault={mainMessages[siteLanguageDefault][insightsKey]}*/}
                    {/*            />*/}
                    {/*        </h4>*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href="https://www.keysight.com/discover"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={discoverInsightsKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][discoverInsightsKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/resources/success-stories.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={successStoriesKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][successStoriesKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/resources.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={resourcesKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][resourcesKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href="https://blogs.keysight.com/blogs.html"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={blogKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][blogKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href="https://community.keysight.com/news"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={communityKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][communityKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*        <h4>*/}
                    {/*            <AnchorText*/}
                    {/*                href={config.keysight + "us/en/partners.html"}*/}
                    {/*                data-location="Footer"*/}
                    {/*                data-name="Footer"*/}
                    {/*                data-trigger="false"*/}
                    {/*                data-parentvalue="Footer"*/}
                    {/*                textkey={partnersKey}*/}
                    {/*                textdefault={mainMessages[siteLanguageDefault][partnersKey]}*/}
                    {/*            />*/}
                    {/*        </h4>*/}
                    {/*    </div>*/}
                    {/*    <div className="footer-column">*/}
                    {/*        <h4>*/}
                    {/*            <AnchorText*/}
                    {/*                href={config.keysight + "us/en/support.html"}*/}
                    {/*                data-location="Footer"*/}
                    {/*                data-name="Footer"*/}
                    {/*                data-trigger="false"*/}
                    {/*                data-parentvalue="Footer"*/}
                    {/*                textkey={supportKey}*/}
                    {/*                textdefault={mainMessages[siteLanguageDefault][supportKey]}*/}
                    {/*            />*/}
                    {/*        </h4>*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/support/product-support.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={keysightProductSupportKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][keysightProductSupportKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href="https://support.ixiacom.com/"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={ixiaProductSupportKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][ixiaProductSupportKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "find/soft_license"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={manageSoftwareLicensesKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][manageSoftwareLicensesKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "find/orderstatus"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={productOrderStatusKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][productOrderStatusKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "find/parts"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={partsKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][partsKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*    <div className="footer-column">*/}
                    {/*        <h4>*/}
                    {/*            <AnchorText*/}
                    {/*                href={config.keysight + "us/en/about.html"}*/}
                    {/*                data-location="Footer"*/}
                    {/*                data-name="Footer"*/}
                    {/*                data-trigger="false"*/}
                    {/*                data-parentvalue="Footer"*/}
                    {/*                textkey={aboutKeysightKey}*/}
                    {/*                textdefault={mainMessages[siteLanguageDefault][aboutKeysightKey]}*/}
                    {/*            />*/}
                    {/*        </h4>*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "go/news"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={newsroomKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][newsroomKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "go/investor"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={investorRelationsKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][investorRelationsKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/about/corporate-social-responsibility.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={corporateSocialKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][corporateSocialKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/about/diversity.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={diversityEquityKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][diversityEquityKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "us/en/about/supply-chain-transparency.html"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={modernSlaveryActKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][modernSlaveryActKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <AnchorText*/}
                    {/*                    href={config.keysight + "go/careers"}*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    textkey={careersKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][careersKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*        <ul id="footer-social">*/}
                    {/*            <li>*/}
                    {/*                <AnchorImg*/}
                    {/*                    hrefkey={facebookLinkKey}*/}
                    {/*                    hrefdefault={mainMessages[siteLanguageDefault][facebookLinkKey]}*/}
                    {/*                    target="_blank"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    src={config.keysight + "content/dam/keysight/en/img/gnav/icon-facebook.svg"}*/}
                    {/*                    textkey={facebookConnectKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][facebookConnectKey]}*/}
                    {/*                />*/}
                    {/*                <AnchorImg*/}
                    {/*                    href="https://www.linkedin.com/company/keysight-technologies"*/}
                    {/*                    target="_blank"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    src={config.keysight + "content/dam/keysight/en/img/gnav/icon-linkedin.svg"}*/}
                    {/*                    textkey={linkedInConnectKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][linkedInConnectKey]}*/}
                    {/*                />*/}
                    {/*                <AnchorImg*/}
                    {/*                    hrefkey={twitterLinkKey}*/}
                    {/*                    hrefdefault={mainMessages[siteLanguageDefault][twitterLinkKey]}*/}
                    {/*                    target="_blank"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    src={config.keysight + "content/dam/keysight/en/img/gnav/icon-twitter.svg"}*/}
                    {/*                    textkey={twitterConnectKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][twitterConnectKey]}*/}
                    {/*                />*/}
                    {/*                <AnchorImg*/}
                    {/*                    hrefkey={youtubeLinkKey}*/}
                    {/*                    hrefdefault={mainMessages[siteLanguageDefault][youtubeLinkKey]}*/}
                    {/*                    target="_blank"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    src={config.keysight + "content/dam/keysight/en/img/gnav/icon-youtube.svg"}*/}
                    {/*                    textkey={youtubeConnectKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][youtubeConnectKey]}*/}
                    {/*                />*/}
                    {/*                <AnchorImg*/}
                    {/*                    href={config.keysight + "us/en/contact/wechat.html"}*/}
                    {/*                    target="_blank"*/}
                    {/*                    data-location="Footer"*/}
                    {/*                    data-name="Footer"*/}
                    {/*                    data-trigger="false"*/}
                    {/*                    data-parentvalue="Footer"*/}
                    {/*                    src={config.keysight + "content/dam/keysight/en/img/gnav/icon-wechat.svg"}*/}
                    {/*                    textkey={weChatConnectKey}*/}
                    {/*                    textdefault={mainMessages[siteLanguageDefault][weChatConnectKey]}*/}
                    {/*                />*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div id="copyright">
                        <ul>
                            <li>&copy; Keysight Technologies
                                2000&ndash;{currentYear}
                            </li>
                            <li>
                                <AnchorText
                                    href={config.keysight + "us/en/contact/privacy.html"}
                                    data-location="Footer"
                                    data-name="Footer"
                                    data-trigger="false"
                                    data-parentvalue="Footer"
                                    textkey={privacyKey}
                                    textdefault={mainMessages[siteLanguageDefault][privacyKey]}
                                />
                            </li>
                            <li>
                                <AnchorText
                                    href={config.keysight + "us/en/contact/terms-of-use.html"}
                                    data-location="Footer"
                                    data-name="Footer"
                                    data-trigger="false"
                                    data-parentvalue="Footer"
                                    textkey={termsKey}
                                    textdefault={mainMessages[siteLanguageDefault][termsKey]}
                                />
                            </li>
                            <li>
                                <AnchorText
                                    href={config.keysight + "us/en/contact/support/site-feedback.html"}
                                    data-location="Footer"
                                    data-name="Footer"
                                    data-trigger="false"
                                    data-parentvalue="Footer"
                                    textkey={feedbackKey}
                                    textdefault={mainMessages[siteLanguageDefault][feedbackKey]}
                                />
                            </li>
                            <li>
                                <AnchorText
                                    href={config.keysight + "us/en/contact/support.html"}
                                    data-location="Footer"
                                    data-name="Footer"
                                    data-trigger="false"
                                    data-parentvalue="Footer"
                                    textkey={supportKey}
                                    textdefault={mainMessages[siteLanguageDefault][supportKey]}
                                />
                            </li>
                            <li>
                                <AnchorText
                                    href={config.keysight + "us/en/lib/resources/selection-guides/" +
                                        "keysight-licensing-overview-terms-and-types.html#LicensingOverview"}
                                    data-location="Footer"
                                    data-name="Footer"
                                    data-trigger="false"
                                    data-parentvalue="Footer"
                                    textkey={aboutLicensingKey}
                                    textdefault={mainMessages[siteLanguageDefault][aboutLicensingKey]}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;