import React, {useCallback, useContext, useRef} from 'react';
import UserContext from '../common/UserContext';

// components
import {PopoverContext} from './HostPanelBars';
import {downloadLicenses} from '../common/utilities';

// react strap
import {Col, Row} from 'reactstrap';

// kendo react
import {Tooltip} from '@progress/kendo-react-tooltip';
import {Checkbox} from "@progress/kendo-react-inputs";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    assignedProductsKey,
    hostTotalKey,
    mainMessages,
    downloadLicensesKey,
} from '../../assets/text/MultilingualText';
import {DownloadIcon, InfoIcon} from "../common/icons";


export const HostPanelBarHeader = (props) => {
    const {
        items,
        index,
        setItems,
        setIsLoading,
        iconClicked,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    //Sets the popover reference based on mouse over
    const popoverRef = useRef(null);
    const {onMouseOver} = useContext(PopoverContext);

    let host = items[index]

    const handleMouseOver = useCallback(() => {
        const icons = host.icons
        onMouseOver({
            show: true,
            popoverRef: popoverRef,
            banked: icons.banked,
            cloud: icons.cloud,
            special: icons.special,
        });
    }, [onMouseOver, host.icons]);

    const handleMouseOut = useCallback(() => {
        const icons = host.icons;
        onMouseOver({
            show: false,
            popoverRef: popoverRef,
            banked: icons.banked,
            cloud: icons.cloud,
            special: icons.special,
        });
    }, [onMouseOver, host.icons]);

    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h5"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {host.name}
                    </div>
                </Col>
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        style={{
                            fontSize: '1rem',
                            fontWeight: '300',
                            color: 'white',
                            lineHeight: '1.5rem'
                        }}
                    >
                        {host.assigned_products !== 0 && (
                            <div>
                                {host.assigned_products} {localization.toLanguageString(assignedProductsKey, mainMessages[siteLanguageDefault][assignedProductsKey])}
                            </div>
                        )}
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            float: 'right'
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.6rem',
                        }}>
                            <div style={{
                                fontSize: '1rem',
                                fontWeight: '300',
                                color: 'white',
                                lineHeight: '1.5rem',
                                marginRight: '0.4rem'
                            }}
                            >
                                {host.assigned_products !== 0 && (
                                    <div>
                                        {localization.toLanguageString(hostTotalKey, mainMessages[siteLanguageDefault][hostTotalKey])} {host.host_total}
                                    </div>
                                )}
                            </div>
                            <div style={{}}>
                                <Checkbox
                                    className={'ksm-panelbar-checkbox'}
                                    value={host.icons.checked}
                                    onClick={(e) => {
                                        iconClicked.current = true
                                        items[index].icons.checked = e.target.checked
                                        setItems(JSON.parse(JSON.stringify(items)))
                                    }}
                                />
                            </div>

                            {host.icons.download && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={true}
                                    parentTitle={true}
                                    openDelay={0}
                                >
                                    <div
                                        title={localization.toLanguageString(downloadLicensesKey, mainMessages[siteLanguageDefault][downloadLicensesKey])}
                                        onClick={() => {
                                            iconClicked.current = true
                                            let eaAllocIds = []
                                            host.products.forEach(product => {
                                                if (product.candownload === 'YES') {
                                                    eaAllocIds.push(product.ea_alloc_id.toString())
                                                }
                                            })
                                            downloadLicenses(eaAllocIds, accessToken, setIsLoading, null, "ENTERPRISE_AGREEMENTS");
                                        }}
                                    >
                                        <DownloadIcon
                                            size={'medium'}
                                            style={{
                                                color: 'white',
                                                marginTop: '0.35rem',
                                                marginLeft: '0.35rem',
                                                marginRight: '0.35rem',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {(host.icons.banked || host.icons.special || host.icons.cloud) && (
                                <div
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                    ref={popoverRef}
                                >
                                    <InfoIcon
                                        size={'medium'}
                                        style={{
                                            color: 'white',
                                            marginTop: '0.35rem',
                                            marginLeft: '0.35rem',
                                            marginRight: '0.35rem',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </div>
                            )}
                            {!host.icons.download && !host.icons.banked && !host.icons.special && !host.icons.cloud && (
                                <div style={{
                                    width: '2rem'
                                }}/>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}