import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {
    companyAssetInfoKey,
    companyInfoKey,
    clientNotesKey,
    mainMessages,
    poNumberKey,
} from "../../../assets/text/MultilingualText";
import React, {useContext} from "react";
import UserContext from "../../common/UserContext";
import {useLocalization} from "@progress/kendo-react-intl";


export default function ClientGrid(props){
    const {
        siteLanguageDefault,
    } = useContext(UserContext);
    const {
        clientInfo
    } = props;

    const localization = useLocalization();
    return(
        <Grid
            className={'client-info-grid'}
            scrollable={'none'}
            data={[clientInfo]}
        >
            <GridColumn
                field="company_info"
                title={localization.toLanguageString(companyInfoKey, mainMessages[siteLanguageDefault][companyInfoKey])}
            />
            <GridColumn
                field="asset_info"
                title={localization.toLanguageString(companyAssetInfoKey, mainMessages[siteLanguageDefault][companyAssetInfoKey])}
            />
            <GridColumn
                field="po_number"
                title={localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey])}
            />
            <GridColumn
                field="client_notes"
                title={localization.toLanguageString(clientNotesKey, mainMessages[siteLanguageDefault][clientNotesKey])}
            />
        </Grid>
    )
}