import React, {useContext} from 'react';
import UserContext from '../common/UserContext';

// Components
import {DateCell, DateIsExpiredCell} from "../common/Grid";

// Multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    eaDetailsKey, currencyKey,
    customerInformationKey, endDateKey,
    mainMessages, remixTypeKey, startDateKey, statusKey
} from "../../assets/text/MultilingualText";

const DetailedViewInfo = (props) => {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const {
        eaDetails,
    } = props

    const localization = useLocalization();

    return (
        <div
            style={{
                display: 'flex',
                gap: '1.9rem',
            }}>
            <div>
                <div className={'k-h5'}>
                    {localization.toLanguageString(customerInformationKey, mainMessages[siteLanguageDefault][customerInformationKey])}
                </div>
                <table>
                    <tbody>
                    {eaDetails.customer_name && (
                        <tr>
                            <td>
                                {eaDetails.customer_name}
                            </td>
                        </tr>
                    )}
                    {eaDetails.customer_address && (
                        <tr>
                            <td>
                                {eaDetails.customer_address}
                            </td>
                        </tr>
                    )}
                    {(eaDetails.customer_city || eaDetails.customer_state || eaDetails.customer_zip_code) && (
                        <tr>
                            <td>
                                {eaDetails.customer_city && eaDetails.customer_state && (
                                    eaDetails.customer_city + ', ' + eaDetails.customer_state + ' '
                                )}
                                {eaDetails.customer_zip_code && (
                                    eaDetails.customer_zip_code
                                )}
                            </td>
                        </tr>
                    )}
                    {eaDetails.customer_country && (
                        <tr>
                            <td>
                                {eaDetails.customer_country}
                            </td>
                        </tr>
                    )}
                    {eaDetails.customer_contact_email && (
                        <tr>
                            <td>
                                {eaDetails.customer_contact_email}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <div>
                <div className={'k-h5'}>
                    {localization.toLanguageString(eaDetailsKey, mainMessages[siteLanguageDefault][eaDetailsKey])}
                </div>
                <table style={{
                    borderSpacing: '0.938rem 2px',
                    marginLeft: '-0.938rem',
                }}>
                    <tbody>
                    <tr>
                        <td>
                            {localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                        </td>
                        {DateCell({field: 'start_date', dataItem: {'start_date': eaDetails.start_date}})}
                    </tr>
                    <tr>
                        <td>
                            {localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                        </td>
                        {DateIsExpiredCell({field: 'end_date', dataItem: {'end_date': eaDetails.end_date}})}
                    </tr>
                    <tr>
                        <td>
                            {localization.toLanguageString(remixTypeKey, mainMessages[siteLanguageDefault][remixTypeKey])}
                        </td>
                        <td>
                            {eaDetails.remix_type}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {localization.toLanguageString(statusKey, mainMessages[siteLanguageDefault][statusKey])}
                        </td>
                        <td className={'capital-letters'}>
                            {eaDetails.status}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {localization.toLanguageString(currencyKey, mainMessages[siteLanguageDefault][currencyKey])}
                        </td>
                        <td>
                            {eaDetails.currency_code}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DetailedViewInfo;