import React, { useState} from 'react';

// kendo react
import {DropDownButton} from '@progress/kendo-react-buttons';
import {menuIcon} from "@progress/kendo-svg-icons";

function HostConfigHamburger(props) {
    const {
        hostData,
        showLicenses,
        showEditHost,
        showRemoveHost
    } = props;

    const [opened, setOpened] = useState(false);

    const openedStyle = {
        padding: 0,
        textAlign: "center",
        color: "var(--keysight-secondary)"
    };
    const closedStyle = {
        padding: 0,
        textAlign: "center",
        color: "var(--color-text-prominent)"
    };
    const anchorAlign = {
        horizontal: "right",
        vertical: "top"
    };
    const popupAlign = {
        horizontal: "left",
        vertical: "top"
    };

    let categories = [
        {
            key: "edit",
            text: "Edit Host"
        },
        {
            key: "remove",
            text: "Remove Host"
        }
    ];

    // append show licenses if show license view flag is YES
    if (hostData.show_license_view === "YES") {
        categories.unshift({
            key: "show",
            text: "Show Licences"
        });
    }

    const onItemClick = (e) => {
        if (e.item.key === "show") {
            showLicenses(hostData);
        } else if (e.item.key === "edit") {
            showEditHost(hostData);
        } else if (e.item.key === "remove") {
            showRemoveHost(hostData);
        }
    }

    return (
        <td
            className="ksm-hamburger"
            style={opened ? openedStyle : closedStyle}
        >
            <DropDownButton
                svgIcon={menuIcon}
                fillMode={"flat"}
                rounded={null}
                opened={opened}
                popupSettings={{
                    animate: false,
                    popupClass: "ksm-hamburger-dropdown",
                    anchorAlign: anchorAlign,
                    popupAlign: popupAlign
                }}
                items={categories}
                onItemClick={onItemClick}
                onOpen={() => setOpened(!opened)}
                onBlur={() => {setOpened(false)}}
            />
        </td>
    );
}

export default HostConfigHamburger;