import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import {DateCell, NoWrapCell} from '../../common/Grid.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    certNumberKey,
    currentSupportEndDate,
    hostIdKey,
    newSupportEndDateKey,
    orderNumberKey,
    mainMessages
} from '../../../assets/text/MultilingualText.js';

// consts for standalone subscriptions licenses table
const initialSort = [
    {
        field: "host_id",
        dir: "desc"
    }
];

function DownloadLicensesGrid(props) {
    const {
        index,
        renewalLicenses,
        gridNoRecordsMessage
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [sort, setSort] = useState(initialSort);
    const [licenses, setLicenses] = useState([]);

    // get all selected licenses from renewalLicenses
    useEffect(() => {
        setLicenses(renewalLicenses[index].licenses.filter((license) => license.selected));
    }, [renewalLicenses]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                className={"renew-standalone-download-license-grid"}
                data={orderBy(licenses, sort)}
                sortable={true}
                scrollable={"none"}
                sort={sort}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}
            >
                <GridNoRecords>
                    {gridNoRecordsMessage}
                </GridNoRecords>
                <GridColumn
                    field="host_id"
                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="order_number"
                    title={localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="certificate_number"
                    title={localization.toLanguageString(certNumberKey, mainMessages[siteLanguageDefault][certNumberKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="current_support_end_date"
                    title={localization.toLanguageString(currentSupportEndDate, mainMessages[siteLanguageDefault][currentSupportEndDate])}
                    cell={DateCell}
                />
                <GridColumn
                    field="new_support_end_date"
                    title={localization.toLanguageString(newSupportEndDateKey, mainMessages[siteLanguageDefault][newSupportEndDateKey])}
                    sortable={false}
                    cell={DateCell}
                />
            </Grid>
        </>
    );
}

export default DownloadLicensesGrid;
