import React, {useContext} from "react";
import UserContext from "../../common/UserContext";

//components
import {DateCell, NoWrapCell, TextAlignMiddleCell} from "../../common/Grid";
import {LicenseTypeCell} from "../AssignProducts/LicenseTypeCell";

//kendo
import {orderBy} from "@progress/kendo-data-query";
import {Grid, GridColumn} from "@progress/kendo-react-grid";

//multilingual
import {
    assignQtyKey,
    descriptionKey,
    endDateKey,
    licenseTypeKey,
    mainMessages,
    productNumberKey,
    qtyKey, startDateKey,
} from "../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";

const RequestDownloadGrid = (props) => {
    const {
        siteLanguageDefault,
    } = useContext(UserContext);

    const {
        products
    } = props
    const localization = useLocalization();

    return (
        <Grid
            className={'request-download-grid'}
            data={orderBy(products, [{
                field: "prod_num_display",
                dir: "desc"
            }])}
            scrollable={'none'}
            sortable={false}
        >
            <GridColumn
                field="prod_num_display"
                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                cell={NoWrapCell}
            />
            <GridColumn
                field="description"
                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
            />
            <GridColumn
                field="unassigned_quantity"
                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                cell={TextAlignMiddleCell}
            />
            <GridColumn
                field="assign_quantity"
                title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                cell={TextAlignMiddleCell}
            />
            <GridColumn
                field="period_start_date"
                title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                cell={DateCell}
            />
            <GridColumn
                field="period_end_date"
                title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                cell={DateCell}
            />
            <GridColumn
                field="license_type"
                title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                cell={LicenseTypeCell}
            />
        </Grid>
    )
}

export default RequestDownloadGrid