import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMEARequestAgainHamburger from '../assets/img/Help/ksm-ea-request-again-hamburger.png';
import KSMEARequestAgainLicenseView from '../assets/img/Help/ksm-ea-request-again-license-view.png';
import KSMEARequestAgainStepper from '../assets/img/Help/ksm-ea-request-again-stepper.png';
import KSMEARequestAgainDatePicker from '../assets/img/Help/ksm-ea-request-again-date-picker.png';
import KSMEARequestAgainEndDatePicker from '../assets/img/Help/ksm-ea-request-again-end-date-picker.png';
import KSMEARequestAgainButton from '../assets/img/Help/ksm-ea-request-again-button.png';
import KSMEARequestAgainAssignProducts from '../assets/img/Help/ksm-ea-request-again-assign-products.png';
import KSMEARequestAgainRequestLicense from '../assets/img/Help/ksm-ea-request-again-request-license.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    aboutEAPortalRequestAgainKey,
    assigningProductsToHostKey,
    eaAccessKey,
    enterpriseAgreementsKey,
    frequentlyAskedQuestionsKey,
    helpEADetailedViewEAAccessQAKey,
    helpEADetailedViewEnterpriseAgreementsQAKey,
    helpEADetailedViewHostsQAKey,
    helpEADetailedViewLicensesQAKey,
    helpEARequestAgainAboutSectionKey,
    helpEARequestAgainAssignProductSectionKey,
    helpEARequestAgainKey,
    helpEARequestAgainNavigationSectionKey,
    helpEARequestAgainQuickStepsSectionKey,
    helpEARequestAgainRequestLicenseSectionKey,
    helpEARequestAgainSelectProductSectionKey,
    hostsKey,
    licensesKey,
    mainMessages,
    navigationKey,
    quickStepsRequestAgainKey,
    requestLicenseKey,
    selectingProductsKey
} from '../assets/text/MultilingualText.js';

function EARequestAgainHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;

        if (item.substeps && item.substeps.length) {
            children = type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        return (
            <li>
                {toHtml(item.step)}
                {children}
            </li>
        );
    }

    const helpContent = {
        'header': {
            key: helpEARequestAgainKey,
            default: mainMessages[siteLanguageDefault][helpEARequestAgainKey]
        },
        'topics': [
            {
                key: aboutEAPortalRequestAgainKey,
                default: mainMessages[siteLanguageDefault][aboutEAPortalRequestAgainKey],
                hash: '#about'
            },
            {
                key: quickStepsRequestAgainKey,
                default: mainMessages[siteLanguageDefault][quickStepsRequestAgainKey],
                hash: '#quick-steps'
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: '#navigation'
            },
            {
                key: selectingProductsKey,
                default: mainMessages[siteLanguageDefault][selectingProductsKey],
                hash: '#select-products'
            },
            {
                key: assigningProductsToHostKey,
                default: mainMessages[siteLanguageDefault][assigningProductsToHostKey],
                hash: '#assign-products'
            },
            {
                key: requestLicenseKey,
                default: mainMessages[siteLanguageDefault][requestLicenseKey],
                hash: '#request-license'
            }
        ],
        'faq': [
            {
                key: enterpriseAgreementsKey,
                default: mainMessages[siteLanguageDefault][enterpriseAgreementsKey],
                hash: '#enterprise-agreements-faq'
            },
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: '#licenses-faq'
            },
            {
                key: hostsKey,
                default: mainMessages[siteLanguageDefault][hostsKey],
                hash: '#hosts-faq'
            },
            {
                key: eaAccessKey,
                default: mainMessages[siteLanguageDefault][eaAccessKey],
                hash: '#ea-access-faq'
            }
        ]
    };

    const aboutContent = localization.toLanguageString(
        helpEARequestAgainAboutSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestAgainAboutSectionKey]
    );

    const quickStepsContent = localization.toLanguageString(
        helpEARequestAgainQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestAgainQuickStepsSectionKey]
    );

    const navigationContent = localization.toLanguageString(
        helpEARequestAgainNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestAgainNavigationSectionKey]
    );

    const selectProductsContent = localization.toLanguageString(
        helpEARequestAgainSelectProductSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestAgainSelectProductSectionKey]
    );

    const assignProductsContent = localization.toLanguageString(
        helpEARequestAgainAssignProductSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestAgainAssignProductSectionKey]
    );

    const requestLicenseContent = localization.toLanguageString(
        helpEARequestAgainRequestLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestAgainRequestLicenseSectionKey]
    );

    const enterpriseAgreementsQA = localization.toLanguageString(
        helpEADetailedViewEnterpriseAgreementsQAKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewEnterpriseAgreementsQAKey]
    );

    const licensesQA = localization.toLanguageString(
        helpEADetailedViewLicensesQAKey,
        mainMessages['en'][helpEADetailedViewLicensesQAKey]
    );

    const hostsQA = localization.toLanguageString(
        helpEADetailedViewHostsQAKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewHostsQAKey]
    );

    const eAAccessQA = localization.toLanguageString(
        helpEADetailedViewEAAccessQAKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewEAAccessQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3"}
                            id="about"
                        >
                            <Text
                                textkey={aboutEAPortalRequestAgainKey}
                                textdefault={mainMessages[siteLanguageDefault][aboutEAPortalRequestAgainKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {toHtml(aboutContent.section_one)}
                            </p>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsRequestAgainKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsRequestAgainKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {toHtml(quickStepsContent.section_one)}
                            </p>
                            <ol>
                                {quickStepsContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <ol>
                                {navigationContent.section_one_steps?.map((step, idx) => {
                                    return <>
                                        <OrderedListItem item={step} key={step.order}/>
                                        {
                                            idx === 0 && <img src={KSMEARequestAgainHamburger}
                                                              alt={'request-again-navigation-hamburger'}/>
                                        }
                                        {
                                            idx === 1 && <img src={KSMEARequestAgainLicenseView}
                                                              alt={'request-again-license-view'}/>
                                        }
                                        {
                                            idx === 2 && <img src={KSMEARequestAgainStepper}
                                                              alt={'request-again-navigation-stepper'}/>
                                        }
                                    </>;
                                })}
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="select-products"
                        >
                            <Text
                                textkey={selectingProductsKey}
                                textdefault={mainMessages[siteLanguageDefault][selectingProductsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <ol>
                                {selectProductsContent.section_one_steps?.map((step, idx) => {
                                    return <>
                                        <OrderedListItem item={step} key={step.order}/>
                                        {
                                            idx === 1 && <>
                                                <Row className={"my-1"}>
                                                    <img src={KSMEARequestAgainDatePicker}
                                                         alt={'request-again-date-picker'}/>
                                                </Row>
                                                <Row className={"my-1"}>
                                                    <img src={KSMEARequestAgainEndDatePicker}
                                                         alt={'request-again-end-date-picker'}/>
                                                </Row>
                                            </>
                                        }
                                        {
                                            idx === 2 && <Row className={"my-1"}>
                                                <img src={KSMEARequestAgainButton} alt={'request-again-button'}/>
                                            </Row>
                                        }
                                    </>;
                                })}
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="assign-products"
                        >
                            <Text
                                textkey={assigningProductsToHostKey}
                                textdefault={mainMessages[siteLanguageDefault][assigningProductsToHostKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <ol>
                                {assignProductsContent.section_one_steps?.map((step, idx) => {
                                    return <>
                                        <OrderedListItem item={step} key={step.order}/>
                                        {idx === 1 && <Row className={"my-1"}>
                                            <img src={KSMEARequestAgainAssignProducts}
                                                 alt={'request-again-assign-products'}/>
                                        </Row>}
                                    </>;
                                })}
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="request-license"
                        >
                            <Text
                                textkey={requestLicenseKey}
                                textdefault={mainMessages[siteLanguageDefault][requestLicenseKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <ol>
                                {requestLicenseContent.section_one_steps?.map((step, idx) => {
                                    return <>
                                        <OrderedListItem item={step} key={step.order}/>
                                        {idx === 3 && <Row className={"my-1"}>
                                            <img src={KSMEARequestAgainRequestLicense}
                                                 alt={'request-again-request-license'}/>
                                        </Row>}
                                    </>;
                                })}
                            </ol>
                        </div>
                        <div className={"k-h3 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="enterprise-agreements-faq">
                            <h3>
                                <Text
                                    textkey={enterpriseAgreementsKey}
                                    textdefault={mainMessages[siteLanguageDefault][enterpriseAgreementsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {enterpriseAgreementsQA ? enterpriseAgreementsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"enterprise-agreements-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="licenses-faq">
                            <h3>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {licensesQA ? licensesQA.map((questionAnswer, index) => {
                                    /*note: remove question: When can I get a new set of licenses for the next period of my EA? (does not apply to request again)*/
                                    return (
                                        index === 1 ? <></> : <div key={"licenses-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="hosts-faq">
                            <h3>
                                <Text
                                    textkey={hostsKey}
                                    textdefault={mainMessages[siteLanguageDefault][hostsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {hostsQA ? hostsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"hosts-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="ea-access-faq">
                            <h3>
                                <Text
                                    textkey={eaAccessKey}
                                    textdefault={mainMessages[siteLanguageDefault][eaAccessKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {eAAccessQA ? eAAccessQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"ea-access-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EARequestAgainHelp;

