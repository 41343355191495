import * as React from "react";
import {convertDateFormat} from "../../common/Grid.js";

// new support end date cell for standalone renewal licenses grid
export const EndDateCell = (props) => {
    const field = props.field

    const hidden = {
        fontSize: "0",
        color: "transparent"
    };
    const visible = {
        fontSize: "inherit",
        color: "inherit"
    };

    let style = field === "new_support_end_date" && props.dataItem.selected ? visible
        : hidden;

    return (
        <td style={style}>
            {convertDateFormat(props.dataItem[field])}
        </td>
    );
}