import React, {useContext, useRef, useState, useEffect} from 'react';
import uuid from 'react-uuid';
import axios from 'axios';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {DateCell, NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';
import Alert from '../../common/Alert.js';
import {toHtml} from '../../common/utilities.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';
import {Label} from '@progress/kendo-react-labels';
import {Popover} from '@progress/kendo-react-tooltip';
import {AutoComplete} from '@progress/kendo-react-dropdowns';
import {filterBy, orderBy} from '@progress/kendo-data-query';
import {NumericTextBox} from '@progress/kendo-react-inputs';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cancelChangesKey,
    contactUsHeaderKey,
    genericErrorTitleKey,
    hostIdFormattingKey,
    mainMessages,
    productDescriptionKey,
    licenseExpirationDateKey,
    productNumberKey,
    versionKey,
    quantityToReassignKey,
    rehostedFromKey,
    saveHostIDKey,
} from '../../../assets/text/MultilingualText.js';
import KSMPopover from "../../common/Popovers";
import {InfoIcon} from "../../common/icons";


export const ReassignModal = (props) => {
    const {
        setIsLoading,
        siteLanguageDefault,
        closeReassignModal,
        product,
        hosts,
        setHosts,
        setDisableUndoChanges,
        hostIDTypeDetails,
    } = props;
    const {accessToken} = useContext(UserContext);
    const localization = useLocalization();

    const [isVisible, setIsVisible] = useState({
        hostIDHint: false,
    })
    const [hostID, setHostID] = useState("");
    const hostIDAnchor = useRef(null);
    const hostIDPopover = useRef(null);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isValidHostID, setIsValidHostID] = useState(true);

    const validateHostRegEx = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let data = {
            "host_id_type": hostIDTypeDetails.host_id_type,
            "host_or_imei_values": [hostID],
            "serial_id_value": ''
        };

        setIsLoading(true);

        axios.post(
            config.request_license.HOST_TYPE_DETAILS,
            data,
            {headers: headers}
        )
            .then((response) => {
                setError('');

                //Validate one per host
                if (product.redeem_one_per_host === 'Y') {
                    let hostIndex = hosts.findIndex(oldHost => oldHost.host_id === hostID);
                    if (hostIndex >= 0) {
                        let productIndex = hosts[hostIndex].products.findIndex(oldProduct => oldProduct.product_id === product.product_id);
                        if (productIndex >= 0) {
                            setError('One_Per_Host');
                            return null;
                        }
                    }
                }

                //Decrease product qty from host that's holding the current product
                let hostIndex = hosts.findIndex(oldHost => oldHost.host_id === product.new_host_id);
                let productIndex = hosts[hostIndex].products.findIndex(oldProduct => oldProduct.product_id === product.product_id); //TODO products on load need to be rolled up
                hosts[hostIndex].products.splice(productIndex, 1) //remove product from old host
                if (hosts[hostIndex].products.length === 0) { //delete old host with no product
                    hosts.splice(hostIndex, 1);
                }

                //Update or create new host
                hostIndex = hosts.findIndex(newHost => newHost.host_id === hostID);
                const note = localization.toLanguageString(rehostedFromKey, mainMessages[siteLanguageDefault][rehostedFromKey]) + ': ' + product.original_host_id;
                if (hostIndex < 0) { //new host
                    let newProduct = JSON.parse(JSON.stringify(product));
                    newProduct.new_host_id = hostID;
                    newProduct.notes = note;
                    newProduct.qty = Number(product.qty);
                    let newHost = {
                        host_id: hostID,
                        products: [newProduct],
                        transaction_id: uuid(),
                        icons: {
                            loading: true,
                            download: false,
                            generalError: false,
                            networkError: false,
                        }
                    };
                    setHosts([...hosts, newHost]);
                } else { //old host
                    let newProduct = JSON.parse(JSON.stringify(product));
                    newProduct.new_host_id = hostID;
                    newProduct.notes = note;
                    newProduct.qty = Number(product.qty);
                    hosts[hostIndex].products.push(newProduct);
                    setHosts([...hosts]);
                }
                setDisableUndoChanges(false);
                closeReassignModal();
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Validation Regex", error);
                let errorCode = typeof error.response.data.error_code === 'undefined' ? "generic" : error.response.data.error_code;
                setError(errorCode);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const onSubmit = () => {
        setHostID(hostID.trim());

        //Validate host ID
        let clientValidate = !hostID ? false : hostIDTypeDetails.host_id_info.patterns.some(regex => new RegExp(regex).test(hostID.trim()));
        if (!clientValidate) {
            setError('MOD_HSVAL_211');
            return null;
        }
        validateHostRegEx();
    }

    const setError = (errorCode) => {
        setErrorTitle('');
        setErrorMessage('');

        switch (errorCode) {
            case '':
                setIsValidHostID(true);
                break;
            case 'MOD_HSVAL_211': //host id validation error
                setErrorTitle(localization.toLanguageString(quantityToReassignKey, mainMessages[siteLanguageDefault][quantityToReassignKey]) + ' ' + hostIDTypeDetails.host_id_info.asl_prompt);
                setErrorMessage(toHtml(hostIDTypeDetails.host_id_info.asl_error_message));
                setIsValidHostID(false);
                break;
            case 'One_Per_Host':
                //TODO need to figure out multilingual solution
                setErrorTitle("Product " + product.product_number + " has a limit of 1 per host and cannot be added again");
                setErrorMessage('The product is already assigned to the same host and cannot be added again.');
                // setErrorTitle(localization.toLanguageString(renewEesofOnePerHostErrorTitle, mainMessages[siteLanguageDefault][renewEesofOnePerHostErrorTitle]) + ': ' + product.product_number )
                // setErrorMessage(localization.toLanguageString(renewEesofOnePerHostErrorMessage, mainMessages[siteLanguageDefault][renewEesofOnePerHostErrorMessage]))
                break
            default: // generic error
                setErrorTitle(localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]));
                setErrorMessage(localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey]));
        }
    }

    // Filter the Host ID suggestion dropdown based on user's input
    const [filteredHostIDSuggestions, setFilteredHostIDSuggestions] = useState([...hostIDTypeDetails.user_profile_tagged_host_ids]);
    const filterData = (value) => {
        const data = hostIDTypeDetails.user_profile_tagged_host_ids;
        const filter = {
            value: value,
            operator: "contains",
            ignoreCase: true,
        };
        if (value) {
            setFilteredHostIDSuggestions(filterBy(data, filter));
        } else {
            setFilteredHostIDSuggestions(data);
        }
    };

    // set focus to host id input field when host modal is HOST
    useEffect(() => {
        hostIDAnchor.current._input.focus();
    }, []);

    return (
        <Dialog
            className={"ksm-dialog"}
            title={<div
                style={{
                    fontSize: '1.5rem',
                    fontWeight: '400',
                    color: "var(--color-text-primary)"
                }}
            >
                {localization.toLanguageString(rehostedFromKey, mainMessages[siteLanguageDefault][rehostedFromKey])}: {product.original_host_id}
            </div>}
            onClose={closeReassignModal}
            width={"64.625rem"}
        >
            <Row
                style={{
                    marginBottom: '0.5rem'
                }}
            >
                <Col>
                    <div style={{
                        display: 'flex',
                        alignItems: 'end',
                        gap: '0.25rem',
                    }}>
                        <Label>{toHtml(hostIDTypeDetails.host_id_info.asl_prompt)}</Label>
                        <InfoIcon
                            onClick={() => {
                                setIsVisible(isVisible => ({
                                    ...isVisible,
                                    hostIDHint: !isVisible.hostIDHint
                                }));
                            }}
                            size={"medium"}
                            style={{
                                color: 'var(--keysight-secondary)',
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <KSMPopover
                        show={isVisible.hostIDHint}
                        setShowHandler={() => {
                            setIsVisible(isVisible => ({
                                ...isVisible,
                                hostIDHint: false,
                            }));
                        }}
                        anchor={hostIDPopover.current}
                        position={'right'}
                        type={'warning'}
                        style={{maxWidth: 500}}
                        message={
                            <>
                                <b>
                                    {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                </b>
                                <br/>
                                {toHtml(hostIDTypeDetails.host_id_info.input_hint)}
                            </>
                        }
                    />
                    <div ref={hostIDPopover}>
                        <AutoComplete
                            ref={hostIDAnchor}
                            value={hostID}
                            data={filteredHostIDSuggestions}
                            valid={isValidHostID}
                            onChange={(e) => {
                                filterData(e.value);
                                setHostID(e.value);
                            }}
                            listNoDataRender={(e) => null}
                        />
                    </div>
                </Col>
                <Col>
                    <Label
                        style={{
                            display: 'inline-block',
                        }}
                    >
                        {localization.toLanguageString(quantityToReassignKey, mainMessages[siteLanguageDefault][quantityToReassignKey])}:
                    </Label>
                    <NumericTextBox
                        value={Number(product.qty)}
                        spinners={false}
                        disabled={true}
                        format={{
                            useGrouping: false,
                        }}
                    />
                </Col>
            </Row>
            <Grid
                className={'reassign-modal-grid'}
                data={orderBy([product], [{
                    field: "product_number",
                    dir: "desc"
                }])}
                scrollable={'none'}
                sortable={false}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <GridColumn
                    field="product_number"
                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="product_desc"
                    title={localization.toLanguageString(productDescriptionKey, mainMessages[siteLanguageDefault][productDescriptionKey])}
                />
                <GridColumn
                    field="version"
                    title={localization.toLanguageString(versionKey, mainMessages[siteLanguageDefault][versionKey])}
                />
                <GridColumn
                    field="lic_exp_date"
                    title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                    cell={DateCell}
                />
                <GridColumn
                    field="qty"
                    title="Total Qty"
                    cell={TextAlignMiddleCell}
                />
            </Grid>
            {errorTitle !== '' && errorMessage !== '' && (
                <div
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Alert
                        type={'error'}
                        title={errorTitle}
                        message={errorMessage}
                    />
                </div>
            )}
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    type={"button"}
                    onClick={closeReassignModal}
                >
                    {localization.toLanguageString(cancelChangesKey, mainMessages[siteLanguageDefault][cancelChangesKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    onClick={onSubmit}
                >
                    {localization.toLanguageString(saveHostIDKey, mainMessages[siteLanguageDefault][saveHostIDKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}
