import React, {useContext} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/App.css';

// react router
import {useHistory} from 'react-router-dom';

// components
import IconCell from './IconCell.js'

// kendo react
import {GRID_COL_INDEX_ATTRIBUTE} from '@progress/kendo-react-grid';
import {useTableKeyboardNavigation} from '@progress/kendo-react-data-tools';
import {Tooltip} from '@progress/kendo-react-tooltip';

function SortableLinkCell({props, setIsLoading}) {
    const field = props.field || "";
    let value = props.dataItem[field];
    value = JSON.parse(value);
    const disabled = (props?.disabled) ? props.disabled : false;
    const text = value.text;
    const url = value.link;
    const {accessToken} = useContext(UserContext);
    const type = value?.type;
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const isIcon = (type) ? true : false;
    let updateItemId = props.dataItem['UpdateItemId'];
    let params = (new URL(document.location)).searchParams;
    let updateId = params.get('uuid');
    let history = useHistory();
    let update_item_ids = [];

    const redirect = (actionLink) => {
        var r = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (!r.test(actionLink)) {
            history.push(actionLink);
        } else {
            try {
                setIsLoading(true)
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                };
                let urls = [actionLink];
                update_item_ids.push(updateItemId);
                let data = {
                    "urls": urls,
                    "update_item_ids": update_item_ids,
                    "update_id": updateId
                };
                let url = config.software_updates.GET_SOFTWARE_UPDATE_URL;
                axios.post(
                    url,
                    data,
                    {
                        headers: headers
                    }
                )
                    .then((response) => {
                        if (response.status === 200) {
                            let presigned_urls = response.data || "";
                            presigned_urls.forEach((presigned_url, index) => {
                                let a = document.createElement("a");
                                a.setAttribute('href', presigned_url);
                                a.setAttribute('download', '');
                                a.setAttribute('target', '_blank');
                                a.click();
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("ERROR: Failed to get presigned url", error);
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            } catch (error) {
                console.log('err', error);
            }
        }
    };

    return !isIcon ? (
        <td
            style={{}}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{[GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex}}
            {...navigationAttributes}
        >
            {disabled ? (<Tooltip
                openDelay={100}
                position="right"
                anchorElement='target'
            >
                <button
                    disabled={disabled}
                    className={"ksm-anchor-button"}
                    onClick={() => {
                        redirect(url.toString());
                    }}
                    title={text.toString().replace(/(<([^>]+)>)/ig, '')}
                >
                    <div
                        title={text.toString().replace(/(<([^>]+)>)/ig, '')}
                        dangerouslySetInnerHTML={{__html: text}}
                    >
                    </div>
                </button>
            </Tooltip>) : (<button
                    disabled={disabled}
                    className={"ksm-anchor-button"}
                    onClick={() => {
                        redirect(url.toString());
                    }}
                >
                    <div dangerouslySetInnerHTML={{__html: text}}></div>
                </button>
            )}
        </td>
    ) : (<td
        style={{}}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{[GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex}}
        {...navigationAttributes}
    >
        <div
            className={"ksm-anchor-button"}
            onClick={() => {
                redirect(url.toString());
            }}
            style={{"textDecoration": "none"}}
        >
            <IconCell {...props}/>
        </div>
    </td>);
}

export default SortableLinkCell;