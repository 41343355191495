import React, {useContext} from 'react';
import config from '../../config.js';
import UserContext from './UserContext.js';

// css
import '../../assets/css/App.css';

// kendo react
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from "@progress/kendo-react-buttons";

// multilingual
import {Text} from './MultilingualText.js';
import {loginKey, sessionExpiredKey, mainMessages} from '../../assets/text/MultilingualText.js';
import {ClockIcon} from "./icons";


function Expired() {
    const {siteLanguageDefault} = useContext(UserContext);

    /*
     * relogin() sends user to ksm login and sets access token to null
    */
    const relogin = () => {
        window.localStorage.setItem('logout', Date.now());
        window.location.href = config.sso.SSO_WELCOME_URL + '?site=ext';
    }

    return (
        <Dialog width={'21rem'}>
            <div style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem'
            }}>
                <div>
                    <ClockIcon size={'xxxlarge'}/>
                </div>
                <h2 style={{margin: 0}}>
                    <Text
                        textkey={sessionExpiredKey}
                        textdefault={mainMessages[siteLanguageDefault][sessionExpiredKey]}
                    />
                </h2>
            </div>
            <DialogActionsBar>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={relogin}
                    className={"k-button"}
                >
                    <Text
                        textkey={loginKey}
                        textdefault={mainMessages[siteLanguageDefault][loginKey]}
                    />
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default Expired;