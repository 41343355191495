import React, {useCallback, useContext, useEffect, useState} from 'react';
import moment from 'moment';
import axios from 'axios';
import uuid from 'react-uuid';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/EARequestLicense.css';

// react router
import {useHistory} from 'react-router-dom';

// components
import Spinner from '../components/common/Spinner.js';
import KSMStepper from '../components/common/Stepper.js';
import PageHeader from '../components/common/PageHeader.js';
import SelectProducts from '../components/EARequestLicense/SelectProducts.js';
import AssignProducts from '../components/EARequestLicense/AssignProducts.js';
import RequestDownload from '../components/EARequestLicense/RequestDownload.js';
import SummaryChart from '../components/EARequestLicense/SummaryChart.js';
import {convertDateFormat} from '../components/common/Grid.js';
import {capitalizeSentence, multiplyMoney} from '../components/common/utilities.js';

// kendo react
import {orderBy} from "@progress/kendo-data-query";

// multilingual
import {Text} from '../components/common/MultilingualText.js';
import {useLocalization} from '@progress/kendo-react-intl';
import {
    hostPendingSpecialKey,
    anyKey,
    enterpriseLicensingKey,
    navigationAlertKey,
    productsHostKey,
    requestLicensesKey,
    selectProductsKey,
    mainMessages
} from '../assets/text/MultilingualText.js';


function EARequestLicense(props) {
    const {
        siteLanguage,
        siteLanguageDefault,
        accessToken
    } = useContext(UserContext);
    const localization = useLocalization();
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const defaultStepperItems = [
        {
            label: localization.toLanguageString(selectProductsKey, mainMessages[siteLanguageDefault][selectProductsKey]),
            number: 1, //number icon
            disabled: false
        },
        {
            label: localization.toLanguageString(productsHostKey, mainMessages[siteLanguageDefault][productsHostKey]),
            number: 2,
            disabled: true
        },
        {
            label: localization.toLanguageString(requestLicensesKey, mainMessages[siteLanguageDefault][requestLicensesKey]),
            number: 3,
            disabled: true
        },
    ];

    const [stepperItems, setStepperItems] = useState(defaultStepperItems);
    const [stepValue, setStepValue] = useState(0);

    //Change step activation for the stepper
    const changeStepActivation = (stepChange) => {
        if (stepValue === 0) {
            //If no unsaved changes then skip
            if (unsavedChanges.size === 0) {
                //next step
                if (stepChange > 0) {
                    if (stepChange === 1) {
                        stepperItems[1].disabled = false
                    } else {
                        stepperItems[1].disabled = false
                        stepperItems[2].disabled = false
                        if (headerInfo.remix_type?.toLowerCase() === 'variable') {
                            saveAssignments(stepChange)
                        }
                    }
                    setStepValue(stepChange);
                    setStepperItems(stepperItems);
                }
                return null
            } else {
                saveSelections(stepChange)
            }
        }

        if (stepValue === 1) {
            if (stepChange === 2) {
                stepperItems[stepChange].disabled = false;
                // stepperItems[1].disabled = true;
            }
        }

        // save my work when changing from step 2 to step 1 or 3
        if (stepValue === 1) {
            if (stepChange === 0 || stepChange === 2) {
                saveAssignments(stepChange)
            }
        }

        setStepValue(stepChange);
        setStepperItems(stepperItems);
    };

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems(defaultStepperItems);
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, []);

    const [afterOnLoad, setAfterOnload] = useState(false)
    const currentURL = window.location.pathname + window.location.search

    //Confirm exiting page that is not caused by redirect or react router push
    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        if (afterOnLoad) {
            const unblock = history.block(() => {
                if (window.confirm(
                    localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
                )) {
                    return true
                } else {
                    window.history.replaceState(null, null, currentURL);
                    return false
                }
            });
            return () => {
                unblock();
            };
        }
    }, [afterOnLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    const [headerInfo, setHeaderInfo] = useState({
        "ea_id": '',
        "company_name": "",
        "start_date": "",
        "end_date": "",
        "remix_type": "",
        "remaining_pool": 0,
        "total_pool": 0,
        "selected_sub_pool": 0,
        "consumed_pool": 0,
        "currency": "USD",
        "locale": "en-US",
        "variableoversubflag": "",
        "subs_year": 0
    })
    const [scalingFactor, setScalingFactor] = useState([])
    const [softwareFamily, setSoftwareFamily] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [assignedProducts, setAssignedProducts] = useState([])
    const [unSavedPool, setUnSavedPool] = useState(0)
    const [unsavedChanges, setUnSavedChanges] = useState(new Set())
    const [unsavedAssignedChanges, setUnsavedAssignedChanges] = useState(new Set())
    const [showQuantityConsumedError, setShowQuantityConsumedError] = useState(false);
    const [showSaveSelectionGeneralErrorAlert, setShowSaveSelectionGeneralErrorAlert] = useState(false)
    const [showSaveSelectionOverErrorAlert, setShowSaveSelectionOverErrorAlert] = useState(false)
    const [eAMinDuration, setEAMinDuration] = useState(0);
    const [futureSubPoolValues, setFutureSubPoolValues] = useState({});
    const [fromRequestAgain, setFromRequestAgain] = useState(false);
    const [remixType, setRemixType] = useState("");

    useEffect(() => {
        headerInfo.remix_type ? setRemixType(headerInfo.remix_type?.toLowerCase()) : setRemixType("");
    }, [headerInfo]);

    const saveSelections = (stepChange) => {
        setShowSaveSelectionGeneralErrorAlert(false)
        setShowSaveSelectionOverErrorAlert(false)

        let savedProducts = []
        let successProducts = []
        for (const alloc_id of unsavedChanges) {
            const productIndex = selectedProducts.findIndex(item => item.ea_alloc_id === alloc_id)
            if (productIndex > -1) { //product has not been deleted
                const product = selectedProducts[productIndex]
                let qty = product.unassigned_quantity
                let productID = 0
                let licenseType = ''
                let basePrice = 0
                for (const license of product.license_type) {
                    if (license.selected) {
                        productID = license.product_id
                        licenseType = license.license_type
                        basePrice = license.base_price
                    }
                }

                successProducts.push({
                    ea_alloc_id: product.ea_alloc_id,
                    base_price: basePrice,
                    quantity: product.quantity,
                })

                savedProducts.push({
                    product_id: productID,
                    prod_num: product.prod_num_display,
                    status: "SELECTED",
                    quantity: qty,
                    ppks_flag: product.ppks_flag,
                    license_type: licenseType,
                    ea_alloc_id: product.ea_alloc_id,
                    start_date: product.saved_start_date,
                    end_date: product.saved_end_date,
                })
            }
        }

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        setIsLoading(true);

        let location = window.location
        let query = new URLSearchParams(location.search);
        const eaID = query.get('eaid');
        const periodType = query.get('show');

        let body = {
            module: "EA",
            sub_module: "allocations",
            action: "SAVE",
            input_json: [
                {
                    ea_id: eaID,
                    period_selection: periodType,
                    variableoversubflag: headerInfo.variableoversubflag,
                    ea_products: savedProducts
                }
            ]
        }

        axios.post(
            config.ea_request_license.SAVE_DELETE,
            body,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data
                    let eaHeaderInfo = data['sub_pool_data'][0]
                    headerInfo.total_pool = eaHeaderInfo.total_pool
                    headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                    setUnSavedPool(0)
                    headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                    headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                    setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))

                    //delete all rows with quantity 0 and no children assigned products
                    for (const alloc_id of unsavedChanges) {
                        const productIndex = selectedProducts.findIndex(item => item.ea_alloc_id === alloc_id)
                        if (productIndex > -1) { //product has not been deleted
                            const product = selectedProducts[productIndex]
                            if (product.quantity === 0 && Object.keys(props.dataItem['status_display']).length === 0) {
                                selectedProducts.splice(productIndex, 1)
                            }
                        }
                    }

                    let newProducts = selectedProducts.map((product) => {
                        for (const saved of successProducts) {
                            //update saved values
                            if (product.ea_alloc_id === saved.ea_alloc_id) {
                                product.saved_quantity = saved.quantity
                                product.saved_cost = saved.base_price
                            }
                        }
                        return product
                    })

                    setSelectedProducts([...newProducts])


                    //reset unsaved changes
                    setUnSavedChanges(new Set())

                    //next step
                    if (stepChange > 0) {
                        if (stepChange === 1) {
                            stepperItems[1].disabled = false
                        } else {
                            stepperItems[1].disabled = false
                            stepperItems[2].disabled = false
                        }
                        setStepValue(stepChange);
                        setStepperItems(stepperItems);
                    }
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to save products", error);
                if (error.response.hasOwnProperty('data')) {
                    let errorCode = error.response.data['error_code'].toUpperCase()
                    if (errorCode === 'MOD_EA_158' || errorCode === 'MOD_EA_132') {
                        setShowSaveSelectionOverErrorAlert(true)
                    } else {
                        setShowSaveSelectionGeneralErrorAlert(true)
                    }
                    if (error.response.data.hasOwnProperty('sub_pool_data')) {
                        let eaHeaderInfo = error.response.data['sub_pool_data'][0]
                        headerInfo.total_pool = eaHeaderInfo.total_pool
                        headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                        headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                        headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                        setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))
                    }
                } else {
                    setShowSaveSelectionGeneralErrorAlert(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /*
     * saveAssignments() saves all assignments prior to advancing to a step
     * @param {stepChange} the step index to move to
    */
    const saveAssignments = async (stepChange) => {
        if (unsavedAssignedChanges.size === 0 && headerInfo.remix_type?.toLowerCase() != 'variable') {
            setStepValue(stepChange);
            return;
        }
        await saveMyWork("SAVE", undefined, undefined)
            .then((response) => {
                if (response.status === 200) {
                    // update sub pool
                    let data = response.data;
                    let eaHeaderInfo = data.sub_pool_data[0];
                    headerInfo.total_pool = eaHeaderInfo.total_pool;
                    headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool;
                    headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool;
                    headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                    setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)));

                    if (headerInfo.variableoversubflag === 'TRUE') {
                        for (const pool of data['list_sub_pool_values']) {
                            const year = pool.subpoolyear
                            futureSubPoolValues[year].availablesubscriptionpool = pool.availablesubscriptionpool
                            futureSubPoolValues[year].redeemedsubscriptionpool = pool.redeemedsubscriptionpool
                            futureSubPoolValues[year].selectedsubscriptionpool = pool.selectedsubscriptionpool
                        }
                        setFutureSubPoolValues(JSON.parse(JSON.stringify(futureSubPoolValues)))
                    }

                    // update assigned products
                    let hosts = [...assignedProducts];
                    hosts.forEach(host => {
                        host.products.forEach(product => {
                            product.unassigned_quantity = product.assign_quantity;
                        });
                    });
                    setAssignedProducts(hosts);

                    setUnsavedAssignedChanges(new Set());
                    setStepValue(stepChange);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Save My Work Assigned Products", error);
                if (error.response.hasOwnProperty('data')) {
                    let errorCode = error.response.data['error_code'].toUpperCase();
                    errorCode === 'MOD_EA_158' || errorCode === 'MOD_EA_132' ? setShowSaveSelectionOverErrorAlert(true)
                        : setShowSaveSelectionGeneralErrorAlert(true);
                    errorCode === 'MOD_EA_161' ? setShowQuantityConsumedError(true) : setShowQuantityConsumedError(false);

                    if (error.response.data.hasOwnProperty('sub_pool_data')) {
                        let eaHeaderInfo = error.response.data['sub_pool_data'][0]
                        headerInfo.total_pool = eaHeaderInfo.total_pool
                        headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                        headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                        headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                        setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))
                    }

                    if (error.response.data.hasOwnProperty('list_sub_pool_values')) {
                        if (headerInfo.variableoversubflag === 'TRUE') {
                            for (const pool of error.response.data['list_sub_pool_values']) {
                                const year = pool.subpoolyear
                                futureSubPoolValues[year].availablesubscriptionpool = pool.availablesubscriptionpool
                                futureSubPoolValues[year].redeemedsubscriptionpool = pool.redeemedsubscriptionpool
                                futureSubPoolValues[year].selectedsubscriptionpool = pool.selectedsubscriptionpool
                            }
                            setFutureSubPoolValues(JSON.parse(JSON.stringify(futureSubPoolValues)))
                        }
                    }

                }
                setIsLoading(false);
            })
    }

    /*
     * saveRemovals() saves all removals
     * @param {products} the array of products to be removed
     * @param {transactionID} the id of the host of products to be removed
    */
    const saveRemovals = async (products, transactionID) => {
        await saveMyWork("DELETE", products, transactionID)
            .then((response) => {
                if (response.status === 200) {
                    // update sub pool
                    let data = response.data;
                    let eaHeaderInfo = data.sub_pool_data[0];
                    headerInfo.total_pool = eaHeaderInfo.total_pool;
                    headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool;
                    headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool;
                    headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                    setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)));

                    if (headerInfo.variableoversubflag === 'TRUE') {
                        for (const pool of data['list_sub_pool_values']) {
                            const year = pool.subpoolyear
                            futureSubPoolValues[year].availablesubscriptionpool = pool.availablesubscriptionpool
                            futureSubPoolValues[year].redeemedsubscriptionpool = pool.redeemedsubscriptionpool
                            futureSubPoolValues[year].selectedsubscriptionpool = pool.selectedsubscriptionpool
                        }
                        setFutureSubPoolValues(JSON.parse(JSON.stringify(futureSubPoolValues)))
                    }

                    remove(products, transactionID);

                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to DELETE Save My Work Assigned Products", error);
                if (error.response.hasOwnProperty('data')) {
                    let errorCode = error.response.data['error_code'].toUpperCase();
                    errorCode === 'MOD_EA_158' || errorCode === 'MOD_EA_132' ? setShowSaveSelectionOverErrorAlert(true) : setShowSaveSelectionGeneralErrorAlert(true);

                    if (error.response.data.hasOwnProperty('sub_pool_data')) {
                        let eaHeaderInfo = error.response.data['sub_pool_data'][0]
                        headerInfo.total_pool = eaHeaderInfo.total_pool
                        headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                        headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                        headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                        setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))
                    }

                    if (error.response.data.hasOwnProperty('list_sub_pool_values')) {
                        if (headerInfo.variableoversubflag === 'TRUE') {
                            for (const pool of error.response.data['list_sub_pool_values']) {
                                const year = pool.subpoolyear
                                futureSubPoolValues[year].availablesubscriptionpool = pool.availablesubscriptionpool
                                futureSubPoolValues[year].redeemedsubscriptionpool = pool.redeemedsubscriptionpool
                                futureSubPoolValues[year].selectedsubscriptionpool = pool.selectedsubscriptionpool
                            }
                            setFutureSubPoolValues(JSON.parse(JSON.stringify(futureSubPoolValues)))
                        }
                    }
                }
                setIsLoading(false);
            })
    }

    /*
     * saveMyWork() saves all changes to hosts
     * @param {action} the api action (ie. save or delete)
     * @param {deletions} the array of products to be removed
     * @param {transactionID} the id of the host of products to be removed
     * @return {axios} returns the axios response
    */
    const saveMyWork = async (action, deletions = [], transactionID = null) => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let periodSelection = new URLSearchParams(window.location.search).get("show");

        let products = [];

        if (action === "DELETE") {
            products = deletions.map(product => {
                return {
                    "ea_alloc_id": product.ea_alloc_id,
                    "status": "ASSIGNED",
                }
            })
        } else {
            let hosts = [...assignedProducts];
            hosts.forEach(host => {
                host.products.forEach(product => {
                    products.push({
                        "ea_alloc_id": product.ea_alloc_id,
                        "product_id": product.product_id,
                        "prod_num": product.prod_num_display,
                        "status": "ASSIGNED",
                        "quantity": product.assign_quantity,
                        "ppks_flag": product.ppks_flag,
                        "license_type": product.license_type
                            .filter(lt => lt.selected)[0].license_type,
                        "period_start_date": moment(product.period_start_date).format('YYYY-MM-DD HH:mm:ss'),
                        "period_end_date": moment(product.period_end_date).format('YYYY-MM-DD HH:mm:ss')
                    });
                })
            })
        }

        let data = {
            module: "EA",
            sub_module: "allocations",
            action: action,
            input_json: [
                {
                    "ea_id": headerInfo.ea_id,
                    "period_selection": periodSelection,
                    variableoversubflag: headerInfo.variableoversubflag,
                    "ea_products": products
                }
            ]
        }

        setShowSaveSelectionGeneralErrorAlert(false);
        setShowSaveSelectionOverErrorAlert(false);
        setShowQuantityConsumedError(false);

        setIsLoading(true);

        // console.log("save my work", action, data);

        return axios({
            method: action === "DELETE" ? "delete" : "post",
            url: config.ea_request_license.SAVE_DELETE,
            data: data,
            headers: headers,
        });
    }

    /*
     * remove() removes product(s) from a host
     * @param {products} the array of products to be removed
     * @param {transactionID} the id of the host of products to be removed
    */
    const remove = (products, transactionID) => {
        let selectedProductUpdates = [...selectedProducts];
        let assignedProductUpdates = [...assignedProducts];

        products.forEach(product => {
            let productIndex = selectedProductUpdates.findIndex(s => s.ea_alloc_id === product.parent_alloc_id);
            let hostIndex = assignedProductUpdates.findIndex(a => a.transaction_id === transactionID);

            if (product.module_name != 'REQUEST_AGAIN') {

                if (selectedProducts[productIndex]) {
                    // update quantity in selectedProducts if product exists
                    let qty = selectedProductUpdates[productIndex].unassigned_quantity += parseInt(product.unassigned_quantity);
                    selectedProductUpdates[productIndex].unassigned_quantity = qty;

                    //remove from status display array
                    delete selectedProductUpdates[productIndex].status_display[product.ea_alloc_id]
                } else {
                    // add product back to selectedProducts if product does not exist
                    let selectedProduct = product;
                    selectedProduct.ea_alloc_id = product.parent_alloc_id;
                    delete selectedProduct.parent_alloc_id;
                    selectedProductUpdates = [...selectedProductUpdates, selectedProduct];
                }

            }
            ;

            if (assignedProducts[hostIndex]) {
                // remove product from host
                let products = assignedProductUpdates[hostIndex].products;
                products = products.filter(p => p.ea_alloc_id !== product.ea_alloc_id);
                assignedProductUpdates[hostIndex].products = products;

                // remove host if no products
                if (products.length <= 0) {
                    let hosts = assignedProductUpdates.filter(a => a.transaction_id !== transactionID);
                    assignedProductUpdates = hosts;
                }
            }
        })

        setSelectedProducts(
            orderBy(
                selectedProductUpdates.map((product) => {
                    let license = product.license_type.filter(lt => lt.selected);
                    product.selected = false;
                    product.assign_quantity = license[0].redeem_one_per_host_flag === "Y" ? 1
                        : product.unassigned_quantity === 1 ? 1
                            : null;
                    product.status = "SELECTED";
                    return product;
                }),
                [{field: 'ea_alloc_id', dir: 'asc'}]
            )
        );
        setAssignedProducts(assignedProductUpdates);
    }

    // equals compares two js arrays to see if they are equal
    const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

    //Process new selected/assigned products.
    const preprocessNewProducts = (headerInfo, product, type = "SELECTED") => {
        let newProduct = JSON.parse(JSON.stringify(product))
        newProduct.quantity = Number(newProduct.quantity)
        newProduct.saved_quantity = Number(newProduct.quantity)
        newProduct.unassigned_quantity = Number(newProduct.quantity)
        newProduct.status_display = {}
        newProduct.subPoolExceed = false
        newProduct.saved_start_date = newProduct.period_start_date
        newProduct.period_start_date = new Date(newProduct.period_start_date)
        newProduct.saved_end_date = newProduct.period_end_date
        newProduct.period_end_date = new Date(newProduct.period_end_date)
        newProduct.remix_type = headerInfo.remix_type
        newProduct.currency_code = headerInfo.currency
        newProduct.currency_locale = headerInfo.locale
        newProduct.saved_period_end_date = new Date(newProduct.period_end_date);
        newProduct.saved_period_start_date = new Date(newProduct.period_start_date);

        //Set default assign qty values
        if (type === "SELECTED") {
            let license = newProduct.license_type.filter(lt => lt.selected)
            newProduct.assign_quantity = license[0].redeem_one_per_host_flag === "Y" ? 1
                : newProduct.unassigned_quantity === 1 ? 1
                    : null;
        } else if (type === "ASSIGNED") {
            newProduct.assign_quantity = newProduct.quantity
        }

        const remixType = headerInfo?.remix_type ? headerInfo.remix_type.toLowerCase() : ""
        // case variable remix: start date is sysdate, start date is disabled, end date is duration of EA
        if (remixType === "variable") {
            if (type === "SELECTED") {
                newProduct.period_start_date = new Date();
                newProduct.period_start_date_disabled = true;
            } else if (type === "ASSIGNED") {
                newProduct.period_start_date_disabled = true;
            }
        }
        // case periodic remix: start/end dates are the period and start/end dates must be disabled
        else {
            if (type === "SELECTED") {
                newProduct.period_start_date_disabled = true;
                newProduct.period_end_date_disabled = true;
            } else if (type === "ASSIGNED") {
                newProduct.period_start_date_disabled = true;
                newProduct.period_end_date_disabled = true;
            }
        }

        //Setup license type values and cost
        for (const licenseType of newProduct.license_type) {
            licenseType.text = capitalizeSentence(licenseType.license_type, true)
            if (licenseType.selected) {
                newProduct.selected_license_type_text = licenseType.text
                newProduct.saved_cost = licenseType.base_price
            }
        }

        // change end date to sys date + ea default duration if ea default duration provided
        if (headerInfo.ea_default_duration && type === "SELECTED") {
            let eAEndDate = new Date(headerInfo.ea_end_date);
            let eaDefaultDuration = parseInt(headerInfo.ea_default_duration);
            let eaDefaultDurationDate = new Date();
            eaDefaultDurationDate.setDate(eaDefaultDurationDate.getDate() + eaDefaultDuration);

            // case: today + ea default duration > ea end date, set period end date to ea end date
            if (eaDefaultDurationDate > eAEndDate) {
                newProduct.period_end_date = eAEndDate;
            } else {
                newProduct.period_end_date = eaDefaultDurationDate
            }
        }

        return newProduct
    }

    useEffect(() => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        setIsLoading(true);
        let location = window.location
        let query = new URLSearchParams(location.search);
        const eaID = query.get('eaid');
        const periodType = query.get('show');
        const step = query.get('step');
        setFromRequestAgain(query.get('again') === "true");

        axios.get(
            config.ea_request_license.ON_LOAD + '?ea=' + eaID + '&period_selection=' + periodType,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];

                    //Preprocess and Set Header Info
                    const eaHeaderInfo = data['ea_header_info'][0]
                    eaHeaderInfo.ea_default_duration = data['ea_default_duration']
                    eaHeaderInfo.start_date = convertDateFormat(eaHeaderInfo.start_date)
                    eaHeaderInfo.end_date = convertDateFormat(eaHeaderInfo.end_date)
                    eaHeaderInfo.consumed_pool = eaHeaderInfo.total_pool - eaHeaderInfo.remaining_pool
                    setUnSavedPool(0)
                    setHeaderInfo(eaHeaderInfo)

                    //Preprocess and Set Selected Products
                    let eaSelectedProducts = []
                    for (const product of data['ea_products_selected']) {
                        if (Number(product.quantity) > 0 || product.haschild === 'Y') {
                            eaSelectedProducts.push(preprocessNewProducts(eaHeaderInfo, product, "SELECTED"))
                        }
                    }

                    //Preprocess and Set Assigned Products and update selected products
                    const eaAssignProducts = []
                    for (const product of data['ea_products_assigned']) {
                        let newProduct = preprocessNewProducts(eaHeaderInfo, product, "ASSIGNED")
                        const parentID = newProduct.parent_alloc_id
                        let parentIndex = eaSelectedProducts.findIndex(item => item.ea_alloc_id === parentID)

                        if (parentIndex > -1) {
                            eaSelectedProducts[parentIndex].quantity += newProduct.quantity
                            eaSelectedProducts[parentIndex].saved_quantity = eaSelectedProducts[parentIndex].quantity
                        }

                        let assignedIndex = eaAssignProducts.findIndex(host => equals(host.host_id, [newProduct.host_id]));

                        let id = uuid();
                        let license = newProduct.license_type.filter(lt => lt.selected);
                        let isPartial = license[0].ea_compatibility === "P" ? "Y" : "N";

                        // panelbar titles for assigned products
                        // host || multi:  Host {newProduct.host_id}
                        // file (c2v or bin): {newProduct.host_id} for c2v and bin
                        // file: {newProduct.file_name} for fingerprint
                        // special handling: Host Assignment Pending (Special Handling Required)
                        let title = (license[0].ea_compatibility === "P") ? localization.toLanguageString(hostPendingSpecialKey, mainMessages[siteLanguageDefault][hostPendingSpecialKey])
                            : (newProduct.host_id) ? newProduct.host_id
                                : (newProduct.file_name) ? newProduct.file_name
                                    : "";

                        // add alias to panel par title
                        product.alias ? title += (' "' + product.alias + '"') : title += "";

                        //Need these two fields for delete modal
                        newProduct.panel_bar_title = title
                        newProduct.transaction_id = id

                        if (assignedIndex === -1) {
                            eaAssignProducts.push({
                                transaction_id: id,
                                is_partial: isPartial,
                                host_id: [newProduct.host_id],
                                alias: newProduct.alias,
                                products: [newProduct],
                                panel_bar_title: title,
                                expanded: ['.0'],
                                icons: {
                                    checked: true,
                                },
                            })
                        } else {
                            newProduct.transaction_id = eaAssignProducts[assignedIndex].transaction_id
                            eaAssignProducts[assignedIndex].products.push(newProduct)
                        }
                    }

                    for (const product of eaSelectedProducts) {
                        let licenseType = product.license_type.filter(lt => lt.selected);
                        let price = licenseType[0].base_price;
                        product.total_cost = multiplyMoney(price, product.quantity)
                    }

                    setSelectedProducts(eaSelectedProducts)
                    setAssignedProducts(eaAssignProducts)

                    //Init stepper
                    if (eaSelectedProducts.length > 0) {
                        stepperItems[1].disabled = false
                    }
                    if (eaAssignProducts.length > 0) {
                        stepperItems[2].disabled = false
                    }
                    setStepperItems(stepperItems)

                    //Set scaling factor
                    setScalingFactor(data['ea_scaling_factor'])

                    // set ea min duration
                    setEAMinDuration(data['ea_min_duration']);

                    // set variable overflow sub object
                    if (eaHeaderInfo.variableoversubflag === 'TRUE') {
                        const subPools = {}
                        for (const pool of data['list_sub_pool_values']) {
                            pool.startdate = new Date(pool.startdate)
                            pool.enddate = new Date(pool.enddate)
                            subPools[pool.subpoolyear] = pool
                        }
                        setFutureSubPoolValues(subPools)
                    }

                    //Preprocess and Set software product family
                    const eaSoftwareFamily = data['ea_product_family']
                    const families = [
                        {
                            id: 0,
                            text: localization.toLanguageString(anyKey, mainMessages[siteLanguageDefault][anyKey])
                        }
                    ]
                    eaSoftwareFamily.forEach((family, index) => {
                        families.push({
                            id: index + 1,
                            text: family
                        })
                    })
                    setSoftwareFamily(families)

                    //Switch to step 2 on copy products and hosts
                    if (step && step === "2" && eaAssignProducts.length > 0) changeStepActivation(1);
                }
                setIsLoading(false)
                setAfterOnload(true)
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET EA Request Licenses On Load data", error);
                setAfterOnload(false)
                history.push('/error');
            })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // update status display field in selected products
    useEffect(() => {
        let updates = [...selectedProducts];
        assignedProducts.forEach(host => {
            host.products.forEach(product => {
                let index = updates.findIndex(item => item.ea_alloc_id === product.parent_alloc_id);
                if (updates[index]) {
                    updates[index].status_display[product.ea_alloc_id] = JSON.parse(JSON.stringify(product));
                    setSelectedProducts(updates);
                }
            })
        })
    }, [assignedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    // if there are no unassigned quantity available and assigned products then disable step 2 & 3
    useEffect(() => {
        stepperItems[1].disabled = true
        if (assignedProducts.length > 0) {
            stepperItems[1].disabled = false
        } else {
            let selectableProducts = selectedProducts.filter((product) => product.unassigned_quantity > 0).length
            if (selectableProducts > 0) {
                stepperItems[1].disabled = false
            }
        }
        stepperItems[2].disabled = assignedProducts.length === 0
        setStepperItems([...stepperItems]);
    }, [selectedProducts, assignedProducts]); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     console.log('headerInfo', headerInfo);
    // }, [headerInfo]);
    //
    // useEffect(() => {
    //     console.log('scaling factor', scalingFactor);
    // }, [scalingFactor]);
    //
    // useEffect(() => {
    //     console.log('selectedProducts', selectedProducts);
    // }, [selectedProducts]);
    //
    // useEffect(() => {
    //     console.log('assignedProducts', assignedProducts);
    // }, [assignedProducts]);

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <div style={{
                        whiteSpace: "nowrap"
                    }} className={"k-h3"}>
                        <Text
                            textkey={enterpriseLicensingKey}
                            textdefault={mainMessages[siteLanguageDefault][enterpriseLicensingKey]}
                        />
                    </div>
                    <div style={{
                        width: '27rem',
                        paddingLeft: '10rem',
                    }}>
                        <KSMStepper
                            stepperItems={stepperItems}
                            stepValue={stepValue}
                            changeStepActivation={changeStepActivation}
                        />
                    </div>
                    <div>
                        <SummaryChart
                            title={headerInfo.company_name}
                            startDate={headerInfo.start_date}
                            endDate={headerInfo.end_date}
                            totalValue={headerInfo.total_pool}
                            data={[
                                {
                                    "category": "consumed",
                                    "value": headerInfo.consumed_pool,
                                    "backgroundColor": "#432668",
                                    "fontColor": "#FFFFFF",
                                },
                                {
                                    "category": "selected",
                                    "value": headerInfo.selected_sub_pool + unSavedPool,
                                    "backgroundColor": "#A895B5",
                                    "fontColor": "#FFFFFF",
                                },
                                {
                                    "category": "available",
                                    "value": headerInfo.remaining_pool - headerInfo.selected_sub_pool - unSavedPool,
                                    "backgroundColor": "#FFFFFF",
                                    "fontColor": "#000000",
                                }
                            ]}
                            currency_code={headerInfo.currency}
                            currency_locale={headerInfo.locale}
                        />
                    </div>
                </div>
                {stepValue === 0 && (
                    <SelectProducts
                        changeStepActivation={changeStepActivation}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        headerInfo={headerInfo}
                        setHeaderInfo={setHeaderInfo}
                        softwareFamily={softwareFamily}
                        setIsLoading={setIsLoading}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                        preprocessNewProducts={preprocessNewProducts}
                        unsavedChanges={unsavedChanges}
                        setUnSavedChanges={setUnSavedChanges}
                        saveSelections={saveSelections}
                        showSaveSelectionGeneralErrorAlert={showSaveSelectionGeneralErrorAlert}
                        setShowSaveSelectionGeneralErrorAlert={setShowSaveSelectionGeneralErrorAlert}
                        showSaveSelectionOverErrorAlert={showSaveSelectionOverErrorAlert}
                        setShowSaveSelectionOverErrorAlert={setShowSaveSelectionOverErrorAlert}
                        unSavedPool={unSavedPool}
                        setUnSavedPool={setUnSavedPool}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                    />
                )}
                {stepValue === 1 && (
                    <AssignProducts
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                        changeStepActivation={changeStepActivation}
                        unsavedAssignedChanges={unsavedAssignedChanges}
                        setUnsavedAssignedChanges={setUnsavedAssignedChanges}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                        remixType={remixType}
                        scalingFactor={scalingFactor}
                        headerInfo={headerInfo}
                        setHeaderInfo={setHeaderInfo}
                        saveAssignments={saveAssignments}
                        saveRemovals={saveRemovals}
                        eAMinDuration={eAMinDuration}
                        futureSubPoolValues={futureSubPoolValues}
                        setFutureSubPoolValues={setFutureSubPoolValues}
                        setUnSavedPool={setUnSavedPool}
                        showQuantityConsumedError={showQuantityConsumedError}
                        setShowQuantityConsumedError={setShowQuantityConsumedError}
                    />
                )}
                {stepValue === 2 && (
                    <RequestDownload
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                        handleBeforeUnload={handleBeforeUnload}
                        changeStepActivation={changeStepActivation}
                        setIsLoading={setIsLoading}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                        headerInfo={headerInfo}
                        setHeaderInfo={setHeaderInfo}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        futureSubPoolValues={futureSubPoolValues}
                        setFutureSubPoolValues={setFutureSubPoolValues}
                        fromRequestAgain={fromRequestAgain}
                    />
                )}
                <br/>
            </div>
        </>
    );
}

export default EARequestLicense;

