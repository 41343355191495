import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import config from "../config";

//reactstrap
import {Col, Row} from "reactstrap";

// css
import '../assets/css/TransportKAL.css';

//multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    licenseIdsKey,
    licenseBelowNotValidKey,
    contactUsHeaderKey,
    genericErrorTitleKey,
    transportLicenseKey,
    alreadyTransportedKey,
    oneMoreLicensesNotAvailableKey,
    TransportInvalidParamsErrorMessage,
    TransportInvalidParamsErrorTitle,
    mainMessages,
    navigationAlertKey,
    transportLicenseLogInTOKSMKey,
    licensesNotLinkedKSMProfileKey,
    saveToKeysightSoftwareManagerKey,
    TransportSearchDirections,
    TransportSelectDirections,
    enterSelectLicensesKey,
    productsHostKey,
    reviewAndSubmitKey
} from "../assets/text/MultilingualText";

//components
import PageHeader from "../components/common/PageHeader";
import UserContext from "../components/common/UserContext";
import KSMStepper from "../components/common/Stepper";
import EnterLicenses from "../components/TransportKAL/EnterLicenses";
import {capitalizeSentence} from "../components/common/utilities";
import Alert from "../components/common/Alert";
import Spinner from "../components/common/Spinner";
import ReviewSubmit from "../components/TransportKAL/ReviewSubmit";
import AssignProducts from "../components/TransportKAL/AssignProducts";


export default function TransportKAL() {
    const {
        siteLanguage,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false);

    const defaultStepperItems = [
        {
            label: localization.toLanguageString(enterSelectLicensesKey, mainMessages[siteLanguageDefault][enterSelectLicensesKey]),
            number: 1,
            disabled: false
        },
        {
            label: localization.toLanguageString(productsHostKey, mainMessages[siteLanguageDefault][productsHostKey]),
            number: 2,
            disabled: true
        },
        {
            label: localization.toLanguageString(reviewAndSubmitKey, mainMessages[siteLanguageDefault][reviewAndSubmitKey]),
            number: 3,
            disabled: true
        },
    ]

    const [stepperItems, setStepperItems] = useState(defaultStepperItems);
    const [stepValue, setStepValue] = useState(0);

    //Changes step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {
        if (stepValue === 0) {
            removeAlerts()
            if (stepChange === 1) {
                stepperItems[stepChange].disabled = false;
                let newUnassigned = JSON.parse(JSON.stringify(licenses.filter(item => item.selected)))
                for (const item of newUnassigned) {
                    item.selected = false
                }
                setUnassigned(newUnassigned)
                setAssigned([])
            }

            if (stepChange === 2) {
                stepperItems[stepChange].disabled = false;
                let selected = JSON.parse(JSON.stringify(licenses.filter(item => item.selected)))
                const panelBarTitle = localization.toLanguageString(saveToKeysightSoftwareManagerKey, mainMessages[siteLanguageDefault][saveToKeysightSoftwareManagerKey])
                const newAssigned = [{
                    panelBarTitle: panelBarTitle,
                    transaction_id: 'banked',
                    products: selected
                }]
                setAssigned(newAssigned)
            }
        }

        if (stepValue === 1) {
            if (stepChange === 2) {
                stepperItems[stepChange].disabled = false;
            }
        }

        if (stepChange === 0) {
            setUnassigned([])
            setAssigned([])
            let id = []
            let code = []
            for (const item of licenses) {
                id.push(item.license_id)
                code.push(item.confirmation_code)
            }
            stepperItems[1].disabled = true;
            stepperItems[2].disabled = true;
            addLicenses(id, code, true)
        }

        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems(defaultStepperItems);
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [addForm, setAddForm] = useState(false)
    const selectDirections = localization.toLanguageString(TransportSelectDirections, mainMessages[siteLanguageDefault][TransportSelectDirections])
    const searchDirections = localization.toLanguageString(TransportSearchDirections, mainMessages[siteLanguageDefault][TransportSearchDirections])
    const [directions, setDirections] = useState(selectDirections)
    const [licenses, setLicenses] = useState([])
    const [xAuthToken, setxAuthToken] = useState('')
    const [unassigned, setUnassigned] = useState([])
    const [assigned, setAssigned] = useState([])
    const [transport, setTransport] = useState(false)

    const [hostDetails, setHostDetails] = useState({})

    const preprocessItems = (item) => {
        let newItem = JSON.parse(JSON.stringify(item))
        newItem.qty_redeemed = parseInt(item.qty_redeemed)
        newItem.status = capitalizeSentence(item.status)
        newItem.selected = false
        newItem.disabled = false
        return newItem
    }

    const removeAlerts = () => {
        setInvalidParams(false)
        setAddError([])
        setSaveError([])
        setNoLongerValid([])
        setGeneralError(false)
    }

    const addLicenses = (license_id = [], confirmation_code = [], reset = false) => {
        setIsLoading(true)
        removeAlerts()

        let queryParam = new URLSearchParams(search);
        let id = queryParam.getAll('id');
        let code = queryParam.getAll('code');

        if (license_id.length === 0 && confirmation_code.length === 0) {
            if (id.length !== code.length) {
                setInvalidParams(true)
                setIsLoading(false)
                return null
            }
        } else {
            queryParam = new URLSearchParams()
            for (const item of license_id) {
                queryParam.append('id', item)
            }
            for (const item of confirmation_code) {
                queryParam.append('code', item)
            }
        }

        let headers = {
            'Content-Type': 'application/json'
        }
        axios.get(
            config.transport.ON_LOAD,
            {
                headers: headers,
                params: queryParam
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    setxAuthToken(response.data['enc_code'])
                    let valid = response.data['valid_licenses']
                    valid = valid.map(item => preprocessItems(item))
                    if (reset) {
                        setLicenses(valid)
                    } else {
                        for (const item of valid) {
                            const containsID = !!licenses.find(license => {
                                return license.license_id === item.license_id
                            })
                            if (!containsID) {
                                licenses.push(item)
                            }
                        }
                        setLicenses([...licenses])
                    }

                    let invalid = response.data['invalid_licenses']
                    setAddError(invalid)

                    let details = response.data['host_details']
                    for (const item of details) {
                        for (const hostType in item) {
                            const detail = item[hostType]
                            hostDetails[hostType] = {
                                hostIDType: hostType,
                                hostIsSerial: detail['get_serial'].toUpperCase() || "",
                                hostIDLabel: detail['host_id_info']['asl_prompt'] || "",
                                hostIDHint: detail['host_id_info']['input_hint'] || "",
                                hostIDError: detail['host_id_info']['asl_error_message'] || "",
                                hostIDPatterns: detail['host_id_info']['patterns'] || [],
                                hostIDSuggestions: detail['user_profile_tagged_host_ids'] || [],
                                serialNumberLabel: detail['serial_number_info']['asl_prompt'] || "",
                                serialNumberHint: detail['serial_number_info']['input_hint'] || "",
                                serialNumberError: detail['serial_number_info']['asl_error_message'] || "",
                                serialNumberPatterns: detail['serial_number_info']['patterns'] || [],
                            }

                            hostDetails[hostType].hostIDPatterns = hostDetails[hostType].hostIDPatterns.map(pattern => new RegExp(pattern))
                            hostDetails[hostType].serialNumberPatterns = hostDetails[hostType].serialNumberPatterns.map(pattern => new RegExp(pattern))

                            if (hostDetails[hostType].hostIsSerial === "O") {
                                let emptyPattern = new RegExp("^$");
                                hostDetails[hostType].serialNumberPatterns.push(emptyPattern);
                            }
                        }
                    }
                    setHostDetails(hostDetails)
                }
            })
            .catch((error) => {
                console.log("ERROR: Get Licenses", error)
                if (error.response.data['error_code'] === "MOD_TRANSPORT_001") {
                    setInvalidParams(true)
                } else if (error.response.data['error_code'] === "MOD_TRANSPORT_002") {
                    setAddError(id)
                } else {
                    setGeneralError(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const [generalError, setGeneralError] = useState(false)
    const hideGeneralError = () => {
        setGeneralError(false)
    }

    const [invalidParams, setInvalidParams] = useState(false)
    const hideInvalidParams = () => {
        setInvalidParams(false)
    }

    const [addError, setAddError] = useState([])
    const hideAddError = () => {
        setAddError([])
    }

    const [saveError, setSaveError] = useState([])
    const hideSaveError = () => {
        setSaveError([])
    }

    const [noLongerValid, setNoLongerValid] = useState([])
    const hideNoLongerValid = () => {
        setNoLongerValid([])
    }

    const {search} = useLocation()
    useEffect(() => {
        let query = new URLSearchParams(search);
        let id = query.getAll('id');
        let code = query.getAll('code');

        if (id.length === 0 && code.length === 0) {
            setDirections(searchDirections)
            setAddForm(true)
        } else {
            setAddForm(false)
            setDirections(selectDirections)
            addLicenses()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={'ksm-page-container'}>
                <Row style={{
                    marginBottom: '0.938rem'
                }}>
                    <Col>
                        <div className={"k-h3"}>
                            {localization.toLanguageString(transportLicenseKey, mainMessages[siteLanguageDefault][transportLicenseKey])}
                        </div>
                    </Col>
                    <Col>
                        <KSMStepper
                            stepperItems={stepperItems}
                            stepValue={stepValue}
                            changeStepActivation={changeStepActivation}
                        />
                    </Col>
                    <Col/>
                </Row>
                {stepValue === 0 && (
                    <EnterLicenses
                        xAuthToken={xAuthToken}
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        addLicenses={addLicenses}
                        addForm={addForm}
                        directions={directions}
                        licenses={licenses}
                        setLicenses={setLicenses}
                        setTransport={setTransport}
                        setSaveError={setSaveError}
                        setNoLongerValid={setNoLongerValid}
                        setGeneralError={setGeneralError}
                        removeAlerts={removeAlerts}
                    />
                )}
                {stepValue === 1 && (
                    <AssignProducts
                        changeStepActivation={changeStepActivation}
                        setIsLoading={setIsLoading}
                        unassigned={unassigned}
                        setUnassigned={setUnassigned}
                        assigned={assigned}
                        setAssigned={setAssigned}
                        hostDetails={hostDetails}
                        xAuthToken={xAuthToken}
                    />
                )}
                {stepValue === 2 && (
                    <ReviewSubmit
                        xAuthToken={xAuthToken}
                        handleBeforeUnload={handleBeforeUnload}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                        changeStepActivation={changeStepActivation}
                        setIsLoading={setIsLoading}
                        transport={transport}
                        licenses={licenses}
                        setLicenses={setLicenses}
                        unassigned={unassigned}
                        setUnassigned={setUnassigned}
                        assigned={assigned}
                        setAssigned={setAssigned}
                        setNoLongerValid={setNoLongerValid}
                        setGeneralError={setGeneralError}
                        addForm={addForm}
                        setSaveError={setSaveError}
                    />
                )}

                {generalError && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',
                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                showHandler={hideGeneralError}
                            />
                        </div>
                    </div>
                )}

                {invalidParams && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',
                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(TransportInvalidParamsErrorTitle, mainMessages[siteLanguageDefault][TransportInvalidParamsErrorTitle])}
                                message={localization.toLanguageString(TransportInvalidParamsErrorMessage, mainMessages[siteLanguageDefault][TransportInvalidParamsErrorMessage])}
                                showHandler={hideInvalidParams}
                            />
                        </div>
                    </div>
                )}

                {addError.length !== 0 && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',
                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(licenseBelowNotValidKey, mainMessages[siteLanguageDefault][licenseBelowNotValidKey])}
                                message={localization.toLanguageString(licenseIdsKey, mainMessages[siteLanguageDefault][licenseIdsKey]) + ': ' + addError.join(', ')}
                                showHandler={hideAddError}
                            />
                        </div>
                    </div>
                )}

                {saveError.length !== 0 && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',

                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(licensesNotLinkedKSMProfileKey, mainMessages[siteLanguageDefault][licensesNotLinkedKSMProfileKey])}
                                message={<>
                                    {localization.toLanguageString(licenseIdsKey, mainMessages[siteLanguageDefault][licenseIdsKey]) + ': ' + saveError.join(', ')}
                                    <br/>
                                    {localization.toLanguageString(transportLicenseLogInTOKSMKey, mainMessages[siteLanguageDefault][transportLicenseLogInTOKSMKey])}
                                </>}
                                showHandler={hideSaveError}
                            />
                        </div>
                    </div>
                )}

                {noLongerValid.length !== 0 && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',

                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(oneMoreLicensesNotAvailableKey, mainMessages[siteLanguageDefault][oneMoreLicensesNotAvailableKey])}
                                message={<>
                                    {localization.toLanguageString(alreadyTransportedKey, mainMessages[siteLanguageDefault][alreadyTransportedKey]) + ': '}
                                    <br/><br/>
                                    {localization.toLanguageString(licenseIdsKey, mainMessages[siteLanguageDefault][licenseIdsKey]) + ': ' + noLongerValid.join(', ')}
                                </>}
                                showHandler={hideNoLongerValid}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}