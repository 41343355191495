import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// multilingual
import {AnchorWrap, Text} from '../components/common/MultilingualText.js';
import {
    pageCannotLoadClickHereKey,
    pageCannotLoadGoToKey,
    pageCannotLoadKey,
    mainMessages,
    pageCannotLoadPleaseKey,
} from '../assets/text/MultilingualText.js';
import {HelpIcon} from "../components/common/icons";


function PageError(props) {
    const {siteLanguageDefault} = useContext(UserContext);

    const history = useHistory();

    const AnchorText = AnchorWrap(Text);

    return (
        <>
            <div className={"ksm-page-container"}
                 style={{
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'center',
                     alignItems: 'center',
                 }}
            >
                <div>
                    <HelpIcon
                        style={{
                            fontSize: "9.375rem",
                            color: 'var(--color-text-primary)'
                        }}
                    />
                </div>
                <div
                    style={{
                        fontSize: "4rem",
                        fontWeight: "900",
                        color: 'var(--color-text-primary)'
                    }}
                >
                    <Text
                        textkey={pageCannotLoadKey}
                        textdefault={mainMessages[siteLanguageDefault][pageCannotLoadKey]}
                    />
                </div>
                <div
                    style={{
                        marginTop: "1rem"
                    }}
                >
                    <div>
                        <Text
                            textkey={pageCannotLoadPleaseKey}
                            textdefault={mainMessages[siteLanguageDefault][pageCannotLoadPleaseKey]}
                        />
                        <b>
                            <AnchorText
                                style={{
                                    color: 'var(--keysight-secondary)',
                                    textUnderlineOffset: '0.188rem',
                                    textDecoration: 'underline'
                                }}
                                onClick={() => {
                                    history.push('/');
                                }}
                                textkey={pageCannotLoadClickHereKey}
                                textdefault={mainMessages[siteLanguageDefault][pageCannotLoadClickHereKey]}
                            />
                        </b>
                        <Text
                            textkey={pageCannotLoadGoToKey}
                            textdefault={mainMessages[siteLanguageDefault][pageCannotLoadGoToKey]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageError;