import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';
import axios from 'axios';
import config from '../../config.js';

// react strap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../common/Alert.js';
import {LicensePreviewDownloadGrid} from './LicensePreview/Grid.js';
import Spinner from "../common/Spinner";

// kendo react
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages, notGeneratedAvailableRedeemKey, errorOccurredRequestKey,
    myAssignedProductsKey,
    requestLicensesKey,
    generateLicensesMessageKey,
    generateLicensesKey,
    cancelKey,
    confirmKey
} from '../../assets/text/MultilingualText.js';
import {useLocation} from "react-router-dom";


function LicensePreview(props) {
    const {
        changeStepActivation,
        assignedProducts,
        setAssignedProducts,
        setRequestedProducts
    } = props;
    const {
        siteLanguageDefault,
        accessToken,
        timeout,
    } = useContext(UserContext);

    const localization = useLocalization();
    const {search} = useLocation();

    const [showAlert, setShowAlert] = useState(false)
    const alertHandler = () => {
        setShowAlert(false)
    }

    //Change to license request/download
    const [changeToDownload, setChangeToDownload] = useState(false)
    useEffect(() => {
        if (changeToDownload) {
            changeStepActivation(2)
        }
    }, [changeToDownload]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkInternalConnection = () => {
        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };
        setIsLoading(true);
        axios.post(
            config.utilities.health,
            {headers: headers, timeout: timeout}
        )
            .then((response) => {
                for (const host of assignedProducts) {
                    for (const product of host.products) {
                        product.target_orders = []
                    }
                    host.icons = {
                        loading: true,
                        download: false,
                        cloudAlert: false,
                        supportError: false,
                        generationError: false,
                        qtyError: false,
                        entitlementError: false,
                    }
                }
                setAssignedProducts([...assignedProducts])
                setRequestedProducts([...assignedProducts])
                setChangeToDownload(true)
            })
            .catch((error) => {
                console.log("ERROR: Failed to internet check", error);
                if (!error.response) { //Internet disconnected
                    setShowAlert(true)
                } else {
                    for (const host of assignedProducts) {
                        for (const product of host.products) {
                            product.target_orders = []
                        }
                        host.icons = {
                            loading: true,
                            download: false,
                            cloudAlert: false,
                            supportError: false,
                            generationError: false,
                            qtyError: false,
                            entitlementError: false,
                        }
                    }
                    setAssignedProducts([...assignedProducts])
                    setRequestedProducts([...assignedProducts])
                    setChangeToDownload(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const sendMedian = () => {
        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };

        let query = new URLSearchParams(search);
        const orderID = query.get('orderID');

        let hosts = []

        for (const host of assignedProducts) {
            hosts.push({
                transaction_id: host['transaction_id'],
                hostid_type: host['host_id_type'],
                products: host['products'],
                host_id: host['host_id'],
                serial_id: host['serial_id'],
                order_id: orderID
            })
        }

        let formData = new FormData();
        let data = {
            'users_accordions': hosts
        }
        formData.append('Data', JSON.stringify(data));

        setIsLoading(true);
        axios.post(
            config.request_license.REQUEST_NEW_LICENSE_MEDIAN,
            formData,
            {headers: headers, timeout: timeout}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data
                    for (const item of data) {
                        let idx = assignedProducts.findIndex(host => host.transaction_id === item.transaction_id)
                        assignedProducts[idx].products = [...item.products]
                        assignedProducts[idx].icons = {
                            loading: true,
                            download: false,
                            cloudAlert: false,
                            supportError: false,
                            generationError: false,
                            qtyError: false,
                            entitlementError: false,
                        }
                    }
                }
                setAssignedProducts([...assignedProducts])
                setRequestedProducts([...assignedProducts])
                setIsLoading(false)
                setChangeToDownload(true)
            })
            .catch((error) => {
                console.log("ERROR: Failed median request", error);
                if (!error.response) { //Internet disconnected
                    setShowAlert(true)
                    setIsLoading(false)
                } else {
                    for (const host of assignedProducts) {
                        for (const product of host.products) {
                            product.target_orders = []
                        }
                        host.icons = {
                            loading: true,
                            download: false,
                            cloudAlert: false,
                            supportError: false,
                            generationError: false,
                            qtyError: false,
                            entitlementError: false,
                        }
                    }
                    setAssignedProducts([...assignedProducts])
                    setRequestedProducts([...assignedProducts])
                    setIsLoading(false)
                    setChangeToDownload(true)
                }
            })
    }

    const [isLoading, setIsLoading] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <div className={"k-h5"}>
                {localization.toLanguageString(myAssignedProductsKey, mainMessages[siteLanguageDefault][myAssignedProductsKey])}
            </div>
            <PanelBar className={'ksm-panelbar-default'}>
                {assignedProducts.map((item) => {
                    return (
                        <PanelBarItem
                            title={
                                <div
                                    className={"k-h5"}
                                    style={{
                                        color: 'white',
                                        margin: '0 0 0 2px'
                                    }}>
                                    {item.panelBarTitle}
                                </div>
                            }
                            key={item.transaction_id}
                            expanded={true}
                        >
                            <LicensePreviewDownloadGrid products={item.products}/>
                        </PanelBarItem>
                    )
                })}
            </PanelBar>
            <div
                className={'k-mt-2'}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div style={{width: "10.625rem"}} />
                {showConfirmation && (
                    <div>
                        <Col>
                            <Row style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <div style={{
                                    maxWidth: '35.813rem'
                                }}>
                                    <Alert
                                        type={'warning'}
                                        title={localization.toLanguageString(generateLicensesKey, mainMessages[siteLanguageDefault][generateLicensesKey])}
                                        message={localization.toLanguageString(generateLicensesMessageKey, mainMessages[siteLanguageDefault][generateLicensesMessageKey])}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div
                                    className={'k-mt-2'}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '0.5rem'
                                    }}
                                >
                                    <Button
                                        themeColor={"primary"}
                                        size={"large"}
                                        fillMode={"outline"}
                                        type={"button"}
                                        onClick={() => {
                                            changeStepActivation(0)
                                        }}
                                    >
                                        {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                                    </Button>
                                    <Button
                                        themeColor={"primary"}
                                        size={"large"}
                                        fillMode={"solid"}
                                        type={"button"}
                                        onClick={() => {
                                            let query = new URLSearchParams(search);
                                            const orderID = query.get('orderID');

                                            if (orderID === null) {
                                                sendMedian()
                                            } else {
                                                checkInternalConnection()
                                            }
                                        }}
                                    >
                                        {localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey])}
                                    </Button>
                                </div>
                            </Row>
                        </Col>
                    </div>
                )}
                <div>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        type={"button"}
                        disabled={showConfirmation}
                        onClick={() => {
                            setShowConfirmation(true)
                        }}
                    >
                        {localization.toLanguageString(requestLicensesKey, mainMessages[siteLanguageDefault][requestLicensesKey])}
                    </Button>
                </div>
            </div>

            {showAlert && (
                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0.938rem'
                }}>
                    <div style={{
                        maxWidth: '35.813rem',
                    }}>
                        <Alert
                            type={'error'}
                            showHandler={alertHandler}
                            title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                            message={localization.toLanguageString(notGeneratedAvailableRedeemKey, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey])}
                        />
                    </div>

                </Row>
            )}
        </>
    );
};

export default LicensePreview;