import React, {useEffect, useState} from 'react';

// kendo react
import {DropDownList} from "@progress/kendo-react-dropdowns";


export const LicenseTypeCell = (props) => {

    const field = props.field;
    const dataValue = props.dataItem[field];
    const [selected, setSelected] = useState(null)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        let newlySelected = null
        for (const item of dataValue) {
            if (item.selected) {
                newlySelected = item
            }
        }
        setSelected(newlySelected)
    }, [dataValue])

    useEffect(() => {
        if (dataValue.length === 1) {
            setDisabled(true)
        } else {
            setDisabled(false)
            if (props.dataItem.hasOwnProperty('status_display')) {
                if (Object.keys(props.dataItem['status_display']).length > 0 || props.dataItem['lictypedisabled'] === 'Y') {
                    setDisabled(true)
                } else {
                    setDisabled(false)
                }
            }
        }
    }, [dataValue, props.dataItem])

    //bound input events and values to the Grid
    const onChange = (ev) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: ev.syntheticEvent,
                value: ev.target.value,
            });
        }
        setSelected(ev.target.value)
    };


    return (
        <td>
            <DropDownList
                style={{
                    marginTop: '0.188rem',
                    marginBottom: '0.188rem',
                }}
                className={'ksm-dropdownlist'}
                data={dataValue}
                textField="text"
                dataItemKey="license_type"
                value={selected}
                onChange={onChange}
                disabled={disabled}
            />
        </td>
    );
};
