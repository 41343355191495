import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import config from "../../config";
import {useLocation} from "react-router-dom";

//components
import UserContext from "../common/UserContext";
import {NoWrapCell} from "../common/Grid";
import AddLicense from "./EnterLicenses/AddLicense";
import {capitalizeSentence} from "../common/utilities";
import AssignProductsModal from "./AssignProducts/AssignProductsModal";

//multilingual
import {
    descriptionKey,
    transportLicenseKey,
    hostIdKey,
    mainMessages,
    genericEmptyGridKey, orderNumberKey,
    productKey,
    qtyKey,
    saveToKeysightSoftwareManagerKey,
    statusKey,
} from "../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";

//kendo
import {Button} from "@progress/kendo-react-buttons";
import {Grid, GridColumn, GridNoRecords} from "@progress/kendo-react-grid";


export default function EnterLicenses(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        xAuthToken,
        setxAuthToken,
        setIsLoading,
        changeStepActivation,
        licenses,
        setLicenses,
        setTransport,
        setSaveError,
        setNoLongerValid,
        setGeneralError,
        removeAlerts,
        setInvalidParams,
        showAddLicenseModal,
        setShowAddLicenseModal,
        generalError,
        hideGeneralError,
        invalidParams,
        hideInvalidParams,
        assigned,
        setAssigned,
    } = props

    const {search} = useLocation()
    useEffect(() => {
        let query = new URLSearchParams(search);
        if (query.size === 0 || query.size === undefined) {
            setShowAddLicenseModal(true)
        } else {
            addLicenses()
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const [disableButtons, setDisableButtons] = useState(true)

    useEffect(() => {
        let disabled = true
        for (const item of licenses) {
            if (item.status.toLowerCase() === 'available') {
                disabled = false
            }
        }
        setDisableButtons(disabled)
    }, [licenses]) // eslint-disable-line react-hooks/exhaustive-deps

    const [hostDetails, setHostDetails] = useState({})

    const preprocessItems = (item) => {
        let newItem = JSON.parse(JSON.stringify(item))
        newItem.qty_redeemed = parseInt(item.qty_redeemed)
        newItem.hostid_type_pk = parseInt(item.hostid_type_pk)
        newItem.status = capitalizeSentence(item.status)
        return newItem
    }

    const addLicenses = (queryParam = "", fromModal = false) => {
        setIsLoading(true)
        removeAlerts()

        if (queryParam === "") {
            queryParam = new URLSearchParams(search).toString();
        }

        let headers = {
            'Content-Type': 'application/json'
        }
        axios.get(
            config.transport.ON_LOAD + '?' + queryParam,
            {
                headers: headers,
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    setxAuthToken(response.data['enc_code'])
                    let valid = response.data['valid_licenses']
                    valid = valid.map(item => preprocessItems(item))
                    for (const item of valid) {
                        const containsID = !!licenses.find(license => {
                            return license.license_id === item.license_id
                        })
                        if (!containsID) {
                            licenses.push(item)
                        }
                    }
                    setLicenses([...licenses])

                    let invalid = response.data['invalid_licenses']
                    if (invalid.length > 0) {
                        setInvalidParams(true)
                    } else {
                        setShowAddLicenseModal(false)
                    }

                    let details = response.data['host_details']
                    for (const item of details) {
                        for (const hostType in item) {
                            const detail = item[hostType]
                            hostDetails[hostType] = {
                                hostIDType: hostType,
                                hostIsSerial: detail['get_serial'].toUpperCase() || "",
                                hostIDLabel: detail['host_id_info']['asl_prompt'] || "",
                                hostIDHint: detail['host_id_info']['input_hint'] || "",
                                hostIDError: detail['host_id_info']['asl_error_message'] || "",
                                hostIDPatterns: detail['host_id_info']['patterns'] || [],
                                hostIDSuggestions: detail['user_profile_tagged_host_ids'] || [],
                                serialNumberLabel: detail['serial_number_info']['asl_prompt'] || "",
                                serialNumberHint: detail['serial_number_info']['input_hint'] || "",
                                serialNumberError: detail['serial_number_info']['asl_error_message'] || "",
                                serialNumberPatterns: detail['serial_number_info']['patterns'] || [],
                            }

                            hostDetails[hostType].hostIDPatterns = hostDetails[hostType].hostIDPatterns.map(pattern => new RegExp(pattern))
                            hostDetails[hostType].serialNumberPatterns = hostDetails[hostType].serialNumberPatterns.map(pattern => new RegExp(pattern))

                            if (hostDetails[hostType].hostIsSerial === "O") {
                                let emptyPattern = new RegExp("^$");
                                hostDetails[hostType].serialNumberPatterns.push(emptyPattern);
                            }
                        }
                    }
                    setHostDetails(hostDetails)
                }
            })
            .catch((error) => {
                console.log("ERROR: Get Licenses", error)
                if (error.response.data['error_code'] === "MOD_TRANSPORT_003" || error.response.data['error_code'] === "MOD_TRANSPORT_002") {
                    setInvalidParams(true)
                } else {
                    setGeneralError(true)
                }
                if(!fromModal){
                    setShowAddLicenseModal(false)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    const validateBanked = () => {
        removeAlerts()
        setIsLoading(true)
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': xAuthToken
        };

        let selectedLicenses = licenses.map((item) => {
            return {
                license_id: item.license_id.toString(),
                sn: item.sn.toString(),
                confirmation_code: item.confirmation_code
            }
        })

        let data = {
            "module": "LICENSE",
            "sub_module": "BANK",
            "action": "VALIDATE",
            "input_json": [
                {
                    "values": selectedLicenses
                }
            ]
        }
        let formData;
        formData = new FormData();
        formData.append('File', '');
        formData.append('Data', JSON.stringify(data));
        axios.post(
            config.transport.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    changeStepActivation(1)
                    setTransport(false)
                }
            })
            .catch((error) => {
                console.log("ERROR: Validate banked license", error.response);
                if (error.response.data.hasOwnProperty('failed_out_resp')) {
                    let response = error.response.data['failed_out_resp']
                    let invalidSave = []
                    let invalidBank = []
                    for (const item of response) {
                        if (item['error_code'] === 'MOD_TRANSPORT_3019') {
                            invalidBank.push(item['lic'])
                        }
                        if (item['error_code'] === 'MOD_TRANSPORT_3017') {
                            invalidSave.push(item['lic'])
                        }
                    }
                    setSaveError(invalidSave)
                    setNoLongerValid(invalidBank)
                } else {
                    setGeneralError(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const validateTransport = () => {
        removeAlerts()
        setIsLoading(true)
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': xAuthToken
        };

        let selectedLicenses = licenses.map((item) => {
            return {
                license_id: item.license_id.toString(),
                sn: item.sn.toString(),
                confirmation_code: item.confirmation_code
            }
        })

        let data = {
            "module": "LICENSE",
            "sub_module": "TRANSPORT",
            "action": "VALIDATE",
            "input_json": [
                {
                    "values": selectedLicenses
                }
            ]
        }
        let formData;
        formData = new FormData();
        formData.append('File', '');
        formData.append('Data', JSON.stringify(data));
        axios.post(
            config.transport.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setTransport(true)
                    const hostIDType = licenses[0]['host_id_type']
                    setDetails(hostDetails[hostIDType])
                }
            })
            .catch((error) => {
                console.log("ERROR: Validate banked license", error.response);
                if (error.response.data.hasOwnProperty('failed_out_resp')) {
                    let response = error.response.data['failed_out_resp']
                    let invalidTransport = []
                    for (const item of response) {
                        if (item['error_code'] === 'MOD_TRANSPORT_3019') {
                            invalidTransport.push(item['lic'])
                        }
                    }
                    setNoLongerValid(invalidTransport)
                } else {
                    setGeneralError(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const [details, setDetails] = useState({})


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.938rem',
        }}>

            <Grid
                className={'enter-transport-ccl-grid'}
                data={licenses}
                scrollable={'none'}
            >
                <GridNoRecords>
                    {localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey])}
                </GridNoRecords>
                <GridColumn
                    field="order_num"
                    title={localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="prod_num"
                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="host_id"
                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="prod_desc"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                />
                <GridColumn
                    field="qty_redeemed"
                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                />
                <GridColumn
                    field="status"
                    title={localization.toLanguageString(statusKey, mainMessages[siteLanguageDefault][statusKey])}
                />
            </Grid>

            <div style={{
                display: 'flex',
                gap: '0.938rem',
                justifyContent: 'end',
            }}>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    rounded={"small"}
                    onClick={() => {
                        validateTransport()
                    }}
                    disabled={disableButtons}
                >
                    {localization.toLanguageString(transportLicenseKey, mainMessages[siteLanguageDefault][transportLicenseKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    rounded={"small"}
                    onClick={() => {
                        validateBanked()
                    }}
                    disabled={disableButtons}
                >
                    {localization.toLanguageString(saveToKeysightSoftwareManagerKey, mainMessages[siteLanguageDefault][saveToKeysightSoftwareManagerKey])}
                </Button>
            </div>

            {showAddLicenseModal && (
                <AddLicense
                    addLicenses={addLicenses}
                    generalError={generalError}
                    hideGeneralError={hideGeneralError}
                    invalidParams={invalidParams}
                    hideInvalidParams={hideInvalidParams}
                    removeAlerts={removeAlerts}
                />
            )}

            {Object.keys(details).length !== 0 && (
                <AssignProductsModal
                    setIsLoading={setIsLoading}
                    details={details}
                    setDetails={setDetails}
                    assigned={assigned}
                    setAssigned={setAssigned}
                    licenses={licenses}
                    xAuthToken={xAuthToken}
                    changeStepActivation={changeStepActivation}
                />
            )}
        </div>
    )
}