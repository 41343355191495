import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import {DateCell, NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';

// kendo react
import {orderBy} from '@progress/kendo-data-query';
import {Grid, GridColumn} from '@progress/kendo-react-grid';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    descriptionKey,
    licenseExpirationDateKey,
    productNumberKey,
    qtyKey,
    supportExpirationDateKey,
    mainMessages
} from '../../../assets/text/MultilingualText.js';


export const LicensePreviewDownloadGrid = (props) => {
    const {products} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const sort = [
        {
            field: "ProductName",
            dir: "desc"
        }
    ];

    return (
        <Grid
            className={"license-preview-download-grid"}
            data={orderBy(products, sort)}
            scrollable={'none'}
        >
            <GridColumn
                field="product_number"
                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                cell={NoWrapCell}
            />
            <GridColumn
                field="product_desc"
                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
            />
            <GridColumn
                field="qty_requested"
                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                cell={TextAlignMiddleCell}
            />
            <GridColumn
                field="license_expiration_date_display"
                title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                cell={DateCell}
            />
            <GridColumn
                field="support_expiration_date_display"
                title={localization.toLanguageString(supportExpirationDateKey, mainMessages[siteLanguageDefault][supportExpirationDateKey])}
                cell={DateCell}
            />
        </Grid>
    )
}