import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMEADetailedViewPeriodicEA from '../assets/img/Help/ksm-ea-detailed-view-periodic-ea.png';
import KSMEADetailedViewVariableEA from '../assets/img/Help/ksm-ea-detailed-view-variable-ea.png';
import KSMEADetailedViewInfo from '../assets/img/Help/ksm-ea-detailed-view-info.png';
import KSMEADetailedViewProductView from '../assets/img/Help/ksm-ea-detailed-view-product-view.png';
import KSMEADetailedViewInitialPool from '../assets/img/Help/ksm-ea-detailed-view-initial-pool.png';
import KSMEADetailedViewSelectionPool from '../assets/img/Help/ksm-ea-detailed-view-selection-pool.png';
import KSMEADetailedViewConsumedPool from '../assets/img/Help/ksm-ea-detailed-view-consumed-pool.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    eaAccessKey,
    helpEADetailedViewEAAccessQAKey,
    helpEADetailedViewEALicenseViewSectionKey,
    eaLicenseViewKey,
    typesEAsKey,
    helpEADetailedViewEATypesSectionKey,
    typesOfEnterpriseAgreementsKey,
    enterpriseAgreementsKey,
    helpEADetailedViewEnterpriseAgreementsQAKey,
    helpEADetailedViewErrorHandlingSectionKey,
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    hostsKey,
    helpEADetailedViewHostsQAKey,
    licensesKey,
    helpEADetailedViewLicensesQAKey,
    mainMessages,
    helpEADetailedViewShortcutSectionKey,
    shortcutProductSelectionKey,
    helpEADetailedViewSubscriptionPoolSectionKey,
    subscriptionPoolStatusKey
} from '../assets/text/MultilingualText.js';

function EADetailedViewHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;

        if (item.substeps && item.substeps.length) {
            let types = item.substeps.map(i => i.type);
            let isDashed = types.every(t => t === "dash");

            children = isDashed ? <>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </> : <ol>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        if (item.type === "dash") {
            return (
                <div
                    style={{
                        ...bulletStyle,
                        ...{display: 'flex'}
                    }}
                >
                    &ndash;&nbsp;{toHtml(item.step)}
                </div>
            )
        } else {
            return (
                <li>
                    <div style={children ? {paddingBottom: 6} : {}}>
                        {toHtml(item.step)}
                    </div>
                    {children}
                </li>
            );
        }
    }

    const bulletStyle = {
        paddingTop: 6,
        paddingBottom: 6
    };

    const borderStyle = {
        border: "1px solid",
        borderCollapse: "collapse"
    }

    const helpContent = {
        'header': {
            key: eaLicenseViewKey,
            default: mainMessages[siteLanguageDefault][eaLicenseViewKey]
        },
        'topics': [
            {
                key: shortcutProductSelectionKey,
                default: mainMessages[siteLanguageDefault][shortcutProductSelectionKey],
                hash: '#shortcut'
            },
            {
                key: eaLicenseViewKey,
                default: mainMessages[siteLanguageDefault][eaLicenseViewKey],
                hash: '#ea-license-view'
            },
            {
                key: subscriptionPoolStatusKey,
                default: mainMessages[siteLanguageDefault][subscriptionPoolStatusKey],
                hash: '#subscription-pool'
            },
            {
                key: typesEAsKey,
                default: mainMessages[siteLanguageDefault][typesEAsKey],
                hash: '#ea-types'
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: '#error-handling'
            }
        ],
        'faq': [
            {
                key: enterpriseAgreementsKey,
                default: mainMessages[siteLanguageDefault][enterpriseAgreementsKey],
                hash: '#enterprise-agreements-faq'
            },
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: '#licenses-faq'
            },
            {
                key: hostsKey,
                default: mainMessages[siteLanguageDefault][hostsKey],
                hash: '#hosts-faq'
            },
            {
                key: eaAccessKey,
                default: mainMessages[siteLanguageDefault][eaAccessKey],
                hash: '#ea-access-faq'
            }
        ]
    };

    const shortcutContent = localization.toLanguageString(
        helpEADetailedViewShortcutSectionKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewShortcutSectionKey]
    );

    const eALicenseViewContent = localization.toLanguageString(
        helpEADetailedViewEALicenseViewSectionKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewEALicenseViewSectionKey]
    );

    const subscriptionPoolContent = localization.toLanguageString(
        helpEADetailedViewSubscriptionPoolSectionKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewSubscriptionPoolSectionKey]
    );

    const eATypesContent = localization.toLanguageString(
        helpEADetailedViewEATypesSectionKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewEATypesSectionKey]
    );

    const errorHandlingContent = localization.toLanguageString(
        helpEADetailedViewErrorHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewErrorHandlingSectionKey]
    );

    const enterpriseAgreementsQA = localization.toLanguageString(
        helpEADetailedViewEnterpriseAgreementsQAKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewEnterpriseAgreementsQAKey]
    );

    const licensesQA = localization.toLanguageString(
        helpEADetailedViewLicensesQAKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewLicensesQAKey]
    );

    const hostsQA = localization.toLanguageString(
        helpEADetailedViewHostsQAKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewHostsQAKey]
    );

    const eAAccessQA = localization.toLanguageString(
        helpEADetailedViewEAAccessQAKey,
        mainMessages[siteLanguageDefault][helpEADetailedViewEAAccessQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3"}
                            id="shortcut"
                        >
                            <Text
                                textkey={shortcutProductSelectionKey}
                                textdefault={mainMessages[siteLanguageDefault][shortcutProductSelectionKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(shortcutContent.section_one)}
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="ea-license-view"
                        >
                            <Text
                                textkey={eaLicenseViewKey}
                                textdefault={mainMessages[siteLanguageDefault][eaLicenseViewKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(eALicenseViewContent.section_one)}
                            <ol type="a">
                                {eALicenseViewContent.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                                {toHtml(eALicenseViewContent.section_two)}
                                <img src={KSMEADetailedViewPeriodicEA} alt={"ksm-ea-detailed-view-periodic-ea"}/>
                                {toHtml(eALicenseViewContent.section_three)}
                                <img src={KSMEADetailedViewVariableEA} alt={"ksm-ea-detailed-view-variable-ea"}/>
                            </ol>
                            <p>
                                {eALicenseViewContent.section_four}
                            </p>
                            {eALicenseViewContent.section_four_bullets ? <ul>
                                {eALicenseViewContent.section_four_bullets.map((bullet, index) => <li
                                    key={'ea-detailed-view-license-view-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                            <p>
                                {eALicenseViewContent.section_five}
                            </p>
                            <ol type="a">
                                {eALicenseViewContent.section_five_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                            <p>
                                {eALicenseViewContent.section_six}
                            </p>
                            <ol type="a" start={4}>
                                {eALicenseViewContent.section_six_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                            <img src={KSMEADetailedViewInfo} alt="ksm-ea-detailed-view-info"/>
                            <p>
                                {eALicenseViewContent.section_seven}
                            </p>
                            <img src={KSMEADetailedViewProductView} alt="ksm-ea-detailed-view-product-view"/>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="subscription-pool"
                        >
                            <Text
                                textkey={subscriptionPoolStatusKey}
                                textdefault={mainMessages[siteLanguageDefault][subscriptionPoolStatusKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(subscriptionPoolContent.section_one)}
                            {subscriptionPoolContent.section_one_bullets ? <ul>
                                {subscriptionPoolContent.section_one_bullets.map((bullet, index) => <li
                                    key={'ea-detailed-view-subscription-pool-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                            {toHtml(subscriptionPoolContent.section_two)}
                            <br/>
                            <table style={borderStyle}>
                                <tbody>
                                <tr style={borderStyle}>
                                    <th style={borderStyle}>{toHtml(subscriptionPoolContent.statusKey)}</th>
                                    <th style={borderStyle}>{toHtml(subscriptionPoolContent.section_two_table_1_2)}</th>
                                </tr>
                                <tr style={borderStyle}>
                                    <td style={borderStyle}>
                                        <div style={{padding: 8}}>
                                            {toHtml(subscriptionPoolContent.section_two_table_2_1)}
                                        </div>
                                    </td>
                                    <td style={borderStyle}>
                                        <img src={KSMEADetailedViewInitialPool}
                                             alt={"ksm-ea-detailed-view-initial-pool"}/>
                                    </td>
                                </tr>
                                <tr style={borderStyle}>
                                    <td style={borderStyle}>
                                        <div style={{padding: 8}}>
                                            {toHtml(subscriptionPoolContent.section_two_table_3_1)}
                                        </div>
                                    </td>
                                    <td style={borderStyle}>
                                        <img src={KSMEADetailedViewSelectionPool}
                                             alt={"ksm-ea-detailed-view-selection-pool"}/>
                                    </td>
                                </tr>
                                <tr style={borderStyle}>
                                    <td style={borderStyle}>
                                        <div style={{padding: 8}}>
                                            {toHtml(subscriptionPoolContent.section_two_table_4_1)}
                                        </div>
                                    </td>
                                    <td style={borderStyle}>
                                        <img src={KSMEADetailedViewConsumedPool}
                                             alt={"ksm-ea-detailed-view-consumed-pool"}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="ea-types"
                        >
                            <Text
                                textkey={typesOfEnterpriseAgreementsKey}
                                textdefault={mainMessages[siteLanguageDefault][typesOfEnterpriseAgreementsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {eATypesContent.section_one}
                            </p>
                            {eATypesContent.section_one_bullets ? <ul>
                                {eATypesContent.section_one_bullets.map((bullet, index) => {
                                    if (index === 0) {
                                        return <li key={'ea-detailed-view-ea-types-bullet-' + index}>
                                            <div style={bulletStyle}>
                                                {toHtml(bullet)}
                                            </div>
                                            {eATypesContent.section_one_sub_bullets_one.map((dash, index) => {
                                                if (index === 2) {
                                                    return <div key={'ea-detailed-view-ea-types-sub-bullet-' + index}>
                                                        <div
                                                            className="indent"
                                                            style={{
                                                                ...bulletStyle,
                                                                ...{display: 'flex'}
                                                            }}
                                                            key={'ea-detailed-view-ea-types-dash-bullet-' + index}
                                                        >
                                                            &ndash;&nbsp;{toHtml(dash)}
                                                        </div>
                                                        <ul>
                                                            {eATypesContent.section_one_sub_bullets_one_three.map((sub, index) =>
                                                                <li
                                                                    key={'ea-detailed-view-ea-types-sub-' + index}
                                                                    style={bulletStyle}
                                                                >
                                                                    {toHtml(sub)}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                } else {
                                                    return <div
                                                        className="indent"
                                                        style={{
                                                            ...bulletStyle,
                                                            ...{display: 'flex'}
                                                        }}
                                                        key={'ea-detailed-view-ea-types-sub-bullet-' + index}
                                                    >
                                                        &ndash;&nbsp;{toHtml(dash)}
                                                    </div>
                                                }
                                            })}
                                        </li>
                                    } else if (index === 1) {
                                        return <li key={'ea-detailed-view-ea-types-bullet-' + index}>
                                            <div style={bulletStyle}>
                                                {toHtml(bullet)}
                                            </div>
                                            {eATypesContent.section_one_sub_bullets_two.map((dash, index) => <div
                                                    className="indent"
                                                    style={{
                                                        ...bulletStyle,
                                                        ...{display: 'flex'}
                                                    }}
                                                    key={'ea-detailed-view-ea-types-sub-bullet-' + index}
                                                >
                                                {(index === 3) ?
                                                    <>
                                                        &ndash;&nbsp;&nbsp;{toHtml(dash)}
                                                    </> :
                                                    <>
                                                        &ndash;&nbsp;{toHtml(dash)}
                                                    </>
                                                }
                                                </div>
                                            )}
                                        </li>
                                    } else {
                                        return <></>
                                    }
                                })}
                            </ul> : <></>}
                            <p>
                                <b>{eATypesContent.section_two}</b>
                            </p>
                            <p>
                                {eATypesContent.section_three}
                            </p>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {errorHandlingContent.section_one}
                            </p>
                            {errorHandlingContent.section_one_bullets ? <ul>
                                {errorHandlingContent.section_one_bullets.map((bullet, index) => <li
                                    key={'ea-detailed-view-error-handling-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div className={"k-h3 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="enterprise-agreements-faq">
                            <h3>
                                <Text
                                    textkey={enterpriseAgreementsKey}
                                    textdefault={mainMessages[siteLanguageDefault][enterpriseAgreementsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {enterpriseAgreementsQA ? enterpriseAgreementsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"enterprise-agreements-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="licenses-faq">
                            <h3>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {licensesQA ? licensesQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"licenses-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="hosts-faq">
                            <h3>
                                <Text
                                    textkey={hostsKey}
                                    textdefault={mainMessages[siteLanguageDefault][hostsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {hostsQA ? hostsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"hosts-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="ea-access-faq">
                            <h3>
                                <Text
                                    textkey={eaAccessKey}
                                    textdefault={mainMessages[siteLanguageDefault][eaAccessKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {eAAccessQA ? eAAccessQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"ea-access-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EADetailedViewHelp;

