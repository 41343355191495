import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import config from '../../../config';
import UserContext from '../../common/UserContext';

//components
import {ColumnMenu, CurrencyCell, NoWrapCell} from "../../common/Grid";
import {LicenseTypeCell} from "./LicenseTypeCell";
import {capitalizeSentence, multiplyMoney} from "../../common/utilities";
import {QtyCell} from "./QtyCell";

//kendo
import {Popover} from "@progress/kendo-react-tooltip";
import {Label} from "@progress/kendo-react-labels";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {InputClearValue, TextBox} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import {Grid, GridColumn, GridItemChangeEvent, GridNoRecords} from "@progress/kendo-react-grid";
import {orderBy, process} from "@progress/kendo-data-query";
import {Icon} from "@progress/kendo-react-common";

//multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    anyKey,
    costPerUnitKey,
    descriptionKey, emptySearchKey,
    licenseTypeKey,
    mainMessages,
    NoRecordsSearchKey,
    productNumberKey,
    qtyKey,
    searchKey,
    selectedItemCannotAddedMessageKey,
    selectedItemCannotAddedKey,
    searchProductsKey,
    searchSoftwareFamilyKey,
    totalKey,
} from "../../../assets/text/MultilingualText";
import KSMPopover from "../../common/Popovers";
import {CartIcon} from "../../common/icons";


const SearchProducts = (props) => {
    const {
        siteLanguage,
        siteLanguageDefault,
        accessToken
    } = useContext(UserContext);

    const {
        selectedProducts,
        setSelectedProducts,
        softwareFamily,
        headerInfo,
        setHeaderInfo,
        setIsLoading,
        preprocessNewProducts,
        unSavedPool,
    } = props

    const localization = useLocalization();


    const [gridInit, setGridInit] = useState(true)
    const gridInitMessage = localization.toLanguageString(NoRecordsSearchKey, mainMessages[siteLanguageDefault][NoRecordsSearchKey])
    const gridNotInitMessage = localization.toLanguageString(emptySearchKey, mainMessages[siteLanguageDefault][emptySearchKey])
    const [gridNoRecordsMessage, setGridNoRecordsMessage] = useState(gridInitMessage)

    useEffect(() => {
        if (gridInit) {
            setGridNoRecordsMessage(gridInitMessage)
        } else {
            setGridNoRecordsMessage(gridNotInitMessage)
        }
    }, [siteLanguage, gridInit, gridInitMessage, gridNotInitMessage])

    const [searchedProducts, setSearchedProducts] = useState([])
    const initialGridState = {
        take: 10, skip: 0,
    };
    const [searchGridState, setSearchGridState] = useState(initialGridState);
    const [searchProductState, setSearchProductState] = useState(
        process(searchedProducts.map((product) => ({
            ...product,
        })), initialGridState)
    );

    const [selectedSoftwareFamily, setSelectedSoftwareFamily] = useState({
        id: 0,
        text: localization.toLanguageString(anyKey, mainMessages[siteLanguageDefault][anyKey])
    })

    const onChangeSoftwareFamily = (event) => {
        setSelectedSoftwareFamily(event.target.value)
    }

    const [searchValue, setSearchValue] = useState('')
    const onChangeSearchValue = useCallback(event => {
        setSearchValue(event.target.value);
    }, []);
    const handleClear = useCallback(() => {
        setSearchValue("");
    }, []);

    const onClickSearchProduct = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        let familyDesc = ''
        if (selectedSoftwareFamily.id !== 0) {
            familyDesc = selectedSoftwareFamily.text
        }

        let body = {
            module: "EA",
            sub_module: "",
            action: "SEARCH",
            input_json: [
                {
                    ea_id: headerInfo.ea_id,
                    search_data: searchValue.trim(),
                    family_desc: familyDesc
                }
            ]
        }

        setIsLoading(true)

        axios.post(
            config.ea_request_license.SEARCH,
            body,
            {headers: headers}
        )
            .then((response) => {
                let data = response.data || [];
                data = data.search_result

                let count = 0
                for (const product of data) {
                    //preprocess search products
                    for (const item of product.license_type) {
                        item.text = capitalizeSentence(item.license_type, true)
                    }

                    product.license_type = orderBy(product.license_type, [{
                        field: "text",
                        dir: "asc"
                    }])

                    let basePrice = 0
                    if (product.license_type.length === 1) {
                        product.license_type[0].selected = true
                        basePrice = product.license_type[0].base_price
                    } else {
                        product.license_type.unshift({
                            type: "Select",
                            selected: true,
                            base_price: 0,
                            text: "Select"
                        })
                    }
                    product.uniqueID = count
                    count++
                    product.quantity = null
                    product.base_price = basePrice
                    product.total_cost = 0
                    product.subPoolExceed = false
                    product.currency_code = headerInfo.currency
                    product.currency_locale = headerInfo.locale
                }
                data = orderBy(data, [{
                    field: "prod_num",
                    dir: "desc",
                }])

                setSearchedProducts(data)

                const newProductState = process(
                    data.map((product) => ({
                        ...product,
                    })),
                    initialGridState
                );
                setSearchProductState(newProductState);

                if (data.length === 0) {
                    setGridInit(false)
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST EA Search Data", error);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /*
     * onGridStateChange(event) sets the grid state and data state on grid's state change
     * @param {event} the grid selection change event
    */
    const onProductStateChange = useCallback((event) => {
        const newProductState = process(
            searchedProducts.map((product) => ({
                ...product,
            })),
            event.dataState
        );
        setSearchGridState(event.dataState);
        setSearchProductState(newProductState);
    }, [searchedProducts]);

    //Update search product state when values in searched product gets updated
    useEffect(() => {
        const newProductState = process(
            searchedProducts.map((product) => ({
                ...product,
            })),
            searchGridState
        );
        setSearchProductState(newProductState);
    }, [searchedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    //update search product values based on user input
    const onItemChange = (e: GridItemChangeEvent) => {
        let newData = searchedProducts.map((item) => {
            if (item.uniqueID === e.dataItem.uniqueID) {

                //Update user input values
                if (e.field === 'license_type') {
                    let selectExist = false
                    item.license_type.forEach(type => {
                        type.selected = false
                        if (type.text === 'Select') {
                            selectExist = true
                        }
                        if (type.text === e.value.text) {
                            type.selected = true
                        }
                    })
                    if (selectExist) {
                        item.license_type.shift()
                    }
                } else {
                    item[e.field || ''] = e.value;
                }

                //update per unit cost and total cost
                for (const type of item.license_type) {
                    if (type.selected) {
                        let basePrice = Number(type.base_price)
                        let quantity = parseInt(item.quantity)
                        if (isNaN(quantity) || quantity <= 0) {
                            quantity = null
                        }
                        item.quantity = quantity
                        item.base_price = basePrice
                        item.total_cost = multiplyMoney(basePrice, quantity)
                        item.subPoolExceed = headerInfo.remaining_pool - headerInfo.selected_sub_pool - item.total_cost - unSavedPool < 0;
                    }
                }
            }
            return item;
        });
        setSearchedProducts([...newData]);
    };

    const CartCell = (props) => {
        let style
        let isError = props.dataItem.subPoolExceed
        if (isError) { //Over subscription pool
            style = {
                color: 'var(--color-alert-danger-base)',
                cursor: 'default',
            }
        } else {
            const selectedLicenseType = props.dataItem.license_type.filter(lt => lt.selected)
            if (props.dataItem.quantity > 0 && selectedLicenseType[0].text !== 'Select') { //valid qty and license type
                style = {
                    color: 'var(--keysight-secondary)',
                    cursor: 'pointer'
                }
            } else { //invalid qty or license type
                style = {
                    color: '#9c9c9c',
                    cursor: 'default',
                }
            }

        }

        const anchor = useRef(null);
        const [popoverShow, setPopoverShow] = useState(false)

        const onMouseOver = () => {
            if (isError) setPopoverShow(true);
        };

        //Remove popover on mouse leave
        const onMouseOut = (ev) => {
            let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
            if (!isChildEl) setPopoverShow(false);
        };

        const saveAllocations = () => {
            let product = props.dataItem
            let productID = 0
            let licenseType = ''
            for (const license of product.license_type) {
                if (license.selected) {
                    productID = license.product_id
                    licenseType = license.license_type
                }
            }
            const addProduct = [{
                product_id: productID,
                prod_num: product.prod_num_display,
                status: "SELECTED",
                quantity: product.quantity,
                ppks_flag: product.ppks_flag,
                license_type: licenseType,
                ea_alloc_id: null,
            }]


            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };
            setIsLoading(true);

            let location = window.location
            let query = new URLSearchParams(location.search);
            const eaID = query.get('eaid');
            const periodType = query.get('show');

            let body = {
                module: "EA",
                sub_module: "allocations",
                action: "SAVE",
                input_json: [
                    {
                        ea_id: eaID,
                        period_selection: periodType,
                        variableoversubflag: headerInfo.variableoversubflag,
                        ea_products: addProduct
                    }
                ]
            }

            axios.post(
                config.ea_request_license.SAVE_DELETE,
                body,
                {headers: headers}
            )
                .then((response) => {
                    if (response.status === 200) {

                        let data = response.data
                        //update sub pool
                        let eaHeaderInfo = data['sub_pool_data'][0]
                        headerInfo.total_pool = eaHeaderInfo.total_pool
                        headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                        headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                        headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                        setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))

                        //Add product in my cart
                        selectedProducts.push(preprocessNewProducts(headerInfo, data['new_alloc'][0]))
                        setSelectedProducts([...selectedProducts])

                        //reset added searched product
                        const searchIndex = searchedProducts.findIndex(item => item.uniqueID === product.uniqueID)
                        searchedProducts[searchIndex].quantity = null
                        searchedProducts[searchIndex].total_cost = 0
                        searchedProducts[searchIndex].base_price = 0
                        for (const license of searchedProducts[searchIndex].license_type) {
                            license.selected = false
                        }
                        if (searchedProducts[searchIndex].license_type.length === 1) {
                            searchedProducts[searchIndex].base_price = searchedProducts[searchIndex].license_type[0].base_price
                            searchedProducts[searchIndex].license_type[0].selected = true
                        } else {
                            searchedProducts[searchIndex].license_type.unshift({
                                type: "Select",
                                selected: true,
                                base_price: 0,
                                text: "Select"
                            })
                        }
                        searchedProducts[searchIndex].license_type = JSON.parse(JSON.stringify(searchedProducts[searchIndex].license_type))
                        setSearchedProducts([...searchedProducts])
                    }
                })
                .catch((error) => {
                    console.log("ERROR: Failed to save new product", error);
                    if (error.response.hasOwnProperty('data')) {
                        let errorCode = error.response.data['error_code'].toUpperCase()
                        if (errorCode === 'MOD_EA_158' || errorCode === 'MOD_EA_132') {
                            const searchIndex = searchedProducts.findIndex(item => item.uniqueID === product.uniqueID)
                            searchedProducts[searchIndex].subPoolExceed = true
                            setSearchedProducts([...searchedProducts])
                        }
                        if (error.response.data.hasOwnProperty('sub_pool_data')) {
                            let eaHeaderInfo = error.response.data['sub_pool_data'][0]
                            headerInfo.total_pool = eaHeaderInfo.total_pool
                            headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                            headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                            headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                            setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))
                        }
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }

        return (
            <td ref={anchor} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {isError && (
                        <KSMPopover
                            show={popoverShow}
                            anchor={anchor.current}
                            position={'left'}
                            type={'error'}
                            style={{
                                maxWidth: '27rem'
                            }}
                            message={<div>
                                <b>{localization.toLanguageString(selectedItemCannotAddedKey, mainMessages[siteLanguageDefault][selectedItemCannotAddedKey])}</b>
                                <br/>
                                {localization.toLanguageString(selectedItemCannotAddedMessageKey, mainMessages[siteLanguageDefault][selectedItemCannotAddedMessageKey])}
                            </div>}
                        />
                    )}
                    <CartIcon
                        className={'ksm-grid-icon'}
                        style={style}
                        onClick={saveAllocations}
                    />
                </div>
            </td>

        )
    }

    return (
        <>
            <div
                className={'k-h5'}
                style={{
                    marginBottom: '0.5rem'
                }}
            >
                {localization.toLanguageString(searchProductsKey, mainMessages[siteLanguageDefault][searchProductsKey])}
            </div>
            <Label editorId={'softwareFamily'}>
                {localization.toLanguageString(searchSoftwareFamilyKey, mainMessages[siteLanguageDefault][searchSoftwareFamilyKey])}
            </Label>
            <div style={{
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'baseline',
                marginBottom: '0.5rem',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.125rem',
                }}>
                    <DropDownList
                        id={'softwareFamily'}
                        className={'ksm-dropdownlist'}
                        style={{
                            width: "20rem",
                        }}
                        data={softwareFamily}
                        textField="text"
                        dataItemKey="id"
                        onChange={onChangeSoftwareFamily}
                        value={selectedSoftwareFamily}
                    />
                </div>

                <div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.125rem',
                    }}>
                        <TextBox value={searchValue} onChange={onChangeSearchValue} placeholder="Search"
                                 suffix={
                                     () =>
                                         <>
                                             {searchValue !== "" &&
                                                 <InputClearValue onClick={handleClear}>
                                                     <Icon name="x"/>
                                                 </InputClearValue>}
                                         </>
                                 }
                                 style={{
                                     width: '25rem',
                                     height: '1.875rem',
                                 }}
                        />
                    </div>
                </div>

                <Button
                    themeColor={"primary"}
                    fillMode={"solid"}
                    type={"button"}

                    onClick={onClickSearchProduct}
                >
                    {localization.toLanguageString(searchKey, mainMessages[siteLanguageDefault][searchKey])}
                </Button>
            </div>
            <Grid
                className={'my-search-product-grid'}
                scrollable={'none'}
                data={searchProductState}
                sortable={true}
                pageable={(searchedProducts.length <= initialGridState.take) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100],
                }}
                onDataStateChange={onProductStateChange}
                total={searchProductState.total}
                editField="inEdit"
                onItemChange={onItemChange}
                {...searchGridState}
            >
                <GridNoRecords>
                    {gridNoRecordsMessage}
                </GridNoRecords>
                <GridColumn
                    field="prod_num_display"
                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                    columnMenu={ColumnMenu}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="description"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="quantity"
                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                    cell={QtyCell}
                    sortable={false}
                />
                <GridColumn
                    field="license_type"
                    title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                    cell={LicenseTypeCell}
                    sortable={false}
                />
                <GridColumn
                    field="base_price"
                    title={localization.toLanguageString(costPerUnitKey, mainMessages[siteLanguageDefault][costPerUnitKey])}
                    cell={CurrencyCell}
                    sortable={false}
                />
                <GridColumn
                    field="total_cost"
                    title={localization.toLanguageString(totalKey, mainMessages[siteLanguageDefault][totalKey])}
                    cell={CurrencyCell}
                    sortable={false}
                />
                <GridColumn
                    field={null}
                    title={" "}
                    cell={CartCell}
                    sortable={false}
                />
            </Grid>
        </>
    )
}

export default SearchProducts;