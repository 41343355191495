import React, {useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/HostConfig.css';

// components
import {ColumnMenu, DateIsExpiredCell, TextAlignMiddleCell} from '../common/Grid.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';
import {orderBy, process} from '@progress/kendo-data-query';

// multilingual
import {
    descriptionKey,
    sourceKey,
    licenseExpKey,
    licenseTypeKey,
    prodNoKey,
    qtyKey,
    supportExpKey,
    termKey,
    backKey,
    licensesHostIdColonKey,
    mainMessages
} from '../../assets/text/MultilingualText.js';
import {useLocalization} from "@progress/kendo-react-intl";


function ShowLicensesModal(props) {
    const {
        host,
        setHost,
        licenses,
        setIsVisible,
        gridNoRecordsMessage
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: 'var(--color-text-primary)'
    };

    // closes the show licenses modal
    const close = () => {
        setHost({});
        setIsVisible(isVisible => ({...isVisible, showLicenses: false}));
    };

    const [sort, setSort] = useState([]);
    const [licenseGridState, setLicenseGridState] = useState({});
    const [licenseState, setLicenseState] = useState(process(licenses, {}));

    /*
     * onLicenseStateChange(event) sets the license grid state and license state on grid's state change
     * @param {event} the grid selection change event
    */
    const onLicenseStateChange = useCallback((event: GridDataStateChangeEvent) => {
        const newLicenseState = process(licenses, event.dataState);

        setLicenseGridState(event.dataState);
        setLicenseState(newLicenseState);
    }, [licenses]); // eslint-disable-line react-hooks/exhaustive-deps

    // handle sort and column filter
    useEffect(() => {
        setLicenseGridState({...licenseGridState, sort: sort});
    }, [sort]); // eslint-disable-line react-hooks/exhaustive-deps

    // initialize show licenses table
    useEffect(() => {
        if (licenses.length) {
            setLicenseState(process(licenses, {}));
        }
    }, [licenses]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Dialog
            className={"show-licenses-modal ksm-dialog"}
            title={<div style={titleStyle}>
                {localization.toLanguageString(licensesHostIdColonKey, mainMessages[siteLanguageDefault][licensesHostIdColonKey]) + " " + host.host_id}
            </div>}
            onClose={close}
            height={"90vh"}
            width={"90vw"}
        >
            <Grid
                className={"show-licenses-grid"}
                data={orderBy(licenseState.data, sort)}
                sortable={true}
                scrollable={"none"}
                sort={sort}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}
                onDataStateChange={onLicenseStateChange}
                {...licenseGridState}
            >
                <GridNoRecords>
                    {gridNoRecordsMessage}
                </GridNoRecords>
                <GridColumn
                    field="prod_num"
                    title={localization.toLanguageString(prodNoKey, mainMessages[siteLanguageDefault][prodNoKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="description"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="license_term"
                    title={localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="locking_type"
                    title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="qty"
                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                    cell={TextAlignMiddleCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="license_expiration_date"
                    title={localization.toLanguageString(licenseExpKey, mainMessages[siteLanguageDefault][licenseExpKey])}
                    cell={DateIsExpiredCell}
                    columnMenu={ColumnMenu}
                    filter={'date'}
                />
                <GridColumn
                    field="support_expiration_date"
                    title={localization.toLanguageString(supportExpKey, mainMessages[siteLanguageDefault][supportExpKey])}
                    cell={DateIsExpiredCell}
                    columnMenu={ColumnMenu}
                    filter={'date'}
                />
                <GridColumn
                    field="order_source"
                    title={localization.toLanguageString(sourceKey, mainMessages[siteLanguageDefault][sourceKey])}
                    columnMenu={ColumnMenu}
                />
            </Grid>
            <DialogActionsBar layout="start">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    onClick={close}
                >
                    {localization.toLanguageString(backKey, mainMessages[siteLanguageDefault][backKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default ShowLicensesModal;