import {useRef, useState} from "react";
import {Tooltip} from "@progress/kendo-react-tooltip";
import KSMPopover from "../common/Popovers";
import {InfoIcon} from "../common/icons";

// Custom info Icon cell
// title ::  tooltip title
// subtitle :: tooltip subtitle
export const InfoIconCell = ({title, subtitle}) => {
    const anchor = useRef(null);
    const [show, setShow] = useState(false);
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        >
            <div
                ref={anchor}
                onMouseOver={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                <InfoIcon className={'ksm-grid-icon'}/>
            </div>
            <KSMPopover
                show={show}
                anchor={anchor && anchor.current}
                position={"left"}
                style={{
                    maxWidth: '42rem'
                }}
                type={'info'}
                message={
                    <div className={'k-d-flex k-flex-column'}>
                        <span
                            style={{
                                fontSize: '1rem'
                            }}
                            className={'k-font-weight-bold'}
                        >
                            {title}
                        </span>
                        <span
                            style={{
                                fontSize: '1rem'
                            }}
                            className={'k-font-weight-normal'}
                        >
                                {subtitle}
                        </span>
                    </div>
                }
            />
        </td>
    )
}

/* action Icon cell
    iconClass : for displying icon on cell
    onHandleClick : to handle click on icon
    showActionIcon : accepts boolean as value. if true it will display icon or else nothing
    title : title then passed to Tooltip component to display tooltip message
*/
export const ActionIconCell = ({onHandleClick, Icon, showActionIcon, title, ...props}) => {
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        >
            {showActionIcon &&
                <Tooltip
                    anchorElement="target"
                    parentTitle={true}
                    position="left"
                >
                    <div title={title} onClick={onHandleClick}>
                        <Icon
                            className={'ksm-grid-icon'}
                        />

                    </div>
                </Tooltip>
            }
        </td>
    );
}

/* Link button cell with Icon
    iconClass : for displying icon on cell
    onHandleClick : to handle click on icon
    showActionIcon : accepts boolean as value. if true it will display icon or else nothing
    title : title then passed to Tooltip component to display tooltip message
    truncate : truncate will reduce number of text to display on UI
*/
export const HostOrderNumberCel = ({onHandleClick, Icon, showActionIcon, title, truncate, ...props}) => {
    const field = props.field;
    const MAX_CHARS_TO_TRUNCATE = 20;
    const truncatedText = (data) => {
        if (data?.length > MAX_CHARS_TO_TRUNCATE) {
            return `${data?.slice(0, 20)}...`;
        }
        return data;
    }
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle',
                color: "var(--keysight-secondary)"
            }}>
            <div className='k-display-flex' style={{
                alignItems: 'center',
            }}>
                <Tooltip
                    anchorElement="target"
                    parentTitle={true}
                    position="left"
                >
                    <span
                        style={{
                            whiteSpace: "nowrap"
                        }}
                        title={props.dataItem[field]}
                        className='k-cursor-pointer'
                    >
                        {truncate ? truncatedText(props.dataItem[field]) : props.dataItem[field]}
                    </span>
                </Tooltip>
                {showActionIcon &&
                    <Tooltip
                        anchorElement="target"
                        parentTitle={true}
                        position="left"
                    >
                        <div title={title}>
                            <Icon
                                className={'ksm-grid-icon'}
                                onClick={onHandleClick}
                                style={{
                                    marginTop: '3px'
                                }}
                                size={"medium"}
                            />
                        </div>
                    </Tooltip>
                }
            </div>
        </td>
    );
}

//Purchase order number Cell
export const PurchaseOrderNumberCell = ({title, truncate, ...props}) => {
    const field = props.field;
    const MAX_CHARS_TO_TRUNCATE = 20;
    const truncatedText = (data) => {
        if (data?.length > MAX_CHARS_TO_TRUNCATE) {
            return `${data?.slice(0, 20)}...`;
        }
        return data;
    }
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle',
                color: "var(--keysight-secondary)"
            }}>
            <div className='k-display-flex'>
                <Tooltip
                    anchorElement="target"
                    parentTitle={true}
                    position="left"
                >
                    {props.dataItem[field] &&
                        <span
                            title={props.dataItem[field]}
                            className='k-cursor-pointer k-font-weight-bold'
                        >
                            {truncate ? truncatedText(props.dataItem[field]) : props.dataItem[field]}
                        </span>
                    }
                </Tooltip>
            </div>
        </td>
    );
}