import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from "reactstrap";

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import NavigationImg from '../assets/img/Help/ksm-renew-eesof-navigation.png';
import ReassignLicenseImg from '../assets/img/Help/ksm-renew-eesof-reassign-licenses.png';
import ReassignButtonImg from '../assets/img/Help/ksm-renew-eesof-reassign-button.png';
import ReassignLicenseFromHostImg from '../assets/img/Help/ksm-renew-eesof-reassign-from-host.png';
import RenewSelectedImg from '../assets/img/Help/ksm-renew-eesof-renew-selected.png';
import GenerateLicensesImg from '../assets/img/Help/ksm-renew-eesof-generate-licenses.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    errorHandlingKey,
    helpRenewEesofErrorHandlingSectionKey,
    frequentlyAskedQuestionsKey,
    renewEESOFLicensesKey,
    mainMessages,
    navigationKey,
    helpRenewEesofNavigationSectionKey,
    helpRenewEesofQuickStepsSectionKey,
    quickStepsRenewEesofKey,
    reassigningLicenceNewHostKey,
    helpRenewEesofReassignLicenseSectionKey,
    renewalKey,
    helpRenewEesofRenewalQAKey,
    requestingRenewalKey,
    helpRenewEesofRequestLicenseSectionKey,
    requestRenewedLicenseKey
} from '../assets/text/MultilingualText.js';


function RenewEesofHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;

        let img;
        let imgInlineStyle = item.img === "ksm-renew-eesof-reassign-button" ? {display: 'flex'} : {};

        if (item.img) {
            switch (item.img) {
                case 'ksm-renew-eesof-reassign-licenses':
                    img = <img src={ReassignLicenseImg} alt={item.img}/>
                    break;
                case 'ksm-renew-eesof-reassign-button':
                    img = <img src={ReassignButtonImg} alt={item.img}/>
                    break;
                case 'ksm-renew-eesof-reassign-from-host':
                    img = <img src={ReassignLicenseFromHostImg} alt={item.img}/>
                    break;
                case 'ksm-renew-eesof-renew-selected':
                    img = <img src={RenewSelectedImg} alt={item.img}/>
                    break;
                case 'ksm-renew-eesof-generate-licenses':
                    img = <img src={GenerateLicensesImg} alt={item.img}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        if (item.substeps && item.substeps.length) {
            children = type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        return (
            <li>
                <div style={imgInlineStyle}>
                    {toHtml(item.step)}
                    {item.img ? <span className="img-container">
                        {img}
                    </span> : <></>}
                </div>
                {children}
            </li>
        );
    }

    const helpMenuContent = {
        'header': {
            key: renewEESOFLicensesKey,
            default: mainMessages[siteLanguageDefault][renewEESOFLicensesKey]
        },
        'topics': [
            {
                key: quickStepsRenewEesofKey,
                default: mainMessages[siteLanguageDefault][quickStepsRenewEesofKey],
                hash: "#quick-steps"
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: "#navigation"
            },
            {
                key: reassigningLicenceNewHostKey,
                default: mainMessages[siteLanguageDefault][reassigningLicenceNewHostKey],
                hash: "#reassign-license"
            },
            {
                key: requestRenewedLicenseKey,
                default: mainMessages[siteLanguageDefault][requestRenewedLicenseKey],
                hash: "#request-license"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            }
        ],
        'faq': [
            {
                key: renewalKey,
                default: mainMessages[siteLanguageDefault][renewalKey],
                hash: "#renewal-faq"
            }
        ]
    }

    const quickStepsContent = localization.toLanguageString(
        helpRenewEesofQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpRenewEesofQuickStepsSectionKey]
    );

    const navigationContent = localization.toLanguageString(
        helpRenewEesofNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpRenewEesofNavigationSectionKey]
    );

    const reassignLicenseContent = localization.toLanguageString(
        helpRenewEesofReassignLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpRenewEesofReassignLicenseSectionKey]
    );

    const requestLicenseContent = localization.toLanguageString(
        helpRenewEesofRequestLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpRenewEesofRequestLicenseSectionKey]
    );

    const errorHandlingContent = localization.toLanguageString(
        helpRenewEesofErrorHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpRenewEesofErrorHandlingSectionKey]
    );

    const renewalQA = localization.toLanguageString(
        helpRenewEesofRenewalQAKey,
        mainMessages[siteLanguageDefault][helpRenewEesofRenewalQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsRenewEesofKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsRenewEesofKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {quickStepsContent.section_one}
                            </p>
                            <ol>
                                {quickStepsContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {navigationContent.section_one}
                            </p>
                            <ul>
                                {navigationContent.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`navigation-${idx}`}>
                                        {toHtml(bullet)}
                                        {
                                            idx === 1 &&
                                            <img src={NavigationImg} alt={'renew-eesof-navigation'}/>
                                        }
                                    </li>;
                                })}
                            </ul>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="reassign-license"
                        >
                            <Text
                                textkey={reassigningLicenceNewHostKey}
                                textdefault={mainMessages[siteLanguageDefault][reassigningLicenceNewHostKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {reassignLicenseContent.section_one}
                            </p>
                            <ol>
                                {reassignLicenseContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="request-license"
                        >
                            <Text
                                textkey={requestingRenewalKey}
                                textdefault={mainMessages[siteLanguageDefault][requestingRenewalKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {requestLicenseContent.section_one}
                            </p>
                            <ol>
                                {requestLicenseContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {errorHandlingContent.section_one}
                            </p>
                            {errorHandlingContent.section_one_bullets ? <ul>
                                {errorHandlingContent.section_one_bullets.map((bullet, index) => <li
                                    key={'navigation-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div className={"k-h3 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="renewal-faq">
                            <h3>
                                <Text
                                    textkey={renewalKey}
                                    textdefault={mainMessages[siteLanguageDefault][renewalKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {renewalQA ? renewalQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"renewal-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default RenewEesofHelp;

