import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

//reactstrap
import {Col, Row} from "reactstrap";

// css
import '../assets/css/TransportCCL.css';

//multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    licenseIdsKey,
    checkTryAgainKey,
    infoProvidedInvalidKey,
    enterLicensesKey,
    contactUsHeaderKey,
    genericErrorTitleKey,
    transportLicenseKey,
    alreadyTransportedKey,
    oneMoreLicensesNotAvailableKey,
    mainMessages,
    navigationAlertKey,
    transportLicenseLogInTOKSMKey,
    licensesNotLinkedKSMProfileKey,
    saveToKeysightSoftwareManagerKey,
    reviewAndSubmitKey
} from "../assets/text/MultilingualText";

//components
import PageHeader from "../components/common/PageHeader";
import UserContext from "../components/common/UserContext";
import KSMStepper from "../components/common/Stepper";
import EnterLicenses from "../components/TransportCCL/EnterLicenses";
import Alert from "../components/common/Alert";
import Spinner from "../components/common/Spinner";
import ReviewSubmit from "../components/TransportCCL/ReviewSubmit";


export default function TransportCCL() {
    const {
        siteLanguage,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false);

    const defaultStepperItems = [
        {
            label: localization.toLanguageString(enterLicensesKey, mainMessages[siteLanguageDefault][enterLicensesKey]),
            number: 1,
            disabled: false
        },
        {
            label: localization.toLanguageString(reviewAndSubmitKey, mainMessages[siteLanguageDefault][reviewAndSubmitKey]),
            number: 2,
            disabled: true
        },
    ]

    const [stepperItems, setStepperItems] = useState(defaultStepperItems);
    const [stepValue, setStepValue] = useState(0);

    //Changes step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {
        if (stepValue === 0) {
            removeAlerts()
            if (stepChange === 1) {
                stepperItems[stepChange].disabled = false;
                if (!transport) {
                    const panelBarTitle = localization.toLanguageString(saveToKeysightSoftwareManagerKey, mainMessages[siteLanguageDefault][saveToKeysightSoftwareManagerKey])
                    const newAssigned = [{
                        panelBarTitle: panelBarTitle,
                        transaction_id: 'banked',
                        products: [...licenses]
                    }]
                    setAssigned(newAssigned)
                }
            }
        }

        if (stepChange === 0) {
            setUnassigned([])
            setAssigned([])
            for (const item of licenses) {
                item.selected = false
            }
            setLicenses([...licenses])
            stepperItems[1].disabled = true;
        }

        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems(defaultStepperItems);
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [licenses, setLicenses] = useState([])
    const [xAuthToken, setxAuthToken] = useState('')
    const [unassigned, setUnassigned] = useState([])
    const [assigned, setAssigned] = useState([])
    const [transport, setTransport] = useState(false)

    const removeAlerts = () => {
        setInvalidParams(false)
        setSaveError([])
        setNoLongerValid([])
        setGeneralError(false)
    }

    const [generalError, setGeneralError] = useState(false)
    const hideGeneralError = () => {
        setGeneralError(false)
    }

    const [invalidParams, setInvalidParams] = useState(false)
    const hideInvalidParams = () => {
        setInvalidParams(false)
    }

    const [saveError, setSaveError] = useState([])
    const hideSaveError = () => {
        setSaveError([])
    }

    const [noLongerValid, setNoLongerValid] = useState([])
    const hideNoLongerValid = () => {
        setNoLongerValid([])
    }

    const [showAddLicenseModal, setShowAddLicenseModal] = useState(false)


    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={'ksm-page-container'}>
                <Row style={{
                    marginBottom: '0.938rem'
                }}>
                    <Col>
                        <div className={"k-h3"}>
                            {localization.toLanguageString(transportLicenseKey, mainMessages[siteLanguageDefault][transportLicenseKey])}
                        </div>
                    </Col>
                    <Col>
                        <KSMStepper
                            stepperItems={stepperItems}
                            stepValue={stepValue}
                            changeStepActivation={changeStepActivation}
                        />
                    </Col>
                    <Col/>
                </Row>
                {stepValue === 0 && (
                    <EnterLicenses
                        xAuthToken={xAuthToken}
                        setxAuthToken={setxAuthToken}
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        licenses={licenses}
                        setLicenses={setLicenses}
                        setTransport={setTransport}
                        setSaveError={setSaveError}
                        setNoLongerValid={setNoLongerValid}
                        setGeneralError={setGeneralError}
                        removeAlerts={removeAlerts}
                        setInvalidParams={setInvalidParams}
                        showAddLicenseModal={showAddLicenseModal}
                        setShowAddLicenseModal={setShowAddLicenseModal}
                        generalError={generalError}
                        hideGeneralError={hideGeneralError}
                        invalidParams={invalidParams}
                        hideInvalidParams={hideInvalidParams}
                        setAssigned={setAssigned}
                        assigned={assigned}
                    />
                )}
                {stepValue === 1 && (
                    <ReviewSubmit
                        xAuthToken={xAuthToken}
                        handleBeforeUnload={handleBeforeUnload}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                        changeStepActivation={changeStepActivation}
                        setIsLoading={setIsLoading}
                        transport={transport}
                        licenses={licenses}
                        setLicenses={setLicenses}
                        unassigned={unassigned}
                        setUnassigned={setUnassigned}
                        assigned={assigned}
                        setAssigned={setAssigned}
                        setNoLongerValid={setNoLongerValid}
                        setGeneralError={setGeneralError}
                        setSaveError={setSaveError}
                    />
                )}

                {!showAddLicenseModal && generalError && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',
                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                showHandler={hideGeneralError}
                            />
                        </div>
                    </div>
                )}

                {!showAddLicenseModal && invalidParams && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',
                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(infoProvidedInvalidKey, mainMessages[siteLanguageDefault][infoProvidedInvalidKey])}
                                message={localization.toLanguageString(checkTryAgainKey, mainMessages[siteLanguageDefault][checkTryAgainKey])}
                                showHandler={hideInvalidParams}
                            />
                        </div>
                    </div>
                )}

                {saveError.length !== 0 && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',

                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(licensesNotLinkedKSMProfileKey, mainMessages[siteLanguageDefault][licensesNotLinkedKSMProfileKey])}
                                message={<>
                                    {localization.toLanguageString(licenseIdsKey, mainMessages[siteLanguageDefault][licenseIdsKey]) + ': ' + saveError.join(', ')}
                                    <br/>
                                    {localization.toLanguageString(transportLicenseLogInTOKSMKey, mainMessages[siteLanguageDefault][transportLicenseLogInTOKSMKey])}
                                </>}
                                showHandler={hideSaveError}
                            />
                        </div>
                    </div>
                )}

                {noLongerValid.length !== 0 && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.938rem',

                    }}>
                        <div style={{
                            width: '35.813rem'
                        }}>
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(oneMoreLicensesNotAvailableKey, mainMessages[siteLanguageDefault][oneMoreLicensesNotAvailableKey])}
                                message={<>
                                    {localization.toLanguageString(alreadyTransportedKey, mainMessages[siteLanguageDefault][alreadyTransportedKey]) + ': '}
                                    <br/><br/>
                                    {localization.toLanguageString(licenseIdsKey, mainMessages[siteLanguageDefault][licenseIdsKey]) + ': ' + noLongerValid.join(', ')}
                                </>}
                                showHandler={hideNoLongerValid}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}