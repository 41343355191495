import React, {useContext, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/HostConfig.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// kendo react
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Input, TextArea, TextBox} from '@progress/kendo-react-inputs';
import {Label} from '@progress/kendo-react-labels';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {Text} from '../common/MultilingualText.js';
import {useLocalization} from '@progress/kendo-react-intl';
import {
    aliasKey,
    assetInfoKey,
    cancelKey,
    cityKey,
    countryKey,
    licenseNotifierHostIdColonKey,
    hostMetaDataKey,
    editHostKey,
    notesKey,
    saveHostIDKey,
    serialNumberKey,
    stateKey,
    mainMessages,
} from '../../assets/text/MultilingualText.js';


function EditHostModal(props) {
    const {
        host,
        setHost,
        getHosts,
        setIsVisible,
        setIsLoading
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: 'var(--color-text-primary)'
    };

    const disabledTextAreaStyle = {
        borderColor: "rgba(0, 0, 0, 0.08)"
    }

    let hostConfIDInfo = host.host_conf_id ? host.host_conf_id: "";
    let hostIDInfo = host.host_id ? host.host_id : "";
    let serialNumberInfo = host.serial_number ? host.serial_number : "";
    let aliasInfo = host.alias ? host.alias : "";
    let assetInfo = host.asset_info ? host.asset_info : "";
    let cityInfo = host.city ? host.city : "";
    let stateInfo = host.state ? host.state : "";
    let countryInfo = host.country ? host.country : "";
    let notesInfo = host.notes ? host.notes : "";

    const [hostConfID] = useState(hostConfIDInfo);
    const [hostID] = useState(hostIDInfo);
    const [serialNumber] = useState(serialNumberInfo);
    const [alias, setAlias] = useState(aliasInfo);
    const [assetInformation, setAssetInformation] = useState(assetInfo);
    const [city, setCity] =  useState(cityInfo);
    const [state, setState] = useState(stateInfo);
    const [country, setCountry] = useState(countryInfo);
    const [notes, setNotes] = useState(notesInfo);

    // closes the edit host modal
    const close = () => {
        setHost({});
        setIsVisible(isVisible => ({...isVisible, editHost: false}));
    };

    /*
    * editHost() edits the host
    */
    const editHost = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let data = {
            "module": "EA",
            "sub_module": "hosts",
            "action": "UPDATE",
            "input_json": [
                {
                    host_conf_id: hostConfID,
                    alias: alias,
                    asset_info: assetInformation,
                    city: city,
                    state: state,
                    country: country,
                    notes: notes
                }
            ]
        };

        setIsLoading(true);

        axios.put(
            config.host_config.HOST_SERVICE,
            data,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    getHosts();
                    close();
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to UPDATE host", error);
                setIsLoading(false);
            });
    }

    return (
        <Dialog
            className={"edit-host-modal ksm-dialog"}
            title={<div style={titleStyle}>
                {localization.toLanguageString(editHostKey, mainMessages[siteLanguageDefault][editHostKey])}
            </div>}
            onClose={close}
        >
            <Row>
                <Col>
                    <Label>
                        {localization.toLanguageString(licenseNotifierHostIdColonKey, mainMessages[siteLanguageDefault][licenseNotifierHostIdColonKey])}
                    </Label>
                    <TextBox
                        value={hostID}
                        disabled={true}
                        style={disabledTextAreaStyle}
                    />
                </Col>
                <Col>
                    <Label>
                        {localization.toLanguageString(serialNumberKey, mainMessages[siteLanguageDefault][serialNumberKey])}
                    </Label>
                    <TextBox
                        value={serialNumber}
                        disabled={true}
                        style={disabledTextAreaStyle}
                    />
                </Col>
            </Row>
            <Row className={"mt-4"}>
                <div
                    className={"k-h5"}
                >
                    <Text
                        textkey={hostMetaDataKey}
                        textdefault={mainMessages[siteLanguageDefault][hostMetaDataKey]}
                    />
                </div>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Label>
                                {localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                            </Label>
                            <Input
                                value={alias}
                                maxLength={50}
                                onChange={(e) => {
                                    setAlias(e.value)
                                }}
                                autoFocus={true}
                            />
                        </Col>
                        <Col>
                            <Label>
                                {localization.toLanguageString(assetInfoKey, mainMessages[siteLanguageDefault][assetInfoKey])}
                            </Label>
                            <Input
                                value={assetInformation}
                                maxLength={150}
                                onChange={(e) => {
                                    setAssetInformation(e.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={6}>
                            <Label>
                                {localization.toLanguageString(cityKey, mainMessages[siteLanguageDefault][cityKey])}
                            </Label>
                            <Input
                                value={city}
                                maxLength={50}
                                onChange={(e) => {
                                    setCity(e.value)
                                }}
                            />
                        </Col>
                        <Col xs={2}>
                            <Label>
                                {localization.toLanguageString(stateKey, mainMessages[siteLanguageDefault][stateKey])}
                            </Label>
                            <Input
                                value={state}
                                maxLength={50}
                                onChange={(e) => {
                                    setState(e.value)
                                }}
                            />
                        </Col>
                        <Col xs={4}>
                            <Label>
                                {localization.toLanguageString(countryKey, mainMessages[siteLanguageDefault][countryKey])}
                            </Label>
                            <Input
                                value={country}
                                maxLength={50}
                                onChange={(e) => {
                                    setCountry(e.value)
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className={"flex"}>
                    <div className={"flex flex-grow-1"}>
                        <Label>
                            {localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey])}
                        </Label>
                        <TextArea
                            className={"flex-grow-1"}
                            value={notes}
                            maxLength={500}
                            onChange={(e) => {
                                setNotes(e.value)
                            }}
                            autoSize={false}
                        />
                    </div>
                </Col>
            </Row>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    type={"button"}
                    onClick={close}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    onClick={editHost}
                >
                    {localization.toLanguageString(saveHostIDKey, mainMessages[siteLanguageDefault][saveHostIDKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default EditHostModal;