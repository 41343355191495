import React from 'react';

// react strap
import {Col, Row} from 'reactstrap';

export const PanelbarHeader = (props) => {
    const {
        hostJSON,
    } = props;

    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h5"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {hostJSON.host_id}
                    </div>
                </Col>
            </Row>
        </>
    )
}