import React, {useContext, useEffect, useState} from 'react';
import UserContext from './UserContext.js';

// components
import Alert from './Alert.js';
import {emailLicenses} from './utilities.js';

// kendo react
import {TextArea} from '@progress/kendo-react-inputs';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cancelKey,
    emailLicensesDirectionsKey,
    contactUsHeaderKey,
    genericErrorTitleKey,
    mainMessages,
    sendLicenseKey,
    mailAllKey,
    emailValidationMessageKey,
    invalidEmailKey
} from '../../assets/text/MultilingualText.js';


export const EmailDialog = (props) => {
    const {
        setShowEmailModal,
        setShowEmailSuccess,
        transactionIDs,
        setIsLoading,
        request_source,
        publicCall=false,
        xAuthToken=null,
    } = props;
    const {
        accessToken,
        userEmail,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [emails, setEmails] = useState(userEmail);
    const [showValidationError, setShowValidationError] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [textAreaStyling, setTextAreaStyling] = useState({});

    //Closes the error email alert
    const alertEmailErrorHandler = () => {
        setShowEmailError(false);
    }

    //Sets and remove the textarea error red lining
    useEffect(() => {
        if (showValidationError) {
            setTextAreaStyling({
                border: 'var(--color-alert-danger-base)'
            });
        } else {
            setTextAreaStyling({});
        }
    }, [showValidationError])

    //Close the email modal
    const closeEmailModal = () => {
        setShowEmailModal(false);
    }

    //Validate emails and sets the validate error state
    const validateEmails = () => {
        let emailList = emails.split(",");
        emailList = emailList.map(item => {
            return item.toLowerCase().trim();
        })

        let mailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        let invalid = false;

        emailList.forEach(item => {
            if (item.match(mailFormat) === null) {
                invalid = true;
            }
        })
        if (invalid) {
            setShowValidationError(true);
            return [];
        } else {
            setShowValidationError(false);
            return emailList;
        }
    }

    //On click function for send licenses
    const sendLicenses = () => {
        const emailList = validateEmails();
        if (emailList.length > 0) {
            setShowValidationError(false);
            let token
            if(publicCall){
                token =xAuthToken
            } else {
                token = accessToken
            }
            emailLicenses(emailList, transactionIDs, token, setIsLoading, request_source, publicCall).then(response => {
                if (response.status === 200) {
                    setShowEmailError(false);
                    setShowEmailSuccess(true);
                    setIsLoading(false);
                    closeEmailModal();
                }
            }).catch((error) => {
                console.log("ERROR: Failed to Email license", error);
                setShowEmailSuccess(false);
                setShowEmailError(true);
                setIsLoading(false);
            });
        }
    }

    //closes the validation error alert
    const alertErrorHandler = () => {
        setShowValidationError(false);
    }

    return (
        <Dialog
            title={localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
            className={'ksm-dialog'}
            onClose={closeEmailModal}
            width={"29.375rem"}
        >
            <div>
                <div
                    style={{
                        color: '#424242',
                        marginBottom: '3px'
                    }}
                >
                    {localization.toLanguageString(emailLicensesDirectionsKey, mainMessages[siteLanguageDefault][emailLicensesDirectionsKey])}
                </div>
                <TextArea
                    defaultValue={emails}
                    onChange={(e) => {
                        setEmails(e.value);
                    }}
                    value={emails}
                    style={textAreaStyling}
                    rows={3}
                    autoFocus={true}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.938rem',
                    marginTop: '0.938rem'
                }}
            >
                {showValidationError &&(
                    <div>
                        <Alert
                            type={'error'}
                            showHandler={alertErrorHandler}
                            title={localization.toLanguageString(invalidEmailKey, mainMessages[siteLanguageDefault][invalidEmailKey])}
                            message={localization.toLanguageString(emailValidationMessageKey, mainMessages[siteLanguageDefault][emailValidationMessageKey])}
                        />
                    </div>
                )}
                {showEmailError && (
                    <div>
                        <Alert
                            type={'error'}
                            showHandler={alertEmailErrorHandler}
                            title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                            message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                        />
                    </div>
                )}
            </div>
            <DialogActionsBar>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '0.5rem'
                    }}
                >
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        type={"button"}
                        onClick={closeEmailModal}
                    >
                        {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                    </Button>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        type={"button"}
                        onClick={sendLicenses}
                        disabled={emails.trim() === ""}
                    >
                        {localization.toLanguageString(sendLicenseKey, mainMessages[siteLanguageDefault][sendLicenseKey])}
                    </Button>
                </div>
            </DialogActionsBar>
        </Dialog>
    )
}