// kendo react
import {Stepper} from '@progress/kendo-react-layout';

function KSMStepper(props) {
    const {
        stepperItems,
        stepValue,
        changeStepActivation
    } = props

    const handleStepChange = (e) => {
        changeStepActivation(e.value)
    };

    return (
        <Stepper
            value={stepValue}
            onChange={handleStepChange}
            items={stepperItems}
        />
    );
}

export default KSMStepper;
