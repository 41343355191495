import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import config from "../../../config";

//components
import UserContext from "../../common/UserContext";
import {NoWrapCell} from "../../common/Grid";
import Alert from "../../common/Alert";

//multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    descriptionKey,
    keysightSoftwareManagerKey,
    licenseIdKey,
    mainMessages,
    productKey,
    qtyKey,
    TransportSuccessBankedMessage,
    licensesAreSuccessfullySavedKey
} from "../../../assets/text/MultilingualText";

//kendo
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {Grid, GridColumn} from "@progress/kendo-react-grid";


export default function SubmitBanked(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        assigned,
        xAuthToken,
        setIsLoading,
        setNoLongerValid,
        setGeneralError,
        setSaveError
    } = props

    const [success, setSuccess] = useState(false)
    const hideSuccess = () => {
        setSuccess(false)
    }

    useEffect(() => {
        let formData;

        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': xAuthToken
        };

        let selectedLicenses = assigned[0].products.map((item) => {
            return {
                license_id: item.license_id.toString(),
                sn: item.sn.toString(),
                confirmation_code: item.confirmation_code
            }
        })

        let data = {
            "module": "LICENSE",
            "sub_module": "BANK",
            "action": "SAVE",
            "input_json": [
                {
                    "values": selectedLicenses
                }
            ]
        }

        formData = new FormData();
        formData.append('File', '');
        formData.append('Data', JSON.stringify(data));

        axios.post(
            config.transport.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    let failed = response.data['failed_out_resp']
                    let invalidSave = []
                    let invalidBank = []
                    for (const item of failed) {
                        if (item['error_code'] === 'MOD_TRANSPORT_3019') {
                            invalidBank.push(item['lic'])
                        }
                        if (item['error_code'] === 'MOD_TRANSPORT_3017') {
                            invalidSave.push(item['lic'])
                        }
                    }
                    setSaveError(invalidSave)
                    setNoLongerValid(invalidBank)

                    setSuccess(response.data['success_out_resp'].length > 0)
                }
            })
            .catch((error) => {
                console.log("ERROR: Validate banked license", error.response);
                setGeneralError(true)
                setSuccess(false)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <PanelBar
                className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                isControlled={true}
                expanded={['.0']}
            >
                {assigned.map((item) => {
                    return (
                        <PanelBarItem
                            title={
                                <div
                                    className={"k-h5"}
                                    style={{
                                        color: 'white',
                                        margin: '0 0 0 2px'
                                    }}>
                                    {item.panelBarTitle}
                                </div>
                            }
                            key={item.transaction_id}
                        >
                            <Grid
                                className={'review-submit-ccl-gird'}
                                scrollable={'none'}
                                data={item.products}
                            >
                                <GridColumn
                                    field="license_id"
                                    title={localization.toLanguageString(licenseIdKey, mainMessages[siteLanguageDefault][licenseIdKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="prod_num"
                                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="prod_desc"
                                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                />
                                <GridColumn
                                    field="qty_redeemed"
                                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                />
                            </Grid>
                        </PanelBarItem>
                    )
                })}
            </PanelBar>

            {success && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0.938rem',

                }}>
                    <div style={{
                        width: '35.813rem'
                    }}>
                        <Alert
                            type={'success'}
                            title={localization.toLanguageString(licensesAreSuccessfullySavedKey, mainMessages[siteLanguageDefault][licensesAreSuccessfullySavedKey])}
                            message={<>
                                {localization.toLanguageString(TransportSuccessBankedMessage, mainMessages[siteLanguageDefault][TransportSuccessBankedMessage])}
                                <br/>
                                <Link to={'/'} className={'hyperlink'}>
                                    {localization.toLanguageString(keysightSoftwareManagerKey, mainMessages[siteLanguageDefault][keysightSoftwareManagerKey])}
                                </Link>
                            </>}
                            showHandler={hideSuccess}
                        />
                    </div>
                </div>
            )}
        </>
    )
}