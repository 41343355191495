import React from 'react';

// kendo react
import {Popover} from "@progress/kendo-react-tooltip";
import {CloseIcon, InfoIcon, SuccessIcon, WarningErrorIcon} from "./icons";


function KSMPopover(props) {
    let {
        show,
        setShowHandler,
        type,
        message,
        ...options
    } = props;

    let color;
    let className;
    let icon;

    switch (type) {
        case 'success':
            color = 'var(--color-alert-success-dark)';
            className = 'ksm-popover-success';
            icon = <SuccessIcon size={'large'} style={{
                'color': 'var(--color-alert-success-base)'
            }}/>;
            break;
        case 'error':
            color = 'var(--color-alert-danger-dark)';
            className = 'ksm-popover-error';
            icon = <WarningErrorIcon size={'large'} style={{
                'color': 'var(--color-alert-danger-base)'
            }}/>;
            break;
        case 'warning':
            color = 'var(--color-alert-warning-dark)';
            className = 'ksm-popover-warning';
            icon = <WarningErrorIcon size={'large'} style={{
                'color': 'var(--color-alert-warning-base)'
            }}/>;
            break;
        default:
            color = 'var(--keysight-dark-purple)';
            className = 'ksm-popover-alert-info';
            icon = <InfoIcon size={'large'} style={{
                'color': 'var(--keysight-purple)'
            }}/>;
    }

    return (
        <Popover
            show={show}
            className={'ksm-popover ' + className}
            animate={false}
            {...options}
        >
            <div style={{
                display: 'flex',
                gap: '0.5rem',
            }}>
                <div>
                    {icon}
                </div>
                <div style={{color: color}}>
                    {message}
                </div>
                {setShowHandler && (
                    <div
                        style={{
                            alignSelf: 'start',
                            cursor: 'pointer'
                        }}
                    >
                        <CloseIcon
                            style={{color: color}}
                            onClick={setShowHandler}
                        />
                    </div>
                )}
            </div>
        </Popover>
    )
}

export default KSMPopover;