import React, {useEffect} from "react";
import Spinner from "./Spinner";
import {useHistory, useLocation} from "react-router-dom";

function RedirectReload() {

    const {search} = useLocation()
    let history = useHistory();

    useEffect(() => {
        let queryParam = new URLSearchParams(search)
        let reloadPath = queryParam.get('path')
        reloadPath ? history.push(reloadPath) : history.push('/')
    }, [])

    return (
        <>
            <Spinner/>
            <div className={"ksm-page-container"}/>
        </>

    )
}

export default RedirectReload