import React, {useContext, useRef, useState} from 'react';
import axios from 'axios';
import config from '../../../../config';
import UserContext from '../../UserContext';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {toHtml} from '../../utilities';
import DialogLayout from "./DialogLayout";

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Upload} from '@progress/kendo-react-upload';
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {
    hostIdFormattingKey,
    mainMessages,
    specificHostFileKey, uploadFileKey
} from "../../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";
import {Label} from "@progress/kendo-react-labels";
import KSMPopover from "../../Popovers";
import {InfoIcon} from "../../icons";


function FileUploadModal(props) {
    const {
        setIsLoading,
        hostDetails,
        assignedProducts,
        selectedProducts,
        onePerHost = 'redeem_one_per_host',
        productNum = 'product_number',
        uniqueID = 'unique_id',
        errors,
        setErrors,
        showError,
        createHost,
        resetSelectedProducts,
        closeModal,
        children,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let hostModal = hostDetails.hostid_type_popup.toUpperCase() || "";
    let hostIDLabel = hostDetails.host_id_info.asl_prompt || "";
    let hostIDHint = hostDetails.host_id_info.input_hint || "";


    const [hostFile, setHostFile] = useState("");
    const [hostFileExtension, setHostFileExtension] = useState("");

    const [isVisible, setIsVisible] = useState({
        uploadHint: false,
    })

    /*
     * onHostFileUpload() uploads the host file
    */
    const onHostFileUpload = (event) => {
        setErrors([]);
        setHostFileExtension(event.target.files[0].extension);
        setHostFile(event.target.files[0].getRawFile());
    };

    const validate = () => {
        setErrors([]);
        if (!hostFile || !hostFileExtension) {
            showError('file')
        }

        if ((hostModal === "C2V" && hostFileExtension === ".c2v") ||
            (hostModal === "HOST_FILE_BIN" && hostFileExtension === ".bin")) {
            validateHostFile();
        }
    }

    /*
     * validate() validates the uploaded .bin or .c2v file
    */
    const validateHostFile = () => {


        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };

        let formData = new FormData();
        formData.append('File', hostFile);
        formData.append('Output', 'HOSTID');

        setIsLoading(true);

        axios.post(
            config.request_license.HOST_FILE_UPLOAD,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    let fileHostID = data.host_id;
                    let fileHostName = data.file_name;

                    setErrors([]);
                    saveHostID(fileHostID, fileHostName);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Validation File", error);
                let status = error?.response?.status

                if (status === 400) {
                    showError('file')
                } else {
                    showError('generic')
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    /*
     * saveHostID() saves the assigned host
     * @param {fileHostID} is an optional id used for saving hosts for file uploads
     * @param {fileHostName} is an optional name used for saving hosts for file uploads
    */
    const saveHostID = (fileHostID = null, fileHostName = null) => {
        let host;
        let hostId;
        let panelBarTitle;

        if (fileHostName) {
            hostId = fileHostName;
            panelBarTitle = "Host " + fileHostName;
            host = assignedProducts.find(host =>
                host.host_id &&
                hostId &&
                host.host_id === hostId &&
                host.file.name === hostFile.name &&
                host.file.size === hostFile.size &&
                host.file.lastModified === hostFile.lastModified
            );
        } else {
            hostId = fileHostID;
            panelBarTitle = fileHostID;
            host = assignedProducts.find(host =>
                host.host_id &&
                hostId &&
                host.host_id === hostId
            );
        }

        // case: products that are 1 per host can only be assigned to a single host
        if (host) {
            let unAssignable = [];
            selectedProducts.forEach((item) => {
                if (item.selected) {
                    let product = host.products.find(product => item[uniqueID] === product[uniqueID]);
                    if (item[onePerHost].toUpperCase() === "Y" && product) {
                        unAssignable.push(product[productNum]);
                    }
                }
            })

            if (unAssignable.length > 0) {
                unAssignable.forEach((item) => {
                    showError('onePerHost', item);
                })
                return null
            }
        }

        createHost(panelBarTitle, host, hostId, "", hostFile)
        resetSelectedProducts()
    }


    const hostFileButtonAnchor = useRef(null);
    const hostFilePopoverAnchor = useRef(null);

    let extension = hostModal === "C2V" ? ".c2v"
        : hostModal === "HOST_FILE_BIN" ? ".bin"
            : ""

    return (
        <DialogLayout
            closeModal={closeModal}
            productTable={children}
            errors={errors}
            validate={validate}
        >
            <Row>
                <Col>
                    <KSMPopover
                        show={isVisible.uploadHint}
                        setShowHandler={() => {
                            setIsVisible(isVisible => ({
                                ...isVisible,
                                uploadHint: false,
                            }));
                        }}
                        anchor={hostFilePopoverAnchor.current}
                        position={'right'}
                        type={'warning'}
                        style={{maxWidth: 400}}
                        message={
                            <>
                                <b style={{fontWeight: '600'}}>
                                    {localization.toLanguageString(specificHostFileKey, mainMessages[siteLanguageDefault][specificHostFileKey])}
                                </b>
                                <br/>
                                {toHtml(hostIDHint)}
                            </>
                        }
                    />
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.2rem',
                        lineHeight: '2rem'
                    }}>
                        <Label>{localization.toLanguageString(uploadFileKey, mainMessages[siteLanguageDefault][uploadFileKey])}</Label>
                        <InfoIcon
                            onClick={() => {
                                setIsVisible(isVisible => ({
                                    ...isVisible,
                                    uploadHint: !isVisible.uploadHint
                                }));
                            }}
                            style={{
                                color: 'var(--keysight-secondary)',
                                cursor: 'pointer',
                            }}
                        />
                        <div ref={hostFilePopoverAnchor}/>
                    </div>
                    <Upload
                        className="ksm-upload"
                        batch={false}
                        multiple={false}
                        withCredentials={false}
                        defaultFiles={[]}
                        restrictions={{
                            allowedExtensions: [extension]
                        }}
                        saveUrl={config.utilities.health}
                        onAdd={onHostFileUpload}
                        selectMessageUI={() =>
                            <Button
                                ref={hostFileButtonAnchor}
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                            >
                                {hostIDLabel}
                            </Button>
                        }
                    />
                </Col>
            </Row>
        </DialogLayout>
    )
}

export default FileUploadModal;