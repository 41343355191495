import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from './UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// kendo react
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';
import {acceptKey, declineKey, findCertKey, mainMessages} from "../../assets/text/MultilingualText";
import {Text} from "./MultilingualText";


function TermsAndConditions(props) {
    const {
        prompt,
        setRestrictedFlag,
        setShowRestrictedPopup,
        setAccessToken,
        lacFlag,
        getMainMenu,
        setIsLoading
    } = props;

    const {
        accessToken,
        siteLanguageDefault,
    } = useContext(UserContext);

    const buttonGroupStyle = {
        display: "flex",
        flexWrap: "wrap",
        gap: "0.5rem",
        alignSelf: "end",
        justifyContent: "end"
    }

    /*
     * refresh() refreshes the jwt to get itar warning message update
    */
    const refresh = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let data = {}
        if (lacFlag === "Y") {
            data['lac_flag'] = lacFlag
        }
        axios.post(
            config.sso.JWT_REFRESH_URL,
            data,
            {headers: headers, withCredentials: true}
        )
            .then((response) => {
                if (response.status === 200 && response.data.access) {
                    setAccessToken(response.data.access);
                    getMainMenu(response.data.access, false, false, true);
                }
            })
            .catch((error) => {
                console.log("ERROR: JWT Refresh for popup update", error);
                setIsLoading(false);
                setShowRestrictedPopup(false);
            });
    }

    /*
     * submitAcknowledgement() submits the user's acknowledgement
     * @param {acknowledgment} the user's acknowledgement based on accepting || declining
    */
    const submitAcknowledgement = (acknowledgment = "decline") => {
        setIsLoading(true);
        let payload = {
            "ack_response": acknowledgment,
        }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        axios.post(
            config.prompts.ACK_RESPONSE,
            payload,
            {headers: headers}
        )
            .then((response) => {
                // user has accepted agreement, do not show alerts anymore
                if (acknowledgment === "accept") {
                    setRestrictedFlag(false);
                    refresh();
                } else {
                    setIsLoading(false);
                    setShowRestrictedPopup(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Terms and Conditions Acknowledgement", error);
                // on error, gracefully close and do not do anything
                setIsLoading(false);
                setShowRestrictedPopup(false);
            })
        ;
    }

    return (
        <>
            <Dialog
                className={"ksm-dialog"}
                title={<h3 className={"m-0"}>
                    {prompt['prompt_title']}
                </h3>}
                closeIcon={false}
                width={"50rem"}
            >
                <div dangerouslySetInnerHTML={{__html: prompt['prompt_message']}}/>
                <DialogActionsBar layout="end">
                    <Row className={"p-0"}>
                        <Col style={buttonGroupStyle}>
                            <Button
                                className={"ksm-internal-button-primary"}
                                themeColor={"primary"}
                                size={"large"}
                                shape={"rectangle"}
                                fillMode={"outline"}
                                rounded={"small"}
                                type={"button"}
                                onClick={() => {
                                    submitAcknowledgement("decline")
                                }}
                            >
                                <Text
                                    textkey={declineKey}
                                    textdefault={mainMessages[siteLanguageDefault][declineKey]}
                                />
                            </Button>
                            <Button
                                className={"ksm-internal-button-primary"}
                                themeColor={"primary"}
                                size={"large"}
                                shape={"rectangle"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                onClick={() => {
                                    submitAcknowledgement("accept")
                                }}
                            >
                                <Text
                                    textkey={acceptKey}
                                    textdefault={mainMessages[siteLanguageDefault][acceptKey]}
                                />
                            </Button>
                        </Col>
                    </Row>
                </DialogActionsBar>
            </Dialog>
        </>
    );
}

export default TermsAndConditions;