import {createContext} from 'react';

const UserContext = createContext({
    userName: '',
    userEmail: '',
    agLocale: {},
    agrlCookie: '',
    userCredentialsCookie: '',
    siteLanguage: 'en',
    siteLanguageDefault: '',
    lacFlag: 'N',
    controlFlag: false,
    accessToken: '',
    timeout: 0,
    mainMenu: [],
});

export default UserContext;