import React, {useContext} from 'react';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';

// multilingual
import {AnchorWrap, Text} from '../components/common/MultilingualText.js'
import {
    firstTimeUsersKey,
    loginKey,
    mainMessages,
    newUserKey,
    pleaseLoginKey,
    pleaseRegisterKey,
    quickReferenceGuideKey,
    returningUsersKey,
} from '../assets/text/MultilingualText.js';


function SignIn(props) {
    const {siteLanguageDefault} = useContext(UserContext);

    const AnchorText = AnchorWrap(Text)
    return (
        <>
            <PageHeader/>
            <div
                style={{
                    'marginTop': '1.25rem',
                    'marginLeft': '13.563rem',
                    'width': '31.25rem'
                }}
                className={'ksm-page-container'}
            >
                <Row style={{
                    'width': '70%'
                }}>
                    <Col xs={6}>
                        <div
                            className={"k-h5 k-text-nowrap"}
                        >
                            <Text
                                textkey={returningUsersKey}
                                textdefault={mainMessages[siteLanguageDefault][returningUsersKey]}
                            />
                        </div>
                        <Text
                            textkey={pleaseLoginKey}
                            textdefault={mainMessages[siteLanguageDefault][pleaseLoginKey]}
                        />
                    </Col>
                    <Col xs={6}>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"solid"}
                            rounded={"small"}
                            type={"button"}
                            name="login"
                            onClick={() => {
                                window.location.href = config.sso.SSO_WELCOME_URL + '?site=ext';
                            }}
                            style={{
                                "width": "8.125rem"
                            }}
                        >
                            <Text
                                textkey={loginKey}
                                textdefault={mainMessages[siteLanguageDefault][loginKey]}
                            />
                        </Button>
                    </Col>
                </Row>
                <hr
                    style={{
                        'marginTop': '1.25rem',
                        'marginBottom': '1.25rem',
                    }}
                />
                <Row style={{
                    'width': '70%'
                }}>
                    <Col xs={6}>
                        <div
                            className={"k-h5 k-text-nowrap"}
                        >
                            <Text
                                textkey={firstTimeUsersKey}
                                textdefault={mainMessages[siteLanguageDefault][firstTimeUsersKey]}
                            />
                        </div>
                        <Text
                            textkey={pleaseRegisterKey}
                            textdefault={mainMessages[siteLanguageDefault][pleaseRegisterKey]}
                        />
                    </Col>
                    <Col xs={6}>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"outline"}
                            rounded={"small"}
                            type={"button"}
                            name="new-user"
                            style={{
                                "width": "8.125rem"
                            }}
                            onClick={() => {
                                window.location.href = config.keysightSupport + 'KeysightdCX/s/user-registration?return_url=' + encodeURIComponent(window.location.origin) + '&appId=KSM'
                            }}
                        >
                            <Text
                                textkey={newUserKey}
                                textdefault={mainMessages[siteLanguageDefault][newUserKey]}
                            />
                        </Button>
                    </Col>
                </Row>
                <Row style={{
                    'width': '70%',
                    'marginTop': '1.25rem',
                    'marginBottom': '1.25rem',
                }}>
                    <div style={{
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                    }}>
                        <b>
                            <AnchorText
                                style={{
                                    color: 'var(--keysight-secondary)',
                                    textUnderlineOffset: '0.188rem',
                                    textDecoration: 'underline',
                                }}
                                target='_blank'
                                href={process.env.PUBLIC_URL + '/content/KSMQuickReferenceGuide.pdf'}
                                textkey={quickReferenceGuideKey}
                                textdefault={mainMessages[siteLanguageDefault][quickReferenceGuideKey]}
                            />
                        </b>
                    </div>
                </Row>
            </div>
        </>
    );
}

export default SignIn;