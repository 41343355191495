import React, {useContext} from 'react';
import UserContext from '../common/UserContext.js';

// components
import FormInput from '../../components/AddCertificate/FormInput.js';

// kendo react
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../common/MultilingualText.js'
import {
    mainMessages,
    orderNumberPurchaseOrderModelEtcKey,
    resetKey,
    searchKey,
    showOnlyUpdatesKey
} from '../../assets/text/MultilingualText.js'


function SearchField(props) {
    const {
        query,
        setQuery,
        handleSubmit
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const isValidQuery = true;
    const validator = value => value ? "" : "";
    return <div
        style={{
            display: 'flex',
            gap: '0.5rem',
            marginBottom: '0.5rem',
            alignItems: 'end',
        }}
    >
        <div
            style={{
                width: '30rem'
            }}
        >
            <Form
                render={() => (
                    <FormElement>
                        <Field
                            id={"query"}
                            name={"query-field"}
                            label={<Text
                                textkey={showOnlyUpdatesKey}
                                textdefault={mainMessages[siteLanguageDefault][showOnlyUpdatesKey]}
                            />}
                            component={FormInput}
                            required={false}
                            validator={validator}
                            isValid={isValidQuery}
                            dataValue={query}
                            onChange={(e) => setQuery(e.value)}
                            className={"query-field"}
                            placeholder={localization.toLanguageString(orderNumberPurchaseOrderModelEtcKey, mainMessages[siteLanguageDefault][orderNumberPurchaseOrderModelEtcKey])}
                        />
                    </FormElement>
                )}/>
        </div>
        <div>
            <Button
                themeColor={"primary"}
                fillMode={"solid"}
                type={"button"}
                name="Search"
                onClick={() => {
                    handleSubmit(query);
                }}
            >
                <Text
                    textkey={searchKey}
                    textdefault={mainMessages[siteLanguageDefault][searchKey]}
                />
            </Button>
        </div>
        <div>
            <Button
                themeColor={"primary"}
                fillMode={"outline"}
                type={"button"}
                name="Reset"
                onClick={() => {
                    setQuery("");
                    handleSubmit("");
                }}
            >
                <Text
                    textkey={resetKey}
                    textdefault={mainMessages[siteLanguageDefault][resetKey]}
                />
            </Button>
        </div>
    </div>
}

export default SearchField;