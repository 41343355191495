import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMAddNewCertificateWelcome from '../assets/img/Help/ksm-add-new-certificate-welcome.png';
import KSMAddNewCertificateDetails from '../assets/img/Help/ksm-add-new-certificate-details.png'
import KSMAddNewCertificateAdded from '../assets/img/Help/ksm-add-new-certificate-added.png';
import KSMAddCertificateHelpOverviewImg from '../assets/img/Help/ksm-add-certificate-help-overview.png'

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    entitlementCertificatesKey,
    helpAddCertificateEntitlementCertFaqQAKey,
    frequentlyAskedQuestionsKey,
    addCertKey,
    ksmOverviewKey,
    helpAddCertificateKSMOverviewSectionKey,
    mainMessages,
    helpAddCertificateObtainLicenseQAKey,
    helpAddCertificateObtainLicenseSectionKey,
    obtainingLicensesKey,
    helpAddCertificateQAKey,
    quickStepsObtainingLicensesKey,
    registrationLoginKey,
    helpAddCertificateRegLoginFaqQAKey,
    helpAddCertificateRegLoginSectionKey,
    helpAddCertificateSectionKey,
    addingCertificatesKey,
    homePageKey,
    helpAddCertificateWelcomePageFaqQAKey
} from '../assets/text/MultilingualText.js';


function AddCertificateHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpContent = {
        'header': {
            key: addCertKey,
            default: mainMessages[siteLanguageDefault][addCertKey]
        },
        'topics': [
            {
                key: addingCertificatesKey,
                default: mainMessages[siteLanguageDefault][addingCertificatesKey],
                hash: "#add-certificate"
            },
            {
                key: quickStepsObtainingLicensesKey,
                default: mainMessages[siteLanguageDefault][quickStepsObtainingLicensesKey],
                hash: "#quick-steps"
            },
            {
                key: ksmOverviewKey,
                default: mainMessages[siteLanguageDefault][ksmOverviewKey],
                hash: "#ksm-overview"
            },
            {
                key: registrationLoginKey,
                default: mainMessages[siteLanguageDefault][registrationLoginKey],
                hash: "#reg-login"
            }
        ],
        'faq': [
            {
                key: registrationLoginKey,
                default: mainMessages[siteLanguageDefault][registrationLoginKey],
                hash: "#reg-login-faq"
            },
            {
                key: homePageKey,
                default: mainMessages[siteLanguageDefault][homePageKey],
                hash: "#welcome-page-faq"
            },
            {
                key: entitlementCertificatesKey,
                default: mainMessages[siteLanguageDefault][entitlementCertificatesKey],
                hash: "#entitlement-certificate-faq"
            },
            {
                key: addingCertificatesKey,
                default: mainMessages[siteLanguageDefault][addingCertificatesKey],
                hash: "#add-certificate-faq"
            },
            {
                key: obtainingLicensesKey,
                default: mainMessages[siteLanguageDefault][obtainingLicensesKey],
                hash: "#obtain-license-faq"
            }
        ]
    }

    const helpAddCertificateContent = localization.toLanguageString(
        helpAddCertificateSectionKey,
        mainMessages[siteLanguageDefault][helpAddCertificateSectionKey]
    );

    const helpAddCertificateQAContent = localization.toLanguageString(
        helpAddCertificateQAKey,
        mainMessages[siteLanguageDefault][helpAddCertificateQAKey]
    );

    const helpAddCertificateObtainLicenseContent = localization.toLanguageString(
        helpAddCertificateObtainLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpAddCertificateObtainLicenseSectionKey]
    );

    const helpAddCertificateKSMOverviewContent = localization.toLanguageString(
        helpAddCertificateKSMOverviewSectionKey,
        mainMessages[siteLanguageDefault][helpAddCertificateKSMOverviewSectionKey]
    );

    const helpAddCertificateRegLoginContent = localization.toLanguageString(
        helpAddCertificateRegLoginSectionKey,
        mainMessages[siteLanguageDefault][helpAddCertificateRegLoginSectionKey]
    );

    const helpAddCertificateRegLoginFaqQAContent = localization.toLanguageString(
        helpAddCertificateRegLoginFaqQAKey,
        mainMessages[siteLanguageDefault][helpAddCertificateRegLoginFaqQAKey]
    );

    const helpAddCertificateWelcomePageFaqQAContent = localization.toLanguageString(
        helpAddCertificateWelcomePageFaqQAKey,
        mainMessages[siteLanguageDefault][helpAddCertificateWelcomePageFaqQAKey]
    );

    const helpAddCertificateEntitlementCertFaqQAContent = localization.toLanguageString(
        helpAddCertificateEntitlementCertFaqQAKey,
        mainMessages[siteLanguageDefault][helpAddCertificateEntitlementCertFaqQAKey]
    );

    const helpAddCertificateObtainLicenseQAContent = localization.toLanguageString(
        helpAddCertificateObtainLicenseQAKey,
        mainMessages[siteLanguageDefault][helpAddCertificateObtainLicenseQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu
                            siteLanguageDefault={siteLanguageDefault}
                            helpContent={helpContent}
                        />
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3 header"}
                            id="add-certificate"
                        >
                            <Text
                                textkey={addingCertificatesKey}
                                textdefault={mainMessages[siteLanguageDefault][addingCertificatesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpAddCertificateContent.section_one}
                            </p>
                            <p>
                                {helpAddCertificateContent.section_two}
                            </p>
                            <p>
                                {helpAddCertificateContent.section_three}
                            </p>
                            <ol>
                                {
                                    helpAddCertificateContent.section_three_bullets?.map((bullet, bulletIndex) => {
                                        return <li key={`add-certificate-${bulletIndex}`}>
                                            {toHtml(bullet)}
                                            {
                                                bulletIndex === 0 &&
                                                <img src={KSMAddNewCertificateWelcome}
                                                     alt={'ksm-add-new-certificate-welcome'}/>
                                            }
                                            {
                                                bulletIndex === 2 &&
                                                <img src={KSMAddNewCertificateDetails}
                                                     alt={'ksm-add-new-certificate-details'}/>
                                            }
                                            {
                                                bulletIndex === 3 &&
                                                <img src={KSMAddNewCertificateAdded}
                                                     alt={'ksm-add-new-certificate-added'}/>
                                            }
                                        </li>;
                                    })
                                }
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsObtainingLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsObtainingLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpAddCertificateObtainLicenseContent.section_one}
                            </p>
                            <ol>
                                {
                                    helpAddCertificateObtainLicenseContent.section_one_bullets?.map((bullet, bulletIndex) => {
                                        return <li key={`obtain-license-${bulletIndex}`}>
                                            {toHtml(bullet)}
                                        </li>;
                                    })
                                }
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="ksm-overview"
                        >
                            <Text
                                textkey={ksmOverviewKey}
                                textdefault={mainMessages[siteLanguageDefault][ksmOverviewKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpAddCertificateKSMOverviewContent.section_one}
                            </p>
                            <ul>
                                {
                                    helpAddCertificateKSMOverviewContent.section_one_bullets?.map((bullet, bulletIndex) => {
                                        return <li key={`ksm-overview-${bulletIndex}`}>
                                            {toHtml(bullet)}
                                        </li>;
                                    })
                                }
                            </ul>
                            <p>
                                {helpAddCertificateKSMOverviewContent.section_two}
                            </p>
                            <img src={KSMAddCertificateHelpOverviewImg} height={380} width={800}
                                 alt={'ksm-add-help-overview'}/>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="reg-login"
                        >
                            <Text
                                textkey={registrationLoginKey}
                                textdefault={mainMessages[siteLanguageDefault][registrationLoginKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpAddCertificateRegLoginContent.section_one}
                            </p>
                        </div>
                        <div
                            className={"k-h3 header"}
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="reg-login-faq">
                            <h3>
                                <Text
                                    textkey={registrationLoginKey}
                                    textdefault={mainMessages[siteLanguageDefault][registrationLoginKey]}
                                />
                            </h3>
                            <ul>
                                {helpAddCertificateRegLoginFaqQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"reg-login-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div id="welcome-page-faq">
                            <h3>
                                <Text
                                    textkey={homePageKey}
                                    textdefault={mainMessages[siteLanguageDefault][homePageKey]}
                                />
                            </h3>
                            <ul>
                                {helpAddCertificateWelcomePageFaqQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"welcome-page-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div id="entitlement-certificate-faq">
                            <h3>
                                <Text
                                    textkey={entitlementCertificatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][entitlementCertificatesKey]}
                                />
                            </h3>
                            <ul>
                                {helpAddCertificateEntitlementCertFaqQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"welcome-page-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div id="add-certificate-faq">
                            <h3>
                                <Text
                                    textkey={addingCertificatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][addingCertificatesKey]}
                                />
                            </h3>
                            <ul>
                                {helpAddCertificateQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"help-licenses-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div
                            id="obtain-license-faq"
                            style={{
                                marginBottom: '35vh'
                            }}
                        >
                            <h3>
                                <Text
                                    textkey={obtainingLicensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][obtainingLicensesKey]}
                                />
                            </h3>
                            <ul>
                                {helpAddCertificateObtainLicenseQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <div key={"obtain-licenses-qa-" + index}>
                                            <li>
                                                {questionAnswer?.Q}
                                                <br/>
                                                <div className="top indent paragraph">
                                                    {toHtml(questionAnswer?.A)}
                                                </div>
                                            </li>
                                        </div>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default AddCertificateHelp;

