import {Grid} from "@progress/kendo-react-grid";

function ProductGrid({items, hostModal, children, className = "assign-products-grid", ...props}) {

    let scrollable = "none"
    let style = {}

    if(hostModal === "MULTI_HOST" && items.length >= 5){
        scrollable = "scrollable"
        style = {maxHeight: "25vh"}
    }
    else if (items.length >= 10){
        scrollable = "scrollable"
        style = {maxHeight: "40vh"}
    }

    return (
        <Grid
            className={className}
            scrollable={scrollable}
            data={items}
            style={style}
            {...props}
        >
            {children}
        </Grid>
    )
}

export default ProductGrid;