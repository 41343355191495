import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import {AssignQtyCell} from './AssignQtyCell.js';
import {DateGridContext, EndDateHeaderCell, EndDatePickerCell, StartDatePickerCell} from './DatePickerCell';
import {LicenseTypeCell} from './LicenseTypeCell.js';
import {NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Popover, Tooltip} from '@progress/kendo-react-tooltip';
import {Calendar} from '@progress/kendo-react-dateinputs';
import {Grid, GridColumn, GridItemChangeEvent} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    deleteProductHostKey,
    mainMessages,
    assignQtyKey,
    descriptionKey,
    endDateKey,
    licenseTypeKey,
    productNumberKey,
    qtyKey,
    startDateKey
} from '../../../assets/text/MultilingualText.js';
import {DeleteIcon} from "../../common/icons";

const HostGrid = (props) => {
    const {
        selectedProducts,
        assignedProducts,
        setAssignedProducts,
        assignedProduct,
        headerInfo,
        saveRemovals,
        remixType,
        calculateCost,
        minEndDate,
        maxEndDate,
        datePickerEnabled,
        setUnsavedAssignedChanges
    } = props;
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [anchor, setAnchor] = useState(null);
    const [visible, setVisible] = useState(false);

    const [columnEndDate, setColumnEndDate] = useState(null);

    const CustomStartDatePickerCell = (props) => {
        return <StartDatePickerCell
            {...props}
            type={"HOST"}
            transactionID={assignedProduct.transaction_id}
            assignedProducts={assignedProducts}
            setAssignedProducts={setAssignedProducts}
            calculateCost={calculateCost}
        />
    }
    const CustomEndDatePickerCell = (props) => {
        return <EndDatePickerCell
            {...props}
            type={"HOST"}
            transactionID={assignedProduct.transaction_id}
            assignedProducts={assignedProducts}
            setAssignedProducts={setAssignedProducts}
            headerInfo={headerInfo}
            calculateCost={calculateCost}
            minEndDate={minEndDate}
            maxEndDate={maxEndDate}
            datePickerEnabled={datePickerEnabled}
            setUnsavedAssignedChanges={setUnsavedAssignedChanges}
            remixType={remixType}
        />
    }

    // delete cell with functionality to manipulate assigned products json state
    const DeleteCell = (props) => {
        const {
            dataItem,
            transactionID,
        } = props;

        return (
            <td>
                <Tooltip
                    anchorElement="target"
                    showCallout={true}
                    parentTitle={true}
                    openDelay={0}
                    position="top"
                >
                    <div
                        title={localization.toLanguageString(deleteProductHostKey, mainMessages[siteLanguageDefault][deleteProductHostKey])}
                        onClick={() => {
                            saveRemovals([dataItem], transactionID);
                        }}
                    >
                        <DeleteIcon
                            className={'ksm-grid-icon'}
                        />
                    </div>
                </Tooltip>
            </td>
        )
    }

    const DeleteProductCell = (props) => {
        return <DeleteCell {...props} transactionID={assignedProduct.transaction_id}/>
    }

    const assignedProductsRef = useRef(assignedProducts);
    // update host state for editable assign qty in license table
    const hostItemChange = (e: GridItemChangeEvent) => {
        let hostIndex = assignedProducts.findIndex(host => host.transaction_id === assignedProduct.transaction_id)
        if (assignedProducts[hostIndex]) {
            let hosts = [...assignedProducts];
            let copyProducts = hosts[hostIndex].products.map((product) => {
                if (product.ea_alloc_id === e.dataItem.ea_alloc_id) {
                    let setValue = parseInt(e.value)
                    if (isNaN(setValue) || setValue <= 0) {
                        setValue = e.dataItem.unassigned_quantity
                    }
                    let selectedIndex = selectedProducts.findIndex(p => p.ea_alloc_id === e.dataItem.parent_alloc_id);

                    let maxQty = parseInt(selectedProducts[selectedIndex].quantity) || 0;
                    let unAssignedQty = parseInt(e.dataItem.unassigned_quantity) || 0;

                    // get all total assigned quantities of a product across all hosts
                    let totalQty = setValue;
                    assignedProducts.forEach(host => {
                        host.products.forEach(p => {
                            if (e.dataItem.ea_alloc_id !== p.ea_alloc_id && e.dataItem.parent_alloc_id === p.parent_alloc_id) {
                                totalQty += parseInt(p.assign_quantity) || 0;
                            }
                        })
                    });

                    // assign quantities across all hosts cannot be greater than original total qty of product
                    if (totalQty <= maxQty) {
                        // assign qty cannot be less than 0, reset to original unassigned qty
                        if (parseInt(e.value) < 0) {
                            setValue = unAssignedQty;
                        }
                    } else {
                        // assign qty cannot be greater than total qty of product, set to most quantity
                        setValue = maxQty + setValue - totalQty;
                    }
                    product[e.field] = setValue;

                    // update unsaved changes
                    setUnsavedAssignedChanges(unsavedAssignedChanges => new Set([...unsavedAssignedChanges, e.dataItem.ea_alloc_id]))
                }
                return product;
            });
            hosts[hostIndex].products = copyProducts;
            setAssignedProducts(hosts);
        }
    };

    // sets ALL end dates to assignedProducts date in column header calendar
    useEffect(() => {
        if (columnEndDate) {
            let cost = 0;
            let tempHosts = [...assignedProducts];
            tempHosts.forEach(host => {
                host.products.forEach(product => {
                    product.period_end_date = columnEndDate;
                })
            });
            cost = calculateCost([], tempHosts);

            // only set ALL end dates in assigned products if remaining sub pool
            if (cost <= headerInfo?.remaining_pool) {
                let hosts = [...assignedProducts];
                hosts.forEach(host => {
                    host.products.forEach(product => {
                        product.period_end_date = columnEndDate;
                        // update unsaved changes
                        setUnsavedAssignedChanges(unsavedAssignedChanges => new Set([
                            ...unsavedAssignedChanges,
                            product.ea_alloc_id
                        ]));
                    })
                });
                setAssignedProducts(hosts);
            }
        } else {
            setColumnEndDate(null);
        }
    }, [columnEndDate]); // eslint-disable-line react-hooks/exhaustive-deps

    // set end date header restriction on load for assignedProducts grid
    useEffect(() => {
        // assignedProductsRef.current always holds the most updated state of assignedProducts
        assignedProductsRef.current = assignedProducts;
    }, [assignedProducts]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Popover
                show={visible}
                anchor={anchor}
                position={'bottom'}
                style={{padding: "none"}}
            >
                <Calendar
                    min={minEndDate}
                    max={maxEndDate}
                    disabled={!datePickerEnabled}
                    onChange={(e) => {
                        setColumnEndDate(e.value);
                        setVisible(false);
                    }}
                />
            </Popover>
            <DateGridContext.Provider
                value={{
                    setAnchor,
                    visible,
                    setVisible
                }}>
                <Grid
                    className={"ea-assign-products-panelbar-grid"}
                    data={orderBy(assignedProduct.products, [{
                        field: "ea_alloc_id",
                        dir: "desc"
                    }])}
                    scrollable={'none'}
                    sortable={false}
                    onItemChange={hostItemChange}
                >
                    <GridColumn
                        field={null}
                        title={""}
                        cell={DeleteProductCell}
                        sortable={false}
                        width={40}
                    />
                    <GridColumn
                        field="prod_num_display"
                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                        cell={NoWrapCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="description"
                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                        sortable={false}
                    />
                    <GridColumn
                        field="unassigned_quantity"
                        title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                        cell={TextAlignMiddleCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="assign_quantity"
                        title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                        cell={AssignQtyCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="period_start_date"
                        title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                        cell={CustomStartDatePickerCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="period_end_date"
                        title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                        headerCell={remixType === "variable" ? EndDateHeaderCell : null}
                        cell={CustomEndDatePickerCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="license_type"
                        title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                        cell={LicenseTypeCell}
                        sortable={false}
                    />
                </Grid>
            </DateGridContext.Provider>
        </>
    )
}

export default HostGrid;