import React, {useContext} from 'react';

// components
import UserContext from '../../components/common/UserContext.js';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    clientInfoOptionalKey,
    companyInfoColonKey,
    companyAssetInfoColonKey,
    poNumberColonKey,
    clientNotesColonKey,
    continueKey,
    backKey, detailedInfoSwaKey
} from "../../assets/text/MultilingualText";
import {Label} from "@progress/kendo-react-labels";
import {Input} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";


function ClientInformation(props) {
    const {
        siteLanguageDefault,
    } = useContext(UserContext);
    const {
        changeStepActivation,
        clientInfo,
        setClientInfo
    } = props;

    const localization = useLocalization();

    return (
        <>
            <div className={"k-h5"}>
                {localization.toLanguageString(clientInfoOptionalKey, mainMessages[siteLanguageDefault][clientInfoOptionalKey])}
            </div>
            <div style={{
                color: 'var(--color-text-primary)',
                fontSize: '0.875rem',
                fontStyle: 'italic',
            }}>
                {localization.toLanguageString(detailedInfoSwaKey, mainMessages[siteLanguageDefault][detailedInfoSwaKey])}
            </div>
            <div className={'k-mt-4'}>
                <Label>
                    {localization.toLanguageString(companyInfoColonKey, mainMessages[siteLanguageDefault][companyInfoColonKey])}
                </Label>
                <Input
                    value={clientInfo.company_info}
                    onChange={(e) => {
                        setClientInfo({
                            ...clientInfo,
                            'company_info': e.value.replace(/[`~!@#$%^&*()_|+=?;:'"<>{}[]\\\/]/gi, '')
                        })
                    }}
                />
            </div>
            <div className={'k-mt-2'}>
                <Label>
                    {localization.toLanguageString(companyAssetInfoColonKey, mainMessages[siteLanguageDefault][companyAssetInfoColonKey])}
                </Label>
                <Input
                    value={clientInfo.asset_info}
                    onChange={(e) => {
                        setClientInfo({
                            ...clientInfo,
                            'asset_info': e.value.replace(/[`~!@#$%^&*()_|+=?;:'"<>{}[]\\\/]/gi, '')
                        })
                    }}
                />
            </div>
            <div className={'k-mt-2'}>
                <Label>
                    {localization.toLanguageString(poNumberColonKey, mainMessages[siteLanguageDefault][poNumberColonKey])}
                </Label>
                <Input
                    value={clientInfo.po_number}
                    onChange={(e) => {
                        setClientInfo({
                            ...clientInfo,
                            'po_number': e.value.replace(/[`~!@#$%^&*()_|+=?;:'"<>{}[]\\\/]/gi, '')
                        })
                    }}
                />
            </div>
            <div className={'k-mt-2'}>
                <Label>
                    {localization.toLanguageString(clientNotesColonKey, mainMessages[siteLanguageDefault][clientNotesColonKey])}
                </Label>
                <Input
                    value={clientInfo.client_notes}
                    onChange={(e) => {
                        setClientInfo({
                            ...clientInfo,
                            'client_notes': e.value.replace(/[`~!@#$%^&*()_|+=?;:'"<>{}[]\\\/]/gi, '')
                        })
                    }}
                />
            </div>
            <div
                className={'k-display-flex k-mt-4'}
                style={{
                    justifyContent: 'end',
                }}
            >
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    onClick={() => {
                        changeStepActivation(3)
                    }}
                >
                    {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                </Button>
            </div>
        </>
    );

}

export default ClientInformation;