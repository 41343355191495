import React, {useContext} from 'react';
import UserContext from '../common/UserContext.js';

// react strap
import {Row} from 'reactstrap';

// multilingual
import {AnchorWrap, Text} from '../common/MultilingualText.js';
import {
    frequentlyAskedQuestionsKey,
    topicsKey,
    mainMessages,
} from '../../assets/text/MultilingualText.js';


function HelpMenu(props) {
    const {helpContent} = props;
    const {siteLanguageDefault} = useContext(UserContext);

    const AnchorText = AnchorWrap(Text);

    const headerStyle = {
        marginBottom: "1rem"
    };

    return (
        <>
            <div
                style={{
                    // position: "-webkit-sticky", /* Safari */
                    position: "sticky",
                    top: "1rem"
                }}
            >
                <Row>
                    <div className={"k-h1"}>
                        <Text
                            textkey={helpContent['header'].key}
                            textdefault={helpContent['header'].default}
                        />
                    </div>
                </Row>
                {!!helpContent['topics'].length && <Row>
                    <div
                        className={"k-h3"}
                        style={headerStyle}
                    >
                        <Text
                            textkey={topicsKey}
                            textdefault={mainMessages[siteLanguageDefault][topicsKey]}
                        />
                    </div>
                    {
                        helpContent['topics'].map((topic) =>
                            <AnchorText
                                key={topic.hash}
                                href={topic.hash}
                                textkey={topic.key}
                                textdefault={topic.default}
                                style={{
                                    marginBottom: '1rem'
                                }}
                            />
                        )
                    }
                </Row>}
                {!!helpContent['faq'].length && <Row>
                    <div
                        className={"k-h3"}
                        style={headerStyle}
                    >
                        <Text
                            textkey={frequentlyAskedQuestionsKey}
                            textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                        />
                    </div>
                    {
                        helpContent['faq'].map((faq) =>
                            <AnchorText
                                key={faq.hash}
                                href={faq.hash}
                                textkey={faq.key}
                                textdefault={faq.default}
                                style={{
                                    marginBottom: '1rem'
                                }}
                            />
                        )
                    }
                </Row>}
            </div>
        </>
    );
};

export default HelpMenu;

