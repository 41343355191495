import {Col, Row} from "reactstrap";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {deleteHostKey, mainMessages} from "../../../assets/text/MultilingualText";
import {DeleteIcon} from "../../common/icons";
import React, {useContext} from "react";
import {useLocalization} from "@progress/kendo-react-intl";
import UserContext from "../../common/UserContext";

export default function PanelBarHeader(props) {
    const {
        title,
        transactionID,
        assignedProducts,
        saveRemovals
    } = props;

    const {
        siteLanguageDefault
    } = useContext(UserContext);

     const localization = useLocalization();

    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h5"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {title}
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            float: 'right'
                        }}
                    >
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="top"
                        >
                            <div
                                title={localization.toLanguageString(deleteHostKey, mainMessages[siteLanguageDefault][deleteHostKey])}
                                onClick={(e) => {
                                    let hostIndex = assignedProducts.findIndex(a => a.transaction_id === transactionID);
                                    if (assignedProducts[hostIndex]) {
                                        let products = assignedProducts[hostIndex].products;
                                        saveRemovals(products, transactionID);
                                    }
                                }}
                            >
                                <DeleteIcon
                                    style={{
                                        cursor: 'pointer',
                                        color: 'white'
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </Col>
            </Row>
        </>
    )
}