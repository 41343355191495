import React, {useContext, useState} from 'react';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';

// components
import {toHtml} from '../utilities.js';
import HostSerialModal from "./GenericAssignModal/HostSerialModal";
import MultiHostModal from "./GenericAssignModal/MultiHostModal";
import FileUploadModal from "./GenericAssignModal/FileUploadModal";

// multilingual
import {
    assignProductDuplicateHostIDMessageErrorKey,
    assignProductDuplicateHostUniqueMessageErrorKey,
    assignProductsGenericMessageIssueErrorKey,
    assignProductsGenericMessagePleaseErrorKey,
    assignProductsOnePerHostTitleLimitErrorKey,
    assignProductsOnePerHostTitleProductErrorKey,
    assignProductsQtyDistributionMessageAppliedErrorKey,
    assignProductsQtyDistributionMessageAvailableErrorKey,
    assignProductsQtyDistributionMessageEnableErrorKey,
    assignProductsQtyDistributionMessageHasErrorKey,
    assignProductsQtyDistributionMessageHostsErrorKey,
    assignProductsQtyDistributionMessageProductErrorKey,
    contactUsKey,
    duplicateHostKey,
    exceedingQtyKey,
    genericErrorTitleKey,
    invalidFileKey, invalidHostColonKey,
    invalidHostKey,
    invalidSerialNumberKey,
    mainMessages,
    onePerHostMessageKey,
    reviewNumberHostKey,
} from "../../../assets/text/MultilingualText";
import {AnchorWrap, Text} from "../MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";


function GenericAssignModal(props) {
    const {
        hostDetails,
    } = props;
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let hostModal = hostDetails.hostid_type_popup.toUpperCase() || "";
    let hostIDLabel = hostDetails.host_id_info.asl_prompt || "";
    let hostIDError = hostDetails.host_id_info.asl_error_message || "";
    let serialNumberLabel = hostDetails.serial_number_info.asl_prompt || "";
    let serialNumberError = hostDetails.serial_number_info.asl_error_message || "";

    const [errors, setErrors] = useState([]);
    const [isValid, setIsValid] = useState({
        hostID: true,
        serialNumber: true,
        multiHost: true,
    });
    /*
     * showError() appends to errors object and redlines field
     * @param {error} field to find the corresponding error
     * @param {items} items to add to error message
    */
    const showError = (field, items) => {
        let title = ''
        let message = ''
        switch (field) {
            case "hostID":
                title = localization.toLanguageString(invalidHostKey, mainMessages[siteLanguageDefault][invalidHostKey])
                message = <>
                    {toHtml(hostIDLabel)}
                    {toHtml(hostIDError)}
                </>
                setIsValid(isValid => ({...isValid, [field]: false}));
                break
            case "multiHost":
                title = localization.toLanguageString(invalidHostKey, mainMessages[siteLanguageDefault][invalidHostKey])
                let invalidMultiHostSubmessage = items.length ? localization.toLanguageString(
                        invalidHostColonKey,
                        mainMessages[siteLanguageDefault][invalidHostColonKey])
                    : "";
                message = <>
                    {toHtml(hostIDLabel)}
                    {toHtml(hostIDError)} <br/>
                    {invalidMultiHostSubmessage} {items.length ? items.join(', ') : ""}
                </>
                setIsValid(isValid => ({...isValid, [field]: false}));
                break
            case "serialNumber":
                title = localization.toLanguageString(invalidSerialNumberKey, mainMessages[siteLanguageDefault][invalidSerialNumberKey])
                message = <>
                    {toHtml(serialNumberLabel)}
                    {toHtml(serialNumberError)}
                </>
                setIsValid(isValid => ({...isValid, [field]: false}));
                break
            case "file":
                title = localization.toLanguageString(invalidFileKey, mainMessages[siteLanguageDefault][invalidFileKey])
                message = <>
                    {toHtml(hostIDLabel)}
                    {toHtml(hostIDError)}
                </>
                break
            case "onePerHost":
                let onePerHostTitleProduct = localization.toLanguageString(assignProductsOnePerHostTitleProductErrorKey, mainMessages[siteLanguageDefault][assignProductsOnePerHostTitleProductErrorKey]);
                let onePerHostTitleLimit = localization.toLanguageString(assignProductsOnePerHostTitleLimitErrorKey, mainMessages[siteLanguageDefault][assignProductsOnePerHostTitleLimitErrorKey]);
                title = onePerHostTitleProduct + items + onePerHostTitleLimit;
                message = localization.toLanguageString(onePerHostMessageKey, mainMessages[siteLanguageDefault][onePerHostMessageKey]);
                break
            case "duplicateHost":
                title = localization.toLanguageString(duplicateHostKey, mainMessages[siteLanguageDefault][duplicateHostKey]);
                let duplicateHostIDMessage = localization.toLanguageString(assignProductDuplicateHostIDMessageErrorKey, mainMessages[siteLanguageDefault][assignProductDuplicateHostIDMessageErrorKey]);
                let duplicateHostUniqueMessage = localization.toLanguageString(assignProductDuplicateHostUniqueMessageErrorKey, mainMessages[siteLanguageDefault][assignProductDuplicateHostUniqueMessageErrorKey]);
                message = items.map(host =>
                    <div key={'duplicate-host-error-' + host}>
                        {duplicateHostIDMessage} {host} {duplicateHostUniqueMessage}
                        <br/>
                    </div>
                );
                break
            case "qtyDistribution":
                title = localization.toLanguageString(reviewNumberHostKey, mainMessages[siteLanguageDefault][reviewNumberHostKey]);
                let qtyDistributionMessage = localization.toLanguageString(exceedingQtyKey, mainMessages[siteLanguageDefault][exceedingQtyKey]);
                let qtyDistributionMessageProduct = localization.toLanguageString(assignProductsQtyDistributionMessageProductErrorKey, mainMessages[siteLanguageDefault][assignProductsQtyDistributionMessageProductErrorKey]);
                let qtyDistributionMessageHas = localization.toLanguageString(assignProductsQtyDistributionMessageHasErrorKey, mainMessages[siteLanguageDefault][assignProductsQtyDistributionMessageHasErrorKey]);
                let qtyDistributionMessageAvailable = localization.toLanguageString(assignProductsQtyDistributionMessageAvailableErrorKey, mainMessages[siteLanguageDefault][assignProductsQtyDistributionMessageAvailableErrorKey]);
                let qtyDistributionMessageEnable = localization.toLanguageString(assignProductsQtyDistributionMessageEnableErrorKey, mainMessages[siteLanguageDefault][assignProductsQtyDistributionMessageEnableErrorKey]);
                let qtyDistributionMessageApplied = localization.toLanguageString(assignProductsQtyDistributionMessageAppliedErrorKey, mainMessages[siteLanguageDefault][assignProductsQtyDistributionMessageAppliedErrorKey]);
                let qtyDistributionMessageHosts = localization.toLanguageString(assignProductsQtyDistributionMessageHostsErrorKey, mainMessages[siteLanguageDefault][assignProductsQtyDistributionMessageHostsErrorKey]);
                message = <>
                    {qtyDistributionMessage}
                    <br/>
                    {items.map((item) =>
                        <div
                            key={'qty-distribution-error-' + item.product}>
                            {qtyDistributionMessageProduct} {item.product} {qtyDistributionMessageHas} {item.qty} {qtyDistributionMessageAvailable} {item.qty_needed} {qtyDistributionMessageEnable}{item.assign_qty} {qtyDistributionMessageApplied} {item.host_count} {qtyDistributionMessageHosts}
                            <br/>
                        </div>
                    )}
                </>
                break
            default:
                title = localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])
                let genericMessagePlease = localization.toLanguageString(
                    assignProductsGenericMessagePleaseErrorKey,
                    mainMessages[siteLanguageDefault][assignProductsGenericMessagePleaseErrorKey]
                );
                let genericMessageIssue = localization.toLanguageString(
                    assignProductsGenericMessageIssueErrorKey,
                    mainMessages[siteLanguageDefault][assignProductsGenericMessageIssueErrorKey]
                );

                const AnchorText = AnchorWrap(Text);
                const contactUs = <AnchorText
                    className={"header-contact-us"}
                    href={config.keysight + "us/en/contact.html"}
                    data-trigger="false"
                    textkey={contactUsKey}
                    textdefault={mainMessages[siteLanguageDefault][contactUsKey]}
                />

                message = <div>{genericMessagePlease} {contactUs} {genericMessageIssue}</div>
                break
        }

        const error = {
            title: title,
            message: message,
        }
        setErrors(errors => [...errors, error]);
    }


    return (
        <>
            {hostModal === 'HOST' && (
                <HostSerialModal
                    errors={errors}
                    setErrors={setErrors}
                    isValid={isValid}
                    setIsValid={setIsValid}
                    showError={showError}
                    {...props}
                />
            )}

            {hostModal === 'MULTI_HOST' && (
                <MultiHostModal
                    errors={errors}
                    setErrors={setErrors}
                    isValid={isValid}
                    setIsValid={setIsValid}
                    showError={showError}
                    {...props}
                />
            )}

            {(hostModal === 'C2V' || hostModal === 'HOST_FILE_BIN')  && (
                <FileUploadModal
                    errors={errors}
                    setErrors={setErrors}
                    isValid={isValid}
                    setIsValid={setIsValid}
                    showError={showError}
                    {...props}
                />
            )}
        </>
    )
}

export default GenericAssignModal;