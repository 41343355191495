import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from "reactstrap";

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import AddLicensesImg from '../assets/img/Help/ksm-kal-transport-add-license.png';
import NoQueryImg from '../assets/img/Help/ksm-kal-transport-no-query-string.png';
import Step1Imag from '../assets/img/Help/ksm-kal-transport-step-1.png';
import Step2Imag from '../assets/img/Help/ksm-kal-transport-step-2.png';
import Step2_1Imag from '../assets/img/Help/ksm-kal-transport-step-2-1.png';
import Step2_2Imag from '../assets/img/Help/ksm-kal-transport-step-2-2.png';
import Step2_3Imag from '../assets/img/Help/ksm-kal-transport-step-2-3.png';
import Step3Imag from '../assets/img/Help/ksm-kal-transport-step-3.png';
import Step4Imag from '../assets/img/Help/ksm-kal-transport-step-4.png';
import Step4_1Imag from '../assets/img/Help/ksm-kal-transport-step-4-1.png';
import SaveStep1Imag from '../assets/img/Help/ksm-kal-save-step-1.png';
import SaveStep2Imag from '../assets/img/Help/ksm-kal-save-step-2.png';
import SaveStep3Imag from '../assets/img/Help/ksm-kal-save-step-3.png';


// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    mainMessages,
    transportKey,
    transportOverviewKey,
    helpTransportQuickStepsTransport,
    helpTransportQuickStepsSave,
    helpTransportMain,
    helpTransportSaving,
    helpTransportLicenses,
    helpTransportingBanking,
    helpTransportOverviewContent,
    helpTransportQuickStepsTransportContent,
    helpTransportQuickStepsSavingContent,
    helpTransportMainContent,
    helpTransportLicensesContent,
    helpTransportSavingContent,
    helpKalTransportErrorHandling,
    helpKALTrasportQAKey,
} from '../assets/text/MultilingualText.js';


function TransportKALHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;
        let img;
        let imgInlineStyle = {};


        if (item.img) {
            switch (item.img) {
                case 'ksm-kal-transport-step-1':
                    img = <img src={Step1Imag} alt={item.img}/>
                    break;
                case 'ksm-kal-transport-step-2':
                    img = <img src={Step2Imag} alt={item.img}/>
                    break;
                case 'ksm-kal-transport-step-2-1':
                    img = <img src={Step2_1Imag} alt={item.img}/>
                    break;
                case 'ksm-kal-transport-step-2-2':
                    img = <img src={Step2_2Imag} alt={item.img}/>
                    break;
                case 'ksm-kal-transport-step-2-3':
                    img = <img src={Step2_3Imag} alt={item.img}/>
                    break;
                case 'ksm-kal-save-step-1':
                    img = <img src={SaveStep1Imag} alt={item.img}/>
                    break;
                case 'ksm-kal-save-step-2':
                    img = <img src={SaveStep2Imag} alt={item.img}/>
                    break;
                case 'ksm-kal-save-step-3':
                    img = <img src={SaveStep3Imag} alt={item.img}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        let className = type === 1 ? 'nested-counter' : ""

        if (item.substeps && item.substeps.length) {
            children = type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type} className={className}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        return (
            <li className={className}>
                <div style={imgInlineStyle}>
                    {toHtml(item.step)}
                    {item.img ? <span className="img-container">
                        {img}
                    </span> : <></>}
                </div>
                {children}
            </li>
        );
    }

    const helpMenuContent = {
        'header': {
            key: transportKey,
            default: mainMessages[siteLanguageDefault][transportKey]
        },
        'topics': [
            {
                key: transportOverviewKey,
                default: mainMessages[siteLanguageDefault][transportOverviewKey],
                hash: "#overview"
            },
            {
                key: helpTransportQuickStepsTransport,
                default: mainMessages[siteLanguageDefault][helpTransportQuickStepsTransport],
                hash: "#quick-steps-transport"
            },
            {
                key: helpTransportQuickStepsSave,
                default: mainMessages[siteLanguageDefault][helpTransportQuickStepsSave],
                hash: "#quick-steps-save-return"
            },
            {
                key: helpTransportMain,
                default: mainMessages[siteLanguageDefault][helpTransportMain],
                hash: "#main-page"
            },
            {
                key: helpTransportLicenses,
                default: mainMessages[siteLanguageDefault][helpTransportLicenses],
                hash: "#transport-licenses"
            },
            {
                key: helpTransportSaving,
                default: mainMessages[siteLanguageDefault][helpTransportSaving],
                hash: "#saving-returning"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            },
        ],
        'faq': [
            {
                key: helpTransportingBanking,
                default: mainMessages[siteLanguageDefault][helpTransportingBanking],
                hash: "#category-faq"
            }
        ]
    }

    const transportOverviewContent = localization.toLanguageString(
        helpTransportOverviewContent,
        mainMessages[siteLanguageDefault][helpTransportOverviewContent]
    );

    const quickStepsTransportContent = localization.toLanguageString(
        helpTransportQuickStepsTransportContent,
        mainMessages[siteLanguageDefault][helpTransportQuickStepsTransportContent]
    );

    const quickStepsSavingContent = localization.toLanguageString(
        helpTransportQuickStepsSavingContent,
        mainMessages[siteLanguageDefault][helpTransportQuickStepsSavingContent]
    );

    const transportMainContent = localization.toLanguageString(
        helpTransportMainContent,
        mainMessages[siteLanguageDefault][helpTransportMainContent]
    );

    const licensesContent = localization.toLanguageString(
        helpTransportLicensesContent,
        mainMessages[siteLanguageDefault][helpTransportLicensesContent]
    );

    const saveContent = localization.toLanguageString(
        helpTransportLicensesContent,
        mainMessages[siteLanguageDefault][helpTransportSavingContent]
    );

    const errorHandlingContent = localization.toLanguageString(
        helpKalTransportErrorHandling,
        mainMessages[siteLanguageDefault][helpKalTransportErrorHandling]
    );

    const categoryQA = localization.toLanguageString(
        helpKALTrasportQAKey,
        mainMessages[siteLanguageDefault][helpKALTrasportQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3"}
                            id="over-view"
                        >
                            <Text
                                textkey={transportOverviewKey}
                                textdefault={mainMessages[siteLanguageDefault][transportOverviewKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {transportOverviewContent.section_one}
                            </p>
                            <div className="top indent">
                                {toHtml(transportOverviewContent.section_two)}
                            </div>
                            <div className="top indent">
                                {toHtml(transportOverviewContent.section_three)}
                            </div>
                            <p>
                                {transportOverviewContent.section_four}
                            </p>
                            <div>
                                {toHtml(transportOverviewContent.section_five)}
                            </div>
                        </div>

                        <div
                            className={"k-h3 header"}
                            id="quick-steps-transport"
                        >
                            <Text
                                textkey={helpTransportQuickStepsTransport}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportQuickStepsTransport]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {quickStepsTransportContent.section_one}
                            </p>
                            <ol>
                                {quickStepsTransportContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>

                        <div
                            className={"k-h3 header"}
                            id="quick-steps-save-return"
                        >
                            <Text
                                textkey={helpTransportQuickStepsSave}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportQuickStepsSave]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {quickStepsSavingContent.section_one}
                            </p>
                            <ol>
                                {quickStepsSavingContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>


                        <div
                            className={"k-h3 header"}
                            id="main-page"
                        >
                            <Text
                                textkey={helpTransportMain}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportMain]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {transportMainContent.section_one}
                            </p>
                            <p>
                                {transportMainContent.section_two}
                            </p>
                            <p>
                                {transportMainContent.section_three}
                            </p>

                            <span className="img-container">
                                <img src={AddLicensesImg} alt={'ksm-kal-transport-add-license'}/>
                            </span>

                            <ol type={'a'}>
                                {transportMainContent.section_four_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>

                            <p style={{
                                paddingInlineStart: '1.25rem'
                            }}>
                                {transportMainContent.section_five}
                            </p>

                            <p>
                                {transportMainContent.section_six}
                            </p>

                            <span className="img-container">
                                <img src={NoQueryImg} alt={'ksm-kal-transport-no-query-string'}/>
                            </span>

                            <p>
                                {transportMainContent.section_seven}
                            </p>
                        </div>

                        <div
                            className={"k-h3 header"}
                            id="transport-licenses"
                        >
                            <Text
                                textkey={helpTransportLicenses}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportLicenses]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {licensesContent.section_one}
                            </p>

                            {licensesContent.section_two_steps.map((item, index) => {
                                let img;
                                if (item.img) {
                                    switch (item.img) {
                                        case 'ksm-kal-transport-step-1':
                                            img = <img src={Step1Imag} alt={item.img}/>
                                            break;
                                        case 'ksm-kal-transport-step-2':
                                            img = <img src={Step2Imag} alt={item.img}/>
                                            break;
                                        case 'ksm-kal-transport-step-2-1':
                                            img = <img src={Step2_1Imag} alt={item.img}/>
                                            break;
                                        case 'ksm-kal-transport-step-2-2':
                                            img = <img src={Step2_2Imag} alt={item.img}/>
                                            break;
                                        case 'ksm-kal-transport-step-2-3':
                                            img = <img src={Step2_3Imag} alt={item.img}/>
                                            break;
                                        case 'ksm-kal-transport-step-3':
                                            img = <img src={Step3Imag} alt={item.img}/>
                                            break;
                                        case 'ksm-kal-transport-step-4':
                                            img = <img src={Step4Imag} alt={item.img}/>
                                            break;
                                        case 'ksm-kal-transport-step-4-1':
                                            img = <img src={Step4_1Imag} alt={item.img}/>
                                            break;
                                        default:
                                            img = <></>
                                            break;
                                    }
                                }
                                let marginLeft = '1rem'
                                if ((Number(item.order) === item.order && item.order % 1 !== 0)) {
                                    marginLeft = '3rem'
                                }

                                if (/^[a-z]$/i.test(item.order)) {
                                    marginLeft = '5rem'
                                }

                                return (
                                    <div
                                        key={index}
                                        style={{
                                            marginLeft: marginLeft,
                                            padding: '0.5rem 0rem',
                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            gap: '0.5rem'
                                        }}>
                                            <div>
                                                {item.order + '.'}
                                            </div>
                                            <div>
                                                {toHtml(item.step)}
                                            </div>

                                        </div>
                                        {item.img ? <span className="img-container">
                                            {img}
                                         </span> : <></>}

                                    </div>
                                )

                            })}

                        </div>

                        <div
                            className={"k-h3 header"}
                            id="saving-returning"
                        >
                            <Text
                                textkey={helpTransportSaving}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportSaving]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <div className={'paragraph'}>
                                {toHtml(saveContent.section_one)}
                            </div>
                            <ol>
                                {saveContent.section_two_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                            <div className={'paragraph'}>
                                {toHtml(saveContent.section_three)}
                            </div>
                            <div className={'paragraph'}>
                                {toHtml(saveContent.section_four)}
                            </div>
                            <div className={'paragraph'}>
                                {toHtml(saveContent.section_five)}
                            </div>
                        </div>


                        <div
                            className={"k-h3 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {errorHandlingContent.section_one}
                            </p>
                            <ul>
                                {errorHandlingContent.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`error-handling-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>;
                                })}
                            </ul>
                        </div>
                        <div className={"k-h3 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="category-faq">
                            <h3>
                                <Text
                                    textkey={helpTransportingBanking}
                                    textdefault={mainMessages[siteLanguageDefault][helpTransportingBanking]}
                                />
                            </h3>
                            <ul className="indent">
                                {categoryQA ? categoryQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"category-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <div className="top indent paragraph">
                                                    {toHtml(questionAnswer.A)}
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default TransportKALHelp;

