import React from 'react';

// css
import '../../assets/css/App.css';

// kendo react
import {Loader} from '@progress/kendo-react-indicators';

function Spinner() {
    const spinnerStyle = {
        display: "flex",
        alignItems: "center"
    };

    return (
        <div
            className="k-overlay"
            style={{
                zIndex: "10003"
            }}
        >
            <Loader
                size="large"
                type="converging-spinner"
                themeColor="light"
                style={spinnerStyle}
            />
        </div>
    );
}

export default Spinner;