import React, {useContext} from "react";
import {useLocalization} from "@progress/kendo-react-intl";

//components
import UserContext from "../../common/UserContext";
import {NoWrapCell} from "../../common/Grid";

//multilingual
import {
    descriptionKey,
    mainMessages,
    orderNumberKey,
    productKey,
    qtyKey,
    requestLicensesKey,
} from "../../../assets/text/MultilingualText";

//kendo
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Button} from "@progress/kendo-react-buttons";


export default function ReviewBanked(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        assigned,
        setReview,
    } = props


    return (
        <>
            <PanelBar className={'ksm-panelbar-default'}>
                {assigned.map((item) => {
                    return (
                        <PanelBarItem
                            title={
                                <div
                                    className={"k-h5"}
                                    style={{
                                        color: 'white',
                                        margin: '0 0 0 2px'
                                    }}>
                                    {item.panelBarTitle}
                                </div>
                            }
                            key={item.transaction_id}
                            expanded={true}
                        >
                            <Grid
                                className={"review-submit-ccl-gird"}
                                scrollable={'none'}
                                data={item.products}
                            >
                                <GridColumn
                                    field="order_num"
                                    title={localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="prod_num"
                                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="prod_desc"
                                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                />
                                <GridColumn
                                    field="qty_redeemed"
                                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                />
                            </Grid>
                        </PanelBarItem>
                    )
                })}
            </PanelBar>

            <div style={{
                display: 'flex',
                gap: '0.938rem',
                justifyContent: 'end',
            }}>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    rounded={"small"}
                    onClick={() => {
                        setReview(false)
                    }}
                >
                    {localization.toLanguageString(requestLicensesKey, mainMessages[siteLanguageDefault][requestLicensesKey])}
                </Button>
            </div>

        </>
    )
}