import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/TrialLicense.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {toHtml} from '../common/utilities.js';
import Alert from '../common/Alert.js';

// kendo react
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Label} from '@progress/kendo-react-labels';
import {Input} from '@progress/kendo-react-inputs';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    addr1LineKey,
    addr2LineKey,
    cancelKey,
    cityKey,
    companyNameKey,
    countryAreaKey,
    emailKey,
    firstNameKey,
    jobTitleKey,
    lastNameKey,
    phoneKey,
    stateProvinceKey,
    submitRequestKey,
    contactInfoKey,
    zipPostalKey,
    enterValidEmailKey,
    invalidEmailKey,
    completeRequiredFieldsKey,
    requiredFieldsMissing,
    trialLicensePrivacyStatementKey
} from '../../assets/text/MultilingualText.js';


function ContactInformationModal(props) {
    const {
        contactInformation,
        setContactInformation,
        countries,
        submitRequest,
        isVisible,
        setIsVisible
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const OPTIONAL = ["jobTitle", "addressLine2"];
    const [isValid, setIsValid] = useState({
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        companyName: true,
        jobTitle: true,
        addressLine1: true,
        addressLine2: true,
        city: true,
        state: true,
        zip: true,
        country: true
    });

    // closes the edit host modal
    const close = () => {
        setIsVisible(isVisible => ({
            ...isVisible,
            contactInformationModal: false,
            missingFieldsError: false,
            invalidEmailError: false
        }));
        setIsValid({
            firstName: true,
            lastName: true,
            email: true,
            phone: true,
            companyName: true,
            jobTitle: true,
            addressLine1: true,
            addressLine2: true,
            city: true,
            state: true,
            zip: true,
            country: true
        });
    };

    /*
     * onContactInformationKeyDown() prevents the user from inputting non-numeric values
     * @param {e} the input change event
    */
    const onContactInformationKeyDown = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) {
            return true;
        } else if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }

    /*
     * onContactInformationChange() updates the contact information object on change
     * @param {e} the input change event
    */
    const onContactInformationChange = (e) => {
        setContactInformation(contactInformation => ({
            ...contactInformation,
            [e.target.props.field]: e.value
        }));
        setIsValid(isValid => ({...isValid, [e.target.props.field]: true}));
    }

    /*
     * validateEmail() validates the email via regex
    */
    const validateEmail = (email) => {
        // regex is RFC 5322 compliant (https://uibakery.io/regex-library/email)
        let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return regex.test(email);
    };

    /*
     * validateContactInformation() validates the inputs in the contact information modal
    */
    const validateContactInformation = () => {
        // redline required fields that are empty
        Object.keys(isValid).forEach(field => {
            if (!contactInformation[field] && !OPTIONAL.includes(field)) {
                setIsValid(isValid => ({...isValid, [field]: false}));
            }
        });

        let emptyFields = Object.keys(contactInformation).filter(field => !OPTIONAL.includes(field) && !contactInformation[field]);
        let invalidFields = Object.keys(isValid).filter(field => !OPTIONAL.includes(field) && !isValid[field]);

        // show error on empty fields
        if (emptyFields.length) {
            setIsVisible(isVisible => ({...isVisible, missingFieldsError: true}));
        }

        // redline and show error on invalid email
        if (!validateEmail(contactInformation.email)) {
            setIsValid(isValid => ({...isValid, email: false}));
            setIsVisible(isVisible => ({...isVisible, invalidEmailError: true}));
        }

        if (!emptyFields.length && !invalidFields.length && validateEmail(contactInformation.email)) {
            submitRequest();
        }
    }

    useEffect(() => {
        setIsVisible(isVisible => ({
            ...isVisible,
            missingFieldsError: false,
            invalidEmailError: false
        }));
    }, [contactInformation]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Dialog
            className={"contact-information-modal ksm-dialog"}
            title={<h2 className={"contact-information-title"}>
                {localization.toLanguageString(contactInfoKey, mainMessages[siteLanguageDefault][contactInfoKey])}
            </h2>}
            onClose={close}
            width={"60vw"}
        >
            <Row>
                <Col xs={12} sm={6}>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(firstNameKey, mainMessages[siteLanguageDefault][firstNameKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.firstName}
                                maxLength={50}
                                field="firstName"
                                onChange={onContactInformationChange}
                                valid={isValid.firstName}
                                autoFocus={true}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(lastNameKey, mainMessages[siteLanguageDefault][lastNameKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.lastName}
                                maxLength={50}
                                field="lastName"
                                onChange={onContactInformationChange}
                                valid={isValid.lastName}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(emailKey, mainMessages[siteLanguageDefault][emailKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.email}
                                maxLength={50}
                                field="email"
                                onChange={onContactInformationChange}
                                valid={isValid.email}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(phoneKey, mainMessages[siteLanguageDefault][phoneKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                className="contact-information-numeric-input"
                                value={contactInformation.phone}
                                maxLength={50}
                                field="phone"
                                onChange={onContactInformationChange}
                                valid={isValid.phone}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(companyNameKey, mainMessages[siteLanguageDefault][companyNameKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.companyName}
                                maxLength={50}
                                field="companyName"
                                onChange={onContactInformationChange}
                                valid={isValid.companyName}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(jobTitleKey, mainMessages[siteLanguageDefault][jobTitleKey])}:
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.jobTitle}
                                maxLength={50}
                                field="jobTitle"
                                onChange={onContactInformationChange}
                                valid={isValid.jobTitle}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(addr1LineKey, mainMessages[siteLanguageDefault][addr1LineKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.addressLine1}
                                maxLength={50}
                                field="addressLine1"
                                onChange={onContactInformationChange}
                                valid={isValid.addressLine1}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(addr2LineKey, mainMessages[siteLanguageDefault][addr2LineKey])}:
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.addressLine2}
                                maxLength={50}
                                field="addressLine2"
                                onChange={onContactInformationChange}
                                valid={isValid.addressLine2}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(cityKey, mainMessages[siteLanguageDefault][cityKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.city}
                                maxLength={50}
                                field="city"
                                onChange={onContactInformationChange}
                                valid={isValid.city}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(stateProvinceKey, mainMessages[siteLanguageDefault][stateProvinceKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.state}
                                maxLength={50}
                                field="state"
                                onChange={onContactInformationChange}
                                valid={isValid.state}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(zipPostalKey, mainMessages[siteLanguageDefault][zipPostalKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Input
                                value={contactInformation.zip}
                                maxLength={50}
                                field="zip"
                                onChange={onContactInformationChange}
                                onKeyDown={onContactInformationKeyDown}
                                valid={isValid.zip}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col
                            className="contact-information-label"
                            xs={4}
                        >
                            <Label>
                                {localization.toLanguageString(countryAreaKey, mainMessages[siteLanguageDefault][countryAreaKey])}:
                                <span style={{color: "var(--color-alert-danger-base)"}}>*</span>
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <DropDownList
                                value={contactInformation.country}
                                data={countries}
                                field="country"
                                textField="country_title"
                                onChange={onContactInformationChange}
                                valid={isValid.country}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <DialogActionsBar layout="end">
                <Row>
                    <Col className="trial-license-privacy-statement" sm={6}>
                        {toHtml(localization.toLanguageString(trialLicensePrivacyStatementKey, mainMessages[siteLanguageDefault][trialLicensePrivacyStatementKey]))}
                    </Col>
                    <Col className="contact-information-modal-buttons" sm={6}>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"outline"}
                            rounded={"small"}
                            type={"button"}
                            onClick={close}
                        >
                            {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                        </Button>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"solid"}
                            rounded={"small"}
                            type={"button"}
                            onClick={validateContactInformation}
                        >
                            {localization.toLanguageString(submitRequestKey, mainMessages[siteLanguageDefault][submitRequestKey])}
                        </Button>
                    </Col>
                </Row>
            </DialogActionsBar>
            {(isVisible.missingFieldsError || isVisible.invalidEmailError) && <>
                <br/>
                <Row className="trial-license-alerts">
                    <Col xs={6}>
                        {isVisible.missingFieldsError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(requiredFieldsMissing, mainMessages[siteLanguageDefault][requiredFieldsMissing])}
                            message={localization.toLanguageString(completeRequiredFieldsKey, mainMessages[siteLanguageDefault][completeRequiredFieldsKey])}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, missingFieldsError: false}))}
                        />}
                        {isVisible.invalidEmailError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(invalidEmailKey, mainMessages[siteLanguageDefault][invalidEmailKey])}
                            message={localization.toLanguageString(enterValidEmailKey, mainMessages[siteLanguageDefault][enterValidEmailKey])}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, invalidEmailError: false}))}
                        />}
                    </Col>
                </Row>
            </>}
        </Dialog>
    )
}

export default ContactInformationModal;