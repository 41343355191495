import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

//assets
import KSMProductViewNavigationImg from '../assets/img/Help/ksm-product-view-navigtion.png';
import KSMProductViewSortingImg from '../assets/img/Help/ksm-product-view-sorting.png';
import KSMProductViewRedeemIconImg from '../assets/img/Help/ksm-product-view-redeem-icon.png';
import KSMProductViewDownloadcon from '../assets/img/Help/ksm-product-dowload-icon.png'
import KSMProductViewProductBehaviorImg from '../assets/img/Help/ksm-product-view-product-behavior.png'
import KSMProductViewHostBehaviorImg from '../assets/img/Help/ksm-product-view-host-behavior.png'

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    helpProductRedeemDownloadLicenseContentKey,
    redeemingDownloadingLicensesKey,
    ksmViewProductHostKey,
    frequentlyAskedQuestionsKey,
    licensesKey,
    helpProductViewLicenseFaqQAKey,
    mainMessages,
    helpProductViewNavigationContentKey,
    navigationKey,
    helpProductViewProductHostContentKey,
    helpProductViewSortFilterContentKey,
    sortingFilteringKey,
    viewingProductsHostKey
} from '../assets/text/MultilingualText.js';


function ProductViewHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpMenuContent = {
        'header': {
            key: ksmViewProductHostKey,
            default: mainMessages[siteLanguageDefault][ksmViewProductHostKey]
        },
        'topics': [
            {
                key: viewingProductsHostKey,
                default: mainMessages[siteLanguageDefault][viewingProductsHostKey],
                hash: "#product-host-view"
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: "#product-navigation"
            },
            {
                key: sortingFilteringKey,
                default: mainMessages[siteLanguageDefault][sortingFilteringKey],
                hash: "#product-filter"
            },
            {
                key: redeemingDownloadingLicensesKey,
                default: mainMessages[siteLanguageDefault][redeemingDownloadingLicensesKey],
                hash: "#product-redeem-download"
            },
        ],
        'faq': [
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: "#product-license-faq"
            }
        ]
    };

    const helpProductViewProductHostContent = localization.toLanguageString(
        helpProductViewProductHostContentKey,
        mainMessages[siteLanguageDefault][helpProductViewProductHostContentKey]
    );

    const helpProductNavigationContent = localization.toLanguageString(
        helpProductViewNavigationContentKey,
        mainMessages[siteLanguageDefault][helpProductViewNavigationContentKey]
    );

    const helpProductViewSortFilterContent = localization.toLanguageString(
        helpProductViewSortFilterContentKey,
        mainMessages[siteLanguageDefault][helpProductViewSortFilterContentKey]
    );

    const helpProductRedeamDownloadLicenseContent = localization.toLanguageString(
        helpProductRedeemDownloadLicenseContentKey,
        mainMessages[siteLanguageDefault][helpProductRedeemDownloadLicenseContentKey]
    );

    const helpProductViewLicenseFaqQAContent = localization.toLanguageString(
        helpProductViewLicenseFaqQAKey,
        mainMessages[siteLanguageDefault][helpProductViewLicenseFaqQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3 header"}
                            id='product-host-view'
                        >
                            <Text
                                textkey={viewingProductsHostKey}
                                textdefault={mainMessages[siteLanguageDefault][viewingProductsHostKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(helpProductViewProductHostContent?.section_one)}
                            <ul>
                                {helpProductViewProductHostContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`product-host-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>;
                                })}
                            </ul>
                            {toHtml(helpProductViewProductHostContent?.section_two)}
                            <ul>
                                {helpProductViewProductHostContent?.section_two_bullets?.map((bullet, idx) => {
                                    return <li key={`product-host-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>;
                                })}
                            </ul>
                            {toHtml(helpProductViewProductHostContent.section_three)}
                        </div>
                        <div
                            className={"k-h3 header"}
                            id='product-navigation'
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpProductNavigationContent?.section_one}</p>
                            <ul>
                                {helpProductNavigationContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`product-host-${idx}`}>
                                        {toHtml(bullet)}
                                        {
                                            idx === 0 &&
                                            <img src={KSMProductViewNavigationImg} alt='product-navigation'/>
                                        }
                                    </li>;
                                })}
                            </ul>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id='product-filter'
                        >
                            <Text
                                textkey={sortingFilteringKey}
                                textdefault={mainMessages[siteLanguageDefault][sortingFilteringKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpProductViewSortFilterContent?.section_one}</p>
                            <ul>
                                {helpProductViewSortFilterContent?.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`product-host-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>;
                                })}
                            </ul>
                            <img src={KSMProductViewSortingImg} alt={'sorting'}/>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id={'product-redeem-download'}
                        >
                            <Text
                                textkey={redeemingDownloadingLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][redeemingDownloadingLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>{helpProductRedeamDownloadLicenseContent?.section_one}</p>
                            <ul>
                                {helpProductRedeamDownloadLicenseContent?.section_one_bullets_withicon?.map((bullet, idx) => {
                                    return <li className="k-d-flex k-gap-4" key={`product-download-${idx}`}>
                                        <span>
                                            {
                                                idx === 0 &&
                                                <img src={KSMProductViewRedeemIconImg} alt={'redeem icon'}/>
                                            }
                                            {
                                                idx === 1 &&
                                                <img src={KSMProductViewDownloadcon} alt={'download icon'}/>
                                            }
                                        </span>
                                        <span>{toHtml(bullet)}</span>
                                    </li>;
                                })}
                            </ul>
                            {toHtml(helpProductRedeamDownloadLicenseContent?.section_two)}
                            <img src={KSMProductViewProductBehaviorImg} alt={'product behavior'}/>
                            {toHtml(helpProductRedeamDownloadLicenseContent?.section_three)}
                            <img src={KSMProductViewHostBehaviorImg} alt={'host behavior'}/>
                        </div>
                        <div
                            className={"k-h3 header"}
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="product-license-faq">
                            <h3>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h3>
                            <ul>
                                {helpProductViewLicenseFaqQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"entitlement-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <div className="top indent">
                                                {
                                                    index === 1 ? <p>
                                                            {questionAnswer?.A?.split('<icon>')[0]}
                                                            <img src={KSMProductViewRedeemIconImg} alt={'redeem icon'}/>
                                                            {questionAnswer?.A?.split('<icon>')?.[1]}
                                                        </p>
                                                        :
                                                        <p>{questionAnswer?.A}</p>
                                                }
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ProductViewHelp;

