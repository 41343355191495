// react
import {useHistory} from "react-router-dom";
import axios from "axios";

// kendo
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";

// multilingual
import {
    copyProductsAndHostsKey,
    ELCopyProductsAndHostsWarningKey,
    ELCopyProductsWarning1Key,
    ELCopyProductsWarning2Key,
    ELCopyProductsWarning3Key,
    cancelKey,
    confirmKey,
    mainMessages
} from "../../assets/text/MultilingualText";

// ksm
import config from "../../config";
import React from "react";
import {WarningErrorIcon} from "../common/icons";

export const CopyProductsAndHostsModal = (props) => {
    const {
        accessToken,
        localization,
        siteLanguageDefault,
        setShowCopyProductsAndHostsModal,
        setAlertJSON,
        setShowAlertModal,
        modalType,
        eaId,
        setIsLoading,
    } = props;

    let history = useHistory();

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: "var(--color-text-primary)"
    };

    const onCloseCopyProductsAndHostsModal = () => {
        setShowCopyProductsAndHostsModal(false);
    }

    const onClickConfirm = () => {
        let period = (modalType === "currentPeriodCopyProductsAndHosts") ?
            "PRODUCTSELECTIONCURRENTPERIOD" : "PRODUCTSELECTIONNEXTPERIOD"
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let data = {
                module: "EA",
                sub_module: "allocations",
                action: "COPY",
                input_json: [
                    {
                        "ea_id": eaId,
                        "products_period_selection_input": period,
                        "copy_selection_input": "COPYLASTPERIODPRODUCTSANDHOSTS"
                    }
                ]
        }
        setIsLoading(true);
        axios.post(
            config.ea_summary.COPY_PRODUCTS,
            data,
            {headers: headers}
        )
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    let data = response.data || [];
                    if (data['display_alert'].toLowerCase() === "yes") {
                        setAlertJSON({
                            eaID: eaId,
                            modalType: modalType,
                            show: 2,
                            period: period
                        })
                        setShowAlertModal(true)
                    } else {
                        let params = '?eaid=' + eaId + '&show=' + period + '&step=2'
                        history.push('/ea-request-license' + params)
                    }
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Data", error);
                setIsLoading(false);
            });
    }

    return (
        <Dialog
            className={"start-from-scratch-modal ksm-dialog"}
            title={
                <div style={titleStyle}>
                    {localization.toLanguageString(copyProductsAndHostsKey, mainMessages[siteLanguageDefault][copyProductsAndHostsKey])}
                </div>
            }
            onClose={onCloseCopyProductsAndHostsModal}
            width={"38.25rem"}
        >
            <div
                style={{
                    'display': 'flex'
                }}
            >
                <WarningErrorIcon
                    size={'xxlarge'}
                    style={{
                        color: 'var(--color-alert-warning-base)',
                    }}
                />
                <div
                    style={{
                        marginLeft: '1rem',
                        fontSize: '1rem',
                        wordBreak: 'normal',
                    }}
                >
                    <div className={"start-from-scratch-modal-text"}>
                        {localization.toLanguageString(ELCopyProductsAndHostsWarningKey, mainMessages[siteLanguageDefault][copyProductsAndHostsKey])}
                    </div>
                    <ul>
                        <li>
                            <div className={"start-from-scratch-modal-text"}>
                                {localization.toLanguageString(ELCopyProductsWarning1Key, mainMessages[siteLanguageDefault][ELCopyProductsWarning1Key])}
                            </div>
                        </li>
                        <li>
                            <div className={"start-from-scratch-modal-text"}>
                                {localization.toLanguageString(ELCopyProductsWarning2Key, mainMessages[siteLanguageDefault][ELCopyProductsWarning2Key])}
                            </div>
                        </li>
                    </ul>
                    <b>
                        {localization.toLanguageString(ELCopyProductsWarning3Key, mainMessages[siteLanguageDefault][ELCopyProductsWarning3Key])}
                    </b>
                </div>
            </div>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    type={"button"}
                    onClick={onCloseCopyProductsAndHostsModal}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    onClick={onClickConfirm}
                >
                    {localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}