import React, {useContext} from "react";

//components
import UserContext from "../../common/UserContext";
import AssignedHostDelete from "../AssignProducts/AssignedHostDelete";

//multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    mainMessages,
    requestLicensesKey,
} from "../../../assets/text/MultilingualText";

//kendo
import {Button} from "@progress/kendo-react-buttons";


export default function ReviewTransport(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        setIsLoading,
        unassigned,
        setUnassigned,
        assigned,
        setAssigned,
        setReview,
    } = props

    const returnLicenses = () => {
        setReview(false)
    }

    return (
        <>
            <AssignedHostDelete
                setIsLoading={setIsLoading}
                unassigned={unassigned}
                setUnassigned={setUnassigned}
                assigned={assigned}
                setAssigned={setAssigned}
            />

            <div style={{
                display: 'flex',
                gap: '0.938rem',
                justifyContent: 'end',
            }}>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    rounded={"small"}
                    onClick={() => {
                        returnLicenses()
                    }}
                >
                    {localization.toLanguageString(requestLicensesKey, mainMessages[siteLanguageDefault][requestLicensesKey])}
                </Button>
            </div>

        </>
    )
}