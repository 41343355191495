import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    notGeneratedSupportAgentV2Key,
    mainMessages,
    notGeneratedAvailableRedeemV2Key,
    genericErrorTitleKey
} from '../../../assets/text/MultilingualText.js';
import KSMPopover from "../../common/Popovers";


export const LicenseDownloadPopover = (props) => {
    const {
        type,
        showPopover,
        popoverRef
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const popoverType = 'error'
    let title;
    let message;
    let popoverStyle = {
        maxWidth: '35rem'
    };

    switch (type) {
        case 'networkError':
            title = localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]);
            message = localization.toLanguageString(notGeneratedAvailableRedeemV2Key, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemV2Key]);
            break
        default:
            title = localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]);
            message = localization.toLanguageString(notGeneratedSupportAgentV2Key, mainMessages[siteLanguageDefault][notGeneratedSupportAgentV2Key]);
    }

    return (
        <KSMPopover
            show={showPopover}
            anchor={popoverRef && popoverRef.current}
            position={'left'}
            style={popoverStyle}
            type={popoverType}
            message={<div>
                <b>{title}</b>
                <br/>
                {message}
            </div>}
        />
    )
}
