import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../components/common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import WelcomeCard from '../components/Welcome/WelcomeCard';
import Downtime from '../components/common/Downtime.js';
import Spinner from '../components/common/Spinner.js';

function Welcome(props) {
    const {
        accessToken,
        mainMenu
    } = useContext(UserContext);

    const {
        isLoading,
        getMainMenu
    } = props

    const [layout, setLayout] = useState(<></>);

    useEffect(() => {
        getMainMenu(accessToken, false)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const rows = [...Array(Math.ceil(mainMenu.length / 4))] || [];
        const cardsPerRow = rows.map((row, index) => mainMenu.slice(index * 4, index * 4 + 4));

        // set 4 cards per row
        setLayout(
            cardsPerRow.map((cards, index) => (
                <Row className="mb-4" xs="4" key={index}>
                    {cards.map((card) =>
                        (card["TITLE"] && card["SUBMENU"]) ?
                            <Col className={"pl-4 pr-4"} key={card["TITLE"]}>
                                <WelcomeCard
                                    card={card}
                                />
                            </Col>
                            :
                            <></>
                    )}
                </Row>
            ))
        );

    }, [mainMenu]); // eslint-disable-line react-hooks/exhaustive-deps

    // Adobe Analytics
    useEffect(() => {
        window.adobeDataLayer.push({
            "page": {
                "pageName": "Welcome"
            }
        })
    }, [])

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Downtime/>
                {(!isLoading) ? layout : <Spinner/>}
            </div>
        </>
    );
}

export default Welcome;