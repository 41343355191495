import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/EARequestAgain.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../components/common/Spinner.js';
import PageHeader from '../components/common/PageHeader.js';
import DetailedViewInfo from '../components/EADetailedView/DetailedViewInfo.js';
import SummaryChart from '../components/EARequestLicense/SummaryChart.js';
import {RequestAgainGrid} from '../components/EARequestLicense/RequestAgain/Grid.js';
import RequestAgainModal from '../components/EARequestLicense/RequestAgain/RequestAgainModal.js';
import Alert from '../components/common/Alert.js';
import {formatCurrency} from '../components/common/utilities.js';

// kendo react
import {DatePicker} from '@progress/kendo-react-dateinputs';
import {Button} from '@progress/kendo-react-buttons';
import {addDays, addMonths} from '@progress/kendo-date-math';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {Text} from '../components/common/MultilingualText.js';
import {useLocalization} from '@progress/kendo-react-intl';
import {
    eARequestAgainKey,
    enterpriseLicensingKey,
    excessAmountKey,
    fromExpiryDateKey,
    licensesExpireDateRangeKey,
    mainMessages,
    pleaseEditSelectedKey,
    requestAgainKey,
    subscriptionPoolFreedKey,
    subscriptionPoolSelectedKey,
    toExpiryDateKey,
    totalCostSelectedKey,
    totalSubscriptionPoolAvailableKey
} from '../assets/text/MultilingualText.js';

const initialEADetails = {
    customer_name: "",
    customer_contact_email: "",
    start_date: "",
    end_date: "",
    remix_type: "",
    status: "",
    currency_code: "",
    customer_address: "",
    customer_city: "",
    customer_country: "",
    customer_state: "",
    customer_zip_code: ""
};
const initialPool = {
    remaining_pool: 0,
    total_pool: 0,
    selected_sub_pool: 0,
    consumed_pool: 0,
    currency: "USD",
    locale: "en-US"
};

function EARequestAgain(props) {
    const {
        siteLanguageDefault,
        accessToken
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    const [alerts, setAlerts] = useState([]);

    const [eADetails, setEADetails] = useState(initialEADetails);

    const [assignments, setAssignments] = useState([]);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const [dateRange, setDateRange] = useState({
        start: today,
        end: addDays(today, 5)
    });
    const [maxEndDate, setMaxEndDate] = useState(addMonths(today, 1));

    const summaryChartStyle = {
        justifyContent: 'right'
    };

    const [unSavedPool, setUnSavedPool] = useState(0);
    const [pool, setPool] = useState(initialPool);

    /*
     * showHandler() handler for all alerts
    */
    const showHandler = (index) => setAlerts(alerts.filter((a, i) => i !== index));

    /*
     * onDateRangeChange() updates the date range object on change
     * @param {e} the input change event
    */
    const onDateRangeChange = (e) => {
        let field = e.target.props.field;

        // handle invalid date picker ranges (ie. start date > end date)
        if (field === 'start' && e.value > dateRange.end) {
            setDateRange(dateRange => ({
                ...dateRange,
                end: null,
                [field]: e.value
            }));
        } else if (field === 'end' && dateRange.start > e.value) {
            setDateRange(dateRange => ({
                ...dateRange,
                start: null,
                [field]: e.value
            }));
        } else {
            setDateRange(dateRange => ({
                ...dateRange,
                [field]: e.value
            }));
        }
    }

    /*
     * calculateCost() calculates total cost
    */
    const calculateCost = () => {
        let totalCost = assignments
            .filter(a => a.selected)
            .map(a => a.total_cost)
            .reduce((a, b) => {
                return a + b
            }, 0);
        setUnSavedPool(totalCost);

        if (totalCost + pool.selected_sub_pool > pool.remaining_pool) {
            // show error on exceeded sub pool
            setAlerts([{
                type: "error",
                title: <>
                    {localization.toLanguageString(subscriptionPoolSelectedKey, mainMessages[siteLanguageDefault][subscriptionPoolSelectedKey])}<br/>
                    {localization.toLanguageString(subscriptionPoolFreedKey, mainMessages[siteLanguageDefault][subscriptionPoolFreedKey])}
                </>,
                message: <Row className={"my-2"}>
                    <Row className={"mt-2"}>
                        <Col xs={3} className={"pe-0"}>
                            {localization.toLanguageString(totalSubscriptionPoolAvailableKey, mainMessages[siteLanguageDefault][totalSubscriptionPoolAvailableKey])}:
                        </Col>
                        <Col className={"pe-0"}>{formatCurrency(pool.remaining_pool - pool.selected_sub_pool, pool.locale, pool.currency)}</Col>
                    </Row>
                    <Row>
                        <Col xs={3} className={"pe-0"}>
                            {localization.toLanguageString(totalCostSelectedKey, mainMessages[siteLanguageDefault][totalCostSelectedKey])}:
                        </Col>
                        <Col className={"pe-0"}>{formatCurrency(totalCost, pool.locale, pool.currency)}</Col>
                    </Row>
                    <Row>
                        <Col xs={3} className={"pe-0"}>
                            {localization.toLanguageString(excessAmountKey, mainMessages[siteLanguageDefault][excessAmountKey])}:
                        </Col>
                        <Col className={"pe-0"}>{formatCurrency(pool.remaining_pool - (totalCost + pool.selected_sub_pool), pool.locale, pool.currency)}</Col>
                    </Row>
                    <Row className={"mt-2"}>
                        <Col className={"pe-0"}>
                            {localization.toLanguageString(pleaseEditSelectedKey, mainMessages[siteLanguageDefault][pleaseEditSelectedKey])}
                        </Col>
                    </Row>
                </Row>
            }]);
            // disable request again button
            setIsDisabled(true);
        } else {
            setAlerts([]);
            assignments.filter(a => a.selected).length ? setIsDisabled(false) : setIsDisabled(true);
        }
    }

    /*
     * getHostAssignments() gets all the host assignments
    */
    const getHostAssignments = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        const params = new URLSearchParams(window.location.search);
        let id = params.get('eaid');

        setIsLoading(true);

        axios.get(
            config.ea_request_license.REQUEST_AGAIN_ON_LOAD,
            {
                headers: headers,
                params: {
                    module: "ea_variable_license",
                    ea: id
                }
            }
        )
            .then((response) => {
                let data = response.data || [];
                let header = data.ea_header_list[0];
                let period = data.ea_periods_list.filter(p => p.period_status === "CURRENT")[0];
                let hostAssignments = data.ea_variable_license_list;

                setIsLoading(false);

                // set ea details
                header ? setEADetails(header) : setEADetails(initialEADetails);

                // set sub pool
                period && header ? setPool(pool => ({
                    ...pool,
                    remaining_pool: period.remain_subscription_pool,
                    total_pool: period.total_subscription_pool,
                    selected_sub_pool: period.selected_subscription_pool,
                    consumed_pool: period.redeemed_subscription_pool,
                    currency: header.currency_code,
                    locale: header.locale
                })) : setPool(initialPool);
                setUnSavedPool(0);

                // set max end date
                if (header.end_date) {
                    let eAEndDate = new Date(header.end_date);
                    if (eAEndDate < maxEndDate) {
                        setMaxEndDate(eAEndDate);
                    }
                }

                // set host assignment grid
                if (hostAssignments.length) {
                    hostAssignments.forEach((h, index) => {
                        h.unique_id = index;
                        h.start_date = new Date(h.start_date).setHours(0, 0, 0, 0);
                        h.end_date = new Date(h.end_date).setHours(0, 0, 0, 0);
                        h.disabled = (h.product_status !== "ACTIVE");
                    })
                }

                hostAssignments = orderBy(
                    hostAssignments,
                    [{
                        field: "days_left",
                        dir: "asc",
                    }]
                );
                setAssignments(hostAssignments);
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET EA Request Again Host Assignments", error);
                setIsLoading(false);
                setEADetails(initialEADetails);
                setPool(initialPool);
                setAssignments([]);
            })
    }

    // on ea request again load
    useEffect(() => {
        getHostAssignments();
    }, []);

    // useEffect(() => {
    //     console.log('customer info', customerInfo);
    // }, [customerInfo]);
    //
    // useEffect(() => {
    //     console.log('ea details', eADetails);
    // }, [eADetails]);
    //
    // useEffect(() => {
    //     console.log('pool', pool);
    // }, [pool]);
    //
    // useEffect(() => {
    //     console.log('assignments', assignments);
    // }, [assignments]);
    //
    // useEffect(() => {
    //     console.log('date range', dateRange);
    // }, [dateRange]);

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <div
                        className={"k-h3"}
                        style={{
                            lineHeight: '2.313rem',
                            marginBottom: '0.938rem'
                        }}
                    >
                        <Text
                            textkey={enterpriseLicensingKey}
                            textdefault={mainMessages[siteLanguageDefault][enterpriseLicensingKey]}
                        />
                    </div>
                </Row>
                <Row>
                    <div
                        className={'k-h5'}
                        style={{lineHeight: '2.313rem'}}
                    >
                        {localization.toLanguageString(eARequestAgainKey, mainMessages[siteLanguageDefault][eARequestAgainKey])}
                    </div>
                </Row>
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '-0.938rem'
                    }}
                >
                    <Col xs={6}>
                        <DetailedViewInfo eaDetails={eADetails}/>
                    </Col>
                    <Col xs={6}>
                        <SummaryChart
                            totalValue={pool.total_pool}
                            data={[
                                {
                                    "category": "consumed",
                                    "value": pool.consumed_pool,
                                    "backgroundColor": "#432668",
                                    "fontColor": "#FFFFFF",
                                },
                                {
                                    "category": "selected",
                                    "value": pool.selected_sub_pool + unSavedPool,
                                    "backgroundColor": "#A895B5",
                                    "fontColor": "#FFFFFF",
                                },
                                {
                                    "category": "available",
                                    "value": pool.remaining_pool - pool.selected_sub_pool - unSavedPool,
                                    "backgroundColor": "#FFFFFF",
                                    "fontColor": "#000000",
                                }
                            ]}
                            currency_code={pool.currency}
                            currency_locale={pool.locale}
                            style={summaryChartStyle}
                        />
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col xs={3}>
                        <div className={"k-h5"}>
                            <Text
                                textkey={licensesExpireDateRangeKey}
                                textdefault={mainMessages[siteLanguageDefault][licensesExpireDateRangeKey]}
                            />
                        </div>
                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col xs={6}>
                                <Text
                                    textkey={fromExpiryDateKey}
                                    textdefault={mainMessages[siteLanguageDefault][fromExpiryDateKey]}
                                />
                                <DatePicker
                                    value={maxEndDate && dateRange.start > maxEndDate ? maxEndDate : dateRange.start}
                                    max={maxEndDate}
                                    format="d-MMM-y"
                                    field={"start"}
                                    onChange={onDateRangeChange}
                                />
                            </Col>
                            <Col xs={6}>
                                <Text
                                    textkey={toExpiryDateKey}
                                    textdefault={mainMessages[siteLanguageDefault][toExpiryDateKey]}
                                />
                                <DatePicker
                                    value={maxEndDate && dateRange.end > maxEndDate ? maxEndDate : dateRange.end}
                                    max={maxEndDate}
                                    format="d-MMM-y"
                                    field={"end"}
                                    onChange={onDateRangeChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col>
                        <RequestAgainGrid
                            assignments={assignments}
                            setAssignments={setAssignments}
                            dateRange={dateRange}
                            calculateCost={calculateCost}
                        />
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"solid"}
                            rounded={"small"}
                            type={"button"}
                            style={{float: "right"}}
                            disabled={isDisabled}
                            onClick={() => setIsVisible(true)}
                        >
                            {localization.toLanguageString(requestAgainKey, mainMessages[siteLanguageDefault][requestAgainKey])}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {alerts.map((alert, index) => <div className={"my-2"}>
                            {alert.type === 'error' ? <Alert
                                className={"my-3 p-0"}
                                key={'error-' + index}
                                type={'error'}
                                title={alert.title}
                                message={alert.message}
                                showHandler={() => showHandler(index)}
                            /> : <></>}
                        </div>)}
                    </Col>
                </Row>
            </div>
            {isVisible && <RequestAgainModal
                pool={pool}
                unSavedPool={unSavedPool}
                assignments={assignments.filter(a => a.selected)}
                setIsLoading={setIsLoading}
                setIsVisible={setIsVisible}
            />}
        </>
    );
}

export default EARequestAgain;

