import React, {useContext} from 'react';
import UserContext from '../common/UserContext.js';

// react router
import {Redirect, Route} from 'react-router-dom';
import PageNotFound from "../../views/PageNotFound";

function NotFoundRoute({component: Component, ...rest}) {
    const {
        accessToken,
    } = useContext(UserContext);

    const checkLegacyLink = () =>{
        let isLegacy = false
        const path = rest.location.pathname
        const legacyEndpoints = ['.aspx', '.asmx', '.ascx']
        legacyEndpoints.forEach(regex => {
            const found = path.match(new RegExp(regex))
            if(found !== null){
                isLegacy = true
            }
        })
        return isLegacy
    }

    return (
        <Route {...rest} render={props =>
            (checkLegacyLink()) ? (
                <Redirect to={{
                    pathname: '/',
                    accessToken: accessToken,
                    path: rest.path,
                    previous: rest.location
                }}
                />
            ) : (
               <Route
                    render={(props) => (
                        <PageNotFound/>
                    )}
                />
            )
        }/>
    );
};

export default NotFoundRoute;