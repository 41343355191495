import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../common/Alert.js'
import {DeactivateGrid, KeepGrid} from './RequestLicense/Grid.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cancelKey,
    hostIdKey,
    mainMessages,
    genericErrorContactUsKey,
    deactivationFailedKey,
    requestLicenseKey,
    processNextStepKey,
    licenseGeneratingKey,
    supportTeamDeactivationKey,
    failedGenerationTimeKey
} from '../../assets/text/MultilingualText.js';


function RequestLicense(props) {
    const {
        setIsLoading,
        changeStepActivation,
        hostID,
        deactivateProducts,
        keepProducts,
        hostFile,
        transactionID
    } = props
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [showGeneratingWarning, setShowGeneratingWarning] = useState(false);
    const [showLicenseQEntryError, setShowLicenseQEntryError] = useState(false);
    const [showLicenseRetrievalError, setShowLicenseRetrievalError] = useState(false);
    const [disableRequestLicense, setDisableRequestLicense] = useState(false);
    const [cancelToStep0, setCancelToStep0] = useState(false);

    //Disable Request button based on alerts
    useEffect(() => {
        if (showGeneratingWarning || showLicenseRetrievalError) {
            setDisableRequestLicense(true);
        }

        if (showLicenseQEntryError) {
            setDisableRequestLicense(false);
        }
    }, [showGeneratingWarning, showLicenseQEntryError, showLicenseRetrievalError])

    //Request deactivate license generation
    async function requestLicense() {
        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let formData = new FormData();
        formData.append('File', hostFile);
        let data = {
            "transaction_id": transactionID,
            "hostid_type": deactivateProducts[0]['hostid_type'],
            "products": deactivateProducts,
            "host_id": hostID,
        };

        formData.append('Data', JSON.stringify(data));

        setShowLicenseQEntryError(false);
        setShowLicenseRetrievalError(false);
        setShowGeneratingWarning(true);
        setIsLoading(true);

        axios.post(
            config.deactivate_license.GENERATE_LICENSE,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    setShowGeneratingWarning(false);
                    changeStepActivation(3);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to request new license", error.response);
                setShowGeneratingWarning(false);
                let errorSet = false;
                if (error.response.hasOwnProperty('data')) {
                    if (error.response.data.hasOwnProperty('error_code')) {
                        if (error.response.data['error_code'].toUpperCase() === 'MOD_DEACT_227') {
                            setShowLicenseQEntryError(true);
                            errorSet = true;
                        }
                    }
                }
                if (!errorSet) {
                    setShowLicenseRetrievalError(true);
                    setCancelToStep0(true);
                }
                setIsLoading(false);
            });
    }

    return (
        <>
            <div className={"k-h5"}>
                {localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])} {hostID}
            </div>
            <DeactivateGrid deactivateProducts={deactivateProducts}/>
            <KeepGrid keepProducts={keepProducts}/>
            <Row>
                <Col>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        type={"button"}
                        onClick={() => {
                            if (cancelToStep0) {
                                changeStepActivation(1)
                            } else {
                                changeStepActivation(0)
                            }
                        }}
                    >
                        {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                    </Button>
                </Col>
                <Col>
                    {showGeneratingWarning && (
                        <Alert
                            type={'warning'}
                            title={localization.toLanguageString(licenseGeneratingKey, mainMessages[siteLanguageDefault][licenseGeneratingKey])}
                            message={localization.toLanguageString(processNextStepKey, mainMessages[siteLanguageDefault][processNextStepKey])}
                        />
                    )}
                    {showLicenseQEntryError && (
                        <Alert
                            type={'error'}
                            title={localization.toLanguageString(deactivationFailedKey, mainMessages[siteLanguageDefault][deactivationFailedKey])}
                            message={<div
                                style={{
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                {localization.toLanguageString(genericErrorContactUsKey, mainMessages[siteLanguageDefault][genericErrorContactUsKey])}
                            </div>}
                        />
                    )}
                    {showLicenseRetrievalError && (
                        <Alert
                            type={'error'}
                            title={localization.toLanguageString(failedGenerationTimeKey, mainMessages[siteLanguageDefault][failedGenerationTimeKey])}
                            message={localization.toLanguageString(supportTeamDeactivationKey, mainMessages[siteLanguageDefault][supportTeamDeactivationKey])}
                        />
                    )}
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '0.938rem',
                            justifyContent: 'end'
                        }}
                    >
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"solid"}
                            type={"button"}
                            disabled={disableRequestLicense}
                            onClick={() => {
                                requestLicense()
                            }}
                        >
                            {localization.toLanguageString(requestLicenseKey, mainMessages[siteLanguageDefault][requestLicenseKey])}
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default RequestLicense;