import React, {useCallback, useContext, useRef} from 'react';
import UserContext from '../../common/UserContext.js';

// react strap
import {Col, Row} from 'reactstrap';

// components
import {PopoverContext} from '../SelectLicense.js';

// kendo react
import {Checkbox} from '@progress/kendo-react-inputs';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {hostKey, mainMessages} from '../../../assets/text/MultilingualText.js';

export const PanelBarHeader = (props) => {
    const {
        hosts,
        setHosts,
        host,
        hostIndex
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    // sets the popover reference based on mouse over
    const popoverRef = useRef(null);
    const {onMouseOver} = useContext(PopoverContext);

    const handleMouseOver = useCallback(() => {
        onMouseOver({
            show: true,
            popoverRef: popoverRef,
            required: host.required_redemption.join(",")
        });
    }, [onMouseOver]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleMouseOut = useCallback(() => {
        onMouseOver({
            show: false,
            popoverRef: popoverRef,
            required: host.required_redemption.join(",")
        });
    }, [onMouseOver]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Row>
            <Col
                style={{
                    flex: '0 0 auto',
                    width: 'auto'
                }}
            >
                <div
                    className={"k-h4"}
                    style={{
                        color: 'white',
                        margin: '0'
                    }}
                >
                    {localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])} {host.host_id}
                </div>
            </Col>
            <Col/>
            <Col>
                <div
                    style={{
                        float: 'right',
                        display: "flex",
                        justifyContent: "end",
                        alignItems: 'baseline'
                    }}
                >
                    <div
                        style={{
                            padding: 0,
                            marginRight: '9px'
                        }}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        ref={popoverRef}
                    >
                        <Checkbox
                            defaultChecked={host.selected}
                            disabled={host.disabled}
                            onClick={(e) => {
                                host.selected = e.target.checked;
                                hosts[hostIndex] = host;
                                setHosts([...hosts]);
                            }}
                            className={'ksm-panelbar-checkbox'}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}