// react
import {useHistory} from "react-router-dom";

// kendo
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";

// multilingual
import {
    okKey,
    ELCopyAlertTitleKey,
    ELCopyProductsOnlyAlertMessageKey,
    ELCopyProductsAndHostsAlertMessageKey,
    mainMessages
} from "../../assets/text/MultilingualText";

export const CopyProductsAlertModal = (props) => {
    const {
        localization,
        siteLanguageDefault,
        showProductsType,
        setShowAlertModal,
        alertJSON
    } = props;

    const titleStyle = {
        color: "var(--color-text-primary)",
    };

    let history = useHistory();

    const onCloseCopyProductsOnlyModal = () => {
        setShowAlertModal(false);
        let params = '?eaid=' + alertJSON.eaID + '&show=' + alertJSON.period + '&step=' + alertJSON.show
        history.push('/ea-request-license' + params)
    }

    return (
        <Dialog
            className={"start-from-scratch-modal ksm-dialog"}
            title={
                <div className={'k-h5'} style={titleStyle}>
                    {localization.toLanguageString(ELCopyAlertTitleKey, mainMessages[siteLanguageDefault][ELCopyAlertTitleKey])}
                </div>
            }
            onClose={onCloseCopyProductsOnlyModal}
            width={"38.25rem"}
        >
            <div
                style={{
                    'display': 'flex'
                }}
            >
                <div
                    style={{
                        marginLeft: '1rem',
                        fontSize: '1rem',
                        wordBreak: 'normal',
                    }}
                >
                    { showProductsType.includes("CopyProductsOnly") && (
                        <div>
                            {localization.toLanguageString(ELCopyProductsOnlyAlertMessageKey, mainMessages[siteLanguageDefault][ELCopyProductsOnlyAlertMessageKey])}
                        </div>
                    )}
                    { showProductsType.includes("CopyProductsAndHosts") && (
                        <div>
                            {localization.toLanguageString(ELCopyProductsAndHostsAlertMessageKey, mainMessages[siteLanguageDefault][ELCopyProductsAndHostsAlertMessageKey])}
                        </div>
                    )}
                </div>
            </div>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    onClick={onCloseCopyProductsOnlyModal}
                >
                    {localization.toLanguageString(okKey, mainMessages[siteLanguageDefault][okKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}