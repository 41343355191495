import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// kendo react
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    notGeneratedSupportAgentKey,
    genericErrorTitleKey,
    mainMessages,
    availableRenewKeys,
} from '../../../assets/text/MultilingualText';
import KSMPopover from "../../common/Popovers";


export const LicenseDownloadPopover = (props) => {
    const {
        type,
        showPopover,
        popoverRef
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const popoverType = 'error';
    let title;
    let message;
    let popoverStyle = {
        maxWidth: '35rem'
    };

    switch (type) {
        case 'networkError':
            title = localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]);
            message = localization.toLanguageString(availableRenewKeys, mainMessages[siteLanguageDefault][availableRenewKeys]);
            break;
        default:
            title = localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]);
            message = localization.toLanguageString(notGeneratedSupportAgentKey, mainMessages[siteLanguageDefault][notGeneratedSupportAgentKey]);
    }

    return (
        <KSMPopover
            show={showPopover}
            anchor={popoverRef && popoverRef.current}
            position={'left'}
            style={popoverStyle}
            type={popoverType}
            message={<div>
                <b>{title}</b>
                <br/>
                {message}
            </div>}
        />
    )
}
