import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from "reactstrap";

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMEAHostManagement from '../assets/img/Help/ksm-ea-host-management.png';
import KSMEAHostManagementShowLicenses from '../assets/img/Help/ksm-ea-host-management-show-licenses.png';
import KSMEAHostManagementEdit from '../assets/img/Help/ksm-ea-host-management-edit.png';
import KSMEAHostManagementRemove from '../assets/img/Help/ksm-ea-host-management-remove.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    helpHostConfigEditingHostSectionKey,
    editingHostInfoKey,
    helpHostConfigErrorHandlingSectionKey,
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    manageHostsKey,
    hostsKey,
    helpHostConfigHostsQAKey,
    helpHostConfigManagingHostsSectionKey,
    managingHostsKey,
    mainMessages,
    helpHostConfigRemovingHostSectionKey,
    removingAHostKey,
    helpHostConfigViewingLicensesSectionKey,
    viewingLicensesKey,
} from '../assets/text/MultilingualText.js';


function HostConfigHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpMenuContent = {
        'header': {
            key: manageHostsKey,
            default: mainMessages[siteLanguageDefault][manageHostsKey]
        },
        'topics': [
            {
                key: managingHostsKey,
                default: mainMessages[siteLanguageDefault][managingHostsKey],
                hash: "#managing-hosts"
            },
            {
                key: viewingLicensesKey,
                default: mainMessages[siteLanguageDefault][viewingLicensesKey],
                hash: "#viewing-licenses"
            },
            {
                key: editingHostInfoKey,
                default: mainMessages[siteLanguageDefault][editingHostInfoKey],
                hash: "#editing-host"
            },
            {
                key: removingAHostKey,
                default: mainMessages[siteLanguageDefault][removingAHostKey],
                hash: "#removing-host"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            }
        ],
        'faq': [
            {
                key: hostsKey,
                default: mainMessages[siteLanguageDefault][hostsKey],
                hash: "#hosts-faq"
            }
        ]
    }

    const managingHostsSection = localization.toLanguageString(
        helpHostConfigManagingHostsSectionKey,
        mainMessages[siteLanguageDefault][helpHostConfigManagingHostsSectionKey]
    );

    const viewingLicensesSection = localization.toLanguageString(
        helpHostConfigViewingLicensesSectionKey,
        mainMessages[siteLanguageDefault][helpHostConfigViewingLicensesSectionKey]
    );

    const editingHostSection = localization.toLanguageString(
        helpHostConfigEditingHostSectionKey,
        mainMessages[siteLanguageDefault][helpHostConfigEditingHostSectionKey]
    );

    const removingHostSection = localization.toLanguageString(
        helpHostConfigRemovingHostSectionKey,
        mainMessages[siteLanguageDefault][helpHostConfigRemovingHostSectionKey]
    );

    const errorHandlingSection = localization.toLanguageString(
        helpHostConfigErrorHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpHostConfigErrorHandlingSectionKey]
    );

    const hostsQA = localization.toLanguageString(
        helpHostConfigHostsQAKey,
        mainMessages[siteLanguageDefault][helpHostConfigHostsQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3"}
                            id="managing-hosts"
                        >
                            <Text
                                textkey={managingHostsKey}
                                textdefault={mainMessages[siteLanguageDefault][managingHostsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(managingHostsSection.section_one)}
                            <p>
                                {managingHostsSection.section_two}
                            </p>
                            {managingHostsSection.section_two_steps ? <ol type="1">
                                {managingHostsSection.section_two_steps.map((step, index) => <li
                                    key={'ea-host-management-step-' + index}>
                                    {toHtml(step)}
                                </li>)}
                            </ol> : <></>}
                            <img src={KSMEAHostManagement} alt={"ksm-ea-host-management"}/>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="viewing-licenses"
                        >
                            <Text
                                textkey={viewingLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][viewingLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(viewingLicensesSection.section_one)}
                            <br/>
                            <img src={KSMEAHostManagementShowLicenses} alt={"ksm-ea-host-management-show-licenses"}/>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="editing-host"
                        >
                            <Text
                                textkey={editingHostInfoKey}
                                textdefault={mainMessages[siteLanguageDefault][editingHostInfoKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(editingHostSection.section_one)}
                            <br/>
                            <img src={KSMEAHostManagementEdit} alt={"ksm-ea-host-management-edit"}/>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="removing-host"
                        >
                            <Text
                                textkey={removingAHostKey}
                                textdefault={mainMessages[siteLanguageDefault][removingAHostKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(removingHostSection.section_one)}
                            <br/>
                            <img src={KSMEAHostManagementRemove} alt={"ksm-ea-host-management-remove"}/>

                        </div>
                        <div
                            className={"k-h3 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(errorHandlingSection.section_one)}
                            {errorHandlingSection.section_one_bullets ? <ul>
                                {errorHandlingSection.section_one_bullets.map((bullet, index) => <li
                                    key={'ea-host-management-error-handling-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h3 header"}
                            id="hosts-faq"
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="hosts-faq">
                            <h3>
                                <Text
                                    textkey={hostsKey}
                                    textdefault={mainMessages[siteLanguageDefault][hostsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {hostsQA ? hostsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"hosts-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default HostConfigHelp;

