import React, {useContext, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// kendo react
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {Text} from '../common/MultilingualText.js';
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cancelKey,
    areYouSureKey,
    hostIdKey,
    removeKey,
    removeHostKey,
    permanentlyDeleteHostIdKey,
    mainMessages,
} from '../../assets/text/MultilingualText.js';

function RemoveHostModal(props) {
    const {
        host,
        setHost,
        getHosts,
        setIsVisible,
        setIsLoading
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: 'var(--color-text-primary)'
    };

    let hostIDInfo = host.host_id ? host.host_id : "";
    let hostConfIDInfo = host.host_conf_id ? host.host_conf_id: "";

    const [hostID] = useState(hostIDInfo);
    const [hostConfID] = useState(hostConfIDInfo);

    // closes the remove host modal
    const close = () => {
        setHost({});
        setIsVisible(isVisible => ({...isVisible, removeHost: false}));
    };

    /*
    * removeHost() removes the host
    */
    const removeHost = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let body = {
            "module": "EA",
            "sub_module": "hosts",
            "action": "DELETE",
            "input_json": [
                {
                    "host_conf_id": hostConfID
                }
            ]
        };

        setIsLoading(true);

        axios.delete(
            config.host_config.HOST_SERVICE,
            {headers: headers, data: body}
        )
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    getHosts();
                    close();
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to DELETE host", error);
                setIsLoading(false);
            });
    }

    return (
        <Dialog
            className={"ksm-dialog"}
            title={<div style={titleStyle}>
                {localization.toLanguageString(removeHostKey, mainMessages[siteLanguageDefault][removeHostKey])}
            </div>}
            onClose={close}
        >
            <div
                className={"k-h5"}
            >
                <Text
                    textkey={areYouSureKey}
                    textdefault={mainMessages[siteLanguageDefault][areYouSureKey]}
                />
            </div>
            <div
                style={{
                    color: "black",
                    fontSize: "1rem"
                }}
            >
                <Text
                    textkey={permanentlyDeleteHostIdKey}
                    textdefault={mainMessages[siteLanguageDefault][permanentlyDeleteHostIdKey]}
                />
            </div>
            <div
                className={"k-h5 k-ml-4"}
            >
                <Text
                    textkey={hostIdKey}
                    textdefault={mainMessages[siteLanguageDefault][hostIdKey]}
                /> {hostID}
            </div>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    type={"button"}
                    onClick={close}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    onClick={removeHost}
                >
                    {localization.toLanguageString(removeKey, mainMessages[siteLanguageDefault][removeKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default RemoveHostModal;