import React, {useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/EASummary.css';

// components
import PageHeader from '../components/common/PageHeader.js';
import Spinner from '../components/common/Spinner.js';
import {ColumnMenu, CurrencyCell, NoWrapCell} from '../components/common/Grid.js';
import {HamburgerMenuCell} from "../components/EASummary/HamburgerMenuCell";
import {CopyProductsAlertModal} from "../components/EASummary/CopyProductsAlertModal";

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy, process} from "@progress/kendo-data-query";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    endDateKey,
    enterpriseAgreementKey,
    myEnterpriseAgreementsKey,
    noActiveAgreementsKey,
    noDataAvailableKey,
    mainMessages,
    enterpriseLicensingKey,
    remainingSubPoolKey,
    remixOpensOnKey,
    remixTypeKey,
    startDateKey,
    totalPoolKey,
} from '../assets/text/MultilingualText.js';
import {capitalizeSentence} from "../components/common/utilities";

// consts for my ea table
const initialGridState = {
    take: 25, skip: 0,
};

function EASummary(props) {
    const {
        siteLanguageDefault,
        accessToken
    } = useContext(UserContext);

    const localization = useLocalization();
    const [isLoading, setIsLoading] = useState(false);

    const [expiredAgreementsFlag, setExpiredAgreementsFlag] = useState(true);
    const [agreements, setAgreements] = useState([]);
    const [agreementsGridState, setAgreementsGridState] = useState(initialGridState);
    const [agreementsState, setAgreementsState] = useState(
        process(agreements.map((agreement) => ({
            ...agreement,
        })), initialGridState)
    );

    /*
     * onAgreementsStateChange(event) sets the agreements grid state and agreements state on grid's state change
     * @param {event} the grid selection change event
    */
    const onAgreementsStateChange = useCallback((event) => {
        const newAgreementsState = process(
            agreements.map((agreement) => ({
                ...agreement,
            })),
            event.dataState
        );
        setAgreementsGridState(event.dataState);
        setAgreementsState(newAgreementsState);
    }, [agreements]);

    // apply disabled styling when ea is expired
    const rowRender = (trElement, props) => {
        let expired = false;
        if (props.dataItem.new_next_remix_date !== null) {
            expired = props.dataItem.new_next_remix_date.toLowerCase() === 'expired';
        }
        const expiredStyle = {
            color: '#AAAAAA',
        };
        const trProps = {
            style: expired ? expiredStyle : {},
        };
        return React.cloneElement(
            trElement,
            {...trProps},
            trElement.props.children
        );
    };

    // convert date/string to into KSM date format (e.g. March 9, 2024)
    const convertDate = (date) => {
        if (date === null || date === undefined || date === '' || date.toString() === "Invalid Date") {
            return null;
        } else if (date.toString().toLowerCase() === "expired") {
            let expiredString = date.toLowerCase();
            return expiredString.charAt(0).toUpperCase() + expiredString.slice(1);
        }

        date = new Date(date);

        const options = {month: 'long', day: 'numeric', year: 'numeric'};
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    // formatted date cell with non-date col data
    const DateCell = (props) => {
        const field = props.field;
        return (
            <td>
                {convertDate(props.dataItem[field])}
            </td>
        );
    };

    const getAgreements = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        setIsLoading(true);
        axios.get(
            config.ea_summary.GET_ENTERPRISE_AGREEMENTS,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    data.forEach((agreement, index) => {
                        // sets date correctly for filtering
                        if (agreement.start_date !== null) {
                            agreement.start_date = new Date(agreement.start_date.replace("T", " ").replace(/-/g, "/"));
                        }
                        if (agreement.end_date !== null) {
                            agreement.end_date = new Date(agreement.end_date.replace("T", " ").replace(/-/g, "/"));
                        }
                        // prepare next remix date filter and conditional render via new key
                        agreement.new_next_remix_date = agreement.next_remix_date
                        if ((agreement.next_remix_date !== null) && (agreement.next_remix_date.toLowerCase() !== "expired")) {
                            agreement.next_remix_date = new Date(agreement.next_remix_date);
                        }

                        if (expiredAgreementsFlag) {
                            if (agreement.new_next_remix_date !== null) {
                                if (agreement.new_next_remix_date.toLowerCase() !== "expired") {
                                    setExpiredAgreementsFlag(false);
                                }
                            } else {
                                setExpiredAgreementsFlag(false);
                            }
                        }

                        agreement.remix_type = capitalizeSentence( agreement.remix_type)

                    });
                    // sort the data by product number and unique id
                    let orderByAgreement = orderBy(data, [{
                        field: "company_name",
                        dir: "asc"
                    }]);
                    // handle initial load/async data states for agreements
                    setAgreements(orderByAgreement);
                    const newAgreementsState = process(
                        orderByAgreement.map((agreement) => ({
                            ...agreement,
                        })),
                        initialGridState
                    );
                    setAgreementsState(newAgreementsState);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Data", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getAgreements();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [alertJSON, setAlertJSON] = useState({
        eaID: '',
        modalType: '',
        show: 1,
        period: ''
    })
    const [showAlertModal, setShowAlertModal] = useState(false)

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <div
                    className={"k-h3"}
                >
                    {localization.toLanguageString(enterpriseLicensingKey, mainMessages[siteLanguageDefault][enterpriseLicensingKey])}
                </div>
                <div
                    className={"k-h5"}
                >
                    {localization.toLanguageString(myEnterpriseAgreementsKey, mainMessages[siteLanguageDefault][myEnterpriseAgreementsKey])}
                </div>
                {!isLoading && expiredAgreementsFlag && (
                    <div
                        style={{
                            color: "var(--color-alert-danger-base)",
                            fontWeight: 600,
                            marginBottom: "0.5rem",
                        }}
                    >
                        {localization.toLanguageString(noActiveAgreementsKey, mainMessages[siteLanguageDefault][noActiveAgreementsKey])}
                    </div>
                )}
                <div
                    style={{
                        "marginBottom": "1.25em"
                    }}
                >
                    <Grid
                        className={'my-ea-grid'}
                        scrollable={"none"}
                        sortable={true}
                        pageable={(agreements.length <= 25) ? false : {
                            buttonCount: 5, pageSizes: [10, 25, 50, 100],
                        }}
                        total={agreementsState.total}
                        data={agreementsState}
                        onDataStateChange={onAgreementsStateChange}
                        sort={[{
                            field: "company_name",
                            dir: "asc",
                        }]}
                        rowRender={rowRender}
                        {...agreementsGridState}
                    >
                        <GridNoRecords>
                            {localization.toLanguageString(noDataAvailableKey, mainMessages[siteLanguageDefault][noDataAvailableKey])}
                        </GridNoRecords>
                        <GridColumn
                            cell={(props) =>
                                <HamburgerMenuCell
                                    localization={localization}
                                    siteLanguageDefault={siteLanguageDefault}
                                    setIsLoading={setIsLoading}
                                    accessToken={accessToken}
                                    setAlertJSON={setAlertJSON}
                                    setShowAlertModal={setShowAlertModal}
                                    {...props}
                                />
                            }
                            width={"1.25rem"}
                        />
                        <GridColumn
                            field="company_name"
                            title={localization.toLanguageString(enterpriseAgreementKey, mainMessages[siteLanguageDefault][enterpriseAgreementKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="start_date"
                            title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                            cell={DateCell}
                            filter={'date'}
                            columnMenu={ColumnMenu}
                            editable={false}
                        />
                        <GridColumn
                            field="end_date"
                            title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                            cell={DateCell}
                            filter={'date'}
                            columnMenu={ColumnMenu}
                            editable={false}
                        />
                        <GridColumn
                            field="remix_type"
                            title={localization.toLanguageString(remixTypeKey, mainMessages[siteLanguageDefault][remixTypeKey])}
                            columnMenu={ColumnMenu}
                            cell={NoWrapCell}
                        />
                        <GridColumn
                            field="total_sub_pool"
                            title={localization.toLanguageString(totalPoolKey, mainMessages[siteLanguageDefault][totalPoolKey])}
                            columnMenu={ColumnMenu}
                            cell={CurrencyCell}
                            filter={"numeric"}
                        />
                        <GridColumn
                            field="remain_sub_pool"
                            title={localization.toLanguageString(remainingSubPoolKey, mainMessages[siteLanguageDefault][remainingSubPoolKey])}
                            columnMenu={ColumnMenu}
                            cell={CurrencyCell}
                            filter={"numeric"}
                        />
                        <GridColumn
                            field="next_remix_date"
                            title={localization.toLanguageString(remixOpensOnKey, mainMessages[siteLanguageDefault][remixOpensOnKey])}
                            cell={DateCell}
                            filter={'date'}
                            columnMenu={ColumnMenu}
                            editable={false}
                        />
                    </Grid>
                </div>
            </div>
            {showAlertModal && (
                <CopyProductsAlertModal
                    siteLanguageDefault={siteLanguageDefault}
                    localization={localization}
                    showProductsType={"currentPeriodCopyProductsAndHosts"}
                    setShowAlertModal={setShowAlertModal}
                    alertJSON={alertJSON}
                />
            )}
        </>
    );
}


export default EASummary;

