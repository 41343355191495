/*
 * getScalingFactor() gets ea scaling factor
 * @param {days} the difference of days between the period end date and start date
*/
export default function getScalingFactor(scalingFactor, days) {
    // sort scaling factors by days in ascending order
    let factors = scalingFactor.sort((a, b) => (a.days > b.days) ? 1 : -1);

    if (factors.length < 1) {
        return 1;
    } else if (factors.length === 1) {
        return factors[0].scaling_factor;
    }

    for (let i = 0; i < factors.length; i++) {
        let prev = factors[i - 1];
        let curr = factors[i];
        let next = factors[i + 1];

        if (prev === undefined) {
            // case: handle first element in scaling factors
            if (days <= curr.days) {
                return curr.scaling_factor;
            } else if (curr.days < days && days <= next.days) {
                return next.scaling_factor;
            }
        } else if (prev !== undefined && next !== undefined) {
            // case: general case
            if (days <= prev.days) {
                return prev.scaling_factor;
            } else if (prev.days < days && days <= curr.days) {
                return curr.scaling_factor;
            } else if (curr.days < days && days <= next.days) {
                return next.scaling_factor;
            }
        } else if (next === undefined) {
            // case: handle last element in scaling factors
            if (days <= prev.days) {
                return prev.sacling_factor
            } else if (prev.days < days && days <= curr.days) {
                return curr.scaling_factor;
            } else if (days > curr.days) {
                return curr.scaling_factor;
            }
        }
    }
}