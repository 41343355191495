import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/RequestLicense.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../components/common/Spinner.js';
import SoftwareGrid from '../components/SoftwareUpdate/SoftwareGrid.js'
import PageHeader from '../components/common/PageHeader.js';
import SearchField from '../components/SoftwareUpdate/SearchField.js';

// multilingual
import {Text} from '../components/common/MultilingualText.js';
import {mainMessages, softwareUpdatesKey,} from '../assets/text/MultilingualText.js';


function SoftwareUpdate(props) {
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [submit, handleSubmit] = useState("");
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        async function getSoftwareUpdates() {
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };

            setIsLoading(true);
            let url = (query === "") ? `${config.software_updates.SOFTWARE_SERVICE}?module=update` : `${config.software_updates.SOFTWARE_SERVICE}?module=update&filter=${query.trim()}`

            axios.get(
                url,
                {headers: headers}
            )
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data || [];
                        let family_tree = {};
                        data.forEach((item, index) => {
                            family_tree[item.PRODUCT_FAMILY] = (family_tree.hasOwnProperty(item.PRODUCT_FAMILY)) ? [...family_tree[item.PRODUCT_FAMILY], {...item}] : [{...item}];
                        });

                        let combined = [];

                        for (const [key, value] of Object.entries(family_tree)) {
                            if (key !== null) {
                                let details = []
                                value.forEach(update => {
                                    details.push({
                                        "SoftwareFamily": key,
                                        "Update": {
                                            "text": update['DESCRIPTION'],
                                            "link": `/software-updates-detail?uuid=${update['UPDATE_TABLE_ID']}`
                                        },
                                        "ReleaseDate": new Date(update['ORIGINAL_RELEASE_DATE'].replace(/-/g, "/"))
                                    })
                                });
                                combined.push({product_family: key, details: details, expanded: false});
                            }
                        }

                        setUpdates(combined);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("ERROR: Failed to GET Software Family data", error);
                    setIsLoading(false);
                });
        }

        getSoftwareUpdates();
    }, [submit]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <div className={"k-h3"}>
                    <Text
                        textkey={softwareUpdatesKey}
                        textdefault={mainMessages[siteLanguageDefault][softwareUpdatesKey]}
                    />
                </div>
                <SearchField
                    query={query}
                    setQuery={setQuery}
                    handleSubmit={handleSubmit}
                />
                {(isLoading) ? "" : <SoftwareGrid updates={updates} setUpdates={setUpdates}/>}
                <br/>
            </div>
        </>
    );
}

export default SoftwareUpdate;
