import React, {useContext, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {EmailDialog} from '../common/EmailModal.js';
import Alert from '../common/Alert.js'
import {DeactivateGrid} from './RequestLicense/Grid.js';
import {downloadLicenses} from '../common/utilities.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Input} from '@progress/kendo-react-inputs';

// multiLingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    confirmConfirmationCodeKey,
    incorrectConfirmationCodeKey,
    confirmationCodeWarningKey,
    confirmCodeRequiredKey,
    confirmKey,
    deactivateMoreProductsKey,
    reassignDeactivateKey,
    deactivationCompleteKey,
    downloadAllLicensesKey,
    mailAllKey,
    enterDeactiviationCodeKey,
    hostIdKey,
    mainMessages,
    genericErrorContactUsKey,
    deactivationFailedKey,
    emailReceiveKey,
    emailSuccessKey,
} from '../../assets/text/MultilingualText.js';
import {Tooltip} from "@progress/kendo-react-tooltip";
import {DownloadIcon, IconButton, MailIcon} from "../common/icons";

function DownloadConfirm(props) {
    const {
        setIsLoading,
        changeStepActivation,
        hostID,
        deactivateProducts,
        transactionID,
        handleBeforeUnload
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [showDeactivateMore, setShowDeactivateMore] = useState(false);
    const [showConfirmationWarming, setShowConfirmationWarning] = useState(true);
    const [showConfirmationError, setShowConfirmationError] = useState(false);
    const [showGeneralError, setShowGeneralError] = useState(false);
    const [showEmailEmailModal, setShowEmailModal] = useState(false);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    //Closes the success email alert
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    const onChangeConfirmationCode = (e) => {
        setConfirmationCode(e.value);
    }

    const closeConfirmationWarning = () => {
        setShowConfirmationWarning(false);
    }

    const closeConfirmationError = () => {
        setShowConfirmationError(false);
    }

    //Validate confirmation code
    async function confirmDeactivation() {
        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let data = {
            "host_id": hostID,
            "code": confirmationCode.trim()
        }
        setIsLoading(true);

        axios.post(
            config.deactivate_license.VALIDATE_CODE,
            data,
            {
                headers: headers,
            }
        )
            .then((response) => {
                setShowDeactivateMore(true);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("ERROR: Failed to validation confirmation code", error.response);
                let errorSet = false;
                if (error.response.hasOwnProperty('data')) {
                    if (error.response.data.hasOwnProperty('error_code')) {
                        if (error.response.data['error_code'].toUpperCase() === 'MOD_DEACT_225') {
                            setShowGeneralError(false);
                            setShowConfirmationError(true);
                            errorSet = true;
                        }
                    }
                }
                if (!errorSet) {
                    setShowGeneralError(true);
                    setShowConfirmationError(false);
                }
                setIsLoading(false);
            });

    }

    return (
        <>
            <div className={"k-h5"}>
                {localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])} {hostID}
            </div>
            <DeactivateGrid deactivateProducts={deactivateProducts}/>
            {!showDeactivateMore && (
                <Row>
                    <Col>
                        {localization.toLanguageString(enterDeactiviationCodeKey, mainMessages[siteLanguageDefault][enterDeactiviationCodeKey])}
                        <br/>
                        <Input
                            value={confirmationCode}
                            onChange={onChangeConfirmationCode}
                            style={{
                                width: '26.188rem',
                                marginBottom: '0.938rem'
                            }}
                        />
                        <br/>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"solid"}
                            type={"button"}
                            onClick={confirmDeactivation}
                            style={{
                                width: '26.188rem',
                            }}
                        >
                            {localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey])}
                        </Button>
                    </Col>
                    <Col
                        style={{
                            width: '42.688rem',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.938rem'
                        }}
                    >
                        {showConfirmationWarming && (
                            <Alert
                                type={"warning"}
                                showHandler={closeConfirmationWarning}
                                title={localization.toLanguageString(confirmCodeRequiredKey, mainMessages[siteLanguageDefault][confirmCodeRequiredKey])}
                                message={<div
                                    style={{
                                        whiteSpace: 'pre-line'
                                    }}
                                >
                                    {localization.toLanguageString(confirmationCodeWarningKey, mainMessages[siteLanguageDefault][confirmationCodeWarningKey])}
                                </div>}
                            />
                        )}
                        {showEmailSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={alertEmailSuccessHandler}
                                title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                            />
                        )}
                        {showConfirmationError && (
                            <Alert
                                type={"error"}
                                showHandler={closeConfirmationError}
                                title={localization.toLanguageString(incorrectConfirmationCodeKey, mainMessages[siteLanguageDefault][incorrectConfirmationCodeKey])}
                                message={localization.toLanguageString(confirmConfirmationCodeKey, mainMessages[siteLanguageDefault][confirmConfirmationCodeKey])}
                            />
                        )}
                        {showGeneralError && (
                            <Alert
                                type={"error"}
                                showHandler={closeConfirmationError}
                                title={localization.toLanguageString(deactivationFailedKey, mainMessages[siteLanguageDefault][deactivationFailedKey])}
                                message={localization.toLanguageString(genericErrorContactUsKey, mainMessages[siteLanguageDefault][genericErrorContactUsKey])}
                            />
                        )}
                    </Col>
                    <Col>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '0.5rem',
                            float: 'right',
                        }}>
                            <Tooltip
                                anchorElement="target"
                                showCallout={true}
                                parentTitle={true}
                                openDelay={0}
                                position="left"
                            >
                                <IconButton
                                    title={localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
                                    themeColor={"primary"}
                                    fillMode={"solid"}
                                    size={"large"}
                                    onClick={() => {
                                        setShowEmailModal(true)
                                    }}
                                    Icon={MailIcon}
                                />
                            </Tooltip>

                            <Tooltip
                                anchorElement="target"
                                showCallout={true}
                                parentTitle={true}
                                openDelay={0}
                                position="left"
                            >
                                <IconButton
                                    title={localization.toLanguageString(downloadAllLicensesKey, mainMessages[siteLanguageDefault][downloadAllLicensesKey])}
                                    themeColor={"primary"}
                                    fillMode={"solid"}
                                    size={"large"}
                                    onClick={() => {
                                        downloadLicenses([transactionID], accessToken, setIsLoading, handleBeforeUnload, 'DEACTIVATE_LICENSE')
                                    }}
                                    Icon={DownloadIcon}
                                />
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
            )}
            {showDeactivateMore && (
                <Row>
                    <Col>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"outline"}
                            type={"button"}
                            onClick={() => {
                                changeStepActivation(0)
                            }}
                        >
                            {localization.toLanguageString(deactivateMoreProductsKey, mainMessages[siteLanguageDefault][deactivateMoreProductsKey])}
                        </Button>
                    </Col>
                    <Col>
                        <Alert
                            type={'success'}
                            title={localization.toLanguageString(deactivationCompleteKey, mainMessages[siteLanguageDefault][deactivationCompleteKey])}
                            message={localization.toLanguageString(reassignDeactivateKey, mainMessages[siteLanguageDefault][reassignDeactivateKey])}
                        />
                    </Col>
                    <Col/>
                </Row>

            )}
            {showEmailEmailModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailModal}
                    setShowEmailSuccess={setShowEmailSuccess}
                    transactionIDs={[transactionID]}
                    setIsLoading={setIsLoading}
                    request_source='DEACTIVATE_LICENSE'
                />
            )}
        </>
    );
}

export default DownloadConfirm;