import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// kendo react

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cloudLicenseMessageKey,
    cloudLicenseKey,
    mainMessages,
    notGeneratedAvailableRedeemKey,
    errorOccurredRequestKey,
    redeemNotAvailableErrorKey,
    cannotGenerateMessageKey,
    cannotGenerateKey,
    notGeneratedSupportAgentKey,
    generationTimeoutKey
} from '../../../assets/text/MultilingualText.js';
import KSMPopover from "../../common/Popovers";


export const LicenseDownloadPopover = (props) => {
    const {
        type,
        showPopover,
        popoverRef
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();
    let title;
    let message;
    let popoverStyle = {
        maxWidth: '35rem'
    };
    let popoverType;

    switch (type) {
        case 'cloudAlert':
            popoverType = 'info'
            title = localization.toLanguageString(
                cloudLicenseKey,
                mainMessages[siteLanguageDefault][cloudLicenseKey]
            );
            message = localization.toLanguageString(
                cloudLicenseMessageKey,
                mainMessages[siteLanguageDefault][cloudLicenseMessageKey]
            );
            break;
        case 'supportError':
            popoverType = 'error'
            title = localization.toLanguageString(
                generationTimeoutKey,
                mainMessages[siteLanguageDefault][generationTimeoutKey]
            );
            message = localization.toLanguageString(
                notGeneratedSupportAgentKey,
                mainMessages[siteLanguageDefault][notGeneratedSupportAgentKey]
            );
            break;
        case 'generationError':
            popoverType = 'error'
            title = localization.toLanguageString(
                errorOccurredRequestKey,
                mainMessages[siteLanguageDefault][errorOccurredRequestKey]
            );
            message = localization.toLanguageString(
                redeemNotAvailableErrorKey,
                mainMessages[siteLanguageDefault][redeemNotAvailableErrorKey]
            );
            break;
        case 'qtyError':
            popoverType = 'error'
            title = localization.toLanguageString(
                cannotGenerateKey,
                mainMessages[siteLanguageDefault][cannotGenerateKey]
            );
            message = localization.toLanguageString(
                cannotGenerateMessageKey,
                mainMessages[siteLanguageDefault][cannotGenerateMessageKey]
            );
            break;
        default:
            popoverType = 'error'
            title = localization.toLanguageString(
                errorOccurredRequestKey,
                mainMessages[siteLanguageDefault][errorOccurredRequestKey]
            );
            message = localization.toLanguageString(
                notGeneratedAvailableRedeemKey,
                mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey]
            );
    }

    return (
        <KSMPopover
            show={showPopover}
            anchor={popoverRef && popoverRef.current}
            position={'left'}
            style={popoverStyle}
            type={popoverType}
            message={<div>
                <b>{title}</b>
                <br/>
                {message}
            </div>}
        />
    )
}
