import React, {cloneElement} from 'react';

// utilities
import {capitalizeSentence, formatCurrency} from './utilities.js';

// kendo react data grid custom column filter
import {GridColumnMenuFilter, GridColumnMenuSort} from "@progress/kendo-react-grid";

// kendo react custom column menu
export const ColumnMenu = props => {
    return <div className={"ksm-column-menu"}>
        <GridColumnMenuSort {...props} />
        <GridColumnMenuFilter
            {...props}
            hideSecondFilter={true}
            expanded={true}
        />
    </div>;
};

//Kendo react custom column menu with sort only
export const ColumnSortOnlyMenu = props => {
    return <div className={"ksm-column-menu"}>
        <GridColumnMenuSort {...props} />
    </div>;
};

// kendo react custom disable row render
export const DisableRowRender = (tr, props) => {
    if (props.dataItem.disabled) {
        return cloneElement(
            tr,
            {...tr.props, className: 'k-state-disabled', style: {pointerEvents: 'auto', opacity: '.7'}},
            tr.props.children
        );
    }
    return tr
};

// text align middle cell
export const TextAlignMiddleCell = (props) => {
    const field = props.field;
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        >
            {props.dataItem[field]}
        </td>
    );
}

// Capitalize the first letter of every word cell
export const CapitalLettersCell = (props) => {
    const field = props.field;
    return (
        <td className={'capital-letters'}>
            {props.dataItem[field]}
        </td>
    );
}

// convert date/string to into KSM date format (e.g. March 9, 2024)
export const convertDateFormat = (date) => {
    if (date === null || date === undefined || date === '' || date.toString() === "Invalid Date") {
        return null;
    }

    date = new Date(date);

    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

// formatted date cell
export const DateCell = (props) => {
    const field = props.field;
    return (
        <td>
            {convertDateFormat(props.dataItem[field])}
        </td>
    );
}

// formatted date cell that turns text red if expired
export const DateIsExpiredCell = (props) => {
    const field = props.field;
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return (
        (new Date(convertDateFormat(currentDate)) <= new Date(convertDateFormat(props.dataItem[field]))) ?
            <td>
                {convertDateFormat(props.dataItem[field])}
            </td>
            :
            <td
                style={{
                    color: "var(--color-alert-danger-base)",
                }}
            >
                {convertDateFormat(props.dataItem[field])}
            </td>
    );
}

// whitespace no wrap for a cell (noticed prod nums get wrapped on spec. chars. -+)
export const NoWrapCell = (props) => {
    return (
        <td
            style={{
                whiteSpace: "nowrap",
            }}
        >
            {props.dataItem[props.field]}
        </td>
    )
}

// TextCapitalizeCell :  convert any text to Capitalized text Ex : KEYSIGHT -> Keysight
export const TextCapitalizeCell = (props) => {
    return (
        <td
            style={{
                whiteSpace: "nowrap",
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        >
            {capitalizeSentence(props.dataItem[props.field] || '')}
        </td>
    )
}

export const CurrencyCell = (props) => {
    return (
        <td style={{
            textAlign: 'right',
        }}>
            {formatCurrency(props.dataItem[props.field],
                props.dataItem['currency_locale'], props.dataItem['currency_code'])}
        </td>
    )
}