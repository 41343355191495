import React, {cloneElement, useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// css
import '../../../assets/css/RenewStandalone.css';

// components
import {ColumnMenu, DateCell, NoWrapCell} from '../../common/Grid.js';
import {EndDateCell} from './EndDateCell.js';

// kendo react
import {getSelectedState, Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy, process} from '@progress/kendo-data-query';
import {getter} from '@progress/kendo-react-common';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    certNumberKey,
    currentSupportEndDate,
    hostIdKey,
    newSupportEndDateKey,
    orderNumberKey,
    mainMessages
} from '../../../assets/text/MultilingualText.js';


// consts for standalone subscriptions licenses table
const RENEWAL_LICENSE_DATA_ITEM_KEY = 'subscription_id';
const SELECTED_FIELD = 'selected';
const renewalLicenseIdGetter = getter(RENEWAL_LICENSE_DATA_ITEM_KEY);
const initialGridState = {};
const initialSort = [
    {
        field: "host_id",
        dir: "desc"
    },
];

function SelectLicensesGrid(props) {
    const {
        index,
        renewalLicenses,
        setRenewalLicenses,
        initialQty,
        partials,
        setPartials,
        gridNoRecordsMessage,
        showError
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const licenses = renewalLicenses[index].licenses || [];

    const [sort, setSort] = useState(initialSort);
    const [renewalLicenseSelectedState, setRenewalLicenseSelectedState] = useState({});
    const [renewalLicenseGridState, setRenewalLicenseGridState] = useState(initialGridState);
    const [renewalLicenseState, setRenewalLicenseState] = useState(
        process(
            licenses.map((license) => ({
                ...license,
                'selected': renewalLicenseSelectedState[renewalLicenseIdGetter(license)],
            })),
            initialGridState
        )
    );

    /*
     * onRenewalLicenseSelectionChange(event) sets the renewal grid state on grid's selection change
     * @param {event} the grid selection change event
    */
    const onRenewalLicenseSelectionChange = useCallback((event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: renewalLicenseSelectedState,
            dataItemKey: RENEWAL_LICENSE_DATA_ITEM_KEY,
        });

        // remove partial on selection removing red outline of checkbox
        let updates = partials.filter(partial =>
            partial.subscription_id && Object.values(newSelectedState).includes(partial.subscription_id)
        );
        setPartials(updates);

        let selected = [];
        // get previously selected licenses from renewalLicenses
        renewalLicenses.forEach(renewal => renewal.licenses.forEach(license => {
            if (license.selected) {
                selected.push(license.subscription_id)
            }
        }));
        // get licenses that will be selected from newSelectedState
        let newSelected = [];
        newSelected = Object.keys(newSelectedState).filter(key => newSelectedState[key]);

        // get ALL selected licenses
        newSelected.forEach(select => {
            if (!selected.includes(parseInt(select))) {
                selected.push(parseInt(select));
            }
        });

        // do not check if count of selected state exceeds initial qty
        if (selected.length <= initialQty) {
            setRenewalLicenseSelectedState(newSelectedState);
        } else {
            showError("noSubscription", initialQty, undefined);
        }
    }, [renewalLicenseSelectedState]); // eslint-disable-line react-hooks/exhaustive-deps

    /*
     * onRenewalLicenseStateChange(event) sets the renewal grid state and renewal state on grid's state change
     * @param {event} the grid selection change event
    */
    const onRenewalLicenseStateChange = useCallback((event: GridDataStateChangeEvent) => {
        const newRenewalState = process(
            licenses.map((license) => ({
                ...license,
                'selected': renewalLicenseSelectedState[renewalLicenseIdGetter(license)],
            })),
            event.dataState
        );
        setRenewalLicenseGridState(event.dataState);
        setRenewalLicenseState(newRenewalState);
    }, [licenses]); // eslint-disable-line react-hooks/exhaustive-deps

    const rowRender = (tr, props) => {
        let hostId = props.dataItem.host_id;
        let selected = props.dataItem.selected;

        // filter partial host ids for each accordion's grid
        let hostIds = partials
            .filter(partial => partial.renewal_index === index)
            .map(partial => partial.host_id);

        // outline unselected partial licenses' checkbox in red if all partials are not selected
        if (hostIds.includes(hostId) && !selected) {
            return cloneElement(
                tr,
                {...tr.props, className: 'partial'},
                tr.props.children
            );
        }

        return tr
    };

    // initialize my renewal licenses table
    useEffect(() => {
        if (licenses.length) {
            setRenewalLicenseState(
                process(
                    licenses.map((license) => ({
                        ...license,
                        'selected': renewalLicenseSelectedState[renewalLicenseIdGetter(license)],
                    })),
                    initialGridState
                )
            );
        }
    }, [licenses]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // set selected flag of renewal licenses if license is selected
        let selected = Object.keys(renewalLicenseSelectedState).filter((license) => renewalLicenseSelectedState[license]);
        let unselected = Object.keys(renewalLicenseSelectedState).filter((license) => !renewalLicenseSelectedState[license]);

        selected.forEach((id) => {
            let updates = [...renewalLicenses];
            updates[index].licenses.forEach((license) => {
                if (license.subscription_id === parseInt(id)) {
                    license.selected = true;
                }
            });
            setRenewalLicenses(updates);
        });

        unselected.forEach((id) => {
            let updates = [...renewalLicenses];
            updates[index].licenses.forEach((license) => {
                if (license.subscription_id === parseInt(id)) {
                    license.selected = false;
                }
            });
            setRenewalLicenses(updates);
        });
    }, [renewalLicenseSelectedState]); // eslint-disable-line react-hooks/exhaustive-deps

    // handle sort and column filter
    useEffect(() => {
        setRenewalLicenseGridState({...renewalLicenseGridState, sort: sort});
    }, [sort]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                className={"renew-standalone-license-grid"}
                scrollable={"none"}
                sortable={true}
                sort={sort}
                rowSelection={true}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: false,
                    drag: false,
                    cell: false,
                    mode: 'multiple'
                }}
                data={orderBy(renewalLicenseState.data.map((license) => ({
                    ...license,
                    [SELECTED_FIELD]: renewalLicenseSelectedState[renewalLicenseIdGetter(license)],
                })), sort)}
                onDataStateChange={onRenewalLicenseStateChange}
                onSelectionChange={onRenewalLicenseSelectionChange}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}
                rowRender={rowRender}
                {...renewalLicenseGridState}
            >
                <GridNoRecords>
                    {gridNoRecordsMessage}
                </GridNoRecords>
                <GridColumn
                    field={SELECTED_FIELD}
                    width="40px"
                    editable={false}
                />
                <GridColumn
                    field="host_id"
                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="order_number"
                    title={localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="certificate_number"
                    title={localization.toLanguageString(certNumberKey, mainMessages[siteLanguageDefault][certNumberKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="current_support_end_date_display"
                    title={localization.toLanguageString(currentSupportEndDate, mainMessages[siteLanguageDefault][currentSupportEndDate])}
                    filter={'date'}
                    cell={DateCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="new_support_end_date"
                    title={localization.toLanguageString(newSupportEndDateKey, mainMessages[siteLanguageDefault][newSupportEndDateKey])}
                    sortable={false}
                    cell={EndDateCell}
                />
            </Grid>
        </>
    );
}

export default SelectLicensesGrid;
