import React, {useCallback, useContext, useEffect, useState} from 'react';
import axios from "axios";
import config from "../../config";

// kendo
import {process} from "@progress/kendo-data-query";
import {Button} from "@progress/kendo-react-buttons";
import {Dialog} from "@progress/kendo-react-dialogs";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {IconsContext} from '@progress/kendo-react-common';

// components
import UserContext from '../../components/common/UserContext.js';
import {EmailDialog} from "./Checkin/EmailModal";
import Alert from "../common/Alert";
import {ColumnMenu, DateCell, NoWrapCell} from "../common/Grid";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    companyAssetInfoKey,
    checkoutDateKey,
    descriptionKey,
    expirationDateKey,
    contactUsHeaderKey,
    genericErrorTitleKey,
    hostIdKey,
    checkInKey,
    productNumberKey,
    reviewCheckInLicensesKey,
    transactionKey,
    checkInMoreLicensesKey,
    confirmationEmailSentKey,
    emailConfirmationKey,
    areYouSureKey,
    undoneCheckInKey,
    cancelKey,
    confirmKey,
    successExclamationKey,
    checkInSuccessKey,
} from "../../assets/text/MultilingualText";

//States and functions for pagination
const initialGridState = {
    take: 10, skip: 0,
};


function Checkin(props) {
    const {
        siteLanguageDefault,
        accessToken,
        timeout
    } = useContext(UserContext);
    const {
        setIsLoading,
        changeStepActivation,
        hostLicenses,
        setHostLicenses,
        stepperItems,
        setStepperItems
    } = props;

    const [genericError, setGenericError] = useState(false);
    const localization = useLocalization();

    // grid data
    const [checkInLicenses, setCheckInLicenses] = useState([]);
    // license ids for api call
    const [licenseIds, setLicenseIds] = useState([]);

    // states controlling page flow
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [showCheckInSuccess, setShowCheckInSuccess] = useState(false);
    const alertCheckInSuccessHandler = () => {
        setShowCheckInSuccess(false);
    }
    const [enableEmailSend, setEnableEmailSend] = useState(false);
    const [showEmailSendModal, setShowEmailSendModal] = useState(false);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    // on load, break down hostLicenses into list of selected licenses
    useEffect(() => {
        let selectedLicenses = [];
        let licenses = []
        hostLicenses.forEach((host) => {
            host.licenses.filter((license) => license.selected).forEach((license) => {
                selectedLicenses.push({
                    host_id: host.host_id,
                    asset: host.asset,
                    ...license
                })
                licenses.push(license.license_id)
            })
        })
        setLicenseIds(licenses);
        setCheckInLicenses(selectedLicenses);
    }, [])

    const [gridState, setGridState] = useState(initialGridState);
    const [dataState, setDataState] = useState(
        process(checkInLicenses
            .map((item) => ({
                ...item,
            })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            checkInLicenses
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [checkInLicenses]);

    useEffect(() => {
        const newDataState = process(
            checkInLicenses
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [checkInLicenses]) // eslint-disable-line react-hooks/exhaustive-deps

    /*
     * checkIn() calls checkin api to check in licenses
    */
    const checkIn = () => {
        setIsLoading(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let payload = {
            "module": "SOFTWARE/ACCESS",
            "sub_module": "CHECKIN",
            "request_from": "",
            "action": "SAVE",
            "input_json": {
                "license_ids": licenseIds,
                "send_email": "N"
            }
        }

        axios.post(
            config.software_access.SOFTWARE_ACCESS,
            payload,
            {
                headers: headers, timeout: timeout
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    setEnableEmailSend(true);
                    setShowCheckInSuccess(true);

                    // disable step 2 of stepper
                    let newStepperItems = stepperItems
                    newStepperItems[1].disabled = true;
                    setStepperItems(newStepperItems)
                }
                setShowAreYouSureModal(false);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Host Type Details", error);
                setShowAreYouSureModal(false);
                setGenericError(true);
                setIsLoading(false);
            });
    }

    return (
        <>
            <IconsContext.Provider value={{type: 'font'}}>
                <div>
                    <div className={"k-h5"}>
                        {localization.toLanguageString(checkInKey, mainMessages[siteLanguageDefault][checkInKey])}
                    </div>
                    <div className={"k-header-desc k-mb-4"}>
                        {localization.toLanguageString(reviewCheckInLicensesKey, mainMessages[siteLanguageDefault][reviewCheckInLicensesKey])}
                    </div>
                    <Grid
                        className={"sa-checkin-review-grid"}
                        scrollable={'none'}
                        data={dataState}
                        sortable={true}
                        //pagination
                        pageable={(checkInLicenses.length <= initialGridState.take) ? false : {
                            buttonCount: 5,
                            pageSizes: [10, 20, 50, 100],
                        }}
                        onDataStateChange={onDataStateChange}
                        total={dataState.total}
                        {...gridState}
                    >
                        <GridColumn
                            field="host_id"
                            title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                            columnMenu={ColumnMenu}
                            cell={NoWrapCell}
                        />
                        <GridColumn
                            field="asset"
                            title={localization.toLanguageString(companyAssetInfoKey, mainMessages[siteLanguageDefault][companyAssetInfoKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="product_number"
                            title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="product_desc"
                            title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="transaction_id"
                            title={localization.toLanguageString(transactionKey, mainMessages[siteLanguageDefault][transactionKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="expiration_date"
                            title={localization.toLanguageString(expirationDateKey, mainMessages[siteLanguageDefault][expirationDateKey])}
                            columnMenu={ColumnMenu}
                            filter={'date'}
                            cell={DateCell}
                        />
                        <GridColumn
                            field="checkout_date"
                            title={localization.toLanguageString(checkoutDateKey, mainMessages[siteLanguageDefault][checkoutDateKey])}
                            columnMenu={ColumnMenu}
                            filter={'date'}
                            cell={DateCell}
                        />
                    </Grid>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '0.5rem',
                        }}
                    >
                        {enableEmailSend ?
                            <div>
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    fillMode={"outline"}
                                    type={"button"}
                                    onClick={() => {
                                        changeStepActivation(0)
                                    }}
                                >
                                    {localization.toLanguageString(checkInMoreLicensesKey, mainMessages[siteLanguageDefault][checkInMoreLicensesKey])}
                                </Button>
                            </div>
                            :
                            <div></div>
                        }
                        <div className={'k-display-flex'}>
                            {genericError ?
                                <div>
                                    <Alert
                                        type={'error'}
                                        title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                        message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                    />
                                </div>
                                :
                                <div></div>
                            }
                            {showCheckInSuccess && !showEmailSuccess && (
                                <Alert
                                    type={'success'}
                                    showHandler={alertCheckInSuccessHandler}
                                    title={localization.toLanguageString(successExclamationKey, mainMessages[siteLanguageDefault][successExclamationKey])}
                                    message={localization.toLanguageString(checkInSuccessKey, mainMessages[siteLanguageDefault][checkInSuccessKey])}
                                />
                            )
                            }
                            {showEmailSuccess && (
                                <Alert
                                    type={'success'}
                                    showHandler={alertEmailSuccessHandler}
                                    title={localization.toLanguageString(successExclamationKey, mainMessages[siteLanguageDefault][successExclamationKey])}
                                    message={localization.toLanguageString(confirmationEmailSentKey, mainMessages[siteLanguageDefault][confirmationEmailSentKey])}
                                />
                            )}
                        </div>
                        {enableEmailSend ?
                            <div>
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    fillMode={"solid"}
                                    type={"button"}
                                    onClick={() => {
                                        setShowEmailSendModal(true)
                                    }}
                                >
                                    <span className={'k-icon k-i-email'}
                                          style={{fontSize: '1rem'}}/> &nbsp;{localization.toLanguageString(emailConfirmationKey, mainMessages[siteLanguageDefault][emailConfirmationKey])}
                                </Button>
                            </div>
                            :
                            <div>
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    fillMode={"solid"}
                                    type={"button"}
                                    onClick={() => {
                                        setShowAreYouSureModal(true)
                                    }}
                                >
                                    {localization.toLanguageString(checkInKey, mainMessages[siteLanguageDefault][checkInKey])}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </IconsContext.Provider>

            {showAreYouSureModal && (
                <Dialog
                    className={'ksm-dialog'}
                    width={"35.938rem"}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div className={'k-h3'}>
                            {localization.toLanguageString(areYouSureKey, mainMessages[siteLanguageDefault][areYouSureKey])}
                        </div>
                        <div className={'k-mb-4'}>
                            {localization.toLanguageString(undoneCheckInKey, mainMessages[siteLanguageDefault][undoneCheckInKey])}
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'end',
                            gap: '0.5rem'
                        }}>
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"outline"}
                                rounded={"small"}
                                type={"button"}
                                onClick={() => {
                                    setShowAreYouSureModal(false)
                                }}
                            >
                                {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                            </Button>

                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                onClick={() => {
                                    checkIn()
                                }}
                            >
                                {localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey])}
                            </Button>
                        </div>
                    </div>
                </Dialog>
            )}
            {showEmailSendModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailSendModal}
                    setIsLoading={setIsLoading}
                    licenseIds={licenseIds}
                    setGenericError={setGenericError}
                    setShowEmailSuccess={setShowEmailSuccess}
                />
            )}
        </>
    )
}

export default Checkin;