import React, {useContext, useEffect, useRef} from "react";
import UserContext from "../../UserContext";

// reactstrap
import {Col, Row} from "reactstrap";

// components
import Alert from "../../Alert";

// kendo
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";

// multilingual
import {
    assignHostKey,
    cancelChangesKey,
    mainMessages,
    saveHostIDKey,
} from "../../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";


function DialogLayout(props) {
    const {
        closeModal,
        children,
        productTable,
        errors,
        validate
    } = props
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const alertRef = useRef()
    useEffect(() => {
        if (errors.length > 0) {
            alertRef.current.focus();
        }
    }, [errors]);

    return (
        <Dialog
            className={"assign-products-modal ksm-dialog"}
            title={<h2 style={{
                fontWeight: "normal",
                margin: 0,
                color: 'var(--color-text-primary)'
            }}>
                {localization.toLanguageString(assignHostKey, mainMessages[siteLanguageDefault][assignHostKey])}
            </h2>}
            onClose={closeModal}
            width={"64.625rem"}
        >
            <div style={{
                height: 'auto',
                minHeight: '45vh',
                maxHeight: '85vh'
            }}>
                {children}
                <Row className="mt-3">
                    <Col>
                        {productTable}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div ref={alertRef} tabIndex={0}>
                            {errors.length > 0 && (errors.map((error, index) => {
                                return (
                                    <div key={'error-' + index}>
                                        <br/>
                                        <Alert
                                            type={'error'}
                                            title={error.title}
                                            message={error.message}
                                        />
                                    </div>
                                )
                            }))}
                        </div>
                    </Col>
                </Row>
            </div>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    type={"button"}
                    onClick={closeModal}
                >
                    {localization.toLanguageString(cancelChangesKey, mainMessages[siteLanguageDefault][cancelChangesKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    type={"button"}
                    onClick={validate}
                >
                    {localization.toLanguageString(saveHostIDKey, mainMessages[siteLanguageDefault][saveHostIDKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default DialogLayout;