import React, {cloneElement, useContext, useState} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/App.css';

// components
import LinkCell from './LinkCell.js'
import {convertDateFormat} from '../common/Grid.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    descriptionKey,
    fileSizeKey,
    mainMessages,
    notesKey,
    releaseDateKey
} from '../../assets/text/MultilingualText.js';


function ExternalGrid(props) {
    const {external, setIsLoading} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let items = external;

    const TokenizedLinkCell = (props) => {
        return (
            <LinkCell setIsLoading={setIsLoading} {...props}/>
        )
    };

    const SoftwareUpdatesDescription = localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey]);
    const SoftwareUpdatesNotes = localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey]);
    const SoftwareUpdatesReleaseDate = localization.toLanguageString(releaseDateKey, mainMessages[siteLanguageDefault][releaseDateKey]);
    const SoftwareUpdatesFileSize = localization.toLanguageString(fileSizeKey, mainMessages[siteLanguageDefault][fileSizeKey]);

    const initialSort = [
        {
            field: "Description",
            dir: "asc"
        },
    ];

    const [currentSort] = useState(initialSort);

    let allItems = [];

    items.forEach((item, index) => {
        let type = ((Boolean(item['is_kit']) === true) ? "kit" : "link");
        allItems.push(
            {
                "Type": {"text": "", "link": `${item['url']}`, "type": `${type}`},
                "Description": {"text": item['description'], "link": `${item['url']}`},
                "Notes": (Boolean(item['is_kit']) === true) ? `${item?.notes ? item.notes : ""} ${item?.available_qty ? item.available_qty : 0} Available` : item['notes'],
                "ReleaseDate": convertDateFormat(typeof item['release_date'] === 'string' ? item['release_date'].replace(/-/g, "/") : item['release_date']),
                "FileSize": item['file_size'],
                "QtyAvailable": item['available_qty'],
                "UpdateItemId": item['update_item_id']
            }
        )
    });

    const rowRender = (tr, props) => {
        let extraStyle = {}
        let extraClasses = ""
        let extraProps = {}
        if (props.dataItem.Type.type === 'kit' && props.dataItem.QtyAvailable <= 0) {
            extraStyle = {'color': 'grey'};
            extraClasses = ' disabled';
            extraProps = {disabled: true, gridType: 'kit'};
        }

        let copy = tr.props.children.map((child) => {
            return cloneElement(
                child,
                {...{...child.props, ...extraProps}, ...{style: extraStyle}},
                child.props.children
            );
        });

        return cloneElement(
            tr,
            {...tr.props, ...{style: extraStyle, className: [tr.props.className + extraClasses]}},
            copy
        );
    };

    return (
        <Grid
            className={'software-updates-external-grid k-mb-4'}
            data={orderBy(allItems, currentSort)}
            rowRender={rowRender}
            scrollable={'none'}
        >
            <GridColumn
                field="Type"
                title=" "
                width="40px"
                cell={TokenizedLinkCell}
            />
            <GridColumn
                field="Description"
                title={SoftwareUpdatesDescription}
                cell={TokenizedLinkCell}
            />
            <GridColumn
                field="Notes"
                title={SoftwareUpdatesNotes}
            />
            <GridColumn
                field="ReleaseDate"
                title={SoftwareUpdatesReleaseDate}
            />
            <GridColumn
                field="FileSize"
                title={SoftwareUpdatesFileSize}
            />
        </Grid>
    );
}

export default ExternalGrid;