import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import Alert from '../../common/Alert.js';

// multilingual
import {Text} from '../../common/MultilingualText.js';
import {
    mainMessages,
    contactUsHeaderKey,
    genericErrorTitleKey,
    shipmentSuccessSubmittedKey,
    shipmentRequestReceivedKey,
    yourOrderNumberIsKey,
} from '../../../assets/text/MultilingualText.js';


export default function Messages(props) {
    const {
        showPopover,
        setShowPopover,
        orderId
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);

    const onClick = () => setShowPopover("");

    if (showPopover === "error") {
        return (
            <div
                style={{
                    width: "525px",
                    height: "101px",
                    margin: "0 auto"
                }}
            >
                <Alert
                    type={"error"}
                    showHandler={onClick}
                    title={
                        <b>
                            <Text
                                textkey={genericErrorTitleKey}
                                textdefault={mainMessages[siteLanguageDefault][genericErrorTitleKey]}
                            />
                        </b>
                    }
                    message={
                        <p>
                            <Text
                                textkey={contactUsHeaderKey}
                                textdefault={mainMessages[siteLanguageDefault][contactUsHeaderKey]}
                            />
                        </p>
                    }
                />
            </div>
        );
    } else if (showPopover === "success") {
        return (
            <div
                style={{
                    width: "525px",
                    height: "101px",
                    margin: "0 auto"
                }}
            >
                <Alert
                    type={"success"}
                    showHandler={onClick}
                    title={
                        <b>
                            <Text
                                textkey={shipmentRequestReceivedKey}
                                textdefault={mainMessages[siteLanguageDefault][shipmentRequestReceivedKey]}
                            />
                        </b>
                    }
                    message={
                        <div>
                            <p>
                                <Text
                                    textkey={shipmentSuccessSubmittedKey}
                                    textdefault={mainMessages[siteLanguageDefault][shipmentSuccessSubmittedKey]}
                                />
                            </p>
                            <p>
                                <Text
                                    textkey={yourOrderNumberIsKey}
                                    textdefault={mainMessages[siteLanguageDefault][yourOrderNumberIsKey]}
                                />
                                {orderId}
                            </p>
                        </div>
                    }
                />
            </div>
        );
    } else {
        return <></>;
    }
}

