import React, {useEffect, useState, useContext} from 'react';

// kendo react
import {DropDownButton} from '@progress/kendo-react-buttons';
import axios from "axios";
import config from "../../config";
import UserContext from "../common/UserContext";
import Spinner from "../common/Spinner";
import {useLocalization} from "@progress/kendo-react-intl";
import {
    howToEA,
    mainMessages,
    managePeriodicEa,
    manageVariableEa,
    registerLoginEa
} from "../../assets/text/MultilingualText";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {mediaManagerIcon} from "@progress/kendo-svg-icons";

function VideoHamburgerMenu(props) {
    const {} = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false)
    const [opened, setOpened] = useState(false);


    const openedStyle = {
        padding: 0,
        textAlign: "center",
    };

    const closedStyle = {
        padding: 0,
        textAlign: "center",
    };

    const anchorAlign = {
        horizontal: "left",
        vertical: "top"
    };
    const popupAlign = {
        horizontal: "right",
        vertical: "top"
    };

    const [dropdownButtonStyle, setDropdownButtonStyle] = useState(closedStyle);
    useEffect(() => {
        opened ? setDropdownButtonStyle(openedStyle) : setDropdownButtonStyle(closedStyle)
    }, [opened])

    let categories = [
        {
            text: localization.toLanguageString(registerLoginEa, mainMessages[siteLanguageDefault][registerLoginEa]),
            link: "https://download.software.keysight.com/CMP/ksmsite/external/KSM_Enterprise_Agreements.mp4",
        },
        {
            text: localization.toLanguageString(managePeriodicEa, mainMessages[siteLanguageDefault][managePeriodicEa]),
            link: "https://download.software.keysight.com/CMP/ksmsite/external/How to Manage Periodic Enterprise Agreements.mp4"
        },
        {
            text: localization.toLanguageString(manageVariableEa, mainMessages[siteLanguageDefault][manageVariableEa]),
            link: "https://download.software.keysight.com/CMP/ksmsite/external/How to Manage Variable Enterprise Agreements.mp4",
        }
    ];

    const onItemClick = (e) => {
        get_pre_signed_url(e.item.link)
    }

    const get_pre_signed_url = (link) => {
        let headers = {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken
        };
        let data = {
            "urls": [link],
            "source": "ea_tutorial"
        }
        setIsLoading(true);

        axios.post(
            config.ea_summary.PRE_SIGN_URL,
            data,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    if (data.length > 0) {
                        window.open(data[0], '_blank')
                    }
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to download url", error);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <div
                className="ksm-hamburger"
                style={dropdownButtonStyle}
            >
                <Tooltip
                    anchorElement="target"
                    showCallout={true}
                    parentTitle={true}
                    openDelay={0}
                    position="left"
                >
                    <DropDownButton
                        title={localization.toLanguageString(howToEA, mainMessages[siteLanguageDefault][howToEA])}
                        svgIcon={mediaManagerIcon}
                        size={'large'}
                        style={{
                            color: "var(--color-text-primary)"
                        }}
                        fillMode={"flat"}
                        rounded={null}
                        popupSettings={{
                            animate: false,
                            popupClass: "ksm-hamburger-dropdown",
                            anchorAlign: anchorAlign,
                            popupAlign: popupAlign
                        }}
                        items={categories}
                        onItemClick={onItemClick}
                        onOpen={() => setOpened(!opened)}
                        onClose={() => setOpened(false)}
                        onBlur={() => {
                            setOpened(false)
                        }}
                    />
                </Tooltip>
            </div>
        </>
    );
}

export default VideoHamburgerMenu;