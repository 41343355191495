import React, {useState, useContext, useRef} from 'react';
import UserContext from '../common/UserContext.js';

// components
import {downloadLicenses} from '../common/utilities.js';

// kendo react
import {Tooltip} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cloudLicenseKey,
    cloudLicenseMessageKey,
    mainMessages,
    downloadLicensesKey,
} from '../../assets/text/MultilingualText.js';
import {DownloadIcon, InfoIcon} from "../common/icons";
import KSMPopover from "../common/Popovers";


export const DownloadCell = (props) => {
    const {
        dataItem,
        setIsLoading,
        handleBeforeUnload
    } = props;
    const {
        siteLanguageDefault,
        accessToken,
    } = useContext(UserContext);
    const localization = useLocalization();

    const anchor = useRef(null);
    const [popoverShow, setPopoverShow] = useState(false);

    // show popover if there is an error on mouse over
    const onMouseOver = () => {
        setPopoverShow(true);
    };

    // remove popover on mouse leave
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) setPopoverShow(false);
    };
    return (
        <>
            <td
                ref={anchor}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                <div>
                    {dataItem.license_type === "CLOUD" && (
                        <>
                            <div
                                title={localization.toLanguageString(downloadLicensesKey, mainMessages[siteLanguageDefault][downloadLicensesKey])}
                            >
                                <InfoIcon
                                    size={'medium'}
                                    style={{
                                        color: 'var(--keysight-gray-4)',
                                        marginLeft: '0.35rem',
                                        marginRight: '0.35rem',
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>
                            <KSMPopover
                                show={popoverShow}
                                anchor={anchor.current}
                                position={'left'}
                                type={'info'}
                                message={<div>
                                    <b>{localization.toLanguageString(cloudLicenseKey, mainMessages[siteLanguageDefault][cloudLicenseKey])}</b>
                                    <br/>
                                    {localization.toLanguageString(cloudLicenseMessageKey, mainMessages[siteLanguageDefault][cloudLicenseMessageKey])}
                                </div>}
                            />
                        </>
                    )}
                    {dataItem.license_type === "FILE" && dataItem.license_id !== null && (
                        <Tooltip
                            anchorElement="pointer"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position={'left'}
                        >
                            <div
                                title={localization.toLanguageString(downloadLicensesKey, mainMessages[siteLanguageDefault][downloadLicensesKey])}
                                onClick={() => {
                                    downloadLicenses([dataItem['license_id']], accessToken, setIsLoading, handleBeforeUnload, "SWA_VIEW_DOWNLOAD");
                                }}
                            >
                                <DownloadIcon
                                    size={'medium'}
                                    style={{
                                        color: 'var(--keysight-gray-4)',
                                        marginLeft: '0.35rem',
                                        marginRight: '0.35rem',
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </td>
        </>
    )
}