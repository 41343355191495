import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// react strap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../common/Alert.js';
import {EmailDialog} from '../common/EmailModal.js';
import {LicenseDownloadPopover} from './LicenseDownload/Popovers.js';
import {PanelBarHeader} from './LicenseDownload/PanelBarHeader.js';
import {DownloadGrid} from './LicenseDownload/Grid.js';
import {downloadLicenses} from '../common/utilities.js';

// kendo react
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    emailReceiveKey,
    emailSuccessKey,
    downloadAllLicensesKey,
    mailAllKey,
    generatedRetrieveLicensesKey,
    requestSuccessfullyKey,
    notGeneratedAvailableRedeemKey,
    genericErrorTitleKey,
    renewMoreLicencesKey,
    renewableLicensesKey, renewMoreSubKey
} from '../../assets/text/MultilingualText.js';
import {downloadIcon, envelopeIcon, fileExcelIcon} from "@progress/kendo-svg-icons";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {DownloadIcon, IconButton, MailIcon} from "../common/icons";

//Context to store popover reference, when to show, and which popover to show
export const PopoverContext = createContext({
    onMouseOver: () => null
});

function LicenseDownload(props) {
    const {
        changeStepActivation,
        requestedHosts,
        setRequestedHosts,
        assignMore,
        handleBeforeUnload,
        setIsLoading,
        stepperItems,
        setStepperItems
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [showPopover, setShowPopover] = useState(false);
    const [popoverType, setPopoverType] = useState('generalError');
    const [popoverRef, setPopoverRef] = useState();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showGeneralSuccess, setShowGeneralSuccess] = useState(true);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [showNetworkError, setShowNetworkError] = useState(false);
    const [disableEmailDownloadButtons, setDisableEmailDownloadButtons] = useState(true);
    const [disableRenewMoreStepperButtons, setDisableRenewMoreStepperButtons] = useState(true);

    async function requestRenewals(host, index) {
        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let formData = new FormData();
        formData.append('File', null);

        let data = {
            "transaction_id": host['transaction_id'],
            "products": host['products'],
            "host_id": host['host_id'],
            "serial_id": '',
        };

        formData.append('Data', JSON.stringify(data));

        let savedHost = requestedHosts[index];
        axios.post(
            config.renew_eesof.REQUEST_RENEWAL,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    savedHost['icons']['download'] = true;
                    savedHost['icons']['loading'] = false;
                }
                setRequestedHosts([...requestedHosts]);
            })
            .catch((error) => {
                console.log("ERROR: Failed to renew essof license", error);
                if (!error.response) {
                    savedHost['icons']['networkError'] = true;
                    savedHost['icons']['loading'] = false;
                } else {
                    savedHost['icons']['generalError'] = true;
                    savedHost['icons']['loading'] = false;
                }
                setRequestedHosts([...requestedHosts]);
            });
    }

    //Filters out all the selected products
    useEffect(() => {
        requestedHosts.forEach((host, index) => {
            requestRenewals(host, index);
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //Remove success message when all host fail
    useEffect( () => {
        const requiredCount = requestedHosts.length
        let failedHost = 0
        requestedHosts.forEach((host)=>{
            if(host.icons.generalError || host.icons.networkError){
                failedHost++
            }
        })
        if(failedHost === requiredCount){
            setShowGeneralSuccess(false)
        }
    }, [requestedHosts])

    //Closes the success general alert
    const alertGeneralSuccessHandler = () => {
        setShowGeneralSuccess(false);
    }

    //Closes the success email alert
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    //Closes the error network alert
    const alertNetworkErrorHandler = () => {
        setShowNetworkError(false);
    }

    const [downloadableTransactionIDs, setDownloadableTransactionIDs] = useState([]);

    //Keeps track of what transaction ids are downloadable
    useEffect(() => {
        let transactionIDs = [];
        requestedHosts.forEach(host => {
            if (host.icons.download) {
                transactionIDs.push(host.transaction_id);
            }
        })
        setDownloadableTransactionIDs(transactionIDs);
    }, [requestedHosts])

    //Download all license from all host with the download icon
    const downloadAllLicenses = () => {
        downloadLicenses(downloadableTransactionIDs, accessToken, setIsLoading, handleBeforeUnload, 'EESOF_RENEWAL');
    }

    //Show network error if all host have network errors
    useEffect(() => {
        const requiredCount = requestedHosts.length;
        let currentCount = 0;
        requestedHosts.forEach(host => {
            if (host.icons.networkError) {
                currentCount++;
            }
        })

        if (requiredCount === currentCount && requiredCount !== 0) {
            setShowGeneralSuccess(false);
            setShowNetworkError(true);
        }
    }, [requestedHosts])

    //Enable or disable email/download selected license button
    useEffect(() => {
        let notFinishedLoading = false;
        let isDownloadable = false;

        requestedHosts.forEach(host => {
            //Enable buttons if all hosts are done loading and at least one downloadable host
            if (host.icons.loading) {
                notFinishedLoading = true;
            }

            if (host.icons.download) {
                isDownloadable = true;
            }
        })

        if (notFinishedLoading) {
            setDisableEmailDownloadButtons(true);
        } else {
            if (isDownloadable) {
                setDisableEmailDownloadButtons(false);
            } else {
                setDisableEmailDownloadButtons(true);
            }
        }
    }, [requestedHosts])

    //Enable or disable stepper and renew more button
    useEffect(() => {
        let notFinishedLoading = false;

        requestedHosts.forEach(host => {
            //Enable buttons if all hosts are done loading
            if (host.icons.loading) {
                notFinishedLoading = true;
            }
        })
        let updateStepper = [...stepperItems];
        if (notFinishedLoading || !assignMore) {
            setDisableRenewMoreStepperButtons(true);
            updateStepper[0].disabled = true;
        } else {
            setDisableRenewMoreStepperButtons(false);
            updateStepper[0].disabled = false;
        }
        setStepperItems(updateStepper);
    }, [requestedHosts]) // eslint-disable-line react-hooks/exhaustive-deps

    //Sets which popover to show and it's corresponding reference
    const handlePopoverMouseOver = useCallback(
        (event) => {
            if (event.show) {
                setShowPopover(true);
            } else {
                setShowPopover(false);
            }

            if (event.networkError) {
                setPopoverType('networkError');
            } else {
                setPopoverType('generalError');
            }

            setPopoverRef(event.popoverRef);
        }, [setShowPopover, setPopoverType, setPopoverRef]);

    return (
        <>
            <div
                className={"k-h5"}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(renewableLicensesKey, mainMessages[siteLanguageDefault][renewableLicensesKey])}
            </div>
            <PopoverContext.Provider
                value={{
                    onMouseOver: handlePopoverMouseOver
                }}
            >
                {requestedHosts.map((host) => {
                    return <PanelBar
                        key={host.transaction_id}
                        expanded={['.0']}
                        isControlled={true}
                        className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                    >
                        <PanelBarItem
                            title={<PanelBarHeader
                                host={host}
                                setIsLoading={setIsLoading}
                                handleBeforeUnload={handleBeforeUnload}
                            />}
                        >
                            <DownloadGrid products={host.products}/>
                        </PanelBarItem>
                    </PanelBar>
                })
                }
            </PopoverContext.Provider>
            <LicenseDownloadPopover
                type={popoverType}
                showPopover={showPopover}
                popoverRef={popoverRef}
            />
            <Row
                style={{
                    marginBottom: '0.5rem'
                }}
            >
                <Col>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        disabled={disableRenewMoreStepperButtons}
                        onClick={() => {
                            changeStepActivation(0);
                        }}
                        style={{
                            marginBottom: '0.5rem'
                        }}
                    >
                        {localization.toLanguageString(renewMoreSubKey, mainMessages[siteLanguageDefault][renewMoreSubKey])}
                    </Button>
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                        }}
                    >
                        {showGeneralSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={alertGeneralSuccessHandler}
                                title={localization.toLanguageString(requestSuccessfullyKey, mainMessages[siteLanguageDefault][requestSuccessfullyKey])}
                                message={localization.toLanguageString(generatedRetrieveLicensesKey, mainMessages[siteLanguageDefault][generatedRetrieveLicensesKey])}
                            />
                        )}
                        {showEmailSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={alertEmailSuccessHandler}
                                title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                            />
                        )}
                        {showNetworkError && (
                            <Alert
                                type={'error'}
                                showHandler={alertNetworkErrorHandler}
                                title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                message={localization.toLanguageString(notGeneratedAvailableRedeemKey, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey])}
                            />
                        )}
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            gap: '0.5rem'
                        }}
                    >
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                disabled={disableEmailDownloadButtons}
                                onClick={() => {
                                    setShowEmailModal(true);
                                }}
                                Icon={MailIcon}
                            />
                        </Tooltip>

                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(downloadAllLicensesKey, mainMessages[siteLanguageDefault][downloadAllLicensesKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                onClick={downloadAllLicenses}
                                disabled={disableEmailDownloadButtons}
                                Icon={DownloadIcon}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </Row>
            {showEmailModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailModal}
                    setShowEmailSuccess={setShowEmailSuccess}
                    transactionIDs={downloadableTransactionIDs}
                    setIsLoading={setIsLoading}
                    request_source={'EESOF_RENEWAL'}
                />
            )}
        </>
    );
}

export default LicenseDownload;