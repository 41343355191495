import React, {useContext, useRef, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// kendo react
import {NumericTextBox} from '@progress/kendo-react-inputs';
import {Popover} from "@progress/kendo-react-tooltip";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    noQuantityKey,
    noQuantityMessageKey
} from '../../../assets/text/MultilingualText.js';
import KSMPopover from "../../common/Popovers";

// custom cell and logic for Assign Qty
export const AssignQtyCell = (props) => {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const field = props.field;
    const dataValue = props.dataItem[field];

    const anchor = useRef(null);
    const [popoverShow, setPopoverShow] = useState(false);

    // set unchangeable element for products that are 1 per host
    let license = props.dataItem.license_type ? props.dataItem.license_type.filter(lt => lt.selected) : [];
    if (license[0]?.redeem_one_per_host_flag === "Y") {
        return (
            <td style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}>
                {props.dataItem.assign_quantity}
            </td>
        )
    }

    // set unchangeable element for products that have 1 unassigned quantity remaining
    let qty = Number(props.dataItem.unassigned_quantity);
    if (qty === 1 && props.dataItem.status === "SELECTED") {
        return (
            <td style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}>
                {qty}
            </td>
        )
    }

    // outline selected product's assign qty cell in red if assign qty is empty or null
    let isError = props.dataItem.selected && (
        props.dataItem.assign_quantity === '' ||
        props.dataItem.assign_quantity === null ||
        isNaN(props.dataItem.assign_quantity)
    )

    let style = {}
    if (isError) {
        style = {
            border: '2px solid var(--color-alert-danger-base)'
        }
    }

    // show popover if there is an error on mouse over
    const onMouseOver = (ev) => {
        if (isError) setPopoverShow(true);
    };

    // remove popover on mouse leave
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) setPopoverShow(false);
    };

    // bound input events and values to the Grid
    const onChange = (ev) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: ev.syntheticEvent,
                value: ev.target.value,
            });
        }
    };

    return (
        <td ref={anchor} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <KSMPopover
                show={popoverShow}
                anchor={anchor.current}
                position={'top'}
                type={'error'}
                message={
                    <div>
                        <b style={{fontWeight: '600'}}>{localization.toLanguageString(noQuantityKey, mainMessages[siteLanguageDefault][noQuantityKey])}</b>
                        <br/>
                        {localization.toLanguageString(noQuantityMessageKey, mainMessages[siteLanguageDefault][noQuantityMessageKey])}
                    </div>
                }
            />
            <NumericTextBox
                className={'ksm-grid-numeric-input'}
                style={style}
                onChange={onChange}
                value={typeof dataValue == "number" && dataValue > 0 && !isNaN(dataValue) ? dataValue : null}
                spinners={false}
                max={Number(props.dataItem.unassigned_quantity)}
                min={1}
                disabled={props.dataItem.disabled}
                format={{
                    useGrouping: false,
                }}
            />
        </td>
    );
};