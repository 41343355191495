import UserContext from "../../common/UserContext";
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import config from "../../../config";
import axios from "axios";
import uuid from "react-uuid";

//reactstrap
import {Col, Row} from "reactstrap";

//components
import {DownloadPanelBarHeader} from "./DownloadPanelBarHeader";
import RequestDownloadGrid from "./RequestDownloadGrid";
import {LicenseDownloadPopover} from "./Popovers";
import Alert from "../../common/Alert";
import {EmailDialog} from "../../common/EmailModal";
import {downloadLicenses} from "../../common/utilities";

//kendo
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";

//multilingual
import {
    downloadSelectedLicensesKey,
    emailSelectedLicensesKey,
    mainMessages,
    emailReceiveKey,
    emailSuccessKey,
    licensesReadyKey,
    processingCompletedKey,
    notGeneratedAvailableRedeemKey,
    errorOccurredRequestKey,
    redeemNotAvailableErrorKey,
    requestProcessingMessageKey,
    requestProcessingKey,
    cannotGenerateMessageKey,
    cannotGenerateKey,
    notGeneratedSupportAgentKey,
    generationTimeoutKey,
    requestAgainLicenseViewKey,
    requestAgainKey,
    selectMoreKey,
} from "../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {useHistory} from "react-router-dom";
import {downloadIcon, envelopeIcon} from "@progress/kendo-svg-icons";
import {DownloadIcon, IconButton, MailIcon} from "../../common/icons";


export const PopoverContext = createContext({
    onMouseOver: () => null,
});

export const LicenseDownload = (props) => {
    const {
        siteLanguageDefault,
        accessToken,
        timeout
    } = useContext(UserContext);

    const {
        stepperItems,
        setStepperItems,
        handleBeforeUnload,
        changeStepActivation,
        setIsLoading,
        assignedProducts,
        setAssignedProducts,
        requestedProducts,
        setRequestedProducts,
        headerInfo,
        setHeaderInfo,
        selectedProducts,
        setSelectedProducts,
        futureSubPoolValues,
        setFutureSubPoolValues,
        fromRequestAgain,
    } = props
    const localization = useLocalization();

    const [eaId, setEaId] = useState('');
    const [downloadableAllocIDs, setDownloadableAllocIDs] = useState([]);

    const [showEmailModal, setShowEmailModal] = useState(false);

    const [disableEmailDownloadButtons, setDisableEmailDownloadButtons] = useState(true);
    const [disableAssignMoreStepperButtons, setDisableAssignMoreStepperButtons] = useState(true);

    const [showInProgress, setShowInProgress] = useState(true)
    const showInProgressHandler = () => {
        setShowInProgress(false);
    }

    const [showSuccess, setShowSuccess] = useState(false)
    const showSuccessHandler = () => {
        setShowSuccess(false);
    }

    const [showSupportError, setShowSupportError] = useState(false)
    const showSupportErrorHandler = () => {
        setShowSupportError(false);
    }

    const [showGenerationError, setShowGenerationError] = useState(false)
    const showGenerationErrorHandler = () => {
        setShowGenerationError(false);
    }

    const [showQtyError, setShowQtyError] = useState(false)
    const showQtyErrorHandler = () => {
        setShowQtyError(false);
    }

    const [showEntitlementError, setShowEntitlementError] = useState(false)
    const showEntitlementErrorHandler = () => {
        setShowEntitlementError(false);
    }

    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    //Show success alert
    useEffect(() => {
        const requiredCount = requestedProducts.length;
        let successCount = 0

        requestedProducts.forEach(host => {
            if (host.icons.download || host.icons.cloudAlert || host.icons.specialAlert) {
                successCount++
            }
        })

        if (successCount === requiredCount) {
            setShowInProgress(false)
            setShowSuccess(true)
        }

    }, [requestedProducts])

    //Keeps track of what transaction ids are downloadable
    useEffect(() => {
        let allocIDs = [];
        requestedProducts.forEach(host => {
            if (host.icons.download && host.icons.checked) {
                for (const product of host.products) {
                    allocIDs.push(product.ea_alloc_id.toString());
                }
            }
        })
        setDownloadableAllocIDs(allocIDs);
    }, [requestedProducts])

    //Enable or disable email/download selected license button
    useEffect(() => {
        let notFinishedLoading = false;
        let isDownloadable = false;
        requestedProducts.forEach(host => {
            //Enable buttons if all hosts are done loading and at least one downloadable host
            if (host.icons.loading) {
                notFinishedLoading = true;
            }

            if (host.icons.download && host.icons.checked) {
                isDownloadable = true;
            }
        })

        if (notFinishedLoading) {
            setDisableEmailDownloadButtons(true);
        } else {
            if (isDownloadable) {
                setDisableEmailDownloadButtons(false);
            } else {
                setDisableEmailDownloadButtons(true);
            }
        }
    }, [requestedProducts])

    //Enable or disable assign more button and stepper
    useEffect(() => {
        let notFinishedLoading = false;
        requestedProducts.forEach(host => {
            //Enable buttons if all hosts are done loading
            if (host.icons.loading) {
                notFinishedLoading = true;
            }
        })
        setDisableAssignMoreStepperButtons(notFinishedLoading)
        let updateStepper = JSON.parse(JSON.stringify(stepperItems));
        updateStepper[0].disabled = notFinishedLoading
        if (!notFinishedLoading) {
            updateStepper[1].disabled = assignedProducts.length === 0;
        } else {
            updateStepper[1].disabled = true
        }
        setStepperItems(updateStepper)
    }, [requestedProducts, assignedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    //Request new license for every host panel bar
    useEffect(() => {
        async function requestNewLicense(host, index) {
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };

            let location = window.location
            let query = new URLSearchParams(location.search);

            setEaId(query.get('eaid'));

            let allocIds = []
            for (const product of host.products) {
                allocIds.push(product.ea_alloc_id)
            }

            let data = {
                module: "LICENSE",
                sub_module: "EA",
                action: "REDEEM",
                input_json: [
                    {
                        ea_id: query.get('eaid'),
                        product_sel_period: query.get('show'),
                        transaction_id: host.transaction_id,
                        variableoversubflag: headerInfo.variableoversubflag,
                        alloc_ids: allocIds
                    }
                ]
            }

            let savedHost = requestedProducts[index];
            axios.post(
                config.ea_request_license.REQUEST_NEW_LICENSE,
                data,
                {headers: headers, timeout: timeout}
            )
                .then((response) => {
                    if (response.status === 200) {
                        //update icons
                        if (response.data['license_generated'] === 'YES') {
                            let licenseType = response.data['license_type']
                            switch (licenseType) {
                                case "FILE":
                                case "KEY":
                                    savedHost['icons']['download'] = true;
                                    savedHost['icons']['loading'] = false;
                                    break;
                                case "CLOUD":
                                    savedHost['icons']['cloudAlert'] = true;
                                    savedHost['icons']['loading'] = false;
                                    break;
                                default:
                                    break;
                            }
                        }

                        if (response.data['license_generated'] === 'PENDING') {
                            savedHost['icons']['specialAlert'] = true;
                            savedHost['icons']['loading'] = false;
                        }
                    }
                    setRequestedProducts([...requestedProducts]);

                    //remove selected qty
                    for (const assignProduct of host.products) {
                        const parentIndex = selectedProducts.findIndex(item => item.ea_alloc_id === assignProduct.parent_alloc_id)
                        selectedProducts[parentIndex].quantity -= assignProduct.assign_quantity
                        selectedProducts[parentIndex].saved_quantity = selectedProducts[parentIndex].quantity
                        selectedProducts[parentIndex].lictypedisabled = 'Y'
                        delete selectedProducts[parentIndex].status_display[assignProduct.ea_alloc_id]
                        if (selectedProducts[parentIndex].quantity === 0 && Object.keys(selectedProducts[parentIndex].status_display).length === 0) {
                            selectedProducts.splice(parentIndex, 1)
                        }
                    }
                    setSelectedProducts([...selectedProducts])

                    //remove redeemed hosts
                    let index = assignedProducts.findIndex(host => host.transaction_id === savedHost.transaction_id)
                    assignedProducts.splice(index, 1)
                    setAssignedProducts([...assignedProducts])

                    //update subscription pool
                    let eaHeaderInfo = response.data.sub_pool_data[0];
                    headerInfo.total_pool = eaHeaderInfo.total_pool;
                    headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool;
                    headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool;
                    headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                    setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)));

                    if (headerInfo.variableoversubflag === 'TRUE') {
                        for (const pool of response.data['list_sub_pool_values']) {
                            const year = pool.subpoolyear
                            futureSubPoolValues[year].availablesubscriptionpool = pool.availablesubscriptionpool
                            futureSubPoolValues[year].redeemedsubscriptionpool = pool.redeemedsubscriptionpool
                            futureSubPoolValues[year].selectedsubscriptionpool = pool.selectedsubscriptionpool
                        }
                        setFutureSubPoolValues(JSON.parse(JSON.stringify(futureSubPoolValues)))
                    }
                })
                .catch((error) => {
                    console.log("ERROR: Failed to request new license", error);
                    setShowInProgress(false)
                    savedHost['icons']['loading'] = false;
                    let retry = false
                    if (!error.response) { //Internet disconnected
                        savedHost['icons']['generationError'] = true;
                        setShowGenerationError(true)
                    } else if (error.toString().includes('timeout')) { //Axios timeout
                        savedHost['icons']['supportError'] = true;
                        setShowSupportError(true)
                    } else {
                        if (error.response.status === 408 || error.response.status === 504) { //API & gateway timeout
                            savedHost['icons']['supportError'] = true;
                            setShowSupportError(true)
                        }
                        if (error.response.hasOwnProperty('data')) {
                            let errorCode = error.response.data['error_code'].toUpperCase();
                            switch (errorCode) {
                                case "MOD_LICENSE_228": //Plugin Fail
                                    savedHost['icons']['supportError'] = true;
                                    setShowSupportError(true)
                                    break;
                                default: //Backend/DB error/rollback & user can retry
                                    savedHost['icons']['entitlementError'] = true;
                                    setShowEntitlementError(true)
                                    retry = true
                                    break
                            }
                            if (error.response.data.hasOwnProperty('sub_pool_data')) {
                                let eaHeaderInfo = error.response.data['sub_pool_data'][0]
                                headerInfo.total_pool = eaHeaderInfo.total_pool
                                headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                                headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                                headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                                setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))
                            }

                            if (error.response.data.hasOwnProperty('list_sub_pool_values')) {
                                if (headerInfo.variableoversubflag === 'TRUE') {
                                    for (const pool of error.response.data['list_sub_pool_values']) {
                                        const year = pool.subpoolyear
                                        futureSubPoolValues[year].availablesubscriptionpool = pool.availablesubscriptionpool
                                        futureSubPoolValues[year].redeemedsubscriptionpool = pool.redeemedsubscriptionpool
                                        futureSubPoolValues[year].selectedsubscriptionpool = pool.selectedsubscriptionpool
                                    }
                                    setFutureSubPoolValues(JSON.parse(JSON.stringify(futureSubPoolValues)))
                                }
                            }
                        }
                    }
                    setRequestedProducts([...requestedProducts]);

                    //Hosts that can retry should appear again in license preview
                    let index = assignedProducts.findIndex(host => host.transaction_id === savedHost.transaction_id)
                    if (retry) {
                        assignedProducts[index].transaction_id = uuid()
                    } else {
                        //remove selected qty
                        for (const assignProduct of host.products) {
                            const parentIndex = selectedProducts.findIndex(item => item.ea_alloc_id === assignProduct.parent_alloc_id)
                            selectedProducts[parentIndex].quantity -= assignProduct.assign_quantity
                            selectedProducts[parentIndex].saved_quantity = selectedProducts[parentIndex].quantity
                            selectedProducts[parentIndex].lictypedisabled = 'Y'
                            delete selectedProducts[parentIndex].status_display[assignProduct.ea_alloc_id]
                            if (selectedProducts[parentIndex].quantity === 0 && Object.keys(selectedProducts[parentIndex].status_display).length === 0) {
                                selectedProducts.splice(parentIndex, 1)
                            }
                        }
                        setSelectedProducts([...selectedProducts])

                        //remove assigned host
                        assignedProducts.splice(index, 1)
                    }
                    setAssignedProducts([...assignedProducts])
                });
        }

        requestedProducts.forEach((host, index) => {
            requestNewLicense(host, index)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const [showPopover, setShowPopover] = useState(false);
    const [popoverType, setPopoverType] = useState('generalError');
    const [popoverRef, setPopoverRef] = useState();
    const handlePopoverMouseOver = useCallback(
        (event) => {
            if (event.show) {
                setShowPopover(true);
            } else {
                setShowPopover(false);
            }
            if (event.cloudAlert) {
                setPopoverType('cloudAlert');
            } else if (event.specialAlert) {
                setPopoverType('specialAlert')
            } else if (event.supportError) {
                setPopoverType('supportError');
            } else if (event.generationError) {
                setPopoverType('generationError');
            } else if (event.qtyError) {
                setPopoverType('qtyError');
            } else {
                setPopoverType('entitlementError');
            }
            setPopoverRef(event.popoverRef);
        },
        [setShowPopover, setPopoverType, setPopoverRef]
    );

    let history = useHistory();

    return (
        <>

            <PopoverContext.Provider
                value={{
                    onMouseOver: handlePopoverMouseOver
                }}
            >
                {requestedProducts.map((host, index) => {
                    return (
                        <PanelBar
                            key={index}
                            isControlled={true}
                            expanded={['.0']}
                            className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                        >
                            <PanelBarItem
                                title={<DownloadPanelBarHeader
                                    handleBeforeUnload={handleBeforeUnload}
                                    requestedProducts={requestedProducts}
                                    setRequestedProducts={setRequestedProducts}
                                    index={index}
                                    setIsLoading={setIsLoading}
                                />}
                            >
                                <RequestDownloadGrid
                                    products={host.products}
                                />

                            </PanelBarItem>
                        </PanelBar>
                    )
                })}
            </PopoverContext.Provider>
            <LicenseDownloadPopover
                type={popoverType}
                showPopover={showPopover}
                popoverRef={popoverRef}
            />
            <Row
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            gap: '0.938rem'
                        }}
                    >
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"outline"}
                            rounded={"small"}
                            disabled={disableAssignMoreStepperButtons}
                            onClick={() => {
                                changeStepActivation(0)
                            }}
                        >
                            {localization.toLanguageString(selectMoreKey, mainMessages[siteLanguageDefault][selectMoreKey])}
                        </Button>
                        {fromRequestAgain && (
                            <Tooltip
                                anchorElement="target"
                                parentTitle={true}
                                openDelay={0}
                            >
                                <Button
                                    title={localization.toLanguageString(requestAgainLicenseViewKey, mainMessages[siteLanguageDefault][requestAgainLicenseViewKey])}
                                    themeColor={"primary"}
                                    size={"large"}
                                    shape={"rectangle"}
                                    fillMode={"outline"}
                                    rounded={"small"}
                                    disabled={disableAssignMoreStepperButtons}
                                    onClick={() => {
                                        history.push('/ea-request-again?eaid=' + eaId);
                                    }}
                                >
                                    <span>{localization.toLanguageString(requestAgainKey, mainMessages[siteLanguageDefault][requestAgainKey])}</span>
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.938rem'
                        }}
                    >
                        {showInProgress && (
                            <Alert
                                type={'default'}
                                showHandler={showInProgressHandler}
                                title={localization.toLanguageString(requestProcessingKey, mainMessages[siteLanguageDefault][requestProcessingKey])}
                                message={localization.toLanguageString(requestProcessingMessageKey, mainMessages[siteLanguageDefault][requestProcessingMessageKey])}
                            />
                        )}

                        {showSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={showSuccessHandler}
                                title={localization.toLanguageString(processingCompletedKey, mainMessages[siteLanguageDefault][processingCompletedKey])}
                                message={localization.toLanguageString(licensesReadyKey, mainMessages[siteLanguageDefault][licensesReadyKey])}
                            />
                        )}

                        {showSupportError && (
                            <Alert
                                type={'error'}
                                showHandler={showSupportErrorHandler}
                                title={localization.toLanguageString(generationTimeoutKey, mainMessages[siteLanguageDefault][generationTimeoutKey])}
                                message={localization.toLanguageString(notGeneratedSupportAgentKey, mainMessages[siteLanguageDefault][notGeneratedSupportAgentKey])}
                            />
                        )}

                        {showGenerationError && (
                            <Alert
                                type={'error'}
                                showHandler={showGenerationErrorHandler}
                                title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                                message={localization.toLanguageString(redeemNotAvailableErrorKey, mainMessages[siteLanguageDefault][redeemNotAvailableErrorKey])}
                            />
                        )}

                        {showQtyError && (
                            <Alert
                                type={'error'}
                                showHandler={showQtyErrorHandler}
                                title={localization.toLanguageString(cannotGenerateKey, mainMessages[siteLanguageDefault][cannotGenerateKey])}
                                message={localization.toLanguageString(cannotGenerateMessageKey, mainMessages[siteLanguageDefault][cannotGenerateMessageKey])}
                            />
                        )}

                        {showEntitlementError && (
                            <Alert
                                type={'error'}
                                showHandler={showEntitlementErrorHandler}
                                title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                                message={localization.toLanguageString(notGeneratedAvailableRedeemKey, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey])}
                            />
                        )}

                        {showEmailSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={alertEmailSuccessHandler}
                                title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                            />
                        )}
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            flexWrap: 'wrap',
                            gap: '0.5rem'
                        }}
                    >
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(emailSelectedLicensesKey, mainMessages[siteLanguageDefault][emailSelectedLicensesKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                disabled={disableEmailDownloadButtons}
                                onClick={() => {
                                    setShowEmailModal(true)
                                }}
                                Icon={MailIcon}
                            />
                        </Tooltip>
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(downloadSelectedLicensesKey, mainMessages[siteLanguageDefault][downloadSelectedLicensesKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                onClick={() => {
                                    downloadLicenses(downloadableAllocIDs, accessToken, setIsLoading, handleBeforeUnload, "ENTERPRISE_AGREEMENTS");
                                }}
                                disabled={disableEmailDownloadButtons}
                                Icon={DownloadIcon}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </Row>

            {showEmailModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailModal}
                    setShowEmailSuccess={setShowEmailSuccess}
                    transactionIDs={downloadableAllocIDs}
                    setIsLoading={setIsLoading}
                    request_source='ENTERPRISE_AGREEMENTS'
                />
            )}
        </>
    )
}