import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';

// css
import '../../../assets/css/App.css';

// react router
import {useHistory, useLocation} from 'react-router-dom';

// react strap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../../common/Spinner.js';
import {FormDropDownList, FormInput, FormNumericTextBox} from '../../common/form-components.js';
import {
    addressLine1Validator,
    cityValidator,
    countryValidator,
    nameValidator,
    phoneValidator,
    postalValidator,
    requestQtyValidator,
    stateValidator
} from './validators.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Dialog} from '@progress/kendo-react-dialogs';
import {Error} from '@progress/kendo-react-labels';

// multilingual
import {Text} from '../../common/MultilingualText.js';
import {
    addr1LineKey,
    addr2LineKey,
    SoftwareUpdatesAreAvailablePleaseEnterAQtyBetween1AndKey,
    availableKey,
    cancelKey,
    cityKey,
    companyKey,
    countryKey,
    departmentKey,
    mainMessages,
    nameKey,
    phoneKey,
    requestQuantityKey,
    requestShipmentKey,
    requestShipmentUpdateKitKey,
    shippingAddressKey,
    stateKey,
    zipCodeKey
} from '../../../assets/text/MultilingualText.js'


// numeric form min max doesn't change state
function ShipmentModal(props) {
    const {
        setRequestStatus,
        isActive,
        setActive,
        setOrderId
    } = props;
    const {
        accessToken,
        userName,
        siteLanguageDefault
    } = useContext(UserContext);

    let params = (new URL(document.location)).searchParams;
    let updateId = params.get('uuid');

    const location = useLocation();
    let history = useHistory();
    let updateItemId = params.get('update_item_id');
    let availableQty = params.get('available_qty');
    const [description, setDescription] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [canSubmit, setCanSubmit] = useState(false);
    const [countries, setCountries] = useState();
    const [validRequestQty, setValidRequestQty] = useState(true);

    useEffect(() => {
        if (location.hash === '#physical-shipment') {
            setIsLoading(true);

            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };

            // so the form doesn't keep the values from the original submit and look weird with the loading overlay on
            // them
            setFormValues({
                "updateId": updateId,
                "requestQty": 0,
                "updateItemId": "",
                "contactName": "",
                "companyName": "",
                "department": "",
                "address1": "",
                "address2": "",
                "city": "",
                "state": "",
                "postalCode": "",
                "country": "",
                "phone": "",
            });

            axios.get(
                config.software_updates.SOFTWARE_SERVICE,
                {
                    headers: headers,
                    params: {
                        module: 'update-item-kit',
                        filter: updateItemId
                    }
                }
            )
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data || [];
                        setDescription(data["item_description"]);
                        setFormValues({
                            "updateId": updateId,
                            "requestQty": 1,
                            "updateItemId": updateItemId,
                            "contactName": data["name"],
                            "companyName": data["company"],
                            "department": data["department"],
                            "address1": data["address1"],
                            "address2": data["address2"],
                            "city": data["city"],
                            "state": data["state"],
                            "postalCode": data["zip"],
                            "country": data["user_country"],
                            "phone": data["phone"],
                            "maxQty": availableQty
                        });
                        setFormErrors({
                            "requestQty": false,
                            "updateItemId": false,
                            "contactName": !nameValidator(data["name"]),
                            "companyName": false,
                            "department": false,
                            "address1": !addressLine1Validator(data["address1"]),
                            "address2": false,
                            "city": !cityValidator(data["city"]),
                            "state": !stateValidator(data["state"]),
                            "postalCode": !postalValidator(data["zip"]),
                            "country": !countryValidator(data["user_country"]),
                            "phone": !phoneValidator(data["phone"]),
                        });
                        setCountries(data["countries"]);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("Error prepopulating modal via Jitterbit; using defaults.", error);
                    setFormValues({
                        "updateId": updateId,
                        "requestQty": 0,
                        "updateItemId": "",
                        "contactName": userName ? userName : "",
                        "companyName": "",
                        "department": "",
                        "address1": "",
                        "address2": "",
                        "city": "",
                        "state": "",
                        "postalCode": "",
                        "country": "",
                        "phone": "",
                    });
                    setFormErrors({
                        "requestQty": false,
                        "updateItemId": false,
                        "contactName": !nameValidator(userName ? userName : ""),
                        "companyName": true,
                        "department": false,
                        "address1": true,
                        "address2": true,
                        "city": true,
                        "state": true,
                        "postalCode": true,
                        "country": !true,
                        "phone": true,
                    });
                    setIsLoading(false);
                })
                .finally(() => {
                    if (availableQty <= 0 || availableQty === null) {
                        setActive(false);
                        setRequestStatus("error");
                        setIsLoading(false);
                        history.goBack();
                    }
                })
        }
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (location.hash === '#physical-shipment') {
            const hasErrors = Object.values(formErrors).indexOf(true) > -1;
            setCanSubmit(!hasErrors);
        }
    }, [location.hash, formErrors]);

    const redirect = (actionLink) => {
        history.push(actionLink);
    };

    const validate = (name, value) => {
        let isValid;
        switch (name) {
            case "requestQty":
                [isValid] = requestQtyValidator(value, 1, availableQty);
                setValidRequestQty(isValid);
                break;
            case "contactName":
                isValid = nameValidator(value);
                break;
            case "companyName":
                isValid = true;
                break;
            case "department":
                isValid = true;
                break;
            case "address1":
                isValid = addressLine1Validator(value);
                break;
            case "address2":
                isValid = true;
                break;
            case "city":
                isValid = cityValidator(value);
                break;
            case "state":
                isValid = stateValidator(value);
                break;
            case "postalCode":
                isValid = postalValidator(value);
                break;
            case "country":
                isValid = countryValidator(value);
                break;
            case "phone":
                isValid = phoneValidator(value);
                break;
            case "updateItemId":
                isValid = Boolean(value);
                break;
            default:
                isValid = true;
                break;
        }
        setFormValueAndError(name, value, isValid);
    }

    const setFormValueAndError = (name, value, isValid) => {
        setFormValues({
            ...formValues,
            [name]: value
        });
        setFormErrors({
            ...formErrors,
            [name]: !isValid || false // formError is true if value not valid
        });
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        validate(name, value);
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };

        axios.post(
            config.software_updates.SOFTWARE_SERVICE,
            {
                module: 'SOFTWARE',
                sub_module: 'kit',
                action: 'SAVE',
                input_json: JSON.stringify(formValues)
            },
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setOrderId(response.data);
                    setRequestStatus("success");
                } else {
                    setRequestStatus("error");
                }
            })
            .catch((error) => {
                setRequestStatus("error");
            })
            .finally(() => {
                setActive(false);
                history.goBack();
                setIsLoading(false);
            });
    }
    const CustomTitleBar = () => {
        return (
            <div
                className="form-title"
                style={{
                    whiteSpace: "nowrap",
                    overflow: "scroll",
                    overflowY: "hidden",
                    overflowX: "auto",
                    margin: "left auto"
                }}
            >
                <Text
                    textkey={requestShipmentUpdateKitKey}
                    textdefault={mainMessages[siteLanguageDefault][requestShipmentUpdateKitKey]}
                />
                <br/>
                {description}
            </div>
        );
    };
    const Modal = () => {
        const requestQtyHeader = () => {
            return (
                <div>
                    <Text
                        textkey={requestQuantityKey}
                        textdefault={mainMessages[siteLanguageDefault][requestQuantityKey]}
                    />
                    {" (" + availableQty + " "}
                    <Text
                        textkey={availableKey}
                        textdefault={mainMessages[siteLanguageDefault][availableKey]}
                    />
                    {")"}
                </div>
            )
        }

        return (
            <>
                {isLoading ? <Spinner/> : <></>}
                <div>
                    <Dialog
                        className={"assign-products-modal ksm-dialog shipment-form"}
                        title={<CustomTitleBar/>}
                        onClose={() => {
                            setActive(!isActive);
                            setFormValues({});
                            setFormErrors({});
                            redirect(`/software-updates-detail?uuid=${updateId}`);
                        }}
                        width={"533px"}
                        height={"755px"}
                    >

                        <form
                            onSubmit={onSubmit}
                            style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}
                        >
                            <Col xs={6}>
                                <FormNumericTextBox
                                    id={"request-quantity"}
                                    name={"requestQty"}
                                    label={requestQtyHeader()}
                                    defaultValue={1}
                                    dataValue={formValues.requestQty}
                                    onChange={handleInputChange}
                                    valid={validRequestQty}
                                    required={true}
                                    spinners={false}
                                    format={{
                                        useGrouping: false,
                                    }}
                                />
                                {!validRequestQty ? (
                                        <Error>
                                            {availableQty}
                                            <Text
                                                textkey={SoftwareUpdatesAreAvailablePleaseEnterAQtyBetween1AndKey}
                                                textdefault={mainMessages[siteLanguageDefault][SoftwareUpdatesAreAvailablePleaseEnterAQtyBetween1AndKey]}
                                            />
                                            {availableQty}
                                        </Error>)
                                    :
                                    null
                                }
                            </Col>
                            <div>
                                <b>
                                    <Text
                                        textkey={shippingAddressKey}
                                        textdefault={mainMessages[siteLanguageDefault][shippingAddressKey]}
                                    />
                                </b>
                                <FormInput
                                    id={"name"}
                                    name={"contactName"}
                                    label={<Text
                                        textkey={nameKey}
                                        textdefault={mainMessages[siteLanguageDefault][nameKey]}
                                    />}
                                    dataValue={formValues.contactName}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </div>
                            <FormInput
                                id={"company"}
                                name={"companyName"}
                                label={<Text
                                    textkey={companyKey}
                                    textdefault={mainMessages[siteLanguageDefault][companyKey]}
                                />}
                                dataValue={formValues.companyName}
                                onChange={handleInputChange}
                            />
                            <FormInput
                                id={"department"}
                                name={"department"}
                                label={<Text
                                    textkey={departmentKey}
                                    textdefault={mainMessages[siteLanguageDefault][departmentKey]}
                                />}
                                dataValue={formValues.department}
                                onChange={handleInputChange}
                            />
                            <FormInput
                                id={"address-line-1"}
                                name={"address1"}
                                label={<Text
                                    textkey={addr1LineKey}
                                    textdefault={mainMessages[siteLanguageDefault][addr1LineKey]}
                                />}
                                validator={addressLine1Validator}
                                dataValue={formValues.address1}
                                onChange={handleInputChange}
                                required={true}
                            />
                            <FormInput
                                id={"address-line-2"}
                                name={"address2"}
                                label={<Text
                                    textkey={addr2LineKey}
                                    textdefault={mainMessages[siteLanguageDefault][addr2LineKey]}
                                />}
                                onChange={handleInputChange}
                                dataValue={formValues.address2}
                            />
                            <Row>
                                <Col>
                                    <FormInput
                                        id={"city"}
                                        name={"city"}
                                        label={<Text
                                            textkey={cityKey}
                                            textdefault={mainMessages[siteLanguageDefault][cityKey]}
                                        />}
                                        component={FormInput}
                                        validator={cityValidator}
                                        dataValue={formValues.city}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                </Col>
                                <Col>
                                    <FormInput
                                        id={"state"}
                                        name={"state"}
                                        label={<Text
                                            textkey={stateKey}
                                            textdefault={mainMessages[siteLanguageDefault][stateKey]}
                                        />}
                                        component={FormInput}
                                        className={"state-field"}
                                        validator={stateValidator}
                                        dataValue={formValues.state}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                </Col>
                                <Col>
                                    <FormInput
                                        id={"zip"}
                                        name={"postalCode"}
                                        label={<Text
                                            textkey={zipCodeKey}
                                            textdefault={mainMessages[siteLanguageDefault][zipCodeKey]}
                                        />}
                                        component={FormInput}
                                        validator={postalValidator}
                                        dataValue={formValues.postalCode}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormDropDownList
                                        label={<Text
                                            textkey={countryKey}
                                            textdefault={mainMessages[siteLanguageDefault][countryKey]}
                                        />}
                                        name={"country"}
                                        data={countries}
                                        defaultItem={formValues.country}
                                        validator={countryValidator}
                                        dataValue={formValues.country}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                </Col>
                                <Col>
                                    <FormInput
                                        id={"phone"}
                                        name={"phone"}
                                        label={<Text
                                            textkey={phoneKey}
                                            textdefault={mainMessages[siteLanguageDefault][phoneKey]}
                                        />}
                                        validator={phoneValidator}
                                        dataValue={formValues.phone}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Button
                                        themeColor={"primary"}
                                        size={"large"}
                                        shape={"rounded"}
                                        fillMode={"outline"}
                                        rounded={"small"}
                                        type={"button"}
                                        onClick={() => {
                                            setActive(!isActive);
                                            setFormValues({});
                                            setFormErrors({});
                                            redirect(`/software-updates-detail?uuid=${updateId}`);
                                        }}
                                        className={"shipment-button"}
                                    >
                                        <Text
                                            textkey={cancelKey}
                                            textdefault={mainMessages[siteLanguageDefault][cancelKey]}
                                        />
                                    </Button>
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start"
                                    }}
                                >
                                    <Button
                                        themeColor={"primary"}
                                        size={"large"}
                                        shape={"rounded"}
                                        fillMode={"solid"}
                                        rounded={"small"}
                                        type={"submit"}
                                        disabled={!canSubmit || isLoading}
                                        className={"shipment-button"}
                                    >
                                        {<Text
                                            textkey={requestShipmentKey}
                                            textdefault={mainMessages[siteLanguageDefault][requestShipmentKey]}
                                        />}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Dialog>
                </div>
            </>
        )
    }

    return (
        <>
            {isActive && Modal()}
        </>
    )
}

export default ShipmentModal;