import React, {cloneElement, useCallback, useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// css
import '../../../assets/css/EARequestLicense.css';

// components
import {ColumnMenu, ColumnSortOnlyMenu, DisableRowRender, NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';
import {AssignQtyCell} from './AssignQtyCell.js';
import {DateGridContext, EndDateHeaderCell, EndDatePickerCell, StartDatePickerCell} from './DatePickerCell.js';

// kendo react
import {Popover} from '@progress/kendo-react-tooltip';
import {Calendar} from '@progress/kendo-react-dateinputs';
import {Checkbox} from '@progress/kendo-react-inputs';
import {getSelectedState, Grid, GridColumn, GridItemChangeEvent, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy, process} from '@progress/kendo-data-query';
import {getter} from '@progress/kendo-react-common';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    incompatibleProductsSelectedKey,
    infoKey,
    noDataAvailableKey,
    assignQtyKey,
    descriptionKey,
    endDateKey,
    licenseTypeKey,
    mainMessages,
    productNumberKey,
    qtyKey,
    startDateKey,
} from '../../../assets/text/MultilingualText.js';
import KSMPopover from "../../common/Popovers";
import GenericSelectSortGrid from "../../common/AssignHost/GenericSelectSortGrid";

// consts for my products table
const uniqueID = 'ea_alloc_id'
const disableArrayID = 'license_type'
const sortByDisableID = 'hostid_type_pk'
const sortByDisableID2 = 'ea_compatibility'
const initialGridState = {
    take: 10,
    skip: 0,
};

const MyProductsGrid = (props) => {
    const {
        selectedProducts,
        setSelectedProducts,
        headerInfo,
        remixType,
        calculateCost,
        minEndDate,
        maxEndDate,
        datePickerEnabled,
    } = props;

    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    // sets the grid no records message
    const gridNoRecordsMessage = localization.toLanguageString(noDataAvailableKey, mainMessages[siteLanguageDefault][noDataAvailableKey]);
    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);
    const [enableSortByDisable, setEnableSortByDisable] = useState(false)
    let sortByDisableValue = useRef(null)
    let sortByDisableValue2 = useRef(null)

    const [anchor, setAnchor] = useState(null);
    const [visible, setVisible] = useState(false);
    const [columnEndDate, setColumnEndDate] = useState(null);

    const CustomStartDatePickerCell = (props) => {
        return <StartDatePickerCell
            {...props}
            type={"SELECTED"}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            headerInfo={headerInfo}
            calculateCost={calculateCost}
        />
    }
    const CustomEndDatePickerCell = (props) => {
        return <EndDatePickerCell
            {...props}
            type={"SELECTED"}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            headerInfo={headerInfo}
            calculateCost={calculateCost}
            minEndDate={minEndDate}
            maxEndDate={maxEndDate}
            datePickerEnabled={datePickerEnabled}
            remixType={remixType}
        />
    }

    const [gridState, setGridState] = useState(initialGridState);
    const gridStateRef = useRef(initialGridState)

    // update product state for editable assign qty in license table
    const onItemChange = (e: GridItemChangeEvent) => {
        let newProducts = selectedProducts.map((product) => {
            if (product.ea_alloc_id === e.dataItem.ea_alloc_id) {
                let setValue = parseInt(e.value)

                // assign qty cannot be greater than the max qty available
                if (setValue > parseInt(e.dataItem.unassigned_quantity)) {
                    setValue = parseInt(e.dataItem.unassigned_quantity)
                }
                // assign qty cannot be less than 1
                if (setValue < 1) {
                    setValue = null
                }

                product[e.field] = setValue;
            }
            return product;
        });

        setSelectedProducts(newProducts);
    };

    // sets ALL end dates to selectedProducts date in column header calendar
    useEffect(() => {
        if (columnEndDate) {
            let cost = 0;
            let tempSelectedProducts = [...selectedProducts];
            tempSelectedProducts.forEach(product => {
                product.period_end_date = columnEndDate;
            });
            cost = calculateCost(tempSelectedProducts, []);

            // only set ALL end dates in selected product if remaining sub pool
            if (cost <= headerInfo?.remaining_pool) {
                let products = [...selectedProducts]
                products.forEach(product => product.period_end_date = columnEndDate);
                setSelectedProducts(products);
            }
        } else {
            setColumnEndDate(null);
        }
    }, [columnEndDate]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Popover
                className="my-products-end-date-popover"
                show={visible}
                anchor={anchor}
                position={'bottom'}
                style={{padding: "none"}}
            >
                <Calendar
                    min={minEndDate}
                    max={maxEndDate}
                    disabled={!datePickerEnabled}
                    onChange={(e) => {
                        setColumnEndDate(e.value);
                        setVisible(false);
                    }}
                />
            </Popover>
            <DateGridContext.Provider
                value={{
                    visible,
                    setVisible,
                    setAnchor
                }}>
                <GenericSelectSortGrid
                    className={'my-products-grid'}
                    items={selectedProducts}
                    setItems={setSelectedProducts}
                    uniqueID={uniqueID}
                    initialGridState={initialGridState}
                    gridState={gridState}
                    setGridState={setGridState}
                    gridStateRef={gridStateRef}
                    selectHeaderCheck={selectHeaderCheck}
                    setSelectHeaderCheck={setSelectHeaderCheck}
                    disableArrayID={disableArrayID}
                    sortByDisableID={sortByDisableID}
                    sortByDisableID2={sortByDisableID2}
                    sortByDisableValue={sortByDisableValue}
                    sortByDisableValue2={sortByDisableValue2}
                    enableSortByDisable={enableSortByDisable}
                    setEnableSortByDisable={setEnableSortByDisable}
                    onItemChange={onItemChange}
                    filterOut={(product) => product.unassigned_quantity > 0}
                >
                    <GridNoRecords>
                        {gridNoRecordsMessage}
                    </GridNoRecords>
                    <GridColumn
                        field="prod_num_display"
                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                        cell={NoWrapCell}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="description"
                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="unassigned_quantity"
                        title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                        cell={TextAlignMiddleCell}
                        filter={'numeric'}
                        columnMenu={ColumnSortOnlyMenu}
                    />
                    <GridColumn
                        field="assign_quantity"
                        title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                        filter={'numeric'}
                        cell={AssignQtyCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="period_start_date"
                        title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                        cell={CustomStartDatePickerCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="period_end_date"
                        title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                        headerCell={remixType === "variable" ? EndDateHeaderCell : null}
                        cell={CustomEndDatePickerCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="selected_license_type_text"
                        title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                        columnMenu={ColumnMenu}
                        cell={NoWrapCell}
                    />
                </GenericSelectSortGrid>
            </DateGridContext.Provider>
        </>
    )
}

export default MyProductsGrid;