import React, {useContext} from 'react';
import UserContext from './UserContext.js';

// react router
import {useHistory, useLocation} from 'react-router-dom'

// multilingual
import {Text} from './MultilingualText.js';
import {
    helpKey,
    keysightSoftwareManagerKey,
    mainMessages,
} from '../../assets/text/MultilingualText.js';
import { Tooltip} from "@progress/kendo-react-tooltip";
import VideoHamburgerMenu from "../EA/VideoHamburgerMenu";
import {linkMappings} from "../../LinkMappings";
import {useLocalization} from "@progress/kendo-react-intl";
import {HelpIcon} from "./icons";


function PageHeader() {
    const {
        siteLanguageDefault,
    } = useContext(UserContext);

    const location = useLocation();
    let pathname = location.pathname.slice(-1) === "/" ?
        location.pathname :
        location.pathname.concat("/");

    let history = useHistory();
    const localization = useLocalization();

    return (
        <>
            <div className={"k-page-header"}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    {!pathname.includes("/help") && (
                        <>
                            <div className="k-h2">
                                <Text
                                    textkey={keysightSoftwareManagerKey}
                                    textdefault={mainMessages[siteLanguageDefault][keysightSoftwareManagerKey]}
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px'
                            }}>
                                <div>
                                    <Tooltip
                                        anchorElement="target"
                                        showCallout={true}
                                        parentTitle={true}
                                        openDelay={0}
                                        position="left"
                                    >
                                        <div
                                            title={localization.toLanguageString(helpKey, mainMessages[siteLanguageDefault][helpKey])}
                                            onClick={() => window.open(pathname + 'help', "_blank")}
                                        >
                                            <HelpIcon size={'medium'} style={{ cursor: 'pointer', marginTop: '3px' }} />
                                        </div>
                                    </Tooltip>
                                </div>

                                {(pathname.includes(linkMappings['Manage My Enterprise Agreements'].actionLink) ||
                                        pathname.includes('/ea-detailed-view') ||
                                        pathname.includes('/ea-request-license') ||
                                        pathname.includes('/ea-request-again')) &&
                                    (
                                        <VideoHamburgerMenu/>
                                    )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default PageHeader;