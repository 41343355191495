import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';
import uuid from 'react-uuid';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../../common/Alert.js';
import {EmailDialog} from '../../common/EmailModal.js';
import {LicenseDownloadPopover} from '../../RequestLicense/LicenseDownload/Popovers.js';
import {PanelbarHeader} from './PanelbarHeader.js';
import {downloadLicenses} from '../../common/utilities.js';

// kendo react
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    mainMessages,
    emailReceiveKey,
    emailSuccessKey,
    notGeneratedAvailableRedeemKey,
    redeemNotAvailableErrorKey,
    errorOccurredRequestKey,
    requestProcessingMessageKey,
    requestProcessingKey,
    cannotGenerateMessageKey,
    cannotGenerateKey,
    notGeneratedSupportAgentKey,
    generationTimeoutKey,
    descriptionKey,
    downloadAllLicensesKey,
    mailAllKey,
    productNumberKey,
    qtyKey,
    processingCompletedKey,
    licensesReadyKey, assignMoreKey
} from '../../../assets/text/MultilingualText.js';
import {orderBy} from "@progress/kendo-data-query";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {NoWrapCell} from "../../common/Grid";
import ClientGrid from "./ClientGrid";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {DownloadIcon, IconButton, MailIcon} from "../../common/icons";

// context to store popover reference, when to show, and which popover to show
export const PopoverContext = createContext({
    onMouseOver: () => null,
});

function Complete(props) {
    const {
        setIsLoading,
        assignedProducts,
        setAssignedProducts,
        changeStepActivation,
        clientInfo,
        requestedProducts,
        setRequestedProducts,
        handleBeforeUnload,
        stepperItems,
        setStepperItems,
        cartProducts
    } = props
    const {
        accessToken,
        siteLanguageDefault,
        timeout
    } = useContext(UserContext);
    const localization = useLocalization();

    const [showPopover, setShowPopover] = useState(false);
    const [popoverType, setPopoverType] = useState('generalError');
    const [popoverRef, setPopoverRef] = useState();
    const [showEmailModal, setShowEmailModal] = useState(false);

    const [disableEmailDownloadButtons, setDisableEmailDownloadButtons] = useState(true);
    const [disableAssignMoreProducts, setDisableAssignMoreProducts] = useState(true);

    const [showInProgress, setShowInProgress] = useState(true)
    const showInProgressHandler = () => {
        setShowInProgress(false);
    }

    const [showSuccess, setShowSuccess] = useState(false)
    const showSuccessHandler = () => {
        setShowSuccess(false);
    }

    const [showGenerationError, setShowGenerationError] = useState(false)
    const showGenerationErrorHandler = () => {
        setShowGenerationError(false);
    }

    const [showSupportError, setShowSupportError] = useState(false)
    const showSupportErrorHandler = () => {
        setShowSupportError(false);
    }

    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    const [showQtyError, setShowQtyError] = useState(false)
    const showQtyErrorHandler = () => {
        setShowQtyError(false);
    }

    const [showEntitlementError, setShowEntitlementError] = useState(false)
    const showEntitlementErrorHandler = () => {
        setShowEntitlementError(false);
    }

    //Show success alert
    useEffect(() => {
        const requiredCount = requestedProducts.length;
        let successCount = 0

        requestedProducts.forEach(host => {
            if (host.icons.download || host.icons.cloudAlert) {
                successCount++
            }
        })

        if (successCount === requiredCount) {
            setShowInProgress(false)
            setShowSuccess(true)
        }

    }, [requestedProducts])

    async function emailSupport(host) {
        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let cleanProducts = JSON.parse(JSON.stringify(host['products']));
        cleanProducts.forEach(product => {
            delete product['inEdit'];
            delete product['unique_id'];
            delete product['transaction_id'];
        })
        let formData = new FormData();
        formData.append('File', host['file']);

        let data = {
            "transaction_id": host['transaction_id'],
            "hostid_type": host['host_id_type'],
            "products": cleanProducts,
            "host_id": host['host_id'][0],
            "serial_id": host['serial_id'],
        };

        formData.append('Data', JSON.stringify(data));
        axios.post(
            config.request_license.EMAIL_SUPPORT,
            formData,
            {headers: headers, timeout: timeout}
        )
            .then((response) => {
            })
            .catch((error) => {
            });
    }

    //Request new license for every host in assign products state
    useEffect(() => {
        async function requestNewLicense(host, index) {
            let headers = {
                'Authorization': 'Bearer ' + accessToken
            };

            let cleanProducts = JSON.parse(JSON.stringify(host['products']));
            cleanProducts.forEach(product => {
                delete product['inEdit']
                delete product['unique_id']
                delete product['transaction_id']
            });
            let formData = new FormData();
            formData.append('File', host['file']);

            let data = {
                sub_module: 'CHECKOUT',
                module: 'SOFTWARE/ACCESS',
                request_from: '',
                action: 'SAVE',
                input_json: {
                    transaction_id: host['transaction_id'],
                    products: cleanProducts,
                    host_id: host['host_id'][0],
                    serial_id: host['serial_id'],
                    client_info: {
                        company_info: clientInfo.company_info,
                        asset_id: clientInfo.asset_info,
                        po_number: clientInfo.po_number,
                        notes: clientInfo.client_notes
                    }
                }
            };

            formData.append('Data', JSON.stringify(data));

            let savedHost = requestedProducts[index];
            axios.post(
                config.software_access.SOFTWARE_ACCESS,
                formData,
                {headers: headers, timeout: timeout}
            )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data['license_generated'] === 'YES') {
                            let licenseType = response.data['license_type']
                            switch (licenseType) {
                                case "FILE":
                                case "KEY":
                                    savedHost['icons']['download'] = true;
                                    savedHost['icons']['loading'] = false;
                                    break;
                                case "CLOUD":
                                    savedHost['icons']['cloudAlert'] = true;
                                    savedHost['icons']['loading'] = false;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    setRequestedProducts([...requestedProducts]);

                    let index = assignedProducts.findIndex(host => host.transaction_id === savedHost.transaction_id)
                    assignedProducts.splice(index, 1)
                    setAssignedProducts([...assignedProducts])
                })
                .catch((error) => {
                    console.log("ERROR: Failed to request new license", error);
                    setShowInProgress(false)
                    savedHost['icons']['loading'] = false;
                    let retry = false
                    if (!error.response) { //Internet disconnected
                        savedHost['icons']['generationError'] = true;
                        setShowGenerationError(true)
                    } else if (error.toString().includes('timeout')) { //Axios timeout
                        savedHost['icons']['supportError'] = true;
                        setShowSupportError(true)
                    } else {
                        if (error.response?.status === 408 || error.response?.status === 504) { //API & gateway timeout
                            savedHost['icons']['supportError'] = true;
                            setShowSupportError(true)
                        }
                        if (error.response?.hasOwnProperty('data')) {
                            let errorCode = error.response.data['error_code'].toUpperCase();
                            switch (errorCode) {
                                case "MOD_LICENSE_228": //Plugin Fail
                                    savedHost['icons']['supportError'] = true;
                                    setShowSupportError(true)
                                    break;
                                case "MOD_LICENSE_220": //Qty consumed & user can retry
                                    savedHost['icons']['qtyError'] = true;
                                    setShowQtyError(true)
                                    retry = true
                                    break;
                                default: //Backend/DB error/rollback & user can retry
                                    savedHost['icons']['entitlementError'] = true;
                                    setShowEntitlementError(true)
                                    retry = true
                                    break
                            }
                        }
                    }
                    setRequestedProducts([...requestedProducts]);

                    //Hosts that can retry should appear again in license preview
                    let index = assignedProducts.findIndex(host => host.transaction_id === savedHost.transaction_id)
                    if (retry) {
                        assignedProducts[index].transaction_id = uuid()
                    } else {
                        assignedProducts.splice(index, 1)
                    }
                    setAssignedProducts([...assignedProducts])

                    if (!error.response) {
                        emailSupport(host);
                    }
                });
        }

        requestedProducts.forEach((host, index) => {
            requestNewLicense(host, index);
        })
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps


    const [downloadableTransactionIDs, setDownloadableTransactionIDs] = useState([]);


    //Keeps track of what transaction ids are downloadable
    useEffect(() => {
        let transactionIDs = [];
        requestedProducts.forEach(host => {
            if (host.icons.download) {
                transactionIDs.push(host.transaction_id);
            }
        })
        setDownloadableTransactionIDs(transactionIDs);
    }, [requestedProducts])

    //Download all license from all host with the download icon
    const downloadAllLicenses = () => {
        downloadLicenses(downloadableTransactionIDs, accessToken, setIsLoading, handleBeforeUnload);
    }

    //Enable or disable email/download selected license button
    useEffect(() => {
        let notFinishedLoading = false;
        let isDownloadable = false;
        requestedProducts.forEach(host => {
            //Enable buttons if all hosts are done loading and at least one downloadable host
            if (host.icons.loading) {
                notFinishedLoading = true;
            }

            if (host.icons.download) {
                isDownloadable = true;
            }
        })

        if (notFinishedLoading) {
            setDisableEmailDownloadButtons(true);
            setDisableAssignMoreProducts(true);
            // reset stepper state
            let newStepperItems = stepperItems
            newStepperItems[0].disabled = true;
            newStepperItems[1].disabled = true;
            newStepperItems[2].disabled = true;
            newStepperItems[3].disabled = false;
            setStepperItems([...newStepperItems])
        } else {
            if (isDownloadable) {
                setDisableEmailDownloadButtons(false);
            } else {
                setDisableEmailDownloadButtons(true);
            }
            setDisableAssignMoreProducts(false);
            // reset stepper state
            let newStepperItems = stepperItems
            newStepperItems[0].disabled = false;
            newStepperItems[1].disabled = (cartProducts.length <= 0);
            newStepperItems[2].disabled = true;
            newStepperItems[3].disabled = true;
            setStepperItems([...newStepperItems])
        }
    }, [requestedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    //Sets which popover to show and it's corresponding reference
    const handlePopoverMouseOver = useCallback(
        (event) => {
            if (event.show) {
                setShowPopover(true);
            } else {
                setShowPopover(false);
            }
            if (event.cloudAlert) {
                setPopoverType('cloudAlert');
            } else if (event.supportError) {
                setPopoverType('supportError');
            } else if (event.generationError) {
                setPopoverType('generationError');
            } else if (event.qtyError) {
                setPopoverType('qtyError');
            } else {
                setPopoverType('entitlementError');
            }

            setPopoverRef(event.popoverRef);
        },
        [setShowPopover, setPopoverType, setPopoverRef]
    );

    return (
        <>
            {(clientInfo.company_info || clientInfo.asset_info || clientInfo.po_number || clientInfo.client_notes) && (
                <ClientGrid
                    clientInfo={clientInfo}
                />
            )}
            <PopoverContext.Provider
                value={{
                    onMouseOver: handlePopoverMouseOver
                }}
            >
                {requestedProducts.length !== 0 && (
                    requestedProducts.map((item) => {
                        return (
                            <PanelBar
                                key={item.transaction_id}
                                expanded={['.0']}
                                isControlled={true}
                                className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                            >
                                <PanelBarItem
                                    title={<PanelbarHeader
                                        hostJSON={item}
                                        setIsLoading={setIsLoading}
                                        handleBeforeUnload={handleBeforeUnload}
                                        showAssignedCount={false}
                                    />}
                                >
                                    <Grid
                                        className={'assign-host-grid'}
                                        scrollable={'none'}
                                        data={orderBy(item.products, [{
                                            field: "prod_num",
                                            dir: "desc"
                                        }])}
                                    >
                                        <GridColumn
                                            field="product_number"
                                            title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                            cell={NoWrapCell}
                                        />
                                        <GridColumn
                                            field="product_desc"
                                            title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                        />
                                        <GridColumn
                                            field="qty_requested"
                                            title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                        />
                                    </Grid>
                                </PanelBarItem>
                            </PanelBar>
                        )
                    }))}
            </PopoverContext.Provider>
            <LicenseDownloadPopover
                type={popoverType}
                showPopover={showPopover}
                popoverRef={popoverRef}
            />
            <Row
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <Col>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        type={"button"}
                        onClick={() => {
                            changeStepActivation(0)
                        }}
                        disabled={disableAssignMoreProducts}
                    >
                        {localization.toLanguageString(assignMoreKey, mainMessages[siteLanguageDefault][assignMoreKey])}
                    </Button>
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.938rem'
                        }}
                    >
                        {showInProgress && (
                            <Alert
                                type={'default'}
                                showHandler={showInProgressHandler}
                                title={localization.toLanguageString(requestProcessingKey, mainMessages[siteLanguageDefault][requestProcessingKey])}
                                message={localization.toLanguageString(requestProcessingMessageKey, mainMessages[siteLanguageDefault][requestProcessingMessageKey])}
                            />
                        )}

                        {showSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={showSuccessHandler}
                                title={localization.toLanguageString(processingCompletedKey, mainMessages[siteLanguageDefault][processingCompletedKey])}
                                message={localization.toLanguageString(licensesReadyKey, mainMessages[siteLanguageDefault][licensesReadyKey])}
                            />
                        )}

                        {showGenerationError && (
                            <Alert
                                type={'error'}
                                showHandler={showGenerationErrorHandler}
                                title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                                message={localization.toLanguageString(redeemNotAvailableErrorKey, mainMessages[siteLanguageDefault][redeemNotAvailableErrorKey])}
                            />
                        )}

                        {showQtyError && (
                            <Alert
                                type={'error'}
                                showHandler={showQtyErrorHandler}
                                title={localization.toLanguageString(cannotGenerateKey, mainMessages[siteLanguageDefault][cannotGenerateKey])}
                                message={localization.toLanguageString(cannotGenerateMessageKey, mainMessages[siteLanguageDefault][cannotGenerateMessageKey])}
                            />
                        )}

                        {showSupportError && (
                            <Alert
                                type={'error'}
                                showHandler={showSupportErrorHandler}
                                title={localization.toLanguageString(generationTimeoutKey, mainMessages[siteLanguageDefault][generationTimeoutKey])}
                                message={localization.toLanguageString(notGeneratedSupportAgentKey, mainMessages[siteLanguageDefault][notGeneratedSupportAgentKey])}
                            />
                        )}

                        {showEntitlementError && (
                            <Alert
                                type={'error'}
                                showHandler={showEntitlementErrorHandler}
                                title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                                message={localization.toLanguageString(notGeneratedAvailableRedeemKey, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey])}
                            />
                        )}

                        {showEmailSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={alertEmailSuccessHandler}
                                title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                            />
                        )}
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            flexWrap: 'wrap',
                            gap: '0.5rem'
                        }}
                    >
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                disabled={disableEmailDownloadButtons}
                                onClick={() => {
                                    setShowEmailModal(true)
                                }}
                                Icon={MailIcon}
                            />
                        </Tooltip>
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(downloadAllLicensesKey, mainMessages[siteLanguageDefault][downloadAllLicensesKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                onClick={downloadAllLicenses}
                                disabled={disableEmailDownloadButtons}
                                Icon={DownloadIcon}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </Row>
            {showEmailModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailModal}
                    setShowEmailSuccess={setShowEmailSuccess}
                    transactionIDs={downloadableTransactionIDs}
                    setIsLoading={setIsLoading}
                    request_source='SWA_CHECKOUT'
                />
            )}
        </>
    );
}

export default Complete;
