import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import config from "../../../config";

//components
import UserContext from "../../common/UserContext";

//multilingual
import {
    addLicenseKey,
    checkTryAgainKey,
    infoProvidedInvalidKey,
    certNumberKey,
    confirmationCodeKey,
    currentHostIdKey,
    whereToFileURLFileKey,
    contactUsHeaderKey,
    genericErrorTitleKey,
    infoKey,
    mainMessages,
    orderNumberKey,
    productNumberKey,
    sequenceNumberKey,
    uploadKeysightURLFileKey,
    TransportURLFile1,
    TransportURLFile2,
    TransportURLFile3,
    TransportURLFile4
} from "../../../assets/text/MultilingualText";

//kendo
import {useLocalization} from "@progress/kendo-react-intl";
import {Label} from "@progress/kendo-react-labels";
import {RadioButton, TextBox} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import {Dialog} from '@progress/kendo-react-dialogs';
import {Col, Row} from "reactstrap";
import {Upload} from "@progress/kendo-react-upload";
import Alert from "../../common/Alert";
import {HelpIcon} from "../../common/icons";
import KSMPopover from "../../common/Popovers";


export default function AddLicense(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        addLicenses,
        generalError,
        hideGeneralError,
        invalidParams,
        hideInvalidParams,
        removeAlerts
    } = props

    const popoverAnchor = useRef(null);
    const [popoverShow, setPopoverShow] = useState(false)

    //Show popover if there is an error on mouse over
    const onMouseOver = (ev) => {
        setPopoverShow(true);
    };

    //Remove popover on mouse leave
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) setPopoverShow(false);
    };

    const searchTextStyleEnable = {}
    const searchTextStyleDisable = {color: '#AAAAAA'}
    const [searchOptionOneStyle, setSearchOptionOneStyle] = useState(searchTextStyleEnable)
    const [searchOptionTwoStyle, setSearchOptionTwoStyle] = useState(searchTextStyleDisable)
    const [searchOption, setSearchOption] = useState('one');
    const searchOptionHandleChange = useCallback(
        (e) => {
            setSearchOption(e.value);
            resetValid()
            removeAlerts()
            if (e.value === 'one') {
                setSearchOptionOneStyle(searchTextStyleEnable)
                setSearchOptionTwoStyle(searchTextStyleDisable)
            } else {
                setSearchOptionOneStyle(searchTextStyleDisable)
                setSearchOptionTwoStyle(searchTextStyleEnable)
            }
        },
        [setSearchOption]); // eslint-disable-line react-hooks/exhaustive-deps

    const extension = '.url'
    const buttonName = 'URL File'
    const [file, setFile] = useState("");
    const [fileContent, setFileContent] = useState("")
    const onFileUpload = (event) => {
        setFile(event.target.files[0].getRawFile());
    };

    useEffect(() => {
        if (file) {
            let reader = new FileReader();
            reader.onload = function (event) {
                setFileContent(event.target.result)
            };
            reader.readAsText(file)
        }
    }, [file])

    const colStyle = {
        width: '13rem'
    }

    const textBoxStyle = {
        borderColor: '#AAAAAA'
    }

    const textBoxInvalidStyle = {
        borderColor: 'rgba(243, 23, 0, 0.5)'
    }

    const [hostID, setHostID] = useState('')
    const hostIDChange = (e) => {
        setHostID(e.target.value)
    }
    const [hostIDValid, setHostIDValid] = useState(textBoxStyle)

    const [confirmCode, setConfirmCode] = useState('')
    const confirmCodeChange = (e) => {
        setConfirmCode(e.target.value)
    }
    const [confirmCodeValid, setConfirmCodeValid] = useState(textBoxStyle)

    const [prodNum, setProdNum] = useState('')
    const prodNumChange = (e) => {
        setProdNum(e.target.value)
    }
    const [prodNumValid, setProdNumValid] = useState(textBoxStyle)

    const [orderNum, setOrderNum] = useState('')
    const orderNumChange = (e) => {
        setOrderNum(e.target.value)
    }
    const [orderNumValid, setOrderNumValid] = useState(textBoxStyle)

    const [certNum, setCertNum] = useState('')
    const certNumChange = (e) => {
        setCertNum(e.target.value)
    }
    const [certNumValid, setCertNumValid] = useState(textBoxStyle)

    const [sn, setSn] = useState('')
    const snChange = (e) => {
        setSn(e.target.value)
    }
    const [snValid, setSnValid] = useState(textBoxStyle)


    const resetValid = () => {
        setHostIDValid(textBoxStyle)
        setConfirmCodeValid(textBoxStyle)
        setOrderNumValid(textBoxStyle)
        setCertNumValid(textBoxStyle)
        setSnValid(textBoxStyle)
        setProdNumValid(textBoxStyle)
    }

    const add = () => {
        resetValid()
        let params = ''
        if (searchOption === 'one') {
            let contentSplit = fileContent.split('?')
            if (contentSplit.length > 1) {
                params = contentSplit[1]
            }
        } else {
            let valid = true
            if (hostID.trim() === "") {
                valid = false
                setHostIDValid(textBoxInvalidStyle)
            }
            if (confirmCode.trim() === "") {
                valid = false
                setConfirmCodeValid(textBoxInvalidStyle)
            }
            if (prodNum.trim() === "") {
                valid = false
                setProdNumValid(textBoxInvalidStyle)
            }
            if (orderNum.trim() === "") {
                valid = false
                setOrderNumValid(textBoxInvalidStyle)
            }
            if (certNum.trim() === "") {
                valid = false
                setCertNumValid(textBoxInvalidStyle)
            }
            if (sn.trim() === "") {
                valid = false
                setSnValid(textBoxInvalidStyle)
            }
            if (valid) {
                params = 'currentHostId=' + hostID.trim() +
                    '&confirmCode=' + confirmCode.trim() +
                    '&prodNum=' + prodNum.trim() +
                    '&orderNum=' + orderNum.trim() +
                    '&certNum=' + certNum.trim() +
                    '&SN=' + sn.trim()
            } else {
                return null
            }
        }
        addLicenses(params, true)
    }

    return (

        <Dialog
            className={'ksm-dialog'}
            width={"53.125rem"}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.938rem'
            }}>
                <div style={{
                    fontSize: '2.5rem'
                }}>
                    {localization.toLanguageString(addLicenseKey, mainMessages[siteLanguageDefault][addLicenseKey])}
                </div>

                <Row>
                    <Col style={{
                        maxWidth: '2rem'
                    }}>
                        <RadioButton
                            name="Search Options"
                            value="one"
                            checked={searchOption === 'one'}
                            onChange={searchOptionHandleChange}
                        />
                    </Col>
                    <Col>
                        <KSMPopover
                            show={popoverShow}
                            anchor={popoverAnchor.current}
                            position={'bottom'}
                            type={'info'}
                            style={{
                                width: '30rem'
                            }}
                            message={<div>
                                <b style={{
                                    fontSize: '1.5rem'
                                }}>
                                    {localization.toLanguageString(infoKey, mainMessages[siteLanguageDefault][infoKey])}
                                </b>

                                <ul style={{
                                    padding: 0,
                                    margin: '0 5px',
                                }}>
                                    <li>
                                        {localization.toLanguageString(TransportURLFile1, mainMessages[siteLanguageDefault][TransportURLFile1])}
                                        :
                                        <br/>
                                        C:\Program Files\Agilent\licensing\Transport Confirmations
                                    </li>
                                    <li>
                                        {localization.toLanguageString(TransportURLFile2, mainMessages[siteLanguageDefault][TransportURLFile2])}
                                    </li>
                                    <li>
                                        {localization.toLanguageString(TransportURLFile3, mainMessages[siteLanguageDefault][TransportURLFile3])}
                                    </li>
                                    <li>
                                        {localization.toLanguageString(TransportURLFile4, mainMessages[siteLanguageDefault][TransportURLFile4])}
                                    </li>
                                </ul>
                            </div>}
                        />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.938rem',
                        }}>
                            <u style={searchOptionOneStyle}>
                                {localization.toLanguageString(uploadKeysightURLFileKey, mainMessages[siteLanguageDefault][uploadKeysightURLFileKey])}
                            </u>
                            <div
                                style={{
                                    color: 'var(--color-alert-danger-base)'
                                }}
                                onMouseOver={onMouseOver}
                                onMouseOut={onMouseOut}
                                ref={popoverAnchor}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <HelpIcon
                                        size={'medium'}
                                        style={{
                                            marginRight: '0.313rem'
                                        }}
                                    />
                                    <div>
                                        {localization.toLanguageString(whereToFileURLFileKey, mainMessages[siteLanguageDefault][whereToFileURLFileKey])}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Upload
                            className="ksm-upload"
                            batch={false}
                            multiple={false}
                            withCredentials={false}
                            defaultFiles={[]}
                            restrictions={{
                                allowedExtensions: [extension]
                            }}
                            saveUrl={config.utilities.health}
                            onAdd={onFileUpload}
                            disabled={searchOption === 'two'}
                            selectMessageUI={() =>
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    fillMode={"solid"}
                                    rounded={"small"}
                                    type={"button"}
                                >
                                    {buttonName}
                                </Button>
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{
                        maxWidth: '2rem',
                        marginTop: '0.313rem'
                    }}>
                        <RadioButton
                            name="Search Options"
                            value="two"
                            checked={searchOption === 'two'}
                            onChange={searchOptionHandleChange}
                        />
                    </Col>
                    <Col>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.938rem',
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <div style={colStyle}>
                                    <Label style={searchOptionTwoStyle}>
                                        {localization.toLanguageString(currentHostIdKey, mainMessages[siteLanguageDefault][currentHostIdKey])}:
                                    </Label>
                                </div>

                                <TextBox
                                    className={'ksm-input'}
                                    value={hostID}
                                    onChange={hostIDChange}
                                    style={hostIDValid}
                                    disabled={searchOption === 'one'}
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <div style={colStyle}>
                                    <Label style={searchOptionTwoStyle}>
                                        {localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}:
                                    </Label>
                                </div>

                                <TextBox
                                    value={prodNum}
                                    onChange={prodNumChange}
                                    style={prodNumValid}
                                    disabled={searchOption === 'one'}
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <div style={colStyle}>
                                    <Label style={searchOptionTwoStyle}>
                                        {localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}:
                                    </Label>
                                </div>

                                <TextBox
                                    value={orderNum}
                                    onChange={orderNumChange}
                                    style={orderNumValid}
                                    disabled={searchOption === 'one'}
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <div style={colStyle}>
                                    <Label style={searchOptionTwoStyle}>
                                        {localization.toLanguageString(confirmationCodeKey, mainMessages[siteLanguageDefault][confirmationCodeKey])}:
                                    </Label>
                                </div>

                                <TextBox
                                    value={confirmCode}
                                    onChange={confirmCodeChange}
                                    style={confirmCodeValid}
                                    disabled={searchOption === 'one'}
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <div style={colStyle}>
                                    <Label style={searchOptionTwoStyle}>
                                        {localization.toLanguageString(certNumberKey, mainMessages[siteLanguageDefault][certNumberKey])}:
                                    </Label>
                                </div>

                                <TextBox
                                    value={certNum}
                                    onChange={certNumChange}
                                    style={certNumValid}
                                    disabled={searchOption === 'one'}
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <div style={colStyle}>
                                    <Label style={searchOptionTwoStyle}>
                                        {localization.toLanguageString(sequenceNumberKey, mainMessages[siteLanguageDefault][sequenceNumberKey])}:
                                    </Label>
                                </div>

                                <TextBox
                                    value={sn}
                                    onChange={snChange}
                                    style={snValid}
                                    disabled={searchOption === 'one'}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <div style={{
                    display: 'flex',
                    justifyContent: 'end',
                    gap: '0.938rem'
                }}>

                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        onClick={() => {
                            add()
                        }}
                    >
                        {localization.toLanguageString(addLicenseKey, mainMessages[siteLanguageDefault][addLicenseKey])}
                    </Button>
                </div>
            </div>

            {generalError && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0.938rem',
                }}>
                    <div style={{
                        width: '35.813rem'
                    }}>
                        <Alert
                            type={'error'}
                            title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                            message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                            showHandler={hideGeneralError}
                        />
                    </div>
                </div>
            )}

            {invalidParams && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0.938rem',
                }}>
                    <div style={{
                        width: '35.813rem'
                    }}>
                        <Alert
                            type={'error'}
                            title={localization.toLanguageString(infoProvidedInvalidKey, mainMessages[siteLanguageDefault][infoProvidedInvalidKey])}
                            message={localization.toLanguageString(checkTryAgainKey, mainMessages[siteLanguageDefault][checkTryAgainKey])}
                            showHandler={hideInvalidParams}
                        />
                    </div>
                </div>
            )}
        </Dialog>
    )
}