import React, {useContext, useEffect, useRef, useState} from 'react';
import uuid from 'react-uuid';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// css
import '../assets/css/TrialLicense.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../components/common/Spinner.js';
import PageHeader from '../components/common/PageHeader.js';
import ContactInformationModal from '../components/TrialLicense/ContactInformationModal.js';
import ContactUsModal from '../components/TrialLicense/ContactUsModal.js';
import Alert from '../components/common/Alert.js';
import {NoWrapCell} from '../components/common/Grid.js';
import {toHtml} from '../components/common/utilities.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Label} from '@progress/kendo-react-labels';
import {AutoComplete} from '@progress/kendo-react-dropdowns';
import {Upload} from '@progress/kendo-react-upload';
import {Input} from '@progress/kendo-react-inputs';
import {Popover} from '@progress/kendo-react-tooltip';
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {filterBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    contactUsHeaderKey,
    contactUsTrailLicenseKey,
    continueKey,
    descriptionKey,
    durationKey,
    encounteredErrorKey,
    ensureHostIdCorrectKey,
    enterHostInfoKey,
    genericErrorTitleKey,
    hostIdFormattingKey,
    hostIdKey,
    invalidFileKey,
    invalidHostIdKey,
    invalidHostKey,
    invalidSerialNumberKey,
    loadingPeriodsKey,
    mainMessages,
    messageSentKey,
    noDataAvailableKey,
    notTrailDurationKey,
    productKey,
    quantityKey,
    requestAnotherTrailKey,
    requestSubmittedKey,
    reviewedByKeysightKey,
    selectHostKey,
    serialNumberFormattingKey,
    specificHostFileKey,
    trailLicenseExistHostKey,
    trailLicenseNotAvailableKey,
    trailLicenseRequestKey,
    trailLicenseSentKey,
    trialDNEKey,
    trialLicenseSubmitErrorClickHereSubtitleKey,
    trialLicenseSubmitErrorSubtitle2Key,
    trialLicenseSubmitSuccessKey,
    trialLicenseSubmitSuccessSubtitle1Key,
    trialLicenseSubmitSuccessSubtitle2Key, uploadFileKey
} from '../assets/text/MultilingualText.js';
import KSMPopover from "../components/common/Popovers";
import {InfoIcon} from "../components/common/icons";

const initialIsVisibleState = {
    hostIDHint: false,
    serialNumberHint: false,
    genericHostIDError: false,
    hostIDError: false,
    dneError: false,
    durationError: false,
    serialNumberError: false,
    fileError: false,
    missingFieldsError: false,
    invalidEmailError: false,
    submitError: false,
    submitSuccess: false,
    contactUsSuccess: false,
    genericSubmitError: false,
    requestError: false,
    contactInformationModal: false,
    contactUsModal: false
};

function TrialLicense(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let history = useHistory();

    const defaultGridMessage = localization.toLanguageString(
        noDataAvailableKey,
        mainMessages[siteLanguageDefault][noDataAvailableKey]
    );
    const defaultLoadingMessage = localization.toLanguageString(
        loadingPeriodsKey,
        mainMessages[siteLanguageDefault][loadingPeriodsKey]
    );

    const [xAuthToken, setXAuthToken] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [gridNoRecordsMessage, setGridNoRecordsMessage] = useState(defaultGridMessage);

    const [product, setProduct] = useState([]);

    const [hostModal, setHostModal] = useState("");
    const [hostIDType, setHostIDType] = useState("");
    const [hostIsSerial, setHostIsSerial] = useState("");

    const [hostID, setHostID] = useState("");
    const hostIDPopover = useRef(null);
    const hostIDAnchor = useRef(null);

    const [serialNumber, setSerialNumber] = useState("");
    const serialNumberAnchor = useRef(null);

    const [hostFile, setHostFile] = useState("");
    const [hostFileExtension, setHostFileExtension] = useState("");
    const [extension, setExtension] = useState("");
    const hostFilePopoverAnchor = useRef(null);

    const [hostIDLabel, setHostIDLabel] = useState("");
    const [hostIDHint, setHostIDHint] = useState("");
    const [hostIDError, setHostIDError] = useState("");
    const [hostIDPatterns, setHostIDPatterns] = useState("");
    const [hostIDSuggestions, setHostIDSuggestions] = useState([]);

    const [serialNumberLabel, setSerialNumberLabel] = useState("");
    const [serialNumberHint, setSerialNumberHint] = useState("");
    const [serialNumberError, setSerialNumberError] = useState("");
    const [serialNumberPatterns, setSerialNumberPatterns] = useState("");

    const [contactInformation, setContactInformation] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        jobTitle: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        country: ""
    });
    const [countries, setCountries] = useState([]);
    const [body, setBody] = useState("");

    const [isValid, setIsValid] = useState({
        hostID: true,
        serialNumber: true
    });

    const [isVisible, setIsVisible] = useState(initialIsVisibleState);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const ContactUs = () => {
        return (
            <>
                {localization.toLanguageString(trailLicenseExistHostKey, mainMessages[siteLanguageDefault][trailLicenseExistHostKey])} "{hostID}".
                <br/>
                <br/>
                {localization.toLanguageString(trialLicenseSubmitErrorSubtitle2Key, mainMessages[siteLanguageDefault][trialLicenseSubmitErrorSubtitle2Key])}
                <p
                    className="trial-license-contact-us"
                    onClick={() => setIsVisible(isVisible => ({...isVisible, contactUsModal: true}))}
                >
                    {localization.toLanguageString(trialLicenseSubmitErrorClickHereSubtitleKey, mainMessages[siteLanguageDefault][trialLicenseSubmitErrorClickHereSubtitleKey])}
                </p>.
            </>
        )
    }

    /*
     * onHostIDChange() updates the host id on change
     * @param {e} the input change event
    */
    const onHostIDChange = (e) => {
        let field = e.target.props.field;

        setIsVisible(initialIsVisibleState);
        setIsValid(isValid => ({
            ...isValid,
            [field]: true
        }));

        if (field === "hostID") {
            filterData(e.value);
            setHostID(e.value);
        } else if (field === "serialNumber") {
            setSerialNumber(e.value);
        }
    }

    /*
     * showHint() shows the hint
     * @param {e} the on mouse over event
     * @param {hint} the hint key
    */
    const showHint = (e, hint) => {
        setIsVisible(isVisible => ({
            ...isVisible,
            [hint]: true
        }))
    }

    /*
     * hideHint() hides the hint
     * @param {e} the on mouse out event
     * @param {hint} the hint key
    */
    const hideHint = (e, hint) => {
        let isChildEl = e.currentTarget.contains(e.relatedTarget);
        if (!isChildEl) {
            setIsVisible(isVisible => ({
                ...isVisible,
                [hint]: false
            }))
        }
    }

    /*
    * onHostFileUpload() uploads the host file
    */
    const onHostFileUpload = (event) => {
        setIsVisible(initialIsVisibleState);
        setHostFileExtension(event.target.files[0].extension);
        setHostFile(event.target.files[0].getRawFile());
    };

    // filter the Host ID suggestion dropdown based on user's input
    const [filteredHostIDSuggestions, setFilteredHostIDSuggestions] = useState([...hostIDSuggestions])
    const filterData = (value) => {
        const data = hostIDSuggestions
        const filter = {
            value: value,
            operator: "contains",
            ignoreCase: true,
        };
        if (value) {
            setFilteredHostIDSuggestions(filterBy(data, filter))
        } else {
            setFilteredHostIDSuggestions(data)
        }
    };

    /*
     * getProduct() gets all product for trial license
     * @param {productNumber} the product number
     * @param {token} the x auth token
    */
    const getProduct = (productNumber, token) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': token
        };

        setXAuthToken(token);
        setIsLoading(true);
        setGridNoRecordsMessage(defaultLoadingMessage);
        setIsVisible(initialIsVisibleState);

        let data = {
            module: "LICENSE",
            sub_module: "TRANSPORT",
            action: "SEARCH",
            input_json: [{"product": productNumber}]
        };

        let formData = new FormData();
        formData.append('File', '');
        formData.append('Data', JSON.stringify(data));

        axios.post(
            config.trial_license.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = typeof response.data === 'undefined' ? [] : response.data;

                    let productInfo = data.product_info || null;
                    let countryList = data.country_list || [];
                    let modalType = data.hostid_type_popup.toUpperCase() || "";
                    let hostType = data.host_id_type || "";
                    let isSerial = data.get_serial.toUpperCase() || "";

                    let hostLabel = data.host_id_info.asl_prompt || "";
                    let hostHint = data.host_id_info.input_hint || "";
                    let hostError = data.host_id_info.asl_error_message || "";
                    let hostPatterns = data.host_id_info.patterns || [];
                    let hostSuggestions = data.user_profile_tagged_host_ids || [];

                    let serialLabel = data.serial_number_info.asl_prompt || "";
                    let serialHint = data.serial_number_info.input_hint || "";
                    let serialError = data.serial_number_info.asl_error_message || "";
                    let serialPatterns = data.serial_number_info.patterns || [];

                    // assign variables from data
                    if (productInfo) {
                        productInfo.qty_available = 1;
                        setProduct([productInfo]);
                    }
                    setCountries(countryList);

                    modalType ? setHostModal(modalType) : setHostModal("");
                    hostType ? setHostIDType(hostType) : setHostIDType("");
                    isSerial ? setHostIsSerial(isSerial) : setHostIsSerial("");

                    hostLabel ? setHostIDLabel(hostLabel) : setHostIDLabel("");
                    hostHint ? setHostIDHint(hostHint) : setHostIDHint("");
                    hostError ? setHostIDError(hostError) : setHostIDError("");
                    hostSuggestions ? setHostIDSuggestions(hostSuggestions.sort()) : setHostIDSuggestions([]);

                    serialLabel ? setSerialNumberLabel(serialLabel) : setSerialNumberLabel("");
                    serialHint ? setSerialNumberHint(serialHint) : setSerialNumberHint("");
                    serialError ? setSerialNumberError(serialError) : setSerialNumberError("");

                    // cast pattern strings to regex
                    // note: regex casting removes addtional \ character for all regex patterns
                    // ex. ^\\w{1,20}$ becomes ^\w{1,20}$
                    hostPatterns.length ? hostPatterns = hostPatterns.map(pattern => new RegExp(pattern))
                        : hostPatterns = [];
                    serialPatterns.length ? serialPatterns = serialPatterns.map(pattern => new RegExp(pattern))
                        : serialPatterns = [];

                    // if optional serial numbers append empty string pattern to valid serial number patterns
                    if (isSerial === "O") {
                        let emptyPattern = new RegExp("^$");
                        serialPatterns.push(emptyPattern);
                    }

                    setHostIDPatterns(hostPatterns);
                    setSerialNumberPatterns(serialPatterns);

                    setIsLoading(false);
                    setGridNoRecordsMessage(defaultGridMessage);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Product", error);
                let status = typeof error?.response?.status === 'undefined' ? "" : error.response.status;
                let errorCode = typeof error?.response?.data?.error_code === 'undefined' ? "" : error.response.data.error_code;

                setIsLoading(false);
                setGridNoRecordsMessage(defaultGridMessage);

                if (status === 400) {
                    if (errorCode === "MOD_TRIAL_001") {
                        setIsVisible(isVisible => ({...isVisible, dneError: true}));
                    } else if (errorCode === "MOD_TRIAL_002") {
                        setIsVisible(isVisible => ({...isVisible, durationError: true}));
                    }
                }
            });
    }

    /*
     * validateHostPatterns() validates patterns for valid host id on the frontend
    */
    const validateHostPatterns = () => {
        setIsVisible(isVisible => ({
            ...isVisible,
            genericHostIDError: false,
            requestError: false,
            hostIDError: false,
            serialNumberError: false,
            fileError: false
        }));

        if (hostModal === "HOST") {
            let isValidHostID = !hostID ? false : hostIDPatterns.some(regex => regex.test(hostID.trim()));
            let isValidSerialNumber = (!serialNumber && hostIsSerial === "Y") ? false : serialNumberPatterns.some(regex => regex.test(serialNumber.trim()));

            // show errors for invalid host ids and serial numbers
            if (!isValidHostID) {
                setIsVisible(isVisible => ({...isVisible, hostIDError: true}));
                setIsValid(isValid => ({...isValid, hostID: false}));
            }
            if (!isValidSerialNumber && (hostIsSerial === "Y" || hostIsSerial === "O")) {
                setIsVisible(isVisible => ({...isVisible, serialNumberError: true}));
                setIsValid(isValid => ({...isValid, serialNumber: false}));
            }

            // call regex validation api for the following cases
            if (isValidHostID && isValidSerialNumber && (hostIsSerial === "Y" || hostIsSerial === "O")) {
                validateHostRegEx();
            }
            if (isValidHostID && hostIsSerial === "N") {
                validateHostRegEx();
            }
        }

        if (hostModal === "C2V" || hostModal === "HOST_FILE_BIN") {
            if ((hostModal === "C2V" && hostFileExtension === ".c2v") || (hostModal === "HOST_FILE_BIN" && hostFileExtension === ".bin")) {
                validateHostFile();
            } else {
                setIsVisible(isVisible => ({...isVisible, fileError: true}))
            }
        }
    }

    /*
     * validateHostRegEx() validates regex for valid host id on the backend
    */
    const validateHostRegEx = () => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': xAuthToken
        };

        let inputJSON = {
            "host_id_type": hostIDType,
            "host_or_imei_values": [hostID.trim()],
            "serial_id_value": serialNumber.trim()
        };

        let data = {
            module: "HOST",
            sub_module: "PATTERN",
            action: "VALIDATE",
            input_json: [inputJSON]
        };

        let formData = new FormData();
        formData.append('File', null);
        formData.append('Data', JSON.stringify(data));

        setIsLoading(true);

        axios.post(
            config.trial_license.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    setIsVisible(initialIsVisibleState);
                    setIsVisible(isVisible => ({...isVisible, contactInformationModal: true}));
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Validation Regex", error);
                let status = typeof error?.response?.status === 'undefined' ? "" : error.response.status;
                let errorCode = typeof error?.response?.data?.error_code === 'undefined' ? "" : error.response.data.error_code;

                setIsLoading(false);

                if (status === 400) {
                    errorCode === "MOD_HSVAL_211" ? setIsVisible(isVisible => ({...isVisible, hostIDError: true}))
                        : errorCode === "MOD_HSVAL_212" ? setIsVisible(isVisible => ({
                                ...isVisible,
                                serialNumberError: true
                            }))
                            : setIsVisible(isVisible => ({...isVisible, genericHostIDError: true}));
                } else {
                    setIsVisible(isVisible => ({...isVisible, requestError: true}));
                }
            });
    }

    /*
     * validateHostFile() validates the uploaded .bin or .c2v file
    */
    const validateHostFile = () => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': xAuthToken
        };

        let data = {
            "module": "HOST",
            "sub_module": "FILE",
            "action": "SUBMIT",
            "input_json": []
        };

        let formData = new FormData();
        formData.append('File', hostFile);
        formData.append('Output', 'HOSTID');
        formData.append('Data', JSON.stringify(data));

        setIsLoading(true);

        axios.post(
            config.trial_license.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let hostId = response?.data?.host_id;

                    setIsLoading(false);
                    setIsVisible(initialIsVisibleState);
                    setIsVisible(isVisible => ({...isVisible, contactInformationModal: true}));
                    setHostID(hostId);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Validation File", error);
                let status = typeof error?.response?.status === 'undefined' ? "" : error.response.status;

                setIsLoading(false);

                if (status === 400) {
                    setIsVisible(isVisible => ({...isVisible, fileError: true}))
                } else {
                    setIsVisible(isVisible => ({...isVisible, requestError: true}));
                }
            });
    }

    /*
     * submitRequest() submits the trial license request
    */
    const submitRequest = () => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': xAuthToken
        };

        let input_Redeem_JSON = {
            "users_accordions": [
                {
                    "transaction_id": uuid(),
                    "hostid_type": hostIDType,
                    "products": product,
                    "host_id": hostID,
                    "serial_id": serialNumber,
                    "order_id": null
                }
            ],
            "personal_information": {
                "first_name": contactInformation.firstName,
                "last_name": contactInformation.lastName,
                "login_name": contactInformation.email,
                "phone": contactInformation.phone,
                "company_name": contactInformation.companyName,
                "job_title": contactInformation.jobTitle,
                "address_1": contactInformation.addressLine1,
                "address_2": contactInformation.addressLine2,
                "city": contactInformation.city,
                "state": contactInformation.state,
                "zipcode": contactInformation.zip,
                "country": contactInformation.country?.country_code
            }
        };

        let data = {
            module: "LICENSE",
            sub_module: "TRIAL",
            action: "REDEEM",
            input_json: [input_Redeem_JSON]
        };

        let formData = new FormData();
        formData.append('File', hostFile);
        formData.append('Data', JSON.stringify(data));

        setIsLoading(true);
        setIsVisible(initialIsVisibleState);

        axios.post(
            config.trial_license.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    setIsSubmitted(true);
                    setIsVisible(isVisible => ({...isVisible, submitSuccess: true}));
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Submit Request", error);
                let status = typeof error?.response?.status === 'undefined' ? "" : error.response.status;
                let errorCode = typeof error?.response?.data?.error_code === 'undefined' ? "" : error.response.data.error_code;

                setIsLoading(false);
                setIsDisabled(true);

                if (status === 400) {
                    (errorCode === "MOD_TRIAL_003") ? setIsVisible(isVisible => ({...isVisible, submitError: true}))
                        : setIsVisible(isVisible => ({...isVisible, genericSubmitError: true}));
                } else {
                    setIsVisible(isVisible => ({...isVisible, genericSubmitError: true}));
                }
            });
    }

    /*
     * submitContactUs() submits the body to contact us on a failed trial license
    */
    const submitContactUs = () => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-auth-token': xAuthToken
        };

        let trialEmailInputJSON = {
            "host_id": hostID,
            "prod_num": product[0].prod_num,
            "user_comments": body,
            "personal_information": {
                "first_name": contactInformation.firstName,
                "last_name": contactInformation.lastName,
                "login_name": contactInformation.email,
                "phone": contactInformation.phone,
                "company_name": contactInformation.companyName,
                "job_title": contactInformation.jobTitle,
                "address_1": contactInformation.addressLine1,
                "address_2": contactInformation.addressLine2,
                "city": contactInformation.city,
                "state": contactInformation.state,
                "zipcode": contactInformation.zip,
                "country": contactInformation.country?.country_code
            }
        };

        let data = {
            module: "LICENSE",
            sub_module: "TRIAL",
            action: "EMAIL",
            input_json: [trialEmailInputJSON]
        };

        setIsLoading(true);
        setIsVisible(initialIsVisibleState);

        let formData = new FormData();
        formData.append('File', '');
        formData.append('Data', JSON.stringify(data));

        axios.post(
            config.trial_license.GUEST_API,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    setIsVisible(isVisible => ({...isVisible, contactUsSuccess: true}));
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Submit Contact Us Request", error);
                setIsLoading(false);
                setIsVisible(isVisible => ({...isVisible, genericSubmitError: true}));
            });
    }

    // initialize product grid on page load, redirect if no product number in qsp
    useEffect(() => {
        let prodNum = new URLSearchParams(window.location.search).get("ProdNum");
        let token = new URLSearchParams(window.location.search).get("Code");
        if (prodNum) {
            getProduct(prodNum, token);
        } else {
            history.push("/error");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // set file extension restriction
    useEffect(() => {
        hostModal === "C2V" ? setExtension(".c2v")
            : hostModal === "HOST_FILE_BIN" ? setExtension(".bin")
                : setExtension("");
    }, [hostModal])

    // enable continue button on the following cases
    useEffect(() => {
        if (hostModal === "HOST") {
            hostID && serialNumber && hostIsSerial === "Y" ? setIsDisabled(false)
                : hostID && (hostIsSerial === "O" || hostIsSerial === "N") ? setIsDisabled(false)
                    : setIsDisabled(true);
        }
        if (hostModal === "C2V" || hostModal === "HOST_FILE_BIN") {
            hostFile && hostModal === "C2V" && hostFileExtension === ".c2v" ? setIsDisabled(false)
                : hostFile && hostModal === "HOST_FILE_BIN" && hostFileExtension === ".bin" ? setIsDisabled(false)
                    : setIsDisabled(true);
        }
    }, [hostID, serialNumber, hostFile]) // eslint-disable-line react-hooks/exhaustive-deps

    // reset error and success messages on host id change
    useEffect(() => {
        setIsVisible(isVisible => ({
            ...isVisible,
            genericHostIDError: false,
            hostIDError: false,
            serialNumberError: false,
            fileError: false
        }));
    }, [hostID, serialNumber, hostFile]);

    //set focus to host id input field when host modal is HOST
    useEffect(() => {
        if (hostModal === "HOST") {
            hostIDAnchor.current._input.focus();
        }
    }, [hostModal]);

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <div className={"k-h3"}>
                        <Text
                            textkey={trailLicenseRequestKey}
                            textdefault={mainMessages[siteLanguageDefault][trailLicenseRequestKey]}
                        />
                    </div>
                </Row>
                {isSubmitted ? <>
                    <Row>
                        <div className={"k-h5 mb-1"}>
                            <Text
                                textkey={requestSubmittedKey}
                                textdefault={mainMessages[siteLanguageDefault][requestSubmittedKey]}
                            />
                        </div>
                        <div className={"k-h5 trial-license-host-subtitle"}>
                            <Text
                                textkey={trailLicenseSentKey}
                                textdefault={mainMessages[siteLanguageDefault][trailLicenseSentKey]}
                            />
                        </div>
                    </Row>
                    <PanelBar
                        className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                        isControlled={true}
                        expanded={['.0']}
                        expandMode={'single'}
                    >
                        <PanelBarItem
                            title={
                                <div
                                    className={"k-h5"}
                                    style={{
                                        color: 'white',
                                        margin: '0 0 0 2px'
                                    }}>
                                    {hostID}
                                </div>
                            }
                        >
                            <Grid data={product}>
                                <GridNoRecords>
                                    {gridNoRecordsMessage}
                                </GridNoRecords>
                                <GridColumn
                                    field="prod_num"
                                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="description"
                                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                />
                                <GridColumn
                                    field="trial_duration"
                                    title={localization.toLanguageString(durationKey, mainMessages[siteLanguageDefault][durationKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="qty_available"
                                    title={localization.toLanguageString(quantityKey, mainMessages[siteLanguageDefault][quantityKey])}
                                    cell={NoWrapCell}
                                />
                            </Grid>
                        </PanelBarItem>
                    </PanelBar>
                    <Row className={"mb-3"}>
                        <Col>
                            <div className="trial-license-button">
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    shape={"rectangle"}
                                    fillMode={"outline"}
                                    rounded={"small"}
                                    onClick={() => {
                                        setIsVisible(initialIsVisibleState);
                                        setHostID("");
                                        setIsSubmitted(false);
                                    }}
                                >
                                    {localization.toLanguageString(requestAnotherTrailKey, mainMessages[siteLanguageDefault][requestAnotherTrailKey])}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </> : <>
                    <Row>
                        <div className={"k-h5 mb-1"}>
                            <Text
                                textkey={selectHostKey}
                                textdefault={mainMessages[siteLanguageDefault][selectHostKey]}
                            />
                        </div>
                        <div className={"k-h5 trial-license-host-subtitle"}>
                            <Text
                                textkey={enterHostInfoKey}
                                textdefault={mainMessages[siteLanguageDefault][enterHostInfoKey]}
                            />
                        </div>
                    </Row>
                    {hostModal === "HOST" ? <>
                        <Row>
                            <Col xs={3}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.2rem',
                                    lineHeight: '2rem'
                                }}>
                                    <Label>{toHtml(hostIDLabel)}</Label>
                                    <InfoIcon
                                        onClick={() => {
                                            setIsVisible(isVisible => ({
                                                ...isVisible,
                                                hostIDHint: !isVisible.hostIDHint,
                                                serialNumberHint: false
                                            }));
                                        }}
                                        size={"medium"}
                                        style={{
                                            color: 'var(--keysight-secondary)',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </div>
                                <KSMPopover
                                    show={isVisible.hostIDHint}
                                    setShowHandler={() => {
                                        setIsVisible(isVisible => ({
                                            ...isVisible,
                                            hostIDHint: false,
                                        }));
                                    }}
                                    anchor={hostIDPopover.current}
                                    position={'right'}
                                    type={'warning'}
                                    style={{maxWidth: 600}}
                                    message={
                                        <>
                                            <b>
                                                {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                            </b>
                                            <br/>
                                            {toHtml(hostIDHint)}
                                        </>
                                    }
                                />
                                <div ref={hostIDPopover}>
                                    <AutoComplete
                                        ref={hostIDAnchor}
                                        value={hostID}
                                        data={filteredHostIDSuggestions}
                                        field="hostID"
                                        hint="hostIDHint"
                                        onChange={onHostIDChange}
                                        valid={isValid.hostID}
                                        listNoDataRender={(e) => null}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {hostIsSerial === "Y" || hostIsSerial === "O" ? <Row className="mt-1">
                            <Col xs={3}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.2rem',
                                    lineHeight: '2rem'
                                }}>
                                    <Label>
                                        {hostIsSerial === "O" ? toHtml(serialNumberLabel + " (Optional)") : toHtml(serialNumberLabel)}
                                    </Label>
                                    <InfoIcon
                                        onClick={() => {
                                            setIsVisible(isVisible => ({
                                                ...isVisible,
                                                hostIDHint: false,
                                                serialNumberHint: !isVisible.serialNumberHint
                                            }));
                                        }}
                                        size={"medium"}
                                        style={{
                                            color: 'var(--keysight-secondary)',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </div>
                                <KSMPopover
                                    show={isVisible.serialNumberHint}
                                    setShowHandler={() => {
                                        setIsVisible(isVisible => ({
                                            ...isVisible,
                                            serialNumberHint: false,
                                        }));
                                    }}
                                    anchor={serialNumberAnchor.current}
                                    position={'left'}
                                    type={'warning'}
                                    style={{maxWidth: 600}}
                                    message={
                                        <>
                                            <b>
                                                {localization.toLanguageString(serialNumberFormattingKey, mainMessages[siteLanguageDefault][serialNumberFormattingKey])}
                                            </b>
                                            <br/>
                                            {toHtml(serialNumberHint)}
                                        </>
                                    }
                                />
                                <div ref={serialNumberAnchor}>
                                    <AutoComplete
                                        value={serialNumber}
                                        field="serialNumber"
                                        hint="serialNumberHint"
                                        onChange={onHostIDChange}
                                        valid={isValid.serialNumber}
                                        listNoDataRender={(e) => null}
                                    />
                                </div>
                            </Col>
                        </Row> : <></>}
                    </> : (hostModal === "C2V" || hostModal === "HOST_FILE_BIN") ? <Row>
                        <Col
                            className="pe-0"
                            xs={6}
                        >
                            <KSMPopover
                                show={isVisible.hostIDHint}
                                setShowHandler={() => {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: false,
                                    }));
                                }}
                                anchor={hostFilePopoverAnchor.current}
                                position={'right'}
                                type={'warning'}
                                style={{maxWidth: 400}}
                                message={
                                    <>
                                        <b>
                                            {localization.toLanguageString(specificHostFileKey, mainMessages[siteLanguageDefault][specificHostFileKey])}
                                        </b>
                                        <br/>
                                        {toHtml(hostIDHint)}
                                    </>
                                }
                            />
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.2rem',
                                lineHeight: '2rem'
                            }}>
                                <Label>{localization.toLanguageString(uploadFileKey, mainMessages[siteLanguageDefault][uploadFileKey])}</Label>
                                <InfoIcon
                                    onClick={() => {
                                        setIsVisible(isVisible => ({
                                            ...isVisible,
                                            hostIDHint: !isVisible.hostIDHint
                                        }));
                                    }}
                                    size={"medium"}
                                    style={{
                                        color: 'var(--keysight-secondary)',
                                        cursor: 'pointer',
                                    }}
                                />
                                <div ref={hostFilePopoverAnchor}/>
                            </div>

                            <div>
                                <Upload
                                    className="ksm-upload"
                                    batch={false}
                                    multiple={false}
                                    withCredentials={false}
                                    defaultFiles={[]}
                                    restrictions={{
                                        allowedExtensions: [extension]
                                    }}
                                    saveUrl={config.utilities.health}
                                    onAdd={onHostFileUpload}
                                    selectMessageUI={() =>
                                        <Button
                                            themeColor={"primary"}
                                            size={"large"}
                                            shape={"rectangle"}
                                            fillMode={"solid"}
                                            rounded={"small"}
                                            type={"button"}
                                        >
                                            {hostIDLabel}
                                        </Button>
                                    }
                                />
                            </div>
                        </Col>
                    </Row> : <Row>
                        <Col xs={3}>
                            <Label>
                                {localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                            </Label>
                            <Input
                                disabled={true}
                                style={{borderColor: "rgba(0, 0, 0, 0.08)"}}
                            />
                        </Col>
                    </Row>}
                    <br/>
                    <div className={"mb-3"}>
                        <Grid data={product}>
                            <GridNoRecords>
                                {gridNoRecordsMessage}
                            </GridNoRecords>
                            <GridColumn
                                field="prod_num"
                                title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="description"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                            />
                            <GridColumn
                                field="trial_duration"
                                title={localization.toLanguageString(durationKey, mainMessages[siteLanguageDefault][durationKey])}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="qty_available"
                                title={localization.toLanguageString(quantityKey, mainMessages[siteLanguageDefault][quantityKey])}
                                cell={NoWrapCell}
                            />
                        </Grid>
                    </div>
                    <Row className={"mb-3"}>
                        <Col>
                            <div className="trial-license-button">
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    shape={"rectangle"}
                                    fillMode={"solid"}
                                    rounded={"small"}
                                    onClick={validateHostPatterns}
                                    disabled={isDisabled}
                                >
                                    {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </>}
                <Row className="trial-license-alerts">
                    <Col xs={4}>
                        {isVisible.genericHostIDError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(invalidHostIdKey, mainMessages[siteLanguageDefault][invalidHostIdKey])}
                            message={localization.toLanguageString(ensureHostIdCorrectKey, mainMessages[siteLanguageDefault][ensureHostIdCorrectKey])}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, genericHostIDError: false}))}
                        />}
                        {isVisible.requestError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                            message={toHtml(localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey]))}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, requestError: false}))}
                        />}
                        {isVisible.dneError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(trailLicenseNotAvailableKey, mainMessages[siteLanguageDefault][trailLicenseNotAvailableKey])}
                            message={localization.toLanguageString(trialDNEKey, mainMessages[siteLanguageDefault][trialDNEKey])}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, dneError: false}))}
                        />}
                        {isVisible.durationError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(trailLicenseNotAvailableKey, mainMessages[siteLanguageDefault][trailLicenseNotAvailableKey])}
                            message={localization.toLanguageString(notTrailDurationKey, mainMessages[siteLanguageDefault][notTrailDurationKey])}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, durationError: false}))}
                        />}
                        {isVisible.hostIDError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(invalidHostKey, mainMessages[siteLanguageDefault][invalidHostKey])}
                            message={toHtml(hostIDError)}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, hostIDError: false}))}
                        />}
                        {isVisible.serialNumberError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(invalidSerialNumberKey, mainMessages[siteLanguageDefault][invalidSerialNumberKey])}
                            message={toHtml(serialNumberError)}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, serialNumberError: false}))}
                        />}
                        {isVisible.fileError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(invalidFileKey, mainMessages[siteLanguageDefault][invalidFileKey])}
                            message={toHtml(hostIDError)}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, fileError: false}))}
                        />}
                        {isVisible.submitError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(trailLicenseNotAvailableKey, mainMessages[siteLanguageDefault][trailLicenseNotAvailableKey])}
                            message={<ContactUs/>}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, submitError: false}))}
                        />}
                        {isVisible.submitSuccess && <Alert
                            type={'success'}
                            title={localization.toLanguageString(trialLicenseSubmitSuccessKey, mainMessages[siteLanguageDefault][trialLicenseSubmitSuccessKey])}
                            message={toHtml(localization.toLanguageString(trialLicenseSubmitSuccessSubtitle1Key, mainMessages[siteLanguageDefault][trialLicenseSubmitSuccessSubtitle1Key]) + hostID + localization.toLanguageString(trialLicenseSubmitSuccessSubtitle2Key, mainMessages[siteLanguageDefault][trialLicenseSubmitSuccessSubtitle2Key]))}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, submitSuccess: false}))}
                        />}
                        {isVisible.contactUsSuccess && <Alert
                            type={'success'}
                            title={localization.toLanguageString(messageSentKey, mainMessages[siteLanguageDefault][messageSentKey])}
                            message={toHtml(localization.toLanguageString(reviewedByKeysightKey, mainMessages[siteLanguageDefault][reviewedByKeysightKey]))}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, contactUsSuccess: false}))}
                        />}
                        {isVisible.genericSubmitError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(encounteredErrorKey, mainMessages[siteLanguageDefault][encounteredErrorKey])}
                            message={toHtml(localization.toLanguageString(contactUsTrailLicenseKey, mainMessages[siteLanguageDefault][contactUsTrailLicenseKey]))}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, genericSubmitError: false}))}
                        />}
                    </Col>
                </Row>
                <br/>
            </div>
            {isVisible.contactInformationModal && <ContactInformationModal
                contactInformation={contactInformation}
                setContactInformation={setContactInformation}
                countries={countries}
                submitRequest={submitRequest}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />}
            {isVisible.contactUsModal && <ContactUsModal
                body={body}
                setBody={setBody}
                product={product}
                hostID={hostID}
                submitContactUs={submitContactUs}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />}
        </>
    );
}

export default TrialLicense;
