import React, {useContext} from "react";
import UserContext from "../../common/UserContext";
import uuid from "react-uuid";
import {cloneDeep} from "lodash";

//components
import GenericAssignModal from "../../common/AssignHost/GenericAssignModal";
import {NoWrapCell, TextAlignMiddleCell} from "../../common/Grid";
import ProductGrid from "../../common/AssignHost/GenericAssignModal/ProductGrid";

// kendo
import {GridColumn} from "@progress/kendo-react-grid";

//multilingual
import {
    descriptionKey,
    mainMessages,
    productNumberKey, qtyKey
} from "../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";


function AssignProductsModal(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        hostDetails,
        setHostDetails,
        scrollRef,
        assignedProducts,
        setAssignedProducts,
        selectedProducts,
        setSelectedProducts,
        sortByDisableValue,
        setSelectHeaderCheck,
        setEnableSortByDisable,
    } = props
    const onePerHost = 'redeem_one_per_host'
    const productNum = 'product_number'
    let hostModal = hostDetails.hostid_type_popup.toUpperCase() || "";
    let hostIDType = hostDetails.host_id_type || ""

    /*
     * createHost() creates the accordion for the products assigned to a host
     * @param {panelBarTitle} is the assignedProduct's accordion title
     * @param {host} is the existing assignedProduct object in assignedProducts
     * @param {hostId} is the assignedProduct's host_id
     * @param {serialNumber} is the assignedProduct's serial number
     * @param {hostFile} is the file of host
     * @param {hostCount} number of host currently being assigned
    */
    const createHost = (panelBarTitle, host, hostId, serialNumber = "", hostFile = null, hostCount = 1) => {

        // get unique transaction id for existing hosts
        // or add a unique transaction id for new hosts
        let transactionID = host ? host.transaction_id : uuid();

        let hosts = [...assignedProducts];

        // 1a. update assignedProducts for the assigned products accordions for existing host
        if (host) {
            // find index of the host in assignedProducts array
            let hostIndex = hosts.findIndex(hostUpdate => hostUpdate === host);

            //set host file if host file is empty string
            if (hosts[hostIndex].file === "") {
                hosts[hostIndex].file = hostFile
            }

            selectedProducts.map((item) => {
                if (item.selected) {
                    let productIndex = host.products.findIndex(product => item.unique_id === product.unique_id);
                    if (productIndex > -1) {
                        hosts[hostIndex].products[productIndex].qty_requested += item.qty_requested
                    } else {
                        let newProduct = cloneDeep(item);
                        newProduct["transaction_id"] = transactionID;
                        hosts[hostIndex].products.push(newProduct)
                    }
                }
                return item
            })
            setAssignedProducts(hosts)
        }
        // 1b. update assignedProducts for the assigned products accordions for a new host
        else {
            let hostProducts = []

            selectedProducts.map((item) => {
                if (item.selected) {
                    let newProduct = cloneDeep(item);
                    newProduct["transaction_id"] = transactionID;
                    hostProducts.push(newProduct);
                    return item
                }
            })

            let assignedProduct = {
                transaction_id: transactionID,
                panelBarTitle: panelBarTitle,
                products: hostProducts,
                checked: true,
                icons: {
                    loading: true,
                    download: false,
                    cloudAlert: false,
                    supportError: false,
                    generationError: false,
                    qtyError: false,
                    entitlementError: false,
                },
                host_id: hostId,
                serial_id: serialNumber,
                file: hostFile,
                host_id_type: hostIDType
            };

            setAssignedProducts(assignedProducts => [...assignedProducts, assignedProduct]);
        }
    }

    const resetSelectedProducts = (hostCount = 1) => {
        let newSelectedProducts = selectedProducts.map((item) => {
            if (item.selected) {
                item.qty_available -= item.qty_requested * hostCount
                item.qty_requested = null
                if (item[onePerHost] === 'Y') {
                    item.qty_requested = 1
                }
            }
            item.selected = false
            item.disabled = false

            return item
        })

        setSelectedProducts(newSelectedProducts)
        sortByDisableValue.current = null
        setSelectHeaderCheck(false)
        setEnableSortByDisable(false)
        closeModal()
    }

    const closeModal = () => {
        setHostDetails({})
        // scroll to assigned products
        if (scrollRef.current) scrollRef.current.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <GenericAssignModal
            onePerHost={onePerHost}
            productNum={productNum}
            uniqueID={'unique_id'}
            createHost={createHost}
            closeModal={closeModal}
            resetSelectedProducts={resetSelectedProducts}
            {...props}
        >
            <ProductGrid
                items={selectedProducts.filter(item => item.selected)}
                hostModal={hostModal}
            >
                <GridColumn
                    field={productNum}
                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="product_desc"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                />
                <GridColumn
                    width={"50px"}
                    field="qty_requested"
                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                    cell={TextAlignMiddleCell}
                />
            </ProductGrid>
        </GenericAssignModal>
    )
}

export default AssignProductsModal;