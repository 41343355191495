import React, {useContext} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/App.css';

// kendo react
import {Loader} from '@progress/kendo-react-indicators';
import {Dialog} from '@progress/kendo-react-dialogs';

// multilingual
import {Text} from './MultilingualText.js';
import {
    redirectingKey,
    workingOnRequestKey,
    mainMessages,
    pleaseWaitKey
} from '../../assets/text/MultilingualText.js';
import {ForwardIcon} from "./icons";


function Redirect() {
    const {siteLanguageDefault} = useContext(UserContext);

    const redirectStyle = {
        display: "flex",
        verticalAlign: "middle",
        textAlign: "center"
    };

    return (
        <>
            <Dialog style={redirectStyle}>
                <ForwardIcon
                    size={'xxxlarge'}
                />
                <h1>
                    <Text
                        textkey={redirectingKey}
                        textdefault={mainMessages[siteLanguageDefault][redirectingKey]}
                    />
                </h1>
                <Loader
                    size="medium"
                    type="pulsing"
                />
                <p>
                    <Text
                        textkey={workingOnRequestKey}
                        textdefault={mainMessages[siteLanguageDefault][workingOnRequestKey]}
                    />
                    <br/>
                    <Text
                        textkey={pleaseWaitKey}
                        textdefault={mainMessages[siteLanguageDefault][pleaseWaitKey]}
                    />
                </p>
            </Dialog>
        </>
    );
}

export default Redirect;