import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext';

// components
import {DateCell, NoWrapCell, TextAlignMiddleCell} from '../common/Grid.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Checkbox} from '@progress/kendo-react-inputs';
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    continueRenewLicensesKey,
    hostKey,
    mainMessages,
    productDescriptionKey,
    licenseExpirationDateKey,
    notesKey,
    productNumberKey,
    qtyKey,
    versionKey,
    renewableLicensesKey,
} from '../../assets/text/MultilingualText.js';


function SelectLicenses(props) {
    const {
        changeStepActivation,
        modifyHosts,
        setModifyHosts,
        setRequestedHosts,
        setAssignMore
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [disableRenewLicenseButton, setDisableRenewLicenseButton] = useState(true);

    //Disables renew license button if no products checked
    useEffect(() => {
        let oneChecked = false;
        modifyHosts.forEach(host => {
            host['products'].forEach(product => {
                if (product.selected) {
                    oneChecked = true;
                }
            })
        })
        setDisableRenewLicenseButton(!oneChecked);
    }, [modifyHosts])

    //Checkbox cell
    const checkboxCell = (props) => {
        const {
            field,
            dataItem
        } = props;

        return (
            <td>
                <Checkbox
                    className={'ksm-inverted-checkbox'}
                    checked={dataItem[field]}
                    onChange={(event) => {
                        let copyModifyHosts = JSON.parse(JSON.stringify(modifyHosts))
                        const hostIndex = copyModifyHosts.findIndex(host => host.host_id === dataItem.new_host_id);
                        const productIndex = copyModifyHosts[hostIndex]['products'].findIndex(product => product.unique_id === dataItem.unique_id);
                        copyModifyHosts[hostIndex]['products'][productIndex][field] = event.value
                        setModifyHosts(copyModifyHosts)
                    }}
                />
            </td>
        )
    }
    return (
        <>
            <div className={"k-h5"}>
                {localization.toLanguageString(renewableLicensesKey, mainMessages[siteLanguageDefault][renewableLicensesKey])}
            </div>
            {modifyHosts.map((host) =>
                <PanelBar
                    key={host.transaction_id}
                    expanded={['.0']}
                    className={'ksm-panelbar-default'}
                >
                    <PanelBarItem
                        title={<div
                            className={"k-h5"}
                            style={{
                                color: 'white',
                                margin: '0 0 0 2px'
                            }}>
                            {localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])} {host.host_id}
                        </div>}
                    >
                        <Grid
                            className={'select-panelbar-grid'}
                            data={orderBy(host.products, [{
                                field: "product_number",
                                dir: "desc"
                            }])}
                            scrollable={'none'}
                            sortable={false}
                        >
                            <GridColumn
                                field='selected'
                                title={' '}
                                cell={checkboxCell}
                            />
                            <GridColumn
                                field="product_number"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="product_desc"
                                title={localization.toLanguageString(productDescriptionKey, mainMessages[siteLanguageDefault][productDescriptionKey])}
                            />
                            <GridColumn
                                field="notes"
                                title={localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey])}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="version"
                                title={localization.toLanguageString(versionKey, mainMessages[siteLanguageDefault][versionKey])}
                            />
                            <GridColumn
                                field="lic_exp_date"
                                title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                                cell={DateCell}
                            />
                            <GridColumn
                                field="qty"
                                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                cell={TextAlignMiddleCell}
                            />
                        </Grid>
                    </PanelBarItem>
                </PanelBar>
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                }}
            >
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    disabled={disableRenewLicenseButton}
                    onClick={() => {

                        let requestedHosts = []
                        let unSelectedProducts = false
                        let copyHosts = JSON.parse(JSON.stringify(modifyHosts))
                        copyHosts.forEach(host => {
                            let selectedProducts = []
                            host['products'].forEach(product => {
                                if (product.selected) {
                                    selectedProducts.push(product)
                                } else {
                                    unSelectedProducts = true
                                }
                            })
                            if (selectedProducts.length > 0) {
                                host['products'] = selectedProducts
                                requestedHosts.push(host)
                            }
                        })

                        setAssignMore(unSelectedProducts)
                        setRequestedHosts(requestedHosts)

                        changeStepActivation(2);
                    }}
                >
                    {localization.toLanguageString(continueRenewLicensesKey, mainMessages[siteLanguageDefault][continueRenewLicensesKey])}
                </Button>
            </div>
        </>
    );
}

export default SelectLicenses;