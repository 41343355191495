import React, {useContext, useRef, useState, useCallback, useEffect} from 'react';
import UserContext from '../../common/UserContext.js';

// kendo react
import {filterBy, process} from "@progress/kendo-data-query";
import {Checkbox} from "@progress/kendo-react-inputs";
import {Grid, GridColumn} from "@progress/kendo-react-grid";

// components
import {ColumnMenu, DateCell} from "../../common/Grid";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    checkoutDateKey,
    descriptionKey,
    expirationDateKey,
    mainMessages,
    productNumberKey,
    transactionKey,

} from '../../../assets/text/MultilingualText.js';

const initialGridState = {
    take: 10, skip: 0,
};

export const LicenseGrid = (props) => {
    const {
        host,
        hostIndex,
        hostLicenses,
        setHostLicenses,
        setCheckInReady
    } = props;
    const {
        siteLanguageDefault,
    } = useContext(UserContext);

    const localization = useLocalization();
    const [licenses, setLicenses] = useState(host.licenses);

    const [gridState, setGridState] = useState(initialGridState);
    const gridStateRef = useRef(initialGridState)
    const [dataState, setDataState] = useState(
        process(licenses
            .map((item) => ({
                ...item,
            })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            licenses
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );
        setSelectHeaderCheck(newDataState.data.filter(item => !item.selected).length === 0)
        gridStateRef.current = event.dataState
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [licenses]);

    useEffect(() => {
        const newDataState = process(
            licenses
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [licenses]) // eslint-disable-line react-hooks/exhaustive-deps

    // state to control licenses select header checkbox
    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);

    // cell and functions for selection
    const SelectCell = (props) => {
        const anchor = useRef(null);
        return <td
            ref={anchor}
        >
            <Checkbox
                type="checkbox"
                onChange={props.selectionChange}
                value={props.dataItem.selected}
                disabled={props.dataItem.disabled}
            />
        </td>
    }

    const onSelectionChange = useCallback((event) => {
        const checked = event.syntheticEvent.target.checked;
        let newLicenses = licenses.map(item => {
            if (event.dataItem.unique_id === item.unique_id) {
                item.selected = checked
            }
            return item
        })
        setSelectHeaderCheck(filterBy(newLicenses, gridStateRef.current.filter).filter(item => !item.selected).length === 0)
        let newHostLicenses = hostLicenses;
        newHostLicenses[hostIndex].licenses = newLicenses;
        setHostLicenses(newHostLicenses);
        setLicenses(newLicenses);

        let licenseChecked = false;
        newHostLicenses.forEach((host) => {
            let checkedLicenses = host.licenses.filter((license) => license.selected)
            if (checkedLicenses.length > 0) licenseChecked = true
        })
        licenseChecked ? setCheckInReady(true) : setCheckInReady(false)

    }, [licenses]); // eslint-disable-line react-hooks/exhaustive-deps

    const onHeaderSelectionChange = useCallback(event => {
        const checked = event.nativeEvent.target.checked;
        let newLicenses = licenses.map(item => {
            for(const filtered of filterBy(licenses, gridStateRef.current.filter)){
                if(item.unique_id === filtered.unique_id){
                    item.selected = checked
                }
            }
            return item
        });
        let newHostLicenses = hostLicenses;
        newHostLicenses[hostIndex].licenses = newLicenses;
        setLicenses(newLicenses);
        setHostLicenses(newHostLicenses);
        setSelectHeaderCheck(checked);

        let licenseChecked = false;
        newHostLicenses.forEach((host) => {
            let checkedLicenses = host.licenses.filter((license) => license.selected)
            if (checkedLicenses.length > 0) licenseChecked = true
        })
        licenseChecked ? setCheckInReady(true) : setCheckInReady(false)
    }, [licenses]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                className={"sa-checkin-licenses-grid"}
                scrollable={'none'}
                data={dataState}
                sortable={true}
                //pagination
                pageable={(licenses.length <= initialGridState.take) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100],
                }}
                onDataStateChange={onDataStateChange}
                total={dataState.total}
                //select checkboxes
                onHeaderSelectionChange={onHeaderSelectionChange}
                onSelectionChange={onSelectionChange}
                selectedField={'selected'}
                selectable={{
                    enabled: false,
                    drag: false,
                    cell: false,
                    mode: 'multiple'
                }}
                {...gridState}
            >
                <GridColumn
                    field={'selected'}
                    cell={SelectCell}
                    headerSelectionValue={selectHeaderCheck}
                    headerClassName={''}
                />
                <GridColumn
                    field="product_number"
                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="product_desc"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="transaction_id"
                    title={localization.toLanguageString(transactionKey, mainMessages[siteLanguageDefault][transactionKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="expiration_date"
                    title={localization.toLanguageString(expirationDateKey, mainMessages[siteLanguageDefault][expirationDateKey])}
                    columnMenu={ColumnMenu}
                    filter={'date'}
                    cell={DateCell}
                />
                <GridColumn
                    field="checkout_date"
                    title={localization.toLanguageString(checkoutDateKey, mainMessages[siteLanguageDefault][checkoutDateKey])}
                    columnMenu={ColumnMenu}
                    filter={'date'}
                    cell={DateCell}
                />
            </Grid>
        </>
    )
}