import React, {useState} from 'react';

// css
import '../../assets/css/App.css';

// kendo react
import {GRID_COL_INDEX_ATTRIBUTE} from '@progress/kendo-react-grid';
import {useTableKeyboardNavigation} from '@progress/kendo-react-data-tools';
import {SvgIcon} from "@progress/kendo-react-common";
import {
    envelopIcon,
    fileConfigIcon, fileCsvIcon,
    fileExcelIcon, fileIcon, filePdfIcon,
    filePptIcon,
    fileTxtIcon,
    fileWordIcon,
    fileZipIcon, filmIcon, hyperlinkOpenIcon, saveIcon
} from "@progress/kendo-svg-icons";

function IconCell(props) {
    const field = props.field || "";
    let value = props.dataItem[field];
    const hasParent = (value?.type) ? true : false;
    const disabled = (props?.disabled) ? props?.disabled : false;
    const [iconColor] = useState(
        () => () => ((disabled) ? '#19416d' : "#808080")
    );
    value = (value?.type) ? value.type : value;
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    let icon = "";
    switch (value) {
        case 'zip':
        case 'zipx':
        case 'tbz':
        case 'z01':
        case 'z06':
        case 'gz':
        case 'tgz':
        case 'bit':
        case 'ffw':
            icon = fileZipIcon;
            break;
        case 'exe':
        case 'msi':
        case 'asp':
        case 'apk':
        case 'sh':
        case 'com':
        case 'chm':
        case 'pbp':
        case 'bz2':
        case 'ova':
        case 'swu':
        case 'mib':
            icon = fileConfigIcon;
            break;
        case 'html':
        case 'xml':
        case 'txt':
        case 'rtf':
        case 'mht':
        case 'oad':
            icon = fileTxtIcon;
            break;
        case 'doc':
        case 'docx':
            icon = fileWordIcon;
            break;
        case 'xls':
        case 'xlsx':
            icon = fileExcelIcon;
            break;
        case 'ppt':
            icon = filePptIcon;
            break;
        case 'csv':
            icon = fileCsvIcon;
            break;
        case 'link':
            icon = hyperlinkOpenIcon;
            break;
        case 'pdf':
            icon = filePdfIcon;
            break;
        case 'mp4':
        case 'wmv':
            icon = filmIcon;
            break;
        case 'iso':
        case 'wim':
            icon = saveIcon;
            break;
        case 'kit':
            icon = envelopIcon;
            break;
        default:
            icon = fileIcon;
            break;
    }

    return !hasParent ? (
        <td
            style={{}}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{[GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex}}
            {...navigationAttributes}
        >
            <center>
                <SvgIcon
                    style={{
                        'color': iconColor
                    }}
                    icon={icon}
                    size={'large'}
                />
            </center>
        </td>
    ) : (<div
        style={{}}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{[GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex}}
        {...navigationAttributes}
    >
        <center>
            <SvgIcon
                style={{
                    'color': iconColor
                }}
                icon={icon}
                size={'large'}
            >
            </SvgIcon>
        </center>
    </div>);
}

export default IconCell;