import React, {useContext, useEffect, useState, useCallback} from 'react';
import UserContext from '../components/common/UserContext.js';

// kendo react
import {Stepper} from '@progress/kendo-react-layout';

// components
import PageHeader from '../components/common/PageHeader.js';
import Spinner from '../components/common/Spinner.js';
import SelectProducts from "../components/SACheckout/SelectProducts";
import '../assets/css/SACheckout.css';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    checkOutLicensesKey,
    selectProductsKey,
    productsHostKey,
    clientInfoKey,
    reviewCompleteKey,
    navigationAlertKey,
} from "../assets/text/MultilingualText";
import AssignProducts from "../components/SACheckout/AssignProducts";
import ReviewAndComplete from "../components/SACheckout/ReviewAndComplete";
import ClientInformation from "../components/SACheckout/ClientInformation";
import {useHistory} from "react-router-dom";


function SACheckout() {
    const {
        siteLanguageDefault,
    } = useContext(UserContext);

    const localization = useLocalization();
    const [isLoading, setIsLoading] = useState(false);
    const [searchProducts, setSearchProducts] = useState([]);
    const [cartProducts, setCartProducts] = useState([]);
    const [assignedProducts, setAssignedProducts] = useState([]);
    const [requestedProducts, setRequestedProducts] = useState([]);
    const [clientInfo, setClientInfo] = useState({});
    const [stepperItems, setStepperItems] = useState([
        {
            label: localization.toLanguageString(selectProductsKey,
                mainMessages[siteLanguageDefault][selectProductsKey]),
            number: 1,
            disabled: false
        },
        {
            label: localization.toLanguageString(productsHostKey,
                mainMessages[siteLanguageDefault][productsHostKey]),
            number: 2,
            disabled: true
        },
        {
            label: localization.toLanguageString(clientInfoKey,
                mainMessages[siteLanguageDefault][clientInfoKey]),
            number: 3,
            disabled: true
        },
        {
            label: localization.toLanguageString(reviewCompleteKey,
                mainMessages[siteLanguageDefault][reviewCompleteKey]),
            number: 4,
            disabled: true
        }
    ]);
    const [stepValue, setStepValue] = useState(0);

    //Changes and step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {
        let newStepperItems = stepperItems
        //Change from Select Products step
        if (stepValue === 0) {
        }

        //Change from Assign Product to Host Step
        if (stepValue === 1) {
        }

        //Change from Client Information Step
        if (stepValue === 2) {
        }

        // Change from Review and Complete Step
        if (stepValue === 3) {
            newStepperItems[2].disabled = assignedProducts.length <= 0;
            newStepperItems[3].disabled = assignedProducts.length <= 0;
        }

        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    useEffect(()=>{
        let newStepperItems = stepperItems
        newStepperItems[1].disabled = (cartProducts.length <= 0 && assignedProducts.length <= 0);
        newStepperItems[2].disabled = assignedProducts.length <= 0;
        newStepperItems[3].disabled = assignedProducts.length <= 0;
        setStepperItems([...newStepperItems])
    }, [cartProducts, assignedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <div className={"k-h3"}>
                    {localization.toLanguageString(checkOutLicensesKey, mainMessages[siteLanguageDefault][checkOutLicensesKey])}
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    }}
                >
                    <div style={{width: "55%"}}>
                        <Stepper
                            value={stepValue}
                            onChange={(e)=>{
                                changeStepActivation(e.value)
                            }}
                            items={stepperItems}
                            style={{fontSize: '0.875rem'}}
                        />
                    </div>
                </div>
                {stepValue === 0 && (
                    <SelectProducts
                        setIsLoading={setIsLoading}
                        searchProducts={searchProducts}
                        setSearchProducts={setSearchProducts}
                        cartProducts={cartProducts}
                        setCartProducts={setCartProducts}
                        changeStepActivation={changeStepActivation}
                        assignedProducts={assignedProducts}
                    />
                )}
                {stepValue === 1 && (
                    <AssignProducts
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        searchProducts={searchProducts}
                        setSearchProducts={setSearchProducts}
                        cartProducts={cartProducts}
                        setCartProducts={setCartProducts}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                    />
                )}
                {stepValue === 2 && (
                    <ClientInformation
                        setIsLoading={setIsLoading}
                        searchProducts={searchProducts}
                        setSearchProducts={setSearchProducts}
                        cartProducts={cartProducts}
                        setCartProducts={setCartProducts}
                        changeStepActivation={changeStepActivation}
                        clientInfo={clientInfo}
                        setClientInfo={setClientInfo}
                    />
                )}
                {stepValue === 3 && (
                    <ReviewAndComplete
                        setIsLoading={setIsLoading}
                        searchProducts={searchProducts}
                        setSearchProducts={setSearchProducts}
                        cartProducts={cartProducts}
                        setCartProducts={setCartProducts}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                        changeStepActivation={changeStepActivation}
                        requestedProducts={requestedProducts}
                        setRequestedProducts={setRequestedProducts}
                        clientInfo={clientInfo}
                        handleBeforeUnload={handleBeforeUnload}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                    />
                )}
            </div>
        </>
    );

}

export default SACheckout;