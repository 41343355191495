import React, {useContext} from 'react';
import UserContext from '../common/UserContext.js';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    redeemTransportableLicensesKey,
    transportableLicensesSavedKey, cloudLicenseMessageKey, cloudLicenseKey,
    mainMessages,
    keysightGenerateLicensesKey,
    specialHandlingKey,
} from '../../assets/text/MultilingualText';
import KSMPopover from "../common/Popovers";


export const HostDetailPopover = (props) => {
    const {
        type,
        showPopover,
        popoverRef
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let title;
    let message;
    let popoverStyle = {
        maxWidth: '35rem'
    };

    switch (type) {
        case 'banked':
            title = localization.toLanguageString(
                transportableLicensesSavedKey,
                mainMessages[siteLanguageDefault][transportableLicensesSavedKey]
            );
            message = localization.toLanguageString(
                redeemTransportableLicensesKey,
                mainMessages[siteLanguageDefault][redeemTransportableLicensesKey]
            );
            break;
        case 'cloud':
            title = localization.toLanguageString(
                cloudLicenseKey,
                mainMessages[siteLanguageDefault][cloudLicenseKey]
            );
            message = localization.toLanguageString(
                cloudLicenseMessageKey,
                mainMessages[siteLanguageDefault][cloudLicenseMessageKey]
            );
            break;
        default:
            title = localization.toLanguageString(
                specialHandlingKey,
                mainMessages[siteLanguageDefault][specialHandlingKey]
            );
            message = localization.toLanguageString(
                keysightGenerateLicensesKey,
                mainMessages[siteLanguageDefault][keysightGenerateLicensesKey]
            );
    }

    return (
        <KSMPopover
            show={showPopover}
            anchor={popoverRef && popoverRef.current}
            position={'left'}
            type={'info'}
            style={popoverStyle}
            message={<div>
                <b>{title}</b>
                <br/>
                {message}
            </div>}
        />
    )
}
