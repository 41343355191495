import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../../common/UserContext';

//Components
import Alert from '../../common/Alert';
import {CurrencyCell, NoWrapCell} from "../../common/Grid";

// kendo react
import {Dialog} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    assignedKey,
    cancelKey,
    costPerUnitKey,
    deleteAllKey,
    confirmDeletionKey,
    deleteUnassignedKey,
    descriptionKey, contactUsHeaderKey,
    genericErrorTitleKey,
    licenseTypeKey,
    mainMessages,
    productNumberKey,
    qtyKey,
    statusKey,
    totalKey,
    unassignedKey
} from "../../../assets/text/MultilingualText";
import {InfoIcon} from "../../common/icons";
import KSMPopover from "../../common/Popovers";


export const DeleteModal = (props) => {
    const {
        setShowModal,
        deleteProduct,
        deleteAllocations,
        showDeleteErrorAlert,
        alertDeleteHandler
    } = props;
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [unAssigned, setUnassigned] = useState(0)
    const [assigned, setAssigned] = useState(0)

    const LicenseTypeCell = (props) => {
        const field = props.field;
        const dataValue = props.dataItem[field];
        const [selected, setSelected] = useState('')

        useEffect(() => {
            let newlySelected = ''
            for (const item of dataValue) {
                if (item.selected) {
                    newlySelected = item.text
                }
            }
            setSelected(newlySelected)
        }, [dataValue])

        return (
            <td>
                {selected}
            </td>
        )
    }

    useEffect(() => {
        let unassigned = deleteProduct['quantity']
        let newAssigned = 0
        for (const allocID in deleteProduct.status_display) {
            newAssigned += deleteProduct.status_display[allocID].assign_quantity
        }
        unassigned -= newAssigned
        setAssigned(newAssigned)
        setUnassigned(unassigned)
    }, [deleteProduct])

    const StatusCell = (props) => {
        const field = props.field;
        const dataValue = props.dataItem[field];
        let products = []
        for (const allocId in dataValue) {
            products.push(dataValue[allocId])
        }

        const popoverAnchor = useRef(null);
        const [popoverShow, setPopoverShow] = useState(false)

        //Show popover if there is an error on mouse over
        const onMouseOver = (ev) => {
            setPopoverShow(true);
        };

        //Remove popover on mouse leave
        const onMouseOut = (ev) => {
            let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
            if (!isChildEl) setPopoverShow(false);
        };

        return (
            <td>
                <span style={{
                    whiteSpace: 'nowrap'
                }}>
                    {unAssigned} {localization.toLanguageString(unassignedKey, mainMessages[siteLanguageDefault][unassignedKey])}

                    {assigned > 0 &&
                        (
                            <div>
                                {assigned} {localization.toLanguageString(assignedKey, mainMessages[siteLanguageDefault][assignedKey])}
                                <span
                                    onMouseOver={onMouseOver}
                                    onMouseOut={onMouseOut}
                                    ref={popoverAnchor}
                                    style={{float: 'right'}}
                                >
                                    <InfoIcon size={'medium'}/>
                                </span>
                                <br/>

                                <KSMPopover
                                    show={popoverShow}
                                    anchor={popoverAnchor.current}
                                    position={'left'}
                                    animate={false}
                                    message={
                                        <div>
                                            {
                                                products.map((product, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {product.panel_bar_title}: {product.assign_quantity} {localization.toLanguageString(assignedKey, mainMessages[siteLanguageDefault][assignedKey])}
                                                            <br/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                />
                            </div>
                        )
                    }
                </span>
            </td>
        )
    }

    return (
        <Dialog
            className={'ksm-dialog'}
            width={"80.125rem"}
            height={"35vh"}
        >

            <div style={{
                fontSize: '1.5rem',
                marginBottom: '0.938rem'
            }}>
                {localization.toLanguageString(confirmDeletionKey, mainMessages[siteLanguageDefault][confirmDeletionKey])}:
            </div>

            <Grid
                className={'delete-grid'}
                scrollable={'none'}
                data={[deleteProduct]}
                sortable={false}
            >
                <GridColumn
                    field="prod_num_display"
                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="description"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                />
                <GridColumn
                    field="quantity"
                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                />
                <GridColumn
                    field="license_type"
                    title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                    cell={LicenseTypeCell}
                />
                <GridColumn
                    field="base_price"
                    title={localization.toLanguageString(costPerUnitKey, mainMessages[siteLanguageDefault][costPerUnitKey])}
                    cell={CurrencyCell}
                />
                <GridColumn
                    field="total_cost"
                    title={localization.toLanguageString(totalKey, mainMessages[siteLanguageDefault][totalKey])}
                    cell={CurrencyCell}
                />
                <GridColumn
                    field={'status_display'}
                    title={localization.toLanguageString(statusKey, mainMessages[siteLanguageDefault][statusKey])}
                    cell={StatusCell}
                />
            </Grid>

            {showDeleteErrorAlert && (
                <div style={{
                    marginTop: '0.938rem'
                }}>
                    <Alert
                        type={'error'}
                        showHandler={alertDeleteHandler}
                        title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                        message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                    />
                </div>
            )}

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'left',
                    gap: '0.938rem',
                    marginTop: '0.938rem'
                }}
            >
                {unAssigned !== 0 && (
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        onClick={() => {
                            deleteAllocations(deleteProduct, false)
                        }}
                        style={{
                            maxWidth: '16.25rem',
                        }}
                    >
                        {localization.toLanguageString(deleteUnassignedKey, mainMessages[siteLanguageDefault][deleteUnassignedKey])}
                    </Button>
                )}

                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={() => {
                        deleteAllocations(deleteProduct, true)
                    }}
                    style={{
                        maxWidth: '9.375rem',
                    }}
                >
                    {localization.toLanguageString(deleteAllKey, mainMessages[siteLanguageDefault][deleteAllKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    onClick={() => {
                        setShowModal(false)
                    }}
                    style={{
                        maxWidth: '9.375rem'
                    }}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
            </div>
        </Dialog>
    )
}