import React, {useEffect} from 'react';
import config from '../../config.js';

// components
import Redirect from '../common/Redirect.js';

function SSORedirect(props) {
    let previous = (typeof props.location.previous === "undefined") ? (props.location.path) : (props.location.previous.pathname + props.location.previous.search);
    if (typeof props.location.previous === "undefined" || previous.includes('sso-redirect')) previous = '';
    else previous = previous.replace('/', '');
    previous = btoa(previous)
    let params = (typeof previous === "undefined") ? '?site=ext&prev_url=' : '?site=ext&prev_url=' + previous;
    useEffect(() => {
        window.location.href = config.sso.SSO_WELCOME_URL + params;
    }, [params]);

    return (
        <div className={"ksm-page-container"}>
            <Redirect/>
        </div>
    );
}

export default SSORedirect;