import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// reactstrap
import {Row} from 'reactstrap';

// components
import DownloadLicensesGrid from './DownloadLicenses/DownloadLicensesGrid.js';
import {DownloadPanelBarHeader} from './DownloadLicenses/DownloadPanelBarHeader.js';

// kendo react
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';

// multilingual
import {Text} from '../common/MultilingualText.js';
import {mainMessages, qtyKey} from '../../assets/text/MultilingualText.js';


function DownloadLicenses(props) {
    const {
        subscription,
        renewalLicenses,
        gridNoRecordsMessage,
        setIsLoading,
        handleBeforeUnload
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);

    const initialQty = parseInt(subscription.qty);
    const [totalQty, setTotalQty] = useState(initialQty);

    // set initial qty on license change
    useEffect(() => {
        isNaN(initialQty) ? setTotalQty(0) : setTotalQty(initialQty);
    }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

    // update qty subheader on renewal license selection
    useEffect(() => {
        let selected = [];
        renewalLicenses.forEach((renewal) => {
            renewal.licenses.forEach((license) => {
                if (license.selected && !selected.includes(license.subscription_id)) {
                    selected.push(license.subscription_id);
                }
            })
        });
        setTotalQty(initialQty - selected.length);
    }, [renewalLicenses]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Row>
                <div className={"k-h5"}>
                    {subscription.product_number && subscription.order_number && subscription.description ? subscription.product_number + " - " + subscription.description : ""} (<Text
                    textkey={qtyKey}
                    textdefault={mainMessages[siteLanguageDefault][qtyKey]}
                /> {totalQty})
                </div>
            </Row>
            {renewalLicenses.map((license, index) => {
                let selected = license.licenses.filter((license) => license.selected) || [];
                let licenseName = license.license_name || "";
                let licenseDescription = license.license_description || "";

                let panelBarTitle = licenseName && licenseDescription ? licenseName + " - " + licenseDescription : "";

                return (
                    <React.Fragment key={"download-license-" + index}>
                        {(selected.length > 0) ?
                            <React.Fragment key={"download-license-panelbar-" + index}>
                                <PanelBar
                                    className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                                    expanded={['.0']}
                                    isControlled={true}
                                >
                                    <PanelBarItem
                                        title={<DownloadPanelBarHeader
                                            panelBarTitle={panelBarTitle}
                                            index={index}
                                            renewalLicenses={renewalLicenses}
                                            setIsLoading={setIsLoading}
                                            handleBeforeUnload={handleBeforeUnload}
                                        />}
                                    >
                                        <DownloadLicensesGrid
                                            index={index}
                                            renewalLicenses={renewalLicenses}
                                            gridNoRecordsMessage={gridNoRecordsMessage}
                                        />
                                    </PanelBarItem>
                                </PanelBar>
                            </React.Fragment>
                            :
                            <></>
                        }
                    </React.Fragment>
                )
            })}
        </>
    );
}

export default DownloadLicenses;
