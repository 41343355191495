import React from "react";

// react strap
import {Label} from "reactstrap";

// kendo react
import {FieldWrapper} from "@progress/kendo-react-form";
import {Input, NumericTextBox} from "@progress/kendo-react-inputs";
import {Error, Hint} from "@progress/kendo-react-labels";
import {DropDownList} from "@progress/kendo-react-dropdowns";

export const FormNumericTextBox = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        disabled,
        hint,
        required,
        valid,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
        <FieldWrapper>
            <Label
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </Label>
            <NumericTextBox
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                format={{
                    useGrouping: false,
                }}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export const FormDropDownList = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        disabled,
        hint,
        wrapperStyle,
        required,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    const valid = true; // Todo if form errors of name (RATHER THAN VALIDATOR)

    let labelElem
    if (required) {
        labelElem = <Label editorId={id}>
            {label}
            <span
                style={{
                    "color": "var(--color-alert-danger-base)",
                    "whiteSpace": "pre"
                }}
            > *</span>
        </Label>
    } else {
        labelElem = <Label editorId={id}>{label}</Label>
    }
    return (
        <FieldWrapper style={wrapperStyle}>
            {labelElem}
            <DropDownList
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export const FormInput = props => {
    const {
        id,
        label,
        validationMessage,
        dataValue,
        required,
        formValues,
        formErrors,
        ...others
    } = props;
    let labelElem
    if (required) {
        labelElem = <Label
            editorId={id}
        >
            {label}
            <span
                style={{
                    "color": "var(--color-alert-danger-base)",
                    "whiteSpace": "pre"
                }}
            > *</span>
        </Label>
    } else {
        labelElem = <Label editorId={id}>{label}</Label>
    }

    const valid = true; // Todo if form errors of name (RATHER THAN VALIDATOR)

    return (
        <FieldWrapper>
            {labelElem}
            <Input
                {...others}
                value={dataValue}
                valid={valid}
                validityStyles={!valid}
            />
            {!valid && <Error>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export default FormInput;