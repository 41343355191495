import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// css
import '../../../assets/css/EARequestAgain.css';

// components
import {ColumnMenu, CurrencyCell, DateCell, DisableRowRender, TextAlignMiddleCell} from '../../common/Grid.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {Checkbox} from '@progress/kendo-react-inputs';
import {DatePickerPropsContext} from '@progress/kendo-react-dateinputs';
import {filterBy, process} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    costPerUnitKey,
    daysLeftKey,
    descriptionKey,
    endDateKey,
    genericEmptyGridKey,
    hostKey,
    infoKey,
    licenseTypeKey,
    mainMessages,
    productNotAvailable,
    productNumberKey,
    qtyKey,
    startDateKey,
    totalCostKey
} from '../../../assets/text/MultilingualText.js';
import KSMPopover from "../../common/Popovers";

export const RequestAgainGrid = (props) => {
    const {siteLanguageDefault} = useContext(UserContext);
    const {
        assignments,
        setAssignments,
        dateRange,
        calculateCost
    } = props;
    const localization = useLocalization();

    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);

    // states and functions for pagination
    let uniqueId = 'unique_id';

    // consts for request again grid
    const initialFilter = {
        logic: "and",
        filters: [
            {
                field: "end_date",
                operator: "gte",
                value: new Date()
            },
            {
                field: "end_date",
                operator: "lte",
                value: new Date()
            }
        ]
    };
    const [filter, setFilter] = useState(initialFilter);

    const initialGridState = {
        take: 10,
        skip: 0,
        sort: [
            {
                field: "days_left",
                dir: "asc",
            }
        ]
    };
    const gridStateRef = useRef(initialGridState);
    const [gridState, setGridState] = useState(initialGridState);
    const [dataState, setDataState] = useState(
        process(assignments.map((item) => ({
            ...item
        })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            assignments
                .filter(a => a.end_date >= dateRange.start && a.end_date <= dateRange.end)
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );
        if (newDataState.data.length === 0) {
            setSelectHeaderCheck(false);
        } else {
            setSelectHeaderCheck(newDataState.data
                .filter(a => a.end_date >= dateRange.start && a.end_date <= dateRange.end)
                .filter(item => !item.selected).length === 0);
        }
        gridStateRef.current = event.dataState;
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [assignments, filter]);

    useEffect(() => {
        const newDataState = process(
            assignments
                .filter(a => a.end_date >= dateRange.start && a.end_date <= dateRange.end)
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [assignments, filter]) // eslint-disable-line react-hooks/exhaustive-deps

    // cell and functions for selection
    const SelectCell = (props) => {
        const anchor = useRef(null);
        const [popoverShow, setPopoverShow] = useState(false);

        // show popover if there is an error on mouse over
        const onMouseOver = (ev) => {
            setPopoverShow(true);
        };

        // remove popover on mouse leave
        const onMouseOut = (ev) => {
            let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
            if (!isChildEl) setPopoverShow(false);
        };

        return <td
            ref={anchor}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {props.dataItem.disabled &&
                <KSMPopover
                    show={popoverShow}
                    anchor={anchor.current}
                    position={'right'}
                    type={'info'}
                    message={<div>
                        <b>{localization.toLanguageString(infoKey, mainMessages[siteLanguageDefault][infoKey])}</b>
                        <br/>
                        {localization.toLanguageString(productNotAvailable, mainMessages[siteLanguageDefault][productNotAvailable])}
                    </div>}
                />
            }
            <Checkbox
                type="checkbox"
                onChange={props.selectionChange}
                value={props.dataItem[props.field]}
                disabled={props.dataItem.disabled}
            />
        </td>
    }

    const onSelectionChange = useCallback((event) => {
        const checked = event.syntheticEvent.target.checked;
        let allChecked = true;
        let updates = assignments.map(item => {
            if (event.dataItem[uniqueId] === item[uniqueId]) {
                item.selected = checked;
            }
            if (!item.selected) {
                allChecked = false;
            }
            return item;
        })
        let dateFiltered = updates.filter(a => a.end_date >= dateRange.start && a.end_date <= dateRange.end);
        setSelectHeaderCheck(filterBy(dateFiltered, gridStateRef.current.filter).filter(item => !item.selected).length === 0);
        setAssignments(updates);
        calculateCost();
    }, [assignments, dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

    const onHeaderSelectionChange = useCallback(event => {
        const checked = event.nativeEvent.target.checked;
        let updates = assignments
            .map(item => {
                let dateFiltered = assignments.filter(a => a.end_date >= dateRange.start && a.end_date <= dateRange.end);
                for (const filtered of filterBy(dateFiltered, gridStateRef.current.filter)) {
                    if (item[uniqueId] === filtered[uniqueId] && !item.disabled) {
                        item.selected = checked;
                    }
                }
                return item;
            });
        if (updates.length !== 0) setSelectHeaderCheck(checked);
        setAssignments(updates);
        calculateCost();
    }, [assignments, dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

    const dateFilterFormat = useCallback(
        (calendarProps) => ({
            ...calendarProps,
            format: 'dd-MMM-yyyy',
        }),
        []
    );

    useEffect(() => {
        if (dateRange) {
            // filter grid based on date range and reset selections
            setFilter({
                logic: "and",
                filters: [
                    {
                        field: "end_date",
                        operator: "gte",
                        value: dateRange.start
                    },
                    {
                        field: "end_date",
                        operator: "lte",
                        value: dateRange.end
                    }
                ]
            });
            // reset to grid to page 1 on date range change
            setGridState(gridState => ({
                ...gridState,
                take: 10,
                skip: 0
            }));
        }
    }, [dateRange]);

    // set header check when date range changes
    useEffect(() => {
        let dateFiltered = assignments.filter(a => a.end_date >= dateRange.start && a.end_date <= dateRange.end);
        let selected = dateFiltered.filter(a => a.selected);
        selected.length === dateFiltered.length && selected.length > 0 ? setSelectHeaderCheck(true) : setSelectHeaderCheck(false);
    }, [dateRange]);

    return (
        <>
            <DatePickerPropsContext.Provider value={dateFilterFormat}>
                <Grid
                    className={'request-again-grid'}
                    scrollable={'none'}
                    data={dataState}
                    sortable={true}
                    rowRender={DisableRowRender}
                    //pagination
                    pageable={(assignments.filter(a => a.end_date >= dateRange.start && a.end_date <= dateRange.end).length <= initialGridState.take) ? false : {
                        buttonCount: 5,
                        pageSizes: [10, 20, 50, 100],
                    }}
                    onDataStateChange={onDataStateChange}
                    total={dataState.total}
                    //select checkboxes
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    onSelectionChange={onSelectionChange}
                    selectedField={'selected'}
                    {...gridState}
                >
                    <GridNoRecords>
                        {localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey])}
                    </GridNoRecords>
                    <GridColumn
                        field={'selected'}
                        cell={SelectCell}
                        headerSelectionValue={selectHeaderCheck}
                    />
                    <GridColumn
                        field="host_id"
                        title={localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="prod_num"
                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="description"
                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="qty"
                        title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                        columnMenu={ColumnMenu}
                        filter={'numeric'}
                        cell={TextAlignMiddleCell}
                    />
                    <GridColumn
                        field={'start_date'}
                        title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                        columnMenu={ColumnMenu}
                        cell={DateCell}
                        filter={'date'}
                    />
                    <GridColumn
                        field={'end_date'}
                        title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                        columnMenu={ColumnMenu}
                        cell={DateCell}
                        filter={'date'}
                    />
                    <GridColumn
                        field="days_left"
                        title={localization.toLanguageString(daysLeftKey, mainMessages[siteLanguageDefault][daysLeftKey])}
                        columnMenu={ColumnMenu}
                        filter={'numeric'}
                        cell={TextAlignMiddleCell}
                    />
                    <GridColumn
                        field="license_type"
                        title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="cost_per_unit"
                        title={localization.toLanguageString(costPerUnitKey, mainMessages[siteLanguageDefault][costPerUnitKey])}
                        columnMenu={ColumnMenu}
                        cell={CurrencyCell}
                    />
                    <GridColumn
                        field="total_cost"
                        title={localization.toLanguageString(totalCostKey, mainMessages[siteLanguageDefault][totalCostKey])}
                        columnMenu={ColumnMenu}
                        cell={CurrencyCell}
                    />
                </Grid>
            </DatePickerPropsContext.Provider>
        </>
    )
}

export const RequestAgainModalGrid = (props) => {
    const {siteLanguageDefault} = useContext(UserContext);
    const {assignments} = props;
    const localization = useLocalization();

    const initialGridState = {
        take: 10,
        skip: 0
    };
    const gridStateRef = useRef(initialGridState);
    const [gridState, setGridState] = useState(initialGridState);
    const [dataState, setDataState] = useState(
        process(assignments.map((item) => ({
            ...item
        })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(assignments.map((item) => ({
            ...item,
        })), event.dataState);
        gridStateRef.current = event.dataState;
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [assignments]);

    useEffect(() => {
        const newDataState = process(assignments.map((item) => ({
            ...item,
        })), gridState);
        setDataState(newDataState);
    }, [assignments]) // eslint-disable-line react-hooks/exhaustive-deps

    const dateFilterFormat = useCallback(
        (calendarProps) => ({
            ...calendarProps,
            format: 'dd-MMM-yyyy',
        }),
        []
    );

    return (
        <>
            <DatePickerPropsContext.Provider value={dateFilterFormat}>
                <Grid
                    className={'request-again-modal-grid'}
                    scrollable={'none'}
                    data={dataState.data}
                    sortable={true}
                    //pagination
                    pageable={(assignments.length <= initialGridState.take) ? false : {
                        buttonCount: 5,
                        pageSizes: [10, 20, 50, 100],
                    }}
                    onDataStateChange={onDataStateChange}
                    total={dataState.total}
                    {...gridState}
                >
                    <GridNoRecords>
                        {localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey])}
                    </GridNoRecords>
                    <GridColumn
                        field="host_id"
                        title={localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="prod_num"
                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="description"
                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="qty"
                        title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                        columnMenu={ColumnMenu}
                        filter={'numeric'}
                        cell={TextAlignMiddleCell}
                    />
                    <GridColumn
                        field={'start_date'}
                        title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                        columnMenu={ColumnMenu}
                        cell={DateCell}
                        filter={'date'}
                    />
                    <GridColumn
                        field={'end_date'}
                        title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                        columnMenu={ColumnMenu}
                        cell={DateCell}
                        filter={'date'}
                    />
                    <GridColumn
                        field="cost_per_unit"
                        title={localization.toLanguageString(costPerUnitKey, mainMessages[siteLanguageDefault][costPerUnitKey])}
                        columnMenu={ColumnMenu}
                        cell={CurrencyCell}
                    />
                    <GridColumn
                        field="total_cost"
                        title={localization.toLanguageString(totalCostKey, mainMessages[siteLanguageDefault][totalCostKey])}
                        columnMenu={ColumnMenu}
                        cell={CurrencyCell}
                    />
                </Grid>
            </DatePickerPropsContext.Provider>
        </>
    )
}