import React from 'react';

// react router
import {Link} from 'react-router-dom';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';

export const Text = props => {
    const localization = useLocalization();
    return localization.toLanguageString(props.textkey, props.textdefault);
};

export const Image = props => {
    const localization = useLocalization();
    return <img
        {...props}
        alt={localization.toLanguageString(props.textkey, props.textdefault)}
        title={localization.toLanguageString(props.textkey, props.textdefault)}
    />
}

export const AnchorWrap = (WrappedComponent) => {
    const Anchor = props => {
        const localization = useLocalization();
        if (props.hrefkey !== undefined && props.hrefdefault !== undefined) {
            return (
                <a
                    {...props}
                    href={localization.toLanguageString(props.hrefkey, props.hrefdefault)}
                    data-value={localization.toLanguageString(props.textkey, props.textdefault)}
                >
                    <WrappedComponent {...props}/>
                </a>
            );
        }
        return (
            <a
                {...props}
                data-value={localization.toLanguageString(props.textkey, props.textdefault)}
            >
                <WrappedComponent {...props}/>
            </a>
        );
    }
    return Anchor;
}

export const AnchorLink = (WrappedComponent) => {
    const Anchor = props => {
        const localization = useLocalization();
        if (props.hrefkey !== undefined && props.hrefdefault !== undefined) {
            return (
                <Link
                    {...props}
                    to={props.to}
                    target={props.target}
                    href={localization.toLanguageString(props.hrefkey, props.hrefdefault)}
                    data-value={localization.toLanguageString(props.textkey, props.textdefault)}
                >
                    <WrappedComponent {...props}/>
                </Link>
            );
        }
        return (
            <Link
                {...props}
                to={props.to}
                target={props.target}
                data-value={localization.toLanguageString(props.textkey, props.textdefault)}
            >
                <WrappedComponent {...props}/>
            </Link>
        );
    }
    return Anchor;
}