import React from 'react';

// kendo react
import {NumericTextBox} from '@progress/kendo-react-inputs';

//Custom cell and logic for Qty
export const QtyCell = (props) => {
    const field = props.field;
    const dataValue = props.dataItem[field];

    //bound input events and values to the Grid
    const onChange = (ev) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: ev.syntheticEvent,
                value: ev.target.value,
            });
        }
    };


    let style = {}

    //Show error handling when assign qty is empty
    let isError = props.dataItem.subPoolExceed

    //Outline border as red
    if (isError) {
        style = {
            border: '2px solid rgb(233, 0, 41)'
        }
    }

    return (
        <td>
            <NumericTextBox
                className={'ksm-grid-numeric-input'}
                style={style}
                onChange={onChange}
                value={dataValue}
                spinners={false}
                format={{
                    useGrouping: false,
                }}
            />
        </td>
    );
};