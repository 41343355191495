import React, {useContext} from "react";
import UserContext from "../../common/UserContext";

//components
import {DateCell, NoWrapCell, TextAlignMiddleCell} from "../../common/Grid";

//kendo
import {useLocalization} from "@progress/kendo-react-intl";
import {orderBy} from "@progress/kendo-data-query";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Tooltip} from "@progress/kendo-react-tooltip";

//multilingual
import {
    assignQtyKey,
    descriptionKey,
    licenseExpirationDateKey,
    mainMessages,
    productNumberKey,
    removeProductKey,
    supportExpirationDateKey
} from "../../../assets/text/MultilingualText";
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {DeleteIcon} from "../../common/icons";


export default function AssignedHostDelete(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        selectedProducts,
        setSelectedProducts,
        assignedProducts,
        setAssignedProducts,
    } = props

    /*
     * removeAssignedProduct() removes the assigned product from the host and readds the product to the product grid
     * @param {product} the product to be removed from the host
    */
    const removeAssignedProduct = (product) => {
        // update assigned products when removing a product from a host
        let assignedProductUpdates = [...assignedProducts];

        assignedProductUpdates.forEach((host, hostIndex) => {
            if (host.transaction_id === product.transaction_id) {
                // remove product from host's product list
                let productIndex = host.products.findIndex(assignedProduct => assignedProduct.unique_id === product.unique_id);
                host.products.splice(productIndex, 1);

                // delete host is there are no more products
                if (host.products.length === 0) {
                    assignedProductUpdates.splice(hostIndex, 1);
                }
            }
        })

        setAssignedProducts(assignedProductUpdates);

        // update the products for the products grid when removing a product from a host
        let productUpdates = [...selectedProducts];
        let index = productUpdates.findIndex(productUpdate => productUpdate.unique_id === product.unique_id);

        // add assigned qty to the product's qty in the products grid for an existing product
        // products are not shown on products grid if qty_available are 0
        if (index > -1) {
            productUpdates[index].qty_available += product.qty_requested
        }
        setSelectedProducts(productUpdates)
    }

    // delete cell with functionality to manipulate assigned products json state
    const deleteCell = (props) => {
        const {
            dataItem
        } = props

        return (<td>
            <Tooltip
                anchorElement="target"
                showCallout={true}
                parentTitle={true}
                openDelay={0}
                position="top"
            >
                <div
                    title={localization.toLanguageString(removeProductKey, mainMessages[siteLanguageDefault][removeProductKey])}
                    onClick={() => removeAssignedProduct(dataItem)}
                >
                    <DeleteIcon
                        className={'ksm-grid-icon'}
                    />
                </div>
            </Tooltip>
        </td>)
    }

    return (<PanelBar
            expandMode={'single'}
            className={'ksm-panelbar-default'}
        >
            {assignedProducts.map((host) =>
                host ? <PanelBarItem
                        title={
                            <div
                                className={"k-h5"}
                                style={{
                                    color: 'white',
                                    margin: '0 0 0 2px'
                                }}>
                                {host.panelBarTitle}
                            </div>
                        }
                        key={host.transaction_id}>
                        <Grid
                            className={"assign-products-panelbar-grid"}
                            data={orderBy(host.products, [{
                                field: "product_number", dir: "desc"
                            }])}
                            scrollable={'none'}
                            sortable={false}
                        >
                            <GridColumn
                                cell={deleteCell}
                                width={"40px"}
                            />
                            <GridColumn
                                field="product_number"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="product_desc"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                            />
                            <GridColumn
                                field="qty_requested"
                                title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                                cell={TextAlignMiddleCell}
                            />
                            <GridColumn
                                field="license_expiration_date"
                                title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                                cell={DateCell}
                            />
                            <GridColumn
                                field="support_expiration_date"
                                title={localization.toLanguageString(supportExpirationDateKey, mainMessages[siteLanguageDefault][supportExpirationDateKey])}
                                cell={DateCell}
                            />
                        </Grid>
                    </PanelBarItem>
                    :
                    <PanelBarItem/>
            )}
        </PanelBar>
    )
}