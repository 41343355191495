import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../../common/Alert.js';
import {EmailDialog} from '../../common/EmailModal.js';
import {LicenseDownloadPopover} from '../../RequestLicense/LicenseDownload/Popovers.js';
import {PanelbarHeader} from './PanelbarHeader';
import {downloadLicenses} from '../../common/utilities.js';
import {NoWrapCell} from "../../common/Grid";

// kendo react
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';
import {Grid, GridColumn} from "@progress/kendo-react-grid";

// multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    emailReceiveKey,
    emailSuccessKey,
    mainMessages,
    notGeneratedAvailableRedeemKey,
    redeemNotAvailableErrorKey,
    errorOccurredRequestKey,
    requestProcessingMessageKey,
    requestProcessingKey,
    cannotGenerateMessageKey,
    cannotGenerateKey,
    notGeneratedSupportAgentKey,
    generationTimeoutKey,
    descriptionKey,
    downloadLicenseKey,
    emailLicenseKey,
    orderNumberKey,
    productKey,
    qtyKey,
    licensesReadyKey,
    processingCompletedKey,
} from '../../../assets/text/MultilingualText.js';
import {Tooltip} from "@progress/kendo-react-tooltip";
import {downloadIcon, envelopeIcon} from "@progress/kendo-svg-icons";
import {DownloadIcon, IconButton, MailIcon} from "../../common/icons";


// context to store popover reference, when to show, and which popover to show
export const PopoverContext = createContext({
    onMouseOver: () => null,
});

function SubmitTransport(props) {
    const {
        setIsLoading,
        handleBeforeUnload,
        assigned,
        setAssigned,
        xAuthToken,
    } = props
    const {
        siteLanguageDefault,
    } = useContext(UserContext);

    const localization = useLocalization();

    const [showPopover, setShowPopover] = useState(false);
    const [popoverType, setPopoverType] = useState('generalError');
    const [popoverRef, setPopoverRef] = useState();
    const [showEmailModal, setShowEmailModal] = useState(false);

    const [disableEmailDownloadButtons, setDisableEmailDownloadButtons] = useState(true);

    const [showInProgress, setShowInProgress] = useState(true)
    const showInProgressHandler = () => {
        setShowInProgress(false);
    }

    const [showSuccess, setShowSuccess] = useState(false)
    const showSuccessHandler = () => {
        setShowSuccess(false);
    }

    const [showSupportError, setShowSupportError] = useState(false)
    const showSupportErrorHandler = () => {
        setShowSupportError(false);
    }

    const [showGenerationError, setShowGenerationError] = useState(false)
    const showGenerationErrorHandler = () => {
        setShowGenerationError(false);
    }

    const [showQtyError, setShowQtyError] = useState(false)
    const showQtyErrorHandler = () => {
        setShowQtyError(false);
    }

    const [showEntitlementError, setShowEntitlementError] = useState(false)
    const showEntitlementErrorHandler = () => {
        setShowEntitlementError(false);
    }

    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    //Show success alert
    useEffect(() => {
        const requiredCount = assigned.length;
        let successCount = 0

        assigned.forEach(host => {
            if (host.icons.download || host.icons.cloudAlert) {
                successCount++
            }
        })

        if (successCount === requiredCount) {
            setShowInProgress(false)
            setShowSuccess(true)
        }

    }, [assigned])


    //Request new license for every host in assign products state
    useEffect(() => {
        assigned.forEach((host, index) => {
            let headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-auth-token': xAuthToken
            };

            let returnProducts = []
            for (const item of host['products']) {
                returnProducts.push({
                    "product_id": item.product_id,
                    "product_number": item.prod_num,
                    "product_desc": item.prod_desc,
                    "license_id": item.license_id.toString(),
                    "seq_num": item.sn.toString(),
                    "confirmation_code": item.confirmation_code,
                    "hostid_type": item.host_id_type,
                    "qty_requested": item.qty_redeemed,
                    "target_orders": item.target_orders
                })

            }


            let data = {
                "module": "LICENSE",
                "sub_module": "TRANSPORT",
                "action": "REDEEM",
                "input_json": [
                    {
                        transaction_id: host['transaction_id'],
                        hostid_type: host['host_id_type'],
                        products: returnProducts,
                        host_id: host['host_id'][0],
                        serial_id: host['serial_id'],
                        order_id: null
                    }
                ]
            }

            let formData = new FormData();
            formData.append('File', null);
            formData.append('Data', JSON.stringify(data));

            let savedHost = assigned[index];
            axios.post(
                config.trial_license.GUEST_API,
                formData,
                {headers: headers,}
            )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data['license_generated'] === 'YES') {
                            let licenseType = response.data['license_type']
                            switch (licenseType) {
                                case "FILE":
                                case "KEY":
                                    savedHost['icons']['download'] = true;
                                    savedHost['icons']['loading'] = false;
                                    break;
                                case "CLOUD":
                                    savedHost['icons']['cloudAlert'] = true;
                                    savedHost['icons']['loading'] = false;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    setAssigned([...assigned]);
                })
                .catch((error) => {
                    console.log("ERROR: Failed to request new license", error);
                    setShowInProgress(false)
                    savedHost['icons']['loading'] = false;
                    if (!error.response) { //Internet disconnected
                        savedHost['icons']['generationError'] = true;
                        setShowGenerationError(true)
                    } else if (error.toString().includes('timeout')) { //Axios timeout
                        savedHost['icons']['supportError'] = true;
                        setShowSupportError(true)
                    } else {
                        if (error.response.status === 408 || error.response.status === 504) { //API & gateway timeout
                            savedHost['icons']['supportError'] = true;
                            setShowSupportError(true)
                        }
                        if (error.response.hasOwnProperty('data')) {
                            let errorCode = error.response.data['error_code'].toUpperCase();
                            switch (errorCode) {
                                case "MOD_LICENSE_228": //Plugin Fail
                                    savedHost['icons']['supportError'] = true;
                                    setShowSupportError(true)
                                    break;
                                case "MOD_LICENSE_220": //Qty consumed & user can retry
                                    savedHost['icons']['qtyError'] = true;
                                    setShowQtyError(true)
                                    break;
                                default: //Backend/DB error/rollback & user can retry
                                    savedHost['icons']['entitlementError'] = true;
                                    setShowEntitlementError(true)
                                    break
                            }
                        }
                    }
                    setAssigned([...assigned]);
                })
        })
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps


    const [downloadableTransactionIDs, setDownloadableTransactionIDs] = useState([]);


    //Keeps track of what transaction ids are downloadable
    useEffect(() => {
        let transactionIDs = [];
        assigned.forEach(host => {
            if (host.icons.download) {
                transactionIDs.push(host.transaction_id);
            }
        })
        setDownloadableTransactionIDs(transactionIDs);
    }, [assigned])

    //Download all license from all host with the download icon
    const downloadAllLicenses = () => {
        downloadLicenses(downloadableTransactionIDs, xAuthToken, setIsLoading, handleBeforeUnload, 'ONLINE_TRANSPORT_DOWNLOAD', true);
    }

    //Enable or disable email/download selected license button
    useEffect(() => {
        let notFinishedLoading = false;
        let isDownloadable = false;
        assigned.forEach(host => {
            //Enable buttons if all hosts are done loading and at least one downloadable host
            if (host.icons.loading) {
                notFinishedLoading = true;
            }

            if (host.icons.download) {
                isDownloadable = true;
            }
        })

        if (notFinishedLoading) {
            setDisableEmailDownloadButtons(true);
        } else {
            if (isDownloadable) {
                setDisableEmailDownloadButtons(false);
            } else {
                setDisableEmailDownloadButtons(true);
            }
        }
    }, [assigned])

    //Sets which popover to show and it's corresponding reference
    const handlePopoverMouseOver = useCallback(
        (event) => {
            if (event.show) {
                setShowPopover(true);
            } else {
                setShowPopover(false);
            }
            if (event.cloudAlert) {
                setPopoverType('cloudAlert');
            } else if (event.supportError) {
                setPopoverType('supportError');
            } else if (event.generationError) {
                setPopoverType('generationError');
            } else if (event.qtyError) {
                setPopoverType('qtyError');
            } else {
                setPopoverType('entitlementError');
            }

            setPopoverRef(event.popoverRef);
        },
        [setShowPopover, setPopoverType, setPopoverRef]
    );

    return (
        <>
            <PopoverContext.Provider
                value={{
                    onMouseOver: handlePopoverMouseOver
                }}
            >
                {assigned.map((item) => {
                    return (
                        <PanelBar
                            key={item.transaction_id}
                            expanded={['.0']}
                            className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                            isControlled={true}
                        >
                            <PanelBarItem
                                title={<PanelbarHeader
                                    hostJSON={item}
                                    setIsLoading={setIsLoading}
                                    handleBeforeUnload={handleBeforeUnload}
                                    showAssignedCount={false}
                                    xAuthToken={xAuthToken}
                                />}
                            >
                                <Grid
                                    className={'review-submit-ccl-gird'}
                                    scrollable={'none'}
                                    data={item.products}
                                >
                                    <GridColumn
                                        field="order_num"
                                        title={localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}
                                        cell={NoWrapCell}
                                    />
                                    <GridColumn
                                        field="prod_num"
                                        title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                                        cell={NoWrapCell}
                                    />
                                    <GridColumn
                                        field="prod_desc"
                                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                    />
                                    <GridColumn
                                        field="qty_redeemed"
                                        title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                    />
                                </Grid>
                            </PanelBarItem>
                        </PanelBar>
                    )
                })
                }
            </PopoverContext.Provider>
            <LicenseDownloadPopover
                type={popoverType}
                showPopover={showPopover}
                popoverRef={popoverRef}
            />
            <Row
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <Col/>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.938rem'
                        }}
                    >
                        {showInProgress && (
                            <Alert
                                type={'default'}
                                showHandler={showInProgressHandler}
                                title={localization.toLanguageString(requestProcessingKey, mainMessages[siteLanguageDefault][requestProcessingKey])}
                                message={localization.toLanguageString(requestProcessingMessageKey, mainMessages[siteLanguageDefault][requestProcessingMessageKey])}
                            />
                        )}

                        {showSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={showSuccessHandler}
                                title={localization.toLanguageString(processingCompletedKey, mainMessages[siteLanguageDefault][processingCompletedKey])}
                                message={localization.toLanguageString(licensesReadyKey, mainMessages[siteLanguageDefault][licensesReadyKey])}
                            />
                        )}

                        {showSupportError && (
                            <Alert
                                type={'error'}
                                showHandler={showSupportErrorHandler}
                                title={localization.toLanguageString(generationTimeoutKey, mainMessages[siteLanguageDefault][generationTimeoutKey])}
                                message={localization.toLanguageString(notGeneratedSupportAgentKey, mainMessages[siteLanguageDefault][notGeneratedSupportAgentKey])}
                            />
                        )}

                        {showGenerationError && (
                            <Alert
                                type={'error'}
                                showHandler={showGenerationErrorHandler}
                                title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                                message={localization.toLanguageString(redeemNotAvailableErrorKey, mainMessages[siteLanguageDefault][redeemNotAvailableErrorKey])}
                            />
                        )}

                        {showQtyError && (
                            <Alert
                                type={'error'}
                                showHandler={showQtyErrorHandler}
                                title={localization.toLanguageString(cannotGenerateKey, mainMessages[siteLanguageDefault][cannotGenerateKey])}
                                message={localization.toLanguageString(cannotGenerateMessageKey, mainMessages[siteLanguageDefault][cannotGenerateMessageKey])}
                            />
                        )}

                        {showEntitlementError && (
                            <Alert
                                type={'error'}
                                showHandler={showEntitlementErrorHandler}
                                title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                                message={localization.toLanguageString(notGeneratedAvailableRedeemKey, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey])}
                            />
                        )}

                        {showEmailSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={alertEmailSuccessHandler}
                                title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                            />
                        )}
                    </div>
                </Col>
                <Col>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '0.5rem',
                        float: 'right',
                    }}>
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(emailLicenseKey, mainMessages[siteLanguageDefault][emailLicenseKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                disabled={disableEmailDownloadButtons}
                                onClick={() => {
                                    setShowEmailModal(true)
                                }}
                                Icon={MailIcon}
                            />
                        </Tooltip>

                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(downloadLicenseKey, mainMessages[siteLanguageDefault][downloadLicenseKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                onClick={downloadAllLicenses}
                                disabled={disableEmailDownloadButtons}
                                Icon={DownloadIcon}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </Row>
            {showEmailModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailModal}
                    setShowEmailSuccess={setShowEmailSuccess}
                    transactionIDs={downloadableTransactionIDs}
                    setIsLoading={setIsLoading}
                    request_source='ONLINE_TRANSPORT_EMAIL'
                    publicCall={true}
                    xAuthToken={xAuthToken}
                />
            )}
        </>
    );
}

export default SubmitTransport;
