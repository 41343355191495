import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import uuid from 'react-uuid';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';
import {useHistory} from "react-router-dom";

// css
import '../assets/css/RenewNemo.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../components/common/Spinner.js';
import PageHeader from '../components/common/PageHeader.js';
import KSMStepper from '../components/common/Stepper.js';
import Alert from '../components/common/Alert.js';
import {DateCell, NoWrapCell} from '../components/common/Grid.js';
import {EmailDialog} from '../components/common/EmailModal.js';
import {downloadLicenses} from '../components/common/utilities.js';

// kendo react
import {Upload} from '@progress/kendo-react-upload';
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {orderBy} from '@progress/kendo-data-query';
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    addC2vFileKey,
    c2vUploadAppliedKey,
    contactUsHeaderKey,
    currentSupportEndDate,
    descriptionKey,
    downloadAllLicensesKey,
    emailReceiveKey,
    emailSuccessKey,
    generationTimeoutKey,
    generationTimeoutMessageV2Key,
    genericErrorTitleKey,
    hostKey,
    invalidFileKey,
    licenseDownloadKey,
    licenseRenewedKey,
    licenseRenewedSuccessKey,
    mailAllKey,
    mainMessages,
    navigationAlertKey,
    nemoSupportRenewalKey,
    newSupportEndDateKey,
    noDataAvailableKey,
    noPendingRenewalsKey,
    pendingRenewalKey,
    productNumberKey,
    renewNemoNoMatchFileMatchMessageErrorKey,
    renewNemoNoMatchPleaseProvideMessageErrorKey,
    renewMoreSubKey,
    requestRenewalKey,
    uploadC2vKey,
    validC2vKey
} from '../assets/text/MultilingualText.js';
import {Tooltip} from "@progress/kendo-react-tooltip";
import {DownloadIcon, IconButton, MailIcon} from "../components/common/icons";


const initialSort = [
    {
        field: "product_number",
        dir: "desc"
    }
];

function RenewNemo(props) {
    const {
        accessToken,
        siteLanguage,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const defaultGridMessage = localization.toLanguageString(
        noDataAvailableKey,
        mainMessages[siteLanguageDefault][noDataAvailableKey]
    );

    const [sort, setSort] = useState(initialSort);
    const [isLoading, setIsLoading] = useState(false);
    const [gridNoRecordsMessage] = useState(defaultGridMessage);
    const [isDisabled, setIsDisabled] = useState(true);

    const [alerts, setAlerts] = useState([]);

    const nemoAnchor = useRef(null);
    const [hostHeader, setHostHeader] = useState("");
    const [hostID, setHostID] = useState("");
    const [hostFile, setHostFile] = useState(null);
    const [renewals, setRenewals] = useState([]);
    const [transactionID, setTransactionID] = useState([]);
    const [showNemoSuccess, setShowNemoSuccess] = useState(false);

    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [disableEmailDownloadButton, setDisableEmailDownloadButton] = useState(true);

    const [stepperItems, setStepperItems] = useState([
        {
            label: localization.toLanguageString(uploadC2vKey, mainMessages[siteLanguageDefault][uploadC2vKey]),
            number: 1,
            disabled: false
        },
        {
            label: localization.toLanguageString(requestRenewalKey, mainMessages[siteLanguageDefault][requestRenewalKey]),
            number: 2,
            disabled: true
        },
        {
            label: localization.toLanguageString(licenseDownloadKey, mainMessages[siteLanguageDefault][licenseDownloadKey]),
            number: 3,
            disabled: true
        }
    ]);
    const [stepValue, setStepValue] = useState(0);

    //Changes step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {
        setAlerts([]);
        setShowEmailSuccess(false);

        //Change from Upload C2V to Request Renewal step
        if (stepValue === 0) {
            if (stepChange === 1) {
                stepperItems[stepChange].disabled = false;
            }
            stepperItems[2].disabled = true;
        }

        //Change from Request Renewal to License Download Step
        if (stepValue === 1) {
            if (stepChange === 0) {
                stepperItems[1].disabled = true;
            }
            if (stepChange === 2) {
                stepperItems[stepChange].disabled = false;
                stepperItems[1].disabled = true;
            }
        }

        //Change from License Download Step
        if (stepValue === 2) {
            if (stepChange === 0) {
                stepperItems[2].disabled = true;
            }
        }

        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems([
            {
                label: localization.toLanguageString(uploadC2vKey, mainMessages[siteLanguageDefault][uploadC2vKey]),
                number: 1,
                disabled: false
            },
            {
                label: localization.toLanguageString(requestRenewalKey, mainMessages[siteLanguageDefault][requestRenewalKey]),
                number: 2,
                disabled: true
            },
            {
                label: localization.toLanguageString(licenseDownloadKey, mainMessages[siteLanguageDefault][licenseDownloadKey]),
                number: 3,
                disabled: true
            }
        ])
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    // closes the success nemo alert
    const alertNemoSuccessHandler = () => {
        setShowNemoSuccess(false);
    }

    // closes the success email alert
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    /*
     * onNemoFileUpload() uploads the host file
    */
    const onNemoFileUpload = (event) => {
        let extension = event.target.files[0].extension;

        changeStepActivation(0);
        if (extension === ".c2v") {
            validateNemoFile(event.target.files[0].getRawFile(), extension);
        } else {
            showError("fileType", undefined);
        }
    };

    /*
     * validateNemoFile() validates the uploaded .c2v file
     * @param {file} the uploaded file the be validated
     * @param {extension} the file extension
    */
    const validateNemoFile = (file, extension) => {
        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };

        let formData = new FormData();
        formData.append('File', file);

        setAlerts([]);
        setIsLoading(true);

        axios.post(
            config.renew_nemo.NEMO_VALIDATION,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    let hostId = data.host_id;
                    let pendingRenewals = data.renewals;

                    let headerPrefix = localization.toLanguageString(
                        hostKey,
                        mainMessages[siteLanguageDefault][hostKey]
                    );
                    let headerSuffix = localization.toLanguageString(
                        c2vUploadAppliedKey,
                        mainMessages[siteLanguageDefault][c2vUploadAppliedKey]
                    );
                    let hostHeader = headerPrefix + hostId + headerSuffix;

                    setHostHeader(hostHeader);
                    setHostID(hostId);
                    setHostFile(file);
                    setRenewals(pendingRenewals);
                    setTransactionID(uuid());

                    // update stepper
                    changeStepActivation(1);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Nemo Validation File", error);
                let type;
                let status = typeof error?.response?.status === 'undefined' ? "" : error.response.status;

                if (status === 400) {
                    type = "noMatch";
                } else if (status === 500) {
                    type = "database";
                } else {
                    type = "generic";
                }

                showError(type, extension);
                setHostFile(null);
                setHostID('');
                setTransactionID('');
                setIsLoading(false);

                // handle no network
                if (!error.response) {
                    setIsLoading(false);
                    showError("generic", undefined);
                }
            });
    }

    /*
     * requestRenewal() requests the nemo licenses to be renewed
    */
    const requestRenewal = () => {
        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };

        let formData = new FormData();
        formData.append('File', hostFile);

        let data = {
            "transaction_id": transactionID,
            "host_id": hostID,
            "renewals": renewals,
        };

        formData.append('Data', JSON.stringify(data))

        setAlerts([]);
        setIsLoading(true);

        axios.post(
            config.renew_nemo.REQUEST_RENEWAL,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    // enable renew more renewals button
                    response.data["pending_renewal_count"] === "TRUE" ? setIsDisabled(false) : setIsDisabled(true);

                    // update stepper, enable buttons, show success
                    changeStepActivation(2);
                    setDisableEmailDownloadButton(false);
                    showSuccess();
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Request Nemo Renewal", error);
                let status = typeof error?.response?.status === 'undefined' ? "" : error.response.status;

                // 1. timeout error: move to step 3 + show timeout error
                // 2. other error: remain on step 2 + show generic error
                if (status === 400) {
                    showError("generic", undefined);
                } else if (status === 408) {
                    changeStepActivation(2);
                    showError("timeout", undefined);
                } else if (status === 500) {
                    showError("database");
                } else {
                    showError("generic", undefined);
                }

                setDisableEmailDownloadButton(true);
                setIsLoading(false);

                // handle no network
                if (!error.response) {
                    setIsLoading(false);
                    showError("generic", undefined);
                }
            });
    }

    /*
     * showError() shows an error notification
     * @param {type} the type of error
     *  type: noMatch, timeout, generic
     * @param {extension} the file extension for noMatch errors
    */
    const showError = (type, extension) => {
        let errorTitleKey = type === "noMatch" ? noPendingRenewalsKey
            : type === "fileType" ? invalidFileKey
                : type === "timeout" ? generationTimeoutKey
                    : genericErrorTitleKey
        let errorTitle = localization.toLanguageString(
            errorTitleKey,
            mainMessages[siteLanguageDefault][errorTitleKey]
        );

        let errorMessageKey = type === "timeout" ? generationTimeoutMessageV2Key
            : type === "fileType" ? validC2vKey
                : contactUsHeaderKey;
        let errorMessage = localization.toLanguageString(
            errorMessageKey,
            mainMessages[siteLanguageDefault][errorMessageKey]
        );

        if (type === "noMatch") {
            let pleaseProvideErrorMessage = localization.toLanguageString(
                renewNemoNoMatchPleaseProvideMessageErrorKey,
                mainMessages[siteLanguageDefault][renewNemoNoMatchPleaseProvideMessageErrorKey]
            );
            let fileMatchErrorMessage = localization.toLanguageString(
                renewNemoNoMatchFileMatchMessageErrorKey,
                mainMessages[siteLanguageDefault][renewNemoNoMatchFileMatchMessageErrorKey]
            );
            let extensionMessage = extension ? extension.toUpperCase().replaceAll('.', '') : "";

            errorMessage = pleaseProvideErrorMessage + extensionMessage + fileMatchErrorMessage;
        }

        let error = {
            type: "error",
            title: errorTitle,
            message: errorMessage
        };

        // if all values in the error object are not null, render the error
        if (!Object.values(error).every(e => !e)) {
            setAlerts(alerts => [...alerts, error]);
        }
    }

    /*
     * showSuccess() shows a success notification
    */
    const showSuccess = () => {
        let successTitle = localization.toLanguageString(
            licenseRenewedSuccessKey,
            mainMessages[siteLanguageDefault][licenseRenewedSuccessKey]
        );
        let successMessage = localization.toLanguageString(
            licenseRenewedKey,
            mainMessages[siteLanguageDefault][licenseRenewedKey]
        );

        let success = {
            type: "success",
            title: successTitle,
            message: successMessage
        };

        setShowNemoSuccess(true);
        setAlerts(alerts => [...alerts, success]);
    }

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Col>
                        <div className={"k-h3"}>
                            <Text
                                textkey={nemoSupportRenewalKey}
                                textdefault={mainMessages[siteLanguageDefault][nemoSupportRenewalKey]}
                            />
                        </div>
                    </Col>
                    <Col>
                        <KSMStepper
                            stepperItems={stepperItems}
                            stepValue={stepValue}
                            changeStepActivation={changeStepActivation}
                        />
                    </Col>
                    <Col/>
                </Row>
                <div
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    {((stepValue === 0) || (stepValue === 1)) && (
                        <Upload
                            className="ksm-upload"
                            batch={false}
                            multiple={false}
                            withCredentials={false}
                            defaultFiles={[]}
                            restrictions={{
                                allowedExtensions: [".c2v"]
                            }}
                            saveUrl={config.utilities.health}
                            onAdd={onNemoFileUpload}
                            selectMessageUI={() =>
                                <Button
                                    ref={nemoAnchor}
                                    themeColor={"primary"}
                                    size={"large"}
                                    fillMode={"solid"}
                                    type={"button"}
                                >
                                    {localization.toLanguageString(addC2vFileKey, mainMessages[siteLanguageDefault][addC2vFileKey])}
                                </Button>
                            }
                        />
                    )}
                </div>
                {(stepValue === 1 || stepValue === 2) && (
                    <>
                        <Row>
                            <div
                                className={"k-h5"}
                                style={{
                                    marginBottom: '0.938rem'
                                }}
                            >
                                <Text
                                    textkey={pendingRenewalKey}
                                    textdefault={mainMessages[siteLanguageDefault][pendingRenewalKey]}
                                />
                            </div>
                        </Row>
                        <PanelBar
                            className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                            expanded={['.0']}
                            isControlled={true}
                        >
                            <PanelBarItem
                                title={
                                    <div
                                        className={"k-h5"}
                                        style={{
                                            color: 'white',
                                            margin: '0 0 0 2px',
                                        }}>
                                        {hostHeader}
                                    </div>
                                }
                            >
                                <Grid
                                    className={"renew-nemo-grid"}
                                    data={orderBy(renewals, sort)}
                                    sortable={true}
                                    scrollable={"none"}
                                    sort={sort}
                                    onSortChange={(e) => {
                                        setSort(e.sort);
                                    }}
                                >
                                    <GridNoRecords>
                                        {gridNoRecordsMessage}
                                    </GridNoRecords>
                                    <GridColumn
                                        field="product_number"
                                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                        cell={NoWrapCell}
                                    />
                                    <GridColumn
                                        field="description"
                                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                        sortable={false}
                                    />
                                    <GridColumn
                                        field="current_support_date"
                                        title={localization.toLanguageString(currentSupportEndDate, mainMessages[siteLanguageDefault][currentSupportEndDate])}
                                        sortable={false}
                                        cell={DateCell}
                                    />
                                    <GridColumn
                                        field="new_support_date"
                                        title={localization.toLanguageString(newSupportEndDateKey, mainMessages[siteLanguageDefault][newSupportEndDateKey])}
                                        sortable={false}
                                        cell={DateCell}
                                    />
                                </Grid>
                            </PanelBarItem>
                        </PanelBar>
                    </>
                )}
                <Row
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Col>
                        {(stepValue === 2) && (
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                shape={"rectangle"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                disabled={isDisabled}
                                onClick={() => changeStepActivation(0)}
                            >
                                {localization.toLanguageString(renewMoreSubKey, mainMessages[siteLanguageDefault][renewMoreSubKey])}
                            </Button>
                        )}
                    </Col>
                    <Col>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.938rem',
                            }}
                        >
                            {alerts.length ? (alerts.map((alert, index) => {
                                    if (alert.type === "error") {
                                        return (
                                            <div key={'error-' + index}>
                                                <Alert
                                                    type={'error'}
                                                    title={alert.title}
                                                    message={alert.message}
                                                />
                                            </div>
                                        )
                                    } else if (alert.type === "success") {
                                        return (
                                            showNemoSuccess && <div key={'success-' + index}>
                                                <Alert
                                                    type={'success'}
                                                    showHandler={alertNemoSuccessHandler}
                                                    title={alert.title}
                                                    message={alert.message}
                                                />
                                            </div>
                                        )
                                    } else {
                                        return <></>
                                    }
                                }))
                                :
                                <></>}
                            {showEmailSuccess && (
                                <Alert
                                    type={'success'}
                                    showHandler={alertEmailSuccessHandler}
                                    title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                    message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                                />
                            )}
                        </div>
                    </Col>
                    <Col>
                        {(stepValue === 1) && (
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                shape={"rectangle"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                onClick={requestRenewal}
                                style={{float: 'right'}}
                            >
                                {localization.toLanguageString(requestRenewalKey, mainMessages[siteLanguageDefault][requestRenewalKey])}
                            </Button>
                        )}
                        {stepValue === 2 && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    flexWrap: 'wrap',
                                    gap: '0.5rem'
                                }}
                            >
                                <Tooltip
                                    anchorElement="target"
                                    showCallout={true}
                                    parentTitle={true}
                                    openDelay={0}
                                    position="left"
                                >
                                    <IconButton
                                        title={localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
                                        themeColor={"primary"}
                                        fillMode={"solid"}
                                        size={"large"}
                                        disabled={disableEmailDownloadButton}
                                        onClick={() => {
                                            setShowEmailModal(true);
                                        }}
                                        Icon={MailIcon}
                                    />
                                </Tooltip>
                                <Tooltip
                                    anchorElement="target"
                                    showCallout={true}
                                    parentTitle={true}
                                    openDelay={0}
                                    position="left"
                                >
                                    <IconButton
                                        title={localization.toLanguageString(downloadAllLicensesKey, mainMessages[siteLanguageDefault][downloadAllLicensesKey])}
                                        themeColor={"primary"}
                                        fillMode={"solid"}
                                        size={"large"}
                                        onClick={() => {
                                            downloadLicenses([transactionID], accessToken, setIsLoading, handleBeforeUnload);
                                        }}
                                        disabled={disableEmailDownloadButton}
                                        Icon={DownloadIcon}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </Col>
                    {showEmailModal && (
                        <EmailDialog
                            setShowEmailModal={setShowEmailModal}
                            setShowEmailSuccess={setShowEmailSuccess}
                            transactionIDs={[transactionID]}
                            setIsLoading={setIsLoading}
                            request_source='RENEW_NEMO'
                        />
                    )}
                </Row>
                <br/>
            </div>
        </>
    );
}

export default RenewNemo;
